// eslint-disable-next-line import/prefer-default-export
export const facilitySearchScreenColumnDefs = [
  {
    headerName: 'Facility',
    children: [
      {
        field: 'facilityNumber',
        headerName: 'Number',
        sortable: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        field: 'operator.name',
        headerName: 'Operator',
        sortable: true,
      },
      {
        field: 'franchisor.name',
        headerName: 'Franchisor',
        sortable: true,
      },
      {
        field: 'type.label',
        headerName: 'Type',
        sortable: true,
      },
      {
        field: 'city',
        headerName: 'City',
        sortable: true,
      },
      {
        field: 'region',
        headerName: 'Region',
        sortable: true,
      },
    ],
  },
];
