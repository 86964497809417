import { lazy } from 'react';
import i18next from 'i18next';

import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

const ProductModels = lazy(() => import('./ProductModels'));

i18next.addResourceBundle('en', 'productModelsPage', en);
i18next.addResourceBundle('tr', 'productModelsPage', tr);
i18next.addResourceBundle('ar', 'productModelsPage', ar);

const ProductModelsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'product-models',
      element: <ProductModels />,
    },
  ],
};

export default ProductModelsConfig;
