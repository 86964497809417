import { createSlice } from '@reduxjs/toolkit';

const stateSlice = createSlice({
  name: 'globalSearchBar/state',
  initialState: false,
  reducers: {
    toggleGlobalSearchBar: (state, action) => !state,
    openGlobalSearchBar: (state, action) => true,
    closeGlobalSearchBar: (state, action) => false,
  },
});

export const { toggleGlobalSearchBar, openGlobalSearchBar, closeGlobalSearchBar } =
  stateSlice.actions;

export const selectGlobalSearchBarState = ({ globalSearchBar }) => globalSearchBar.state;

export default stateSlice.reducer;
