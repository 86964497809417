import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonCurrencyField } from 'app/shared-components/Common';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_DATA,
  UPDATE_WORK_ORDER_ISSUE_NTE,
} from './queries';

const defaultValues = {
  notToExceedAmount: null,
};

const schema = yup.object().shape({
  notToExceedAmount: yup.number().nullable().min(0.01, 'A value must be provided.'),
});

const WorkOrderIssueNteUpdateDialog = ({ serviceTicketId, workOrderIssueId, onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [
    fetchWorkOrderIssueNteUpdateDialogData,
    {
      data: workOrderIssueNteUpdateDialogData,
      loading: workOrderIssueNteUpdateDialogLoading,
      refetch: workOrderIssueNteUpdateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Work Order Issue Data',
          variant: 'error',
        })
      );
    },
  });

  const [updateWorkOrderIssueNte, { loading: updateWorkOrderIssueNteLoading }] = useMutation(
    UPDATE_WORK_ORDER_ISSUE_NTE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Successfully Updated',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Updating Work Order Issue', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [updateWorkOrderIssueNteLoading, workOrderIssueNteUpdateDialogLoading].includes(true),
    [updateWorkOrderIssueNteLoading, workOrderIssueNteUpdateDialogLoading]
  );

  useEffect(() => {
    if (workOrderIssueNteUpdateDialogData?.workOrderIssue) {
      setValue(
        'notToExceedAmount',
        workOrderIssueNteUpdateDialogData?.workOrderIssue.notToExceedAmount
      );
    }
  }, [setValue, workOrderIssueNteUpdateDialogData?.workOrderIssue]);

  useEffect(() => {
    if (workOrderIssueId) {
      fetchWorkOrderIssueNteUpdateDialogData({
        variables: { where: { id: workOrderIssueId } },
      });
    }
  }, [fetchWorkOrderIssueNteUpdateDialogData, workOrderIssueId]);

  const onSubmit = async (data) => {
    try {
      await updateWorkOrderIssueNte({
        variables: {
          where: { id: serviceTicketId },
          data: { id: workOrderIssueId, ...data },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Update Work Order Issue
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-update-nte-form"
            name="work-order-issue-update-nte-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="notToExceedAmount"
              render={({ field }) => (
                <CommonCurrencyField
                  {...field}
                  className="mb-24"
                  control={control}
                  disabled={false}
                  error={errors?.notToExceedAmount}
                  placeholder="Enter Not To Exceed..."
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-update-nte-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueNteUpdateDialog;
