import {
  faBookmark,
  faEye,
  faLock,
  faLockOpen,
  faTableColumns,
  faThumbTack,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItemAvatar, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { useCallback, useMemo } from 'react';

const UserSearchViewHitsListOption = ({ activeView, hit, onClick }) => {
  const selected = useMemo(() => activeView?.id === hit?.id, [activeView, hit]);

  const handleClick = useCallback(
    (params) => {
      if (typeof onClick === 'function') {
        onClick(params);
      }
    },
    [onClick]
  );

  const ListOption = useMemo(() => {
    if (!hit?.id) return null;

    return (
      <ListItemButton
        key={hit.id}
        selected={selected}
        onClick={() => {
          if (!selected) {
            handleClick(hit);
          }
        }}
      >
        <ListItemAvatar>
          {selected ? (
            <Avatar className="bg-light-blue-600">
              <FontAwesomeIcon icon={faEye} size="sm" />
            </Avatar>
          ) : (
            <Avatar>
              <FontAwesomeIcon icon={faTableColumns} size="sm" />
            </Avatar>
          )}
        </ListItemAvatar>

        <ListItemText
          primary={hit.name}
          primaryTypographyProps={{ className: 'mr-40', noWrap: true }}
          secondary={hit.user?.name}
          secondaryTypographyProps={{ className: 'mr-40', noWrap: true }}
        />

        {hit.isDefault && (
          <div className="ml-12">
            <Tooltip title="Default View">
              <FontAwesomeIcon
                className="text-purple-600"
                icon={faBookmark}
                style={{ width: '18px', height: '18px' }}
              />
            </Tooltip>
          </div>
        )}

        {hit.isPinned && (
          <div className="ml-12">
            <Tooltip title="Pinned View">
              <FontAwesomeIcon
                className="text-amber-600"
                icon={faThumbTack}
                style={{ width: '18px', height: '18px' }}
              />
            </Tooltip>
          </div>
        )}

        {hit.commonSharingRule?.type?.enum === 'READ_ONLY' && (
          <div className="ml-12">
            <Tooltip title="Read Only">
              <FontAwesomeIcon
                className="text-green-600"
                icon={faLockOpen}
                style={{ width: '18px', height: '18px' }}
              />
            </Tooltip>
          </div>
        )}

        {hit.commonSharingRule?.type?.enum === 'PRIVATE' && (
          <div className="ml-12">
            <Tooltip title="Private">
              <FontAwesomeIcon
                className="text-blue-600"
                icon={faLock}
                style={{ width: '18px', height: '18px' }}
              />
            </Tooltip>
          </div>
        )}
      </ListItemButton>
    );
  }, [handleClick, hit, selected]);

  return ListOption;
};

export default UserSearchViewHitsListOption;
