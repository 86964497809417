import { gql } from '@apollo/client';
import { USER_SEARCH_VIEW_UPDATE_SETTINGS_DIALOG_USER_SEARCH_VIEW_FRAGMENT } from './fragments';

export const FETCH_USER_SEARCH_VIEW_UPDATE_SETTINGS_DIALOG_DATA = gql`
  query FetchUserSearchViewUpdateSettingsDialogData($where: UserSearchViewWhereUniqueInput!) {
    userSearchView(where: $where) {
      id
      ...UserSearchViewUpdateSettingsDialogUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_UPDATE_SETTINGS_DIALOG_USER_SEARCH_VIEW_FRAGMENT}
`;

export const UPDATE_USER_SEARCH_VIEW_SETTINGS = gql`
  mutation UpdateUserSearchViewSettings(
    $where: UserSearchViewWhereUniqueInput!
    $data: UserSearchViewUpdateSettingsInput!
  ) {
    updateUserSearchViewSettings(where: $where, data: $data) {
      id
      ...UserSearchViewUpdateSettingsDialogUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_UPDATE_SETTINGS_DIALOG_USER_SEARCH_VIEW_FRAGMENT}
`;
