import { InputAdornment, TextField } from '@mui/material';
import { forwardRef } from 'react';
import { useWatch } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

// ROADMAP: Change Name => CommonNumberField
const CommonCurrencyField = forwardRef(
  (
    {
      className,
      control,
      disabled,
      displayMode = 'amount',
      error,
      placeholder,
      onChange,
      ...field
    },
    ref
  ) => {
    const value = useWatch({ control, name: field.name });

    return (
      <NumericFormat
        {...field}
        className={className}
        customInput={TextField}
        decimalScale={2}
        disabled={disabled}
        error={!!error}
        fullWidth
        getInputRef={ref}
        helperText={error?.message}
        InputProps={{
          endAdornment: displayMode === 'percentage' && (
            <InputAdornment position="end">%</InputAdornment>
          ),
          startAdornment: displayMode === 'amount' && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
        placeholder={placeholder}
        thousandSeparator
        valueIsNumericString
        variant="outlined"
        value={value || undefined}
        onValueChange={({ floatValue, formattedValue }) => {
          onChange(floatValue);
        }}
      />
    );
  }
);

export default CommonCurrencyField;
