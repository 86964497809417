import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const IssueTemplatesViewPage = lazy(() => import('./IssueTemplatesViewPage'));
const issueTemplatesViewPageBase = '/issue-templates';

// i18next.addResourceBundle('en', 'issueTemplatesViewPage', en);
// i18next.addResourceBundle('tr', 'issueTemplatesViewPage', tr);
// i18next.addResourceBundle('ar', 'issueTemplatesViewPage', ar);

const issueTemplatesViewPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${issueTemplatesViewPageBase}/view/:issueTemplateId/*`,
      element: <IssueTemplatesViewPage />,
    },
    {
      path: issueTemplatesViewPageBase,
      element: <Navigate to={`${issueTemplatesViewPageBase}/search`} />,
    },
  ],
};

export default issueTemplatesViewPageConfig;
