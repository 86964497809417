import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_SCHEDULE_SITE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const FETCH_WORK_ORDER_ISSUE_SCHEDULE_SITE_VISIT_DIALOG_DATA = gql`
  query FetchWorkOrderIssueScheduleSiteVisitDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueScheduleSiteVisitDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_SCHEDULE_SITE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const SCHEDULE_SITE_VISIT_WORK_ORDER_ISSUE = gql`
  mutation ScheduleSiteVisitWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueScheduleSiteVisitInput!
  ) {
    scheduleSiteVisitWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueScheduleSiteVisitDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_SCHEDULE_SITE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
