import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

const CommonImageLightbox = ({ open, slides, onClose }) => {
  // PLACEHOLDER

  return (
    <Lightbox
      carousel={{ finite: slides?.length <= 1 }}
      close={onClose}
      open={open}
      plugins={[Fullscreen, Zoom]}
      render={{
        buttonPrev: slides?.length <= 1 ? () => null : undefined,
        buttonNext: slides?.length <= 1 ? () => null : undefined,
      }}
      slides={slides}
    />
  );
};

export default CommonImageLightbox;
