import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_ISSUE_ATTACHMENT_DELETE_DIALOG_SERVICE_ISSUE_FRAGMENT = gql`
  fragment ServiceIssueAttachmentDeleteDialogServiceIssueFragment on ServiceIssue {
    id
    attachments {
      id
      createdAt
      commonStoredImage {
        id
        commonStoredUpload {
          id
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredType {
        id
        enum
        name
      }
      commonStoredPdf {
        id
        commonStoredUpload {
          id
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        originalPdfUrl
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredVideo {
        id
        captionsFileUrl
        commonStoredUpload {
          id
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        playlistFileUrl
        standardImageUrl
        thumbnailImageUrl
      }
      isCompletionAttachment
    }
  }
`;
