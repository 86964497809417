import React from 'react';
import { __ApolloHeartbeatContext } from './ApolloHeartbeatProvider';

const useApolloHeartbeat = () => {
  const context = React.useContext(__ApolloHeartbeatContext);

  if (context === undefined) {
    throw new Error('useApolloHeartbeat must be used within a ApolloHeartbeatProvider');
  }

  return context;
};

export default useApolloHeartbeat;
