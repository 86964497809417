import { forwardRef, useEffect, useRef, useState } from 'react';
import _ from '@lodash';
import clsx from 'clsx';
import { Button, Popover, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Keyboard from 'react-simple-keyboard';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';

const CommonNumericKeyboard = forwardRef(
  ({ error, name, placeholder, type = 'password', value, /*onChange,*/ }, rootRef) => {
    const [formattedValue, setFormattedValue] = useState('');
    const [inputValue, setInputValue] = useState(value);
    const inputMaskTimer = useRef(null);
    const [keyboardMenuAnchorEl, setKeyboardMenuAnchorEl] = useState(null);
    const keyboardMenuOpen = Boolean(keyboardMenuAnchorEl);
    const keyboardRef = useRef(null);
    const mainTheme = useSelector(selectMainTheme);

    // TODO: Add to CommonDatepicker / CommonDropzone
    useEffect(() => {
      const keyboard = keyboardRef.current;

      return () => {
        console.log('// FIXME: CommonNumericKeyboard Keyboard Cleanup', { keyboard });
        keyboard?.destroy();
      };
    }, []);

    useEffect(() => {
      const handleKeyDown = (event) => {
        // const layoutKeyName =
        //   keyboardRef.current?.getSimpleKeyboardLayoutKey(event);

        console.log('// FIXME:', { event, layoutKeyName: null });
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        console.log('// FIXME: CommonNumericKeyboard Event Cleanup');
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    useEffect(() => {
      if (type !== 'password') {
        return setFormattedValue(inputValue);
      }

      if (inputMaskTimer.current) {
        clearTimeout(inputMaskTimer.current);
      }

      const inputValues = inputValue?.split('');
      const _formattedValue =
        inputValues.length >= 2
          ? `${'*'.repeat(inputValues.length - 1)}${_.last(inputValues)}`
          : inputValue;

      setFormattedValue(_formattedValue);

      inputMaskTimer.current = setTimeout(() => {
        if (inputValues.length >= 2) {
          setFormattedValue('*'.repeat(inputValues.length));
        }
        // TODO: Review Timeout Value
      }, 300);

      return () => clearTimeout(inputMaskTimer.current);
    }, [inputValue, type]);

    const [layout, setLayout] = useState('default');






  const onChange = (input) => {
    setInputValue(input);
    console.log("Input changed", input);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = button => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setInputValue(input);
    keyboardRef.current.setInput(input);
  };

  // let keyboard = new Keyboard({
  //   onChange: input => onChange(input),
  //   onKeyPress: button => onKeyPress(button),
  //   layout: {
  //     default: ["1 2 3", "4 5 6", "7 8 9", "{shift} 0 _", "{bksp}"],
  //     shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
  //   },
  //   theme: "hg-theme-default hg-layout-numeric numeric-theme"
  // });

  const handleCloseKeyboardMenu = (event) => setKeyboardMenuAnchorEl(null);
  const handleOpenKeyboardMenu = (event) => setKeyboardMenuAnchorEl(event.currentTarget);

  return (
    <div ref={rootRef}>
      {/* <input
        value={input}
        placeholder={"Tap on the virtual keyboard to start"}
        onChange={onChangeInput}
      /> */}

      <TextField
        // {...field}
        // {...props}

        fullWidth
        InputProps={{
          readOnly: true,
        }}
        value={inputValue}
        variant="outlined"
        onChange={onChangeInput}
        onClick={handleOpenKeyboardMenu}

        // TODO:
        className="mb-24"
        type={type}
        // label="Approver Pin"
        // type="password"
        // error={!!errors.approverPin}
        // helperText={errors?.approverPin?.message}
        // required
        // inputProps={{ inputMode: 'numeric' }}
      />

      <Popover
        anchorEl={keyboardMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={keyboardMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleCloseKeyboardMenu}
      >
        <div className="flex w-full items-center">
          <div className="flex flex-1 items-center">
            <Button>Cancel</Button>
          </div>

          <div className="flex items-center">
            <Button>Clear</Button>
            <Button>Set</Button>
          </div>
        </div>

        <div className="flex w-full h-40 items-center">
          <Typography noWrap>{formattedValue}</Typography>
        </div>

        <Keyboard
          keyboardRef={r => (keyboardRef.current = r)}
          layout={{
            // default: ['1 2 3', '4 5 6', '7 8 9', '{shift} 0 _', '{bksp}'],
            default: ['1 2 3', '4 5 6', '7 8 9', ' 0 ', '{bksp}'],
            shift: ['! / #', '$ % ^', '& * (', '{shift} ) +', '{bksp}'],
          }}
          layoutName={layout}
          // theme="hg-theme-default hg-layout-numeric numeric-theme"
            theme={clsx(
              { 'dark-keyboard-theme': mainTheme?.palette.mode === 'dark' },
              'hg-theme-default hg-layout-numeric numeric-keyboard-theme'
            )}
          // physicalKeyboardHighlight
          // physicalKeyboardHighlightPress
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </Popover>
    </div>
  );
});

export default CommonNumericKeyboard;
