import { gql, useApolloClient } from '@apollo/client';
import { Alert, Typography } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  SegmentDefinitionViewFormFacilityConditions,
  SegmentDefinitionViewFormTargetFacilityAreaConditions,
} from './components';

const SegmentDefinitionViewForm = ({ basePath }) => {
  const apolloClient = useApolloClient();
  const [defaultSegmentGroupingOperator, setDefaultSegmentGroupingOperator] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const watchCommonTargetTypeField = useWatch({ name: 'commonTargetType' });

  useEffect(() => {
    const getDefaultSegmentGroupingOperator = async () => {
      try {
        setLoading(true);

        const {
          data: { segmentGroupingOperators },
        } = await apolloClient.query({
          query: gql`
            query GetDefaultSegmentGroupingOperator($where: SegmentGroupingOperatorWhereInput!) {
              segmentGroupingOperators(where: $where) {
                id
                enum
                name
              }
            }
          `,
          variables: { where: {} },
        });

        const result = segmentGroupingOperators?.find(
          (segmentGroupingOperator) => segmentGroupingOperator.enum === 'OR'
        );

        if (result) {
          setDefaultSegmentGroupingOperator({
            result,
            label: result?.name,
            value: result?.id,
          });
        }
      } catch (err) {
        dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
      } finally {
        setLoading(false);
      }
    };

    if (apolloClient) {
      getDefaultSegmentGroupingOperator();
    }
  }, [apolloClient, dispatch]);

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col w-full max-w-5xl mx-auto p-16 sm:p-24">
          <div className="mb-24">
            <Typography className="text-16 font-500 uppercase">Facility Conditions</Typography>
          </div>

          <SegmentDefinitionViewFormFacilityConditions
            defaultSegmentGroupingOperator={defaultSegmentGroupingOperator}
            fieldName="facilityConditions"
          />
        </div>

        <div className="flex flex-col w-full max-w-5xl mx-auto p-16 sm:p-24 border-t-1">
          <div className="mb-24">
            <Typography className="text-16 font-500 uppercase">Target Conditions</Typography>
          </div>

          {!watchCommonTargetTypeField && (
            <Alert severity="info" sx={{ mb: 3 }}>
              No Segment Target Type Selected
            </Alert>
          )}

          {/* TODO: */}
          {watchCommonTargetTypeField?.result?.enum === 'EQUIPMENT' && <div>EQUIPMENT</div>}

          {watchCommonTargetTypeField?.result?.enum === 'FACILITY_AREA' && (
            <SegmentDefinitionViewFormTargetFacilityAreaConditions
              defaultSegmentGroupingOperator={defaultSegmentGroupingOperator}
              fieldName="targetFacilityAreaConditions"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SegmentDefinitionViewForm;
