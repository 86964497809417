import { memo, useMemo } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { AppBar, Button, Grid, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { ServiceIssueCreateDialog } from 'app/shared-components/ServiceIssue/ServiceIssueCreateDialog';

const drawerAppBarHeight = 65;
const DrawerAppBar = styled(AppBar)(({ theme }) => ({
  height: drawerAppBarHeight,
  borderStyle: 'solid',
  borderColor: theme.palette.mode === 'dark' ? '#353e4f' : grey[200],
  borderBottomWidth: 1,
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ServiceIssueQuickViewDrawer = memo(({ serviceIssuedId, toggleDrawer, width }) => {
  console.log('// FIXME: RENDER => ServiceIssueQuickViewDrawer', { serviceIssuedId });

  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const viewportOverflow = useMemo(() => drawerAppBarHeight + (isMobile ? 0 : 64), [isMobile]);

  return (
    <div className="flex flex-1 flex-col" style={{ width }}>
      {serviceIssuedId ? (
        <>
          <DrawerAppBar color="inherit" elevation={0} position="static">
            <Toolbar>
              <div className="flex-1">
                <Typography component="div" noWrap variant="h6">
                  TODO
                  {/* {title} */}
                </Typography>
              </div>

              <Button
                onClick={() =>
                  dispatch(
                    // FIXME: z-index 9999 + 1
                    openDialog({
                      children: (
                        <ServiceIssueCreateDialog onClose={() => dispatch(closeDialog())} />
                      ),
                      classes: {
                        // TODO: Move to FuseDialog?
                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                      },
                    })
                  )
                }
                variant="contained"
                color="secondary"
              >
                Open Dialog
              </Button>

              {toggleDrawer && (
                <IconButton className="w-32 h-32" onClick={toggleDrawer}>
                  <FontAwesomeIcon icon={faTimes} size="sm" />
                </IconButton>
              )}
            </Toolbar>
          </DrawerAppBar>

          <div
            className="flex flex-col overflow-auto"
            style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
          >
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {Array.from(Array(50)).map((_, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Item>xs=2</Item>
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {Array.from(Array(50)).map((_, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Item>xxs=2</Item>
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      ) : (
        <div className="flex flex-1 items-center justify-center">
          Select a Service Issue to View
        </div>
      )}
    </div>
  );
});

export default ServiceIssueQuickViewDrawer;
