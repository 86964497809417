import { useEffect, useState } from 'react';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DemoSidebarContent from '@fuse/core/DemoSidebarContent';
import { Avatar, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { SizeMe } from 'react-sizeme';
import { useNavigate } from 'react-router-dom';
import { EntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchDrawer } from 'app/shared-components/EntitySearch/EntitySearchDrawer';
import { EntitySearchHeader } from 'app/shared-components/EntitySearch/EntitySearchHeader';
import { EntitySearchInfiniteHitsGrid } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsGrid';
import { EntitySearchInfiniteHitsList } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsList';
import { EntitySearchInfiniteHitsMap } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsMap';
import { FacilitySearchAdapter } from './adapters';
import { facilitySearchScreenColumnDefs } from './columnDefs';
import { facilitySearchScreenFilterDefs } from './filterDefs';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

const FacilitySearchCard = ({ hit }) => {
  const navigate = useNavigate();

  return (
    <ListItemButton divider onClick={() => navigate(`/facilities/${hit?.facility}`)}>
      <ListItemIcon>
        <Avatar src={(hit.images?.find(({ isDefault }) => isDefault === true))?.thumbnail}>
          <FontAwesomeIcon icon={faImage} />
        </Avatar>
      </ListItemIcon>

      {/* TODO: Dot Notation? */}
      <ListItemText
        primary={`#${hit.facilityNumber} ${hit.name}`}
        // secondary={`${hit['productModel.organization.name']} | ${hit['productModel.category.label']}`}
      />
    </ListItemButton>
  );
};

const FacilitySearchScreen = () => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  // TODO: Based on selectedEntity
  const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile);
  // TODO: Rename
  const [selectedHitsViewMode, setSelectedHitsViewMode] = useState('grid');

  const navigate = useNavigate();

  // TODO: Remove?
  // useEffect(() => productModelSearchCache.clearCache(), []);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
    // TODO: Based on selectedEntity
    setRightSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <EntitySearchApp indexName="FacilityFacetData" SearchAdapter={FacilitySearchAdapter}>
      <Root
        header={
          <EntitySearchHeader
            availableSearchViews={['grid', 'list', 'map']}
            initialSearchView="grid"
            onSearchViewChange={setSelectedHitsViewMode}
            // TODO: Rename
            leftSidebarToggle={(ev) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
            // TODO: Rename
            rightSidebarToggle={(ev) => {
              setRightSidebarOpen(!rightSidebarOpen);
            }}
          />
        }
        content={
          <SizeMe
            // TODO: Review
            // refreshMode="debounce"
            monitorHeight
            monitorWidth={false}
          >
            {({ size }) => (
              <div className="flex flex-row w-full h-full">
                {selectedHitsViewMode === 'grid' && (
                  <EntitySearchInfiniteHitsGrid
                    columnDefs={facilitySearchScreenColumnDefs}
                    indexName="FacilityFacetData"
                    renameIdField="facility"
                    onRowClicked={(id) => id && navigate(`/facilities/${id}`)}
                    {...size}
                  />
                )}

                {selectedHitsViewMode === 'list' && (
                  <>
                    {/* TODO: */}
                    <EntitySearchInfiniteHitsList
                      {...size}
                      RenameHitComponent={FacilitySearchCard}
                    />
                    {/* <div className="flex flex-1">Sidebar</div> */}
                  </>
                )}

                {selectedHitsViewMode === 'map' && (
                  <EntitySearchInfiniteHitsMap {...size} RenameHitComponent={FacilitySearchCard} />
                )}
              </div>
            )}
          </SizeMe>
        }
        // TODO: Review
        leftSidebarWidth={341}
        leftSidebarOpen={leftSidebarOpen}
        leftSidebarOnClose={() => {
          setLeftSidebarOpen(false);
        }}
        leftSidebarContent={
          <EntitySearchDrawer
            dateRangeAttribute="createdAt"
            filterDefs={facilitySearchScreenFilterDefs}
            title="Facilities"
            toggleDrawer={(ev) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
          />
        }
        // TODO:
        // rightSidebarOpen={rightSidebarOpen && selectedHitsViewMode !== 'list'}
        // rightSidebarOnClose={() => {
        //   setRightSidebarOpen(false);
        // }}
        // rightSidebarContent={<DemoSidebarContent />}
        scroll="content"
      />
    </EntitySearchApp>
  );
};

export default FacilitySearchScreen;
