import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const IssueTemplatesDashboardPage = lazy(() => import('./IssueTemplatesDashboardPage'));
const issueTemplatesDashboardPageBase = '/issue-templates';

// i18next.addResourceBundle('en', 'issueTemplatesDashboardPage', en);
// i18next.addResourceBundle('tr', 'issueTemplatesDashboardPage', tr);
// i18next.addResourceBundle('ar', 'issueTemplatesDashboardPage', ar);

const issueTemplatesDashboardPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${issueTemplatesDashboardPageBase}/dashboard`,
      element: <IssueTemplatesDashboardPage />,
    },
    {
      path: issueTemplatesDashboardPageBase,
      element: <Navigate to={`${issueTemplatesDashboardPageBase}/search`} />,
    },
  ],
};

export default issueTemplatesDashboardPageConfig;
