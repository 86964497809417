import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  EntitySearchAppProvider,
  useEntitySearchApp,
} from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchDrawer } from 'app/shared-components/EntitySearch/EntitySearchDrawer';
import { EntitySearchHeader } from 'app/shared-components/EntitySearch/EntitySearchHeader';
import { EntitySearchInfiniteHitsGrid } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsGrid';
import { ServiceTicketCreateDialog } from 'app/shared-components/ServiceTicket';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { buildServiceTicketSearchScreenColumnDefs } from './columnDefs';
import { serviceTicketSearchScreenFilterDefs } from './filterDefs';

// eslint-disable-next-line
import { adaptRequestsForDateRange } from 'app/shared-components/EntitySearch/EntitySearchDateRangeMenu/utils';

// TODO: Move / Rename?
export const EntitySearchCommonClientAdapter = (instantSearchClient) => (requests) => {
  return new Promise((resolveSearch, rejectSearch) => {
    try {
      const adaptedRequests = adaptRequestsForDateRange(requests);

      // TODO: Verify Cache
      instantSearchClient.search(adaptedRequests).then((results) => {
        resolveSearch(results);
      });
    } catch (err) {
      rejectSearch(err);
    }
  });
};

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},

  // TODO:
  '& .FusePageSimple-rightSidebar': {
    flex: '1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
  '& .FusePageSimple-contentWrapper': {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 400,
    },
  },
}));

const ServiceTicketSearchScreenRoot = () => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  // TODO: Based on selectedEntity
  const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile);
  const { viewMode, setViewMode } = useEntitySearchApp();

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
    // TODO: Based on selectedEntity
    setRightSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <Root
      header={
        <EntitySearchHeader
          leftSidebarToggle={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
          rightSidebarToggle={(ev) => {
            setRightSidebarOpen(!rightSidebarOpen);
          }}
          rightToolbarContent={
            <>
              <Button
                color="success"
                size="small"
                variant="contained"
                onClick={() =>
                  dispatch(
                    // FIXME: z-index 9999 + 1
                    openDialog({
                      children: (
                        <ServiceTicketCreateDialog onClose={() => dispatch(closeDialog())} />
                      ),
                      classes: {
                        // TODO: Move to FuseDialog?
                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                      },
                    })
                  )
                }
              >
                Create
              </Button>
            </>
          }
        />
      }
      content={
        <SizeMe
          // TODO: Review
          // refreshMode="debounce"
          monitorHeight
          monitorWidth={false}
        >
          {({ size }) => (
            <div className="flex flex-row w-full h-full">
              {viewMode === 'grid' && (
                <EntitySearchInfiniteHitsGrid
                  onRowClicked={({ id }) => id && navigate(`/service-tickets/view/${id}`)}
                  {...size}
                />
              )}
            </div>
          )}
        </SizeMe>
      }
      // TODO: Review
      leftSidebarWidth={341}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarContent={
        <EntitySearchDrawer
          dateRangeAttribute="createdAtTimestamp"
          filterDefs={serviceTicketSearchScreenFilterDefs}
          title="Service Tickets"
          toggleDrawer={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
        />
      }
      // TODO:
      // rightSidebarOpen={
      //   rightSidebarOpen
      //   // && selectedHitsViewMode !== 'list'
      // }
      // rightSidebarOnClose={() => {
      //   setRightSidebarOpen(false);
      // }}
      // rightSidebarContent={(
      //   <div className="flex-1 min-w-340">
      //     Stuff
      //   </div>
      // )}
      scroll="content"
    />
  );
};

const ServiceTicketSearchScreen = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const serviceTicketSearchScreenColumnDefs = buildServiceTicketSearchScreenColumnDefs({
    theme,
    user,
  });

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      indexName="service_tickets"
      initialColumnDefs={serviceTicketSearchScreenColumnDefs}
      initialViewMode="grid"
      SearchAdapter={EntitySearchCommonClientAdapter}
    >
      <ServiceTicketSearchScreenRoot />
    </EntitySearchAppProvider>
  );
};

export default ServiceTicketSearchScreen;
