import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const ServiceTicketsDashboardPage = () => {
  const navigate = useNavigate();
  const { sectionName } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (navigate && sectionName) {
      navigate({
        pathname: `/${sectionName}/search`,
        ...(searchParams ? { search: createSearchParams(searchParams).toString() } : {}),
      });
    }
  }, [navigate, searchParams, sectionName]);

  return null;
};

export default ServiceTicketsDashboardPage;
