import { SegmentViewScreen } from 'app/screens/SegmentViewScreen';
import { Navigate, Route, Routes } from 'react-router-dom';

const SegmentsViewPage = () => {
  return (
    <Routes>
      <Route element={<SegmentViewScreen />} path=":sectionName/:viewName" />
      <Route element={<SegmentViewScreen />} path=":sectionName/:viewName/view/:viewId" />

      <Route path="*" element={<Navigate to="tabs/definition" replace />} />
    </Routes>
  );
};

export default SegmentsViewPage;
