import { gql } from '@apollo/client';
import { USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT } from './fragments';

export const FETCH_USER_SEARCH_VIEW_CONTROL_DATA = gql`
  query FetchUserSearchViewControlData($where: UserSearchViewWhereUniqueInput!) {
    userSearchView(where: $where) {
      id
      ...UserSearchViewControlUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT}
`;

export const GET_DEFAULT_USER_SEARCH_VIEW = gql`
  query GetDefaultUserSearchView($data: UserSearchViewGetDefaultInput!) {
    getDefaultUserSearchView(data: $data) {
      id
      ...UserSearchViewControlUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT}
`;

export const PIN_USER_SEARCH_VIEW = gql`
  mutation PinUserSearchView($where: UserSearchViewWhereUniqueInput!) {
    pinUserSearchView(where: $where) {
      id
      ...UserSearchViewControlUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT}
`;

export const SET_USER_SEARCH_VIEW_AS_DEFAULT = gql`
  mutation SetUserSearchViewAsDefault($where: UserSearchViewWhereUniqueInput!) {
    setUserSearchViewAsDefault(where: $where) {
      id
      ...UserSearchViewControlUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT}
`;

export const UNPIN_USER_SEARCH_VIEW = gql`
  mutation UnpinUserSearchView($where: UserSearchViewWhereUniqueInput!) {
    unpinUserSearchView(where: $where) {
      id
      ...UserSearchViewControlUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT}
`;

export const UNSET_USER_SEARCH_VIEW_AS_DEFAULT = gql`
  mutation UnsetUserSearchViewAsDefault($where: UserSearchViewWhereUniqueInput!) {
    unsetUserSearchViewAsDefault(where: $where) {
      id
      ...UserSearchViewControlUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT}
`;

export const UPDATE_USER_SEARCH_VIEW_DEFINITION = gql`
  mutation UpdateUserSearchViewDefinition(
    $where: UserSearchViewWhereUniqueInput!
    $data: UserSearchViewUpdateDefinitionInput!
  ) {
    updateUserSearchViewDefinition(where: $where, data: $data) {
      id
      ...UserSearchViewControlUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT}
`;
