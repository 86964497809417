import { useLazyQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FETCH_PROGRAM_CAMPAIGN_VIEW_APP_DATA } from './queries';

const ProgramCampaignViewApp = ({ basePath }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const [
    fetchProgramCampaignViewAppData,
    {
      data: programCampaignViewAppData,
      loading: programCampaignViewAppLoading,
      refetch: programCampaignViewAppRefetch,
    },
  ] = useLazyQuery(FETCH_PROGRAM_CAMPAIGN_VIEW_APP_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Program Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [programCampaignViewAppLoading].includes(true),
    [programCampaignViewAppLoading]
  );

  const program = useMemo(
    () => programCampaignViewAppData?.program,
    [programCampaignViewAppData?.program]
  );

  useEffect(() => {
    if (params?.programId) {
      fetchProgramCampaignViewAppData({ variables: { where: { id: params?.programId } } });
    }
  }, [fetchProgramCampaignViewAppData, params?.programId]);

  const [openTables, setOpenTables] = useState({});

  const handleToggleTable = ({ tableId }) =>
    setOpenTables({ ...(openTables || {}), [tableId]: !openTables?.[tableId] });

  return (
    <SizeMe monitorHeight monitorWidth={false}>
      {({ size }) => (
        <div className="flex flex-1 flex-row h-full">
          {(loading && !fetched) || !program ? (
            <div className="flex w-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <Paper
              className="w-full overflow-scroll"
              elevation={0}
              square
              sx={{ height: size?.height }}
            >
              {_.isEmpty(program.campaigns) ? (
                <div className="flex items-center justify-center w-full h-full">
                  <Typography className="text-24">No Campaigns Found</Typography>
                </div>
              ) : (
                <div className="">
                  {_.map(_.sortBy(program.campaigns || [], 'createdAt'), (programCampaign) => (
                    <div className="" key={programCampaign.id}>
                      <IconButton
                        className="mr-12 w-32 h-32"
                        onClick={() => handleToggleTable({ tableId: programCampaign?.id })}
                      >
                        <FontAwesomeIcon
                          icon={openTables?.[programCampaign?.id] ? faAngleDown : faAngleRight}
                          size="sm"
                        />
                      </IconButton>

                      {programCampaign.name}

                      <Collapse in={openTables?.[programCampaign?.id]} timeout="auto" unmountOnExit>
                        <>
                          <div>
                            {programCampaign.serviceTickets?.map((serviceTicket) => (
                              <div key={programCampaign.id}>
                                <Typography>{serviceTicket.serviceReferenceNumber}</Typography>
                              </div>
                            ))}
                          </div>
                        </>
                      </Collapse>
                    </div>
                  ))}
                </div>

                // <List disablePadding>
                //   {_.map(_.sortBy(program.campaigns || [], 'createdAt'), (programCampaign) => (
                //     <ListItemButton key={programCampaign.id}>
                //       <ListItemText primary={programCampaign.name} />

                //       <List>
                //         {programCampaign.serviceTickets?.map((serviceTicket) => (
                //           <ListItemButton key={programCampaign.id}>
                //             <ListItemText primary={serviceTicket.serviceReferenceNumber} />
                //           </ListItemButton>
                //         ))}
                //       </List>
                //     </ListItemButton>
                //   ))}
                // </List>
              )}
            </Paper>
          )}
        </div>
      )}
    </SizeMe>
  );
};

export default ProgramCampaignViewApp;
