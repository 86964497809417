import { useMutation } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DELETE_SERVICE_ISSUE_ATTACHMENT } from './queries';

const ServiceIssueAttachmentDeleteDialog = ({
  serviceIssueId,
  serviceIssueAttachmentId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [deleteServiceIssueAttachment, { loading: deleteServiceIssueAttachmentLoading }] =
    useMutation(DELETE_SERVICE_ISSUE_ATTACHMENT, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Issue Attachment Successfully Deleted',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Deleting Service Issue Attachment', variant: 'error' })
        );
      },
    });

  const loading = useMemo(
    () => [deleteServiceIssueAttachmentLoading].includes(true),
    [deleteServiceIssueAttachmentLoading]
  );

  const onSubmit = async (data) => {
    try {
      await deleteServiceIssueAttachment({
        variables: {
          where: { id: serviceIssueId },
          data: {
            id: serviceIssueAttachmentId,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Delete Service Issue Attachment
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ padding: '24px 0' }}>
          This action cannot be undone; do you wish to proceed?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={loading}
          form="work-order-issue-action-item-create-form"
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceIssueAttachmentDeleteDialog;
