import _ from '@lodash';
import moment from 'moment';
import numeral from 'numeral';

// eslint-disable-next-line import/prefer-default-export
export const buildServiceTicketSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'serviceTicket',
    headerName: 'Service Ticket',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'serviceReferenceNumber',
        headerName: 'SRN',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'phase.name',
        headerName: 'Phase',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'workOrder.issues.serviceIssue.serviceResponseSeverity',
        headerName: 'Severities',
        sortable: false,
        valueFormatter: ({ value }) => {
          const severities = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(severities) ? _.uniq(severities).join(', ') : undefined;
        },
        valueGetter: ({ data }) =>
          data?.workOrder?.issues
            ?.map(({ serviceIssue }) => serviceIssue?.serviceResponseSeverity)
            ?.filter((el) => el) || undefined,
      },
      {
        cellDataType: 'boolean',
        field: 'isRequestForQuote',
        headerName: 'Is RFQ',
        sortable: true,
      },
      {
        cellDataType: 'boolean',
        field: 'hasPausedIssues',
        headerName: 'Has Paused Issues',
        sortable: true,
      },
      {
        cellDataType: 'boolean',
        field: 'hasOpenRfq',
        headerName: 'Has Open RFQ',
        sortable: true,
      },
      {
        cellClass: ({ data }) => {
          const classes = [];

          if (
            ['OPEN'].includes(data?.status.enum) &&
            data?.completeBy &&
            moment().isAfter(moment(data?.completeBy).startOf('day'))
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-red-200' : 'bg-red-800');
          } else if (
            ['OPEN'].includes(data?.status.enum) &&
            data?.completeBy &&
            moment().add(4, 'days').isAfter(moment(data?.completeBy).startOf('day'))
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-amber-200' : 'bg-amber-800');
          }

          return classes;
        },
        cellDataType: 'number',
        field: 'completeByTimestamp',
        headerName: 'Latest Complete By',
        sortable: true,
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY') : undefined,
      },
      {
        cellClass: ({ data }) => {
          const classes = [];

          if (
            ['OPEN'].includes(data?.status.enum) &&
            !!data?.openConsumerTaskCount &&
            ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum)
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-amber-200' : 'bg-amber-800');
          }

          return classes;
        },
        cellDataType: 'number',
        field: 'openConsumerTaskCount',
        headerName: 'Open Consumer Tasks',
        sortable: true,
      },
      {
        cellClass: ({ data }) => {
          const classes = [];

          if (
            ['OPEN'].includes(data?.status.enum) &&
            !!data?.openVendorTaskCount &&
            ['MANUFACTURER', 'VENDOR'].includes(user?.data?.organization.type.enum)
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-amber-200' : 'bg-amber-800');
          }

          return classes;
        },
        cellDataType: 'number',
        field: 'openVendorTaskCount',
        headerName: 'Open Vendor Tasks',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'workOrder.issues.accountingWorkOrderType',
        headerName: 'Work Order Types',
        sortable: false,
        valueFormatter: ({ value }) => {
          const workOrderTypes = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(workOrderTypes) ? _.uniq(workOrderTypes).join(', ') : undefined;
        },
        valueGetter: ({ data }) =>
          data?.workOrder?.issues
            ?.map(({ accountingWorkOrderType }) => accountingWorkOrderType)
            ?.filter((el) => el) || undefined,
      },
      // TODO: AccountingProject
      {
        cellDataType: 'text',
        field: 'serviceProvision.organizationConnection.consumer.name',
        headerName: 'Consumer',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'serviceProvision.organizationConnection.vendor.name',
        headerName: 'Vendor',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Program',
    children: [
      {
        cellDataType: 'text',
        field: 'programCampaign.program.name',
        headerName: 'Program',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'programCampaign.name',
        headerName: 'Program Campaign',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Facility',
    children: [
      {
        cellDataType: 'text',
        field: 'facility.franchiseNumber',
        headerName: 'Number',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.marketTeam',
        headerName: 'Market Team',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.regionalTeam',
        headerName: 'Regional Team',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.businessConsultantContact.name',
        headerName: 'Business Consultant',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.esrContact.name',
        headerName: 'ESR',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.fieldConsultantContact.name',
        headerName: 'Field Consultant',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.leadAdvisorContact.name',
        headerName: 'Lead Advisor',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.operatorContact.name',
        headerName: 'Operator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.projectAdministratorContact.name',
        headerName: 'Project Administrator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'facility.regionalCoordinatorContact.name',
        headerName: 'Regional Coordinator',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Work Order',
    children: [
      // DEPRECATED:
      {
        cellDataType: 'number',
        columnGroupShow: 'open',
        field: 'workOrder.discountAmount',
        headerName: 'Discount',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      // DEPRECATED:
      {
        cellDataType: 'number',
        columnGroupShow: 'open',
        field: 'workOrder.subtotalAmount',
        headerName: 'Subtotal',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'workOrder.totalAmount',
        headerName: 'Total',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'workOrder.notToExceedAmount',
        headerName: 'NTE',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
    ],
  },
];
