import { gql, useQuery } from '@apollo/client';
import { faCircleXmark, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { grey, lightBlue, red } from '@mui/material/colors';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonCurrencyField } from 'app/shared-components/Common/CommonField';
import { CommonTargetTypeSelectField } from 'app/shared-components/CommonTargetType';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch/EntitySearchSelectField';
import { ServiceIssueHitsListOption } from 'app/shared-components/ServiceIssue';
import { ServiceIssueTagSelectField } from 'app/shared-components/ServiceIssueTag';
import { ServiceResponseSeveritySelectField } from 'app/shared-components/ServiceResponseSeverity';
// ROADMAP: Re-Implement or Remove
// import { ServiceResponsiblePartySelectField } from 'app/shared-components/ServiceResponsibleParty';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ServiceTicketCreateDialogWorkOrderIssueActionItems,
  ServiceTicketCreateDialogWorkOrderIssueAttachments,
  ServiceTicketCreateDialogWorkOrderIssueTemplate,
} from '.';

const getDerivedServiceResponseSeverity = ({ serviceResponseSeverityOptions, watchFields }) => {
  let derivedServiceResponseSeverity;
  let longestDurationHours;

  let serviceResponseSeverity;

  watchFields?.forEach(({ serviceIssue, ...watchField }) => {
    if (serviceIssue.mutation === 'CONNECT') {
      serviceResponseSeverity = serviceResponseSeverityOptions?.find(
        ({ value }) => value === serviceIssue.connect?.hit?.serviceResponseSeverity?.id
      );
    } else if (serviceIssue.mutation === 'CREATE') {
      serviceResponseSeverity = serviceResponseSeverityOptions?.find(
        ({ value }) => value === serviceIssue.create?.serviceResponseSeverity?.value
      );
    }

    if (serviceResponseSeverity) {
      const duration = moment.duration(
        moment()
          .add(
            serviceResponseSeverity.result?.value,
            serviceResponseSeverity.result?.commonTemporalDurationUnit?.enum
          )
          .diff(moment())
      );

      if (
        !derivedServiceResponseSeverity ||
        !longestDurationHours ||
        duration.asHours() > longestDurationHours
      ) {
        derivedServiceResponseSeverity = serviceResponseSeverity;
        longestDurationHours = duration.asHours();
      }
    }
  });

  return derivedServiceResponseSeverity;
};

const ServiceTicketCreateDialogWorkOrderIssues = ({
  defaultAccountingWorkOrderType,
  defaultServiceResponseSeverity,
  name,
  serviceTicketServiceResponseSeverityFieldName,
}) => {
  const { axiosSearchClient } = useMeilisearch();
  const dispatch = useDispatch();
  const [expandedField, setExpandedField] = useState(null);
  const [serviceResponseSeverityOptions, setServiceResponseSeverityOptions] = useState(null);
  const user = useSelector(selectUser);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext();

  const { append, fields, insert, move, prepend, remove, replace, swap, update } = useFieldArray({
    control,
    name,
  });

  const fieldCount = useRef(0);
  const watchFacility = watch('facility');
  const watchDeferredUntil = watch('deferredUntil');
  const watchServiceProvision = watch('serviceProvision');
  const watchFields = watch(name);

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    if (fields?.length > fieldCount.current) {
      setExpandedField(_.get(_.last(fields || []), 'id'));
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  useQuery(
    gql`
      query FetchServiceTicketCreateDialogWorkOrderIssuesData(
        $where: ServiceResponseSeverityWhereInput!
      ) {
        serviceResponseSeverities(where: $where) {
          id
          color
          commonTemporalDurationUnit {
            id
            enum
            name
          }
          name
          value
        }
      }
    `,
    {
      skip: !!serviceResponseSeverityOptions,
      variables: { where: {} },
      onCompleted: ({ serviceResponseSeverities }) =>
        setServiceResponseSeverityOptions(
          serviceResponseSeverities?.map((result) => ({
            result,
            label: result?.name,
            value: result?.id,
          }))
        ),
      onError: ({ message }) =>
        dispatch(
          showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
        ),
    }
  );

  const derivedServiceResponseSeverity = getDerivedServiceResponseSeverity({
    serviceResponseSeverityOptions,
    watchFields,
  });

  useEffect(() => {
    if (
      !_.isEmpty(derivedServiceResponseSeverity) &&
      serviceTicketServiceResponseSeverityFieldName
    ) {
      setValue(serviceTicketServiceResponseSeverityFieldName, derivedServiceResponseSeverity, {
        shouldValidate: true,
      });
    } else if (serviceTicketServiceResponseSeverityFieldName) {
      setValue(serviceTicketServiceResponseSeverityFieldName, null, { shouldValidate: false });
    }
  }, [derivedServiceResponseSeverity, setValue, serviceTicketServiceResponseSeverityFieldName]);

  const handleChangeExpandedField = (fieldId) => (event, newFieldId) => {
    setExpandedField(newFieldId ? fieldId : false);
  };

  const handleIssueTemplateSelect = async ({ index, issueTemplate }) => {
    const {
      data: { hits },
    } = await axiosSearchClient.post('/indexes/facility_areas/search', {
      filter: [
        `facility.id = '${watchFacility?.value}'`,
        `serviceProvisions.serviceProvision.id = '${watchServiceProvision?.value}'`,
      ],
      limit: undefined,
      q: '',
    });

    const targetFacilityAreas = hits?.filter((facilityArea) =>
      issueTemplate?.hit?.serviceIssue?.targetFacilityAreaTypes
        .map(({ facilityAreaType }) => facilityAreaType.id)
        .includes(facilityArea.typeId)
    );

    [
      {
        path: 'serviceIssue.mutation',
        value: 'CREATE',
      },
      {
        path: 'isSaveAsIssueTemplate',
        value: false,
      },
      {
        path: 'serviceIssue.create.name',
        value: issueTemplate.hit?.serviceIssue?.name,
      },
      {
        path: 'accountingWorkOrderType',
        value: {
          label: issueTemplate.hit?.workOrderIssue?.accountingWorkOrderType?.name,
          result: issueTemplate.hit?.workOrderIssue?.accountingWorkOrderType,
          value: issueTemplate.hit?.workOrderIssue?.accountingWorkOrderType?.id,
        },
      },
      {
        path: 'serviceIssue.create.commonTargetType',
        value: {
          label: issueTemplate.hit?.serviceIssue?.commonTargetType?.name,
          result: issueTemplate.hit?.serviceIssue?.commonTargetType,
          value: issueTemplate.hit?.serviceIssue?.commonTargetType?.id,
        },
      },
      {
        path: 'serviceIssue.create.targetFacilityAreas',
        value:
          targetFacilityAreas?.map((targetFacilityArea) => ({
            hit: targetFacilityArea,
            label: targetFacilityArea?.name,
            value: targetFacilityArea?.id,
          })) || [],
      },
      {
        path: 'serviceIssue.create.description',
        value: issueTemplate.hit?.serviceIssue?.description,
      },
      {
        path: 'serviceIssue.create.tags',
        value:
          issueTemplate.hit?.serviceIssue?.serviceIssueTags?.map((serviceIssueTag) => ({
            label: serviceIssueTag?.name,
            result: serviceIssueTag,
            value: serviceIssueTag?.id,
          })) || [],
      },
      {
        path: 'actionItems',
        value:
          issueTemplate.hit?.workOrderIssue?.actionItems?.map((actionItem) => ({
            csiClassification: {
              hit: actionItem?.csiClassification,
              label: `${actionItem?.csiClassification?.name} (${actionItem?.csiClassification?.number})`,
              value: actionItem?.csiClassification?.id,
            },
            description: actionItem?.description,
            notToExceedAmount: actionItem?.notToExceedAmount,
          })) || [],
      },
      {
        path: 'notToExceedAmount',
        value: issueTemplate.hit?.workOrderIssue?.notToExceedAmount,
      },
      {
        path: 'serviceIssue.create.attachments',
        value: issueTemplate.hit?.serviceIssue?.attachments
          ?.map((attachment, attachmentIndex) => {
            let attachmentsValue;

            if (attachment.commonStoredType?.enum === 'COMMON_STORED_CAD') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredCad?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredCad?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredImage?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredImage?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredPdf?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredPdf?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredVideo?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredVideo?.commonStoredUpload?.key },
              };
            }

            return attachmentsValue;
          })
          .filter((el) => el),
      },
    ].forEach(({ path, value }) => {
      setValue(`${`${name}[${index}]`}.${path}`, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: false,
      });
    });

    trigger();
  };

  return (
    <>
      {errors?.[name] && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More Service Issues Contain Errors'}
        </Alert>
      )}

      {fields.map((item, index) => {
        let borderLeftColor = grey[600];

        if (_.isArray(_.get(errors, name)) && errors[name][index]) {
          // eslint-disable-next-line prefer-destructuring
          borderLeftColor = red[800];
        } else if (expandedField === item.id) {
          // eslint-disable-next-line prefer-destructuring
          borderLeftColor = lightBlue[600];
        }

        const completeByMoment = moment(watchDeferredUntil || undefined).add(
          item.serviceIssue?.create?.serviceResponseSeverity?.result.value,
          item.serviceIssue?.create?.serviceResponseSeverity?.result.commonTemporalDurationUnit.enum
        );

        return (
          <Accordion
            expanded={expandedField === item.id}
            key={item.id}
            variant="outlined"
            onChange={handleChangeExpandedField(item.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ borderLeft: `4px solid ${borderLeftColor}` }}
            >
              <div className="flex flex-1 items-center">
                {_.isArray(_.get(errors, name)) &&
                  _.isPlainObject(_.get(errors, name)[index]) &&
                  _.some(Object.keys(_.get(errors, name)[index]), (key) =>
                    _.isPlainObject(_.get(errors, name)[index][key])
                  ) && (
                    <FontAwesomeIcon className="mr-8 text-red-800" icon={faCircleXmark} size="lg" />
                  )}

                <Typography>
                  {item?.serviceIssue?.create?.name ||
                    item?.serviceIssue?.connect?.hit?.name ||
                    `Service Issue ${index + 1}`}
                </Typography>
              </div>

              <IconButton
                sx={{ mr: 1, padding: 0, width: '24px', height: '24px' }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  remove(index);
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} size="xs" />
              </IconButton>
            </AccordionSummary>

            <AccordionDetails sx={{ borderLeft: `4px solid ${borderLeftColor}` }}>
              {item.serviceIssue?.mutation === 'CONNECT' && (
                <>
                  <Controller
                    control={control}
                    name={`${name}[${index}].serviceIssue.connect`}
                    render={({ field }) => (
                      <EntitySearchSelectField
                        {...field}
                        className="mb-24"
                        components={{ Option: ServiceIssueHitsListOption }}
                        error={_.get(errors, `${name}[${index}].serviceIssue.connect`)}
                        filter={[
                          `targetFacilityAreas.facilityArea.facility.id = '${watchFacility.value}'`,
                          `targetFacilityAreas.facilityArea.serviceProvisions.serviceProvision.id = '${watchServiceProvision?.value}'`,
                          `status.enum != 'IN_PROGRESS'`,
                        ]}
                        idField="id"
                        indexName="service_issues"
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        placeholder="Select Service Issue..."
                        nameField="name"
                        sort={['name:asc']}
                        onChange={(params) => {
                          setValue(`${name}[${index}].accountingWorkOrderType`, null);

                          if (params && _.isEmpty(watchFields?.[index]?.actionItems)) {
                            setValue(
                              `${name}[${index}].notToExceedAmount`,
                              params?.hit?.estimatedAmount
                            );
                          }

                          field.onChange(params);
                        }}
                      />
                    )}
                  />

                  {watchFields?.[index]?.serviceIssue?.connect && (
                    <>
                      <Controller
                        control={control}
                        name={`${name}[${index}].accountingWorkOrderType`}
                        render={({ field }) => {
                          const selectedCommonTargetType =
                            watchFields?.[index]?.serviceIssue?.connect?.hit?.commonTargetType;
                          const selectedTargetFacilityAreas =
                            watchFields?.[index]?.serviceIssue?.connect?.hit?.targetFacilityAreas;

                          return (
                            <EntitySearchSelectField
                              {...field}
                              className="mb-24"
                              error={_.get(errors, `${name}[${index}].accountingWorkOrderType`)}
                              filter={[
                                [
                                  // ROADMAP: Remove After Data is Provided
                                  `acceptedCommonTargetTypes.id NOT EXISTS`,
                                  `acceptedCommonTargetTypes.id = '${selectedCommonTargetType?.id}'`,
                                ],
                                [
                                  // ROADMAP: Remove After Data is Provided
                                  `targetFacilityAreaTypes.facilityAreaType.id NOT EXISTS`,
                                  // ROADMAP: Replace `typeId` with `type?.id`
                                  `targetFacilityAreaTypes.facilityAreaType.id IN [${selectedTargetFacilityAreas
                                    ?.map(({ facilityArea }) => `'${facilityArea?.typeId}'`)
                                    .join(',')}]`,
                                ],
                              ]}
                              idField="id"
                              indexName="accounting_work_order_types"
                              isClearable
                              isDisabled={false}
                              isMulti={false}
                              placeholder="Select Work Order Type..."
                              sort={['name:asc']}
                            />
                          );
                        }}
                      />
                    </>
                  )}
                </>
              )}

              {item.serviceIssue?.mutation === 'CREATE' && (
                <>
                  <ServiceTicketCreateDialogWorkOrderIssueTemplate
                    workOrderIssueFieldName={`${name}[${index}]`}
                    onIssueTemplateSelect={({ issueTemplate }) =>
                      handleIssueTemplateSelect({ index, issueTemplate })
                    }
                  />

                  <Divider sx={{ mb: 3 }} />

                  <Controller
                    control={control}
                    name={`${name}[${index}].serviceIssue.create.name`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24"
                        error={_.get(errors, `${name}[${index}].serviceIssue.create.name`)}
                        fullWidth
                        helperText={_.get(
                          errors,
                          `${name}[${index}].serviceIssue.create.name.message`
                        )}
                        placeholder="Enter Name..."
                        required
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`${name}[${index}].accountingWorkOrderType`}
                    render={({ field }) => (
                      <EntitySearchSelectField
                        {...field}
                        className="mb-24"
                        error={_.get(errors, `${name}[${index}].accountingWorkOrderType`)}
                        // ROADMAP: Implement Territory Filter Based on Selected Facility
                        filter={null}
                        idField="id"
                        indexName="accounting_work_order_types"
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        placeholder="Select Work Order Type..."
                        sort={['name:asc']}
                        onChange={(params) => {
                          setValue(`${name}[${index}].serviceIssue.create.commonTargetType`, null);
                          setValue(`${name}[${index}].serviceIssue.create.targetFacilityAreas`, []);

                          watchFields?.[index]?.actionItems?.forEach(
                            (actionItem, actionItemIndex) => {
                              setValue(`${name}[${index}].actionItems[${actionItemIndex}]`, {
                                ...actionItem,
                                csiClassification: null,
                              });
                            }
                          );

                          field.onChange(params);
                        }}
                      />
                    )}
                  />

                  {watchFields?.[index]?.accountingWorkOrderType && (
                    <>
                      <Controller
                        control={control}
                        name={`${name}[${index}].serviceIssue.create.commonTargetType`}
                        render={({ field }) => (
                          <CommonTargetTypeSelectField
                            {...field}
                            className="mb-24"
                            error={_.get(
                              errors,
                              `${name}[${index}].serviceIssue.create.commonTargetType`
                            )}
                            filters={{
                              ...(watchFields?.[index]?.accountingWorkOrderType?.hit
                                ?.acceptedCommonTargetTypes
                                ? {
                                    enum: {
                                      in: watchFields?.[
                                        index
                                      ]?.accountingWorkOrderType?.hit?.acceptedCommonTargetTypes.map(
                                        (acceptedCommonTargetType) => acceptedCommonTargetType.enum
                                      ),
                                    },
                                  }
                                : {}),
                            }}
                            isClearable
                            isDisabled={false}
                            isMulti={false}
                            placeholder="Select Target Type..."
                            onChange={(params) => {
                              setValue(
                                `${name}[${index}].serviceIssue.create.targetFacilityAreas`,
                                []
                              );

                              field.onChange(params);
                            }}
                          />
                        )}
                      />

                      {watchFields?.[index]?.serviceIssue?.create?.commonTargetType?.result
                        ?.enum === 'FACILITY_AREA' && (
                        <Controller
                          control={control}
                          name={`${name}[${index}].serviceIssue.create.targetFacilityAreas`}
                          render={({ field }) => (
                            <EntitySearchSelectField
                              {...field}
                              className="mb-24"
                              error={_.get(
                                errors,
                                `${name}[${index}].serviceIssue.create.targetFacilityAreas`
                              )}
                              filter={[
                                `facility.id = '${watchFacility.value}'`,
                                `serviceProvisions.serviceProvision.id = '${watchServiceProvision?.value}'`,
                                !_.isEmpty(
                                  watchFields?.[index]?.accountingWorkOrderType?.hit
                                    ?.targetFacilityAreaTypes
                                ) &&
                                  `typeId IN [${watchFields?.[
                                    index
                                  ]?.accountingWorkOrderType?.hit?.targetFacilityAreaTypes
                                    .map(({ facilityAreaType }) => `'${facilityAreaType.id}'`)
                                    .join(',')}]`,
                              ].filter((el) => el)}
                              idField="id"
                              indexName="facility_areas"
                              isClearable
                              isDisabled={false}
                              isMulti
                              placeholder="Select Target Facility Areas..."
                              sort={['name:asc']}
                              onChange={(params) => {
                                watchFields?.[index]?.actionItems?.forEach(
                                  (actionItem, actionItemIndex) => {
                                    setValue(`${name}[${index}].actionItems[${actionItemIndex}]`, {
                                      ...actionItem,
                                      csiClassification: null,
                                    });
                                  }
                                );

                                field.onChange(params);
                              }}
                            />
                          )}
                        />
                      )}

                      {!_.isEmpty(
                        watchFields?.[index]?.serviceIssue?.create?.targetFacilityAreas
                      ) && (
                        <>
                          <Controller
                            control={control}
                            name={`${name}[${index}].serviceIssue.create.serviceResponseSeverity`}
                            render={({ field }) => (
                              <ServiceResponseSeveritySelectField
                                {...field}
                                className="mb-24"
                                error={_.get(
                                  errors,
                                  `${name}[${index}].serviceIssue.create.serviceResponseSeverity`
                                )}
                                filters={null}
                                isClearable
                                isDisabled={false}
                                isMulti={false}
                                placeholder="Select Response Severity..."
                              />
                            )}
                          />

                          {watchFields?.[index]?.serviceIssue?.create?.serviceResponseSeverity && (
                            <div className="mb-24">
                              <Alert
                                severity="info"
                                sx={{
                                  backgroundColor:
                                    watchFields?.[index]?.serviceIssue?.create
                                      ?.serviceResponseSeverity?.result.color,
                                  color: 'white',
                                  '& .MuiAlert-icon': {
                                    color: 'white',
                                  },
                                }}
                              >
                                <span className="mr-8">Complete By:</span>

                                <strong>
                                  {completeByMoment && completeByMoment.format('MMMM DD, YYYY')}
                                </strong>
                              </Alert>
                            </div>
                          )}

                          <Controller
                            control={control}
                            name={`${name}[${index}].serviceIssue.create.description`}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                error={_.get(
                                  errors,
                                  `${name}[${index}].serviceIssue.create.description`
                                )}
                                fullWidth
                                helperText={_.get(
                                  errors,
                                  `${name}[${index}].serviceIssue.create.description.message`
                                )}
                                maxRows={4}
                                minRows={4}
                                multiline
                                placeholder="Enter Description; Where / What..."
                                required
                                variant="outlined"
                              />
                            )}
                          />

                          {['FRANCHISOR', 'OPERATOR'].includes(
                            user?.data?.organization?.type.enum
                          ) && (
                            <Controller
                              control={control}
                              name={`${name}[${index}].serviceIssue.create.tags`}
                              render={({ field }) => (
                                <ServiceIssueTagSelectField
                                  {...field}
                                  className="mb-24"
                                  error={_.get(
                                    errors,
                                    `${name}[${index}].serviceIssue.create.tags`
                                  )}
                                  isClearable
                                  isDisabled={false}
                                  isMulti
                                  placeholder="Select / Create Service Issue Tags..."
                                />
                              )}
                            />
                          )}

                          {/* ROADMAP: Re-Implement or Remove */}
                          {/* {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum) && (
                            <>
                              <Controller
                                control={control}
                                name={`${name}[${index}].serviceIssue.create.serviceResponsibleParty`}
                                render={({ field }) => (
                                  <ServiceResponsiblePartySelectField
                                    {...field}
                                    className="mb-24"
                                    error={_.get(
                                      errors,
                                      `${name}[${index}].serviceIssue.create.serviceResponsibleParty`
                                    )}
                                    filters={null}
                                    isClearable
                                    isDisabled={false}
                                    isMulti={false}
                                    placeholder="Select Responsible Party..."
                                  />
                                )}
                              />
                            </>
                          )} */}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <Controller
                control={control}
                name={`${name}[${index}].notToExceedAmount`}
                render={({ field }) => (
                  <CommonCurrencyField
                    {...field}
                    className="mb-24"
                    control={control}
                    disabled={!!watchFields[index].actionItems?.length}
                    error={_.get(errors, `${name}[${index}].notToExceedAmount`)}
                    placeholder="Enter Not To Exceed..."
                  />
                )}
              />

              <ServiceTicketCreateDialogWorkOrderIssueActionItems
                accountingWorkOrderTypeFieldName={`${name}[${index}].accountingWorkOrderType`}
                name={`${name}[${index}].actionItems`}
                serviceIssueFieldName={`${name}[${index}].serviceIssue`}
                totalFieldName={`${name}[${index}].notToExceedAmount`}
              />

              {item.serviceIssue?.mutation === 'CREATE' && (
                <>
                  <Divider sx={{ mb: 3 }} />

                  <ServiceTicketCreateDialogWorkOrderIssueAttachments
                    name={`${name}[${index}].serviceIssue.create.attachments`}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}

      <div className="flex w-full items-center justify-center my-24">
        <Button
          color="inherit"
          disabled={false}
          sx={{ mx: 1 }}
          variant="outlined"
          onClick={() =>
            append({
              accountingWorkOrderType: defaultAccountingWorkOrderType || null,
              actionItems: [],
              isSaveAsIssueTemplate: false,
              issueTemplate: null,
              notToExceedAmount: null,
              saveAsIssueTemplate: {
                description: '',
                name: '',
                tags: [],
              },
              serviceIssue: {
                create: {
                  commonTargetType: null,
                  description: '',
                  name: '',
                  targetAsset: null,
                  targetFacilityAreas: [],
                  serviceResponseSeverity: defaultServiceResponseSeverity || null,
                },
                mutation: 'CREATE',
              },
            })
          }
        >
          Add New Service Issue
        </Button>

        <Button
          color="inherit"
          disabled={false}
          sx={{ mx: 1 }}
          variant="outlined"
          onClick={() =>
            append({
              accountingWorkOrderType: null,
              actionItems: [],
              notToExceedAmount: null,
              serviceIssue: {
                connect: null,
                mutation: 'CONNECT',
              },
            })
          }
        >
          Add Existing Service Issue
        </Button>
      </div>
    </>
  );
};

export default ServiceTicketCreateDialogWorkOrderIssues;
