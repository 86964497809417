import { gql, useQuery } from '@apollo/client';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { forwardRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { CommonSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';

const ServiceResponseSeveritySelectOption = (props) => {
  const serviceResponseSeverity = props.data?.result;

  const item = useMemo(() => {
    if (!serviceResponseSeverity) return null;

    return (
      <ListItem component="div" dense>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <FontAwesomeIcon icon={faCircle} style={{ color: serviceResponseSeverity.color }} />
        </ListItemIcon>

        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2">
              {`${serviceResponseSeverity.name} (${serviceResponseSeverity.value} ${serviceResponseSeverity.commonTemporalDurationUnit?.name})`}
            </Typography>
          }
        />
      </ListItem>
    );
  }, [serviceResponseSeverity]);

  return <components.Option {...props}>{item}</components.Option>;
};

const ServiceResponseSeveritySelectField = forwardRef(
  ({ className, filters, ...SelectProps }, rootRef) => {
    const dispatch = useDispatch();
    const [options, setOptions] = useState(null);

    useQuery(
      gql`
        query GetServiceResponseSeveritySelectFieldData(
          $where: ServiceResponseSeverityWhereInput!
        ) {
          serviceResponseSeverities(where: $where) {
            id
            color
            commonTemporalDurationUnit {
              id
              enum
              name
            }
            name
            value
          }
        }
      `,
      {
        skip: !!options,
        variables: { where: {} },
        onCompleted: ({ serviceResponseSeverities }) =>
          setOptions(
            serviceResponseSeverities?.map((result) => ({
              result,
              label: result?.name,
              value: result?.id,
            }))
          ),
        onError: ({ message }) =>
          dispatch(
            showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
          ),
      }
    );

    return (
      <div className={className} ref={rootRef}>
        <CommonSelect
          {...SelectProps}
          components={{ Option: ServiceResponseSeveritySelectOption }}
          options={options}
        />
      </div>
    );
  }
);

export default ServiceResponseSeveritySelectField;
