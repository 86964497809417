import { gql, useMutation } from '@apollo/client';
import _ from '@lodash';
import { Alert, AlertTitle, Button, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { ServiceTicketPhaseReviewCompleteDialog } from 'app/shared-components/ServiceTicketPhaseReview';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT } from '../fragments';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

const WorkOrderViewAppApprovalPhaseBar = ({
  consumer,
  phase,
  phaseReviews,
  serviceTicketId,
  user,
  vendor,
  workOrder,
}) => {
  const dispatch = useDispatch();

  const [recallServiceTicketPhaseReview, { loading: recallServiceTicketPhaseReviewLoading }] =
    useMutation(
      gql`
        mutation RecallServiceTicketPhaseReview(
          $where: ServiceTicketWhereUniqueInput!
          $data: ServiceTicketPhaseReviewRecallInput!
        ) {
          recallServiceTicketPhaseReview(where: $where, data: $data) {
            id
            ...WorkOrderViewAppServiceTicketFragment
          }
        }
        ${WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT}
      `
    );

  const serviceTicketPhaseReview = useMemo(
    () =>
      phaseReviews?.find(({ isCancelled, isCompleted, serviceTicketPhase }) =>
        Boolean(!isCancelled && !isCompleted && serviceTicketPhase?.id === phase?.id)
      ),
    [phase?.id, phaseReviews]
  );

  const loading = useMemo(
    () => [recallServiceTicketPhaseReviewLoading].includes(true),
    [recallServiceTicketPhaseReviewLoading]
  );

  return (
    <>
      {serviceTicketPhaseReview && (
        <>
          {phase.enum === 'APPROVAL' && consumer?.id === user?.data?.organization?.id && (
            <>
              <Alert
                square
                action={
                  <Button
                    className="mr-8"
                    disabled={loading}
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      dispatch(
                        openDialog({
                          children: (
                            <ServiceTicketPhaseReviewCompleteDialog
                              serviceTicketId={serviceTicketId}
                              serviceTicketPhaseReviewId={serviceTicketPhaseReview.id}
                              onClose={() => dispatch(closeDialog())}
                            />
                          ),
                          classes: {
                            paper: 'w-full max-w-640 min-w-320 rounded-8',
                          },
                        })
                      )
                    }
                  >
                    Complete Review
                  </Button>
                }
                classes={{ action: 'items-center' }}
                severity="warning"
              >
                <AlertTitle>Complete Service Ticket Phase Review</AlertTitle>
                Review each service issue and provide an outcome decision.
              </Alert>

              <StyledDivider />
            </>
          )}

          {phase.enum === 'APPROVAL' && vendor?.id === user?.data?.organization?.id && (
            <>
              <Alert
                square
                action={
                  <Button
                    className="mr-8"
                    disabled={loading}
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      recallServiceTicketPhaseReview({
                        variables: {
                          where: { id: serviceTicketId },
                          data: { id: serviceTicketPhaseReview.id },
                        },
                      })
                    }
                  >
                    Recall Review
                  </Button>
                }
                classes={{ action: 'items-center' }}
                severity="info"
              >
                <AlertTitle>Recall Request For Review</AlertTitle>
                Cancel complete service phase review task and reopen ticket for editing.
              </Alert>

              <StyledDivider />
            </>
          )}
        </>
      )}
    </>
  );
};

export default WorkOrderViewAppApprovalPhaseBar;
