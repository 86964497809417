import { gql } from '@apollo/client';
import { ISSUE_TEMPLATE_CREATE_DIALOG_ISSUE_TEMPLATE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ISSUE_TEMPLATE = gql`
  mutation CreateIssueTemplate($data: IssueTemplateCreateInput!) {
    createIssueTemplate(data: $data) {
      id
      ...IssueTemplateCreateDialogIssueTemplateFragment
    }
  }
  ${ISSUE_TEMPLATE_CREATE_DIALOG_ISSUE_TEMPLATE_FRAGMENT}
`;
