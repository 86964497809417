import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import {
  faAnglesRight,
  faEllipsis,
  faExclamationCircle,
  faFileImage,
  faFilePdf,
  faFileVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import {
  WorkOrderIssueActionItemDeleteDialog,
  WorkOrderIssueActionItemUpdateDialog,
} from 'app/shared-components/WorkOrderIssueActionItem';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import numeral from 'numeral';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SizeMe } from 'react-sizeme';

const WorkOrderIssueActionItemTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? 'white' : '#1b2535',
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

const WorkOrderViewAppWorkOrderIssueActionItemTable = ({
  canUpdateWorkOrder,
  handleOpenImageLightbox,
  handleOpenPdfWindow,
  handleOpenVideoJsPlayer,
  openWorkOrderIssueReview,
  phase,
  serviceTicketId,
  workOrderIssue,
}) => {
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [actionMenuSelected, setActionMenuSelected] = useState(null);
  const dispatch = useDispatch();

  const workOrderIssueActionItems = useMemo(
    () => workOrderIssue?.actionItems,
    [workOrderIssue?.actionItems]
  );

  const handleCloseActionMenu = () => {
    setActionMenuAnchorEl(null);
    setActionMenuSelected(null);
  };

  const handleOpenActionMenu = ({ currentTarget, selected }) => {
    setActionMenuAnchorEl(currentTarget);
    setActionMenuSelected(selected);
  };

  return (
    <SizeMe monitorHeight={false} monitorWidth>
      {({ size }) => (
        <TableContainer component={Paper} elevation={0} square>
          <Table>
            <TableBody>
              {!_.isEmpty(workOrderIssueActionItems) ? (
                <>
                  {_.map(
                    _.sortBy(workOrderIssueActionItems || [], 'csiClassification.name'),
                    (workOrderIssueActionItem) => {
                      let quantity;
                      let rateAmount;

                      if (workOrderIssueActionItem.commonStandardRateType?.enum === 'FLAT_RATE') {
                        rateAmount = workOrderIssueActionItem.commonStandardFlatRate?.quantity;
                      } else if (
                        workOrderIssueActionItem.commonStandardRateType?.enum === 'HOURLY_RATE'
                      ) {
                        quantity =
                          workOrderIssueActionItem.commonStandardHourlyRate?.hours *
                          workOrderIssueActionItem.commonStandardHourlyRate?.technicians;

                        rateAmount = workOrderIssueActionItem.commonStandardHourlyRate?.rateAmount;
                      }

                      return (
                        <TableRow key={workOrderIssueActionItem?.id}>
                          <WorkOrderIssueActionItemTableCell sx={{ width: '34%' }}>
                            <div className="flex flex-1 items-start">
                              {canUpdateWorkOrder &&
                              !workOrderIssueActionItem.totalAmount &&
                              ['IN_PROGRESS', 'QUOTING'].includes(phase.enum) &&
                              ['IN_PROGRESS', 'OPEN', 'PENDING', 'QUOTED', 'SCHEDULED'].includes(
                                workOrderIssue?.status?.enum
                              ) ? (
                                <Tooltip title="Action Item Missing Details">
                                  <IconButton
                                    className="mr-12 sm:hidden md:flex"
                                    onClick={() => {
                                      handleCloseActionMenu();

                                      dispatch(
                                        openDialog({
                                          children: (
                                            <WorkOrderIssueActionItemUpdateDialog
                                              serviceTicketId={serviceTicketId}
                                              workOrderIssueActionItemId={
                                                workOrderIssueActionItem.id
                                              }
                                              onClose={() => dispatch(closeDialog())}
                                            />
                                          ),
                                          classes: {
                                            paper: 'w-full max-w-640 min-w-320 rounded-8',
                                          },
                                        })
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className="text-amber-800"
                                      icon={faExclamationCircle}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <FontAwesomeIcon
                                  className="w-20 h-20 mr-12 p-6 text-grey-700 sm:hidden md:block"
                                  icon={faAnglesRight}
                                />
                              )}

                              <div className="flex flex-col">
                                <Typography className="text-12">
                                  {`${workOrderIssueActionItem.csiClassification?.accountingTaskCode.name} (${workOrderIssueActionItem.csiClassification?.accountingTaskCode.number})`}
                                </Typography>

                                <div
                                  className="flex items-center"
                                  style={{
                                    width: Math.floor((size?.width || 0) * 0.34 - (32 + (32 + 12))),
                                  }}
                                >
                                  <Typography className="text-14" noWrap>
                                    {`${workOrderIssueActionItem.csiClassification?.name} (${workOrderIssueActionItem.csiClassification?.number})`}
                                  </Typography>

                                  {workOrderIssueActionItem.isCapitalExpense && (
                                    <Tooltip title="Capital Expense">
                                      <FontAwesomeIcon className="ml-12" icon={faBuilding} />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            </div>

                            {!_.isEmpty(
                              workOrderIssueActionItem.csiClassification?.attachments
                            ) && (
                              <div className="flex flex-1 md:pl-40 mt-4">
                                {workOrderIssueActionItem.csiClassification?.attachments?.map(
                                  (csiClassificationAttachment) => (
                                    <div key={csiClassificationAttachment.id}>
                                      {csiClassificationAttachment.commonStoredType?.enum ===
                                        'COMMON_STORED_IMAGE' && (
                                        <IconButton
                                          onClick={() => {
                                            handleOpenImageLightbox({
                                              slides: [
                                                {
                                                  src: csiClassificationAttachment.commonStoredImage
                                                    ?.lightboxImageUrl,
                                                },
                                              ],
                                            });
                                          }}
                                        >
                                          <Avatar
                                            src={
                                              csiClassificationAttachment.commonStoredImage
                                                ?.thumbnailImageUrl
                                            }
                                            sx={{ border: '2px solid', width: 42, height: 42 }}
                                          >
                                            <FontAwesomeIcon icon={faFileImage} />
                                          </Avatar>
                                        </IconButton>
                                      )}

                                      {csiClassificationAttachment.commonStoredType?.enum ===
                                        'COMMON_STORED_PDF' && (
                                        <IconButton
                                          onClick={() =>
                                            handleOpenPdfWindow({
                                              pdfUrl:
                                                csiClassificationAttachment.commonStoredPdf
                                                  ?.originalPdfUrl,
                                            })
                                          }
                                        >
                                          <Avatar
                                            src={
                                              csiClassificationAttachment.commonStoredPdf
                                                ?.thumbnailImageUrl
                                            }
                                            sx={{ border: '2px solid', width: 42, height: 42 }}
                                          >
                                            <FontAwesomeIcon icon={faFilePdf} />
                                          </Avatar>
                                        </IconButton>
                                      )}

                                      {csiClassificationAttachment.commonStoredType?.enum ===
                                        'COMMON_STORED_VIDEO' && (
                                        <IconButton
                                          onClick={() => {
                                            handleOpenVideoJsPlayer({
                                              options: {
                                                autoplay: false,
                                                controls: true,
                                                responsive: true,
                                                fluid: true,
                                                poster:
                                                  csiClassificationAttachment.commonStoredVideo
                                                    ?.standardImageUrl,
                                                sources: [
                                                  {
                                                    src: csiClassificationAttachment
                                                      .commonStoredVideo?.playlistFileUrl,
                                                    type: 'application/x-mpegURL',
                                                  },
                                                ],
                                                tracks: [
                                                  {
                                                    src: csiClassificationAttachment
                                                      .commonStoredVideo?.captionsFileUrl,
                                                    kind: 'captions',
                                                    srclang: 'en',
                                                    label: 'English',
                                                  },
                                                ],
                                              },
                                            });
                                          }}
                                        >
                                          <Avatar
                                            src={
                                              csiClassificationAttachment.commonStoredVideo
                                                ?.thumbnailImageUrl
                                            }
                                            sx={{ border: '2px solid', width: 42, height: 42 }}
                                          >
                                            <FontAwesomeIcon icon={faFileVideo} />
                                          </Avatar>
                                        </IconButton>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {workOrderIssueActionItem.description && (
                              <div className="flex flex-1 md:pl-40 mt-16">
                                <Typography className="text-14">
                                  {workOrderIssueActionItem.description}
                                </Typography>
                              </div>
                            )}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '12%' }} align="right">
                            {quantity || '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '12%' }} align="right">
                            {quantity && `${quantity} @ `}
                            {rateAmount ? numeral(rateAmount).format('$0,0.00') : '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '12%' }} align="right">
                            {workOrderIssueActionItem.discountAmount
                              ? numeral(workOrderIssueActionItem.discountAmount).format('$0,0.00')
                              : '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '12%' }} align="right">
                            {workOrderIssueActionItem.totalAmount
                              ? numeral(workOrderIssueActionItem.totalAmount).format('$0,0.00')
                              : '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '12%' }} align="right">
                            {workOrderIssueActionItem.notToExceedAmount
                              ? numeral(workOrderIssueActionItem.notToExceedAmount).format(
                                  '$0,0.00'
                                )
                              : '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell
                            sx={{ width: '6%', paddingLeft: 0 }}
                            align="right"
                          >
                            {canUpdateWorkOrder &&
                              !openWorkOrderIssueReview &&
                              ['DRAFT', 'IN_PROGRESS', 'QUOTING'].includes(phase.enum) &&
                              ['IN_PROGRESS', 'OPEN', 'PENDING', 'QUOTED', 'SCHEDULED'].includes(
                                workOrderIssue?.status?.enum
                              ) && (
                                <>
                                  <IconButton
                                    className="w-32 h-32"
                                    onClick={({ currentTarget }) =>
                                      handleOpenActionMenu({
                                        currentTarget,
                                        selected: workOrderIssueActionItem.id,
                                      })
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEllipsis} size="sm" />
                                  </IconButton>

                                  <Menu
                                    anchorEl={actionMenuAnchorEl}
                                    id="action-menu"
                                    open={Boolean(
                                      actionMenuAnchorEl &&
                                        actionMenuSelected === workOrderIssueActionItem.id
                                    )}
                                    onClose={handleCloseActionMenu}
                                  >
                                    <MenuItem
                                      disabled={!canUpdateWorkOrder}
                                      onClick={() => {
                                        handleCloseActionMenu();

                                        dispatch(
                                          openDialog({
                                            children: (
                                              <WorkOrderIssueActionItemUpdateDialog
                                                serviceTicketId={serviceTicketId}
                                                workOrderIssueActionItemId={
                                                  workOrderIssueActionItem.id
                                                }
                                                onClose={() => dispatch(closeDialog())}
                                              />
                                            ),
                                            classes: {
                                              paper: 'w-full max-w-640 min-w-320 rounded-8',
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      Edit Action Item
                                    </MenuItem>

                                    <MenuItem
                                      disabled={!canUpdateWorkOrder}
                                      onClick={() => {
                                        handleCloseActionMenu();

                                        dispatch(
                                          openDialog({
                                            children: (
                                              <WorkOrderIssueActionItemDeleteDialog
                                                serviceTicketId={serviceTicketId}
                                                workOrderIssueActionItemId={
                                                  workOrderIssueActionItem.id
                                                }
                                                onClose={() => dispatch(closeDialog())}
                                              />
                                            ),
                                            classes: {
                                              paper: 'w-full max-w-640 min-w-320 rounded-8',
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      Delete Action Item
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                          </WorkOrderIssueActionItemTableCell>
                        </TableRow>
                      );
                    }
                  )}
                </>
              ) : (
                <TableRow>
                  <WorkOrderIssueActionItemTableCell align="center">
                    <Typography className="font-500">No Action Items Found</Typography>
                  </WorkOrderIssueActionItemTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </SizeMe>
  );
};

export default WorkOrderViewAppWorkOrderIssueActionItemTable;
