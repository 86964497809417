import { gql, useApolloClient } from '@apollo/client';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Alert, Button, Typography } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SegmentDefinitionViewFormTargetFacilityAreaConditionsItem from './SegmentDefinitionViewFormTargetFacilityAreaConditionsItem';

const SegmentDefinitionViewFormTargetFacilityAreaConditions = ({
  defaultSegmentGroupingOperator,
  fieldName,
}) => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const [facilityAreaTotalHits, setFacilityAreaTotalHits] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useFormContext();

  const { append, fields, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const segmentFacilityAreaExpressionCalculationFieldName = useMemo(
    () => `segmentFacilityAreaExpressionCalculation`,
    []
  );

  const watchFacilityConditionsField = useWatch({ name: 'facilityConditions' });
  const watchIsEditableField = useWatch({ name: 'isEditable' });
  const watchTargetFacilityAreaConditionsField = useWatch({ name: fieldName });
  const watchSegmentFacilityAreaExpressionCalculationField = useWatch({
    name: segmentFacilityAreaExpressionCalculationFieldName,
  });

  useEffect(() => {
    const getSegmentFacilityAreaExpressionCalculation = async () => {
      try {
        setLoading(true);

        const {
          data: {
            calculateSegmentFacilityAreaExpression: segmentFacilityAreaExpressionCalculation,
          },
        } = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: gql`
            query CalculateSegmentFacilityAreaExpression(
              $data: SegmentFacilityAreaExpressionCalculateInput!
            ) {
              calculateSegmentFacilityAreaExpression(data: $data) {
                id
                createdAt

                count
                meilisearchFilter
                sqlQuery
              }
            }
          `,
          variables: {
            data: {
              segmentFacilityConditions: watchFacilityConditionsField?.map(
                (watchFacilityCondition, conditionIndex) => ({
                  attribute: { id: watchFacilityCondition.attribute?.result?.id },
                  filterOperator: { id: watchFacilityCondition.filterOperator?.result?.id },
                  groupingOperator: { id: watchFacilityCondition.groupingOperator?.result?.id },
                  ordinal: conditionIndex,
                  value: _.isArray(watchFacilityCondition.value)
                    ? JSON.stringify(watchFacilityCondition.value?.map(({ value }) => value))
                    : watchFacilityCondition.value?.value,
                })
              ),
              segmentFacilityAreaConditions: watchTargetFacilityAreaConditionsField?.map(
                (watchTargetFacilityAreaCondition, index) => ({
                  attribute: { id: watchTargetFacilityAreaCondition.attribute?.result?.id },
                  filterOperator: {
                    id: watchTargetFacilityAreaCondition.filterOperator?.result?.id,
                  },
                  groupingOperator: {
                    id: watchTargetFacilityAreaCondition.groupingOperator?.result?.id,
                  },
                  ordinal: index,
                  value: _.isArray(watchTargetFacilityAreaCondition.value)
                    ? JSON.stringify(
                        watchTargetFacilityAreaCondition.value.map(({ value }) => value)
                      )
                    : watchTargetFacilityAreaCondition.value.value,
                })
              ),
            },
          },
        });

        setValue(
          segmentFacilityAreaExpressionCalculationFieldName,
          segmentFacilityAreaExpressionCalculation
        );
      } catch (err) {
        dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
      } finally {
        setLoading(false);
      }
    };

    if (
      apolloClient &&
      dispatch &&
      segmentFacilityAreaExpressionCalculationFieldName &&
      setValue &&
      (_.isEmpty(watchTargetFacilityAreaConditionsField) ||
        (!_.isEmpty(watchTargetFacilityAreaConditionsField) &&
          _.isArray(watchTargetFacilityAreaConditionsField) &&
          _.every(watchTargetFacilityAreaConditionsField, (watchTargetFacilityAreaCondition) =>
            Boolean(
              watchTargetFacilityAreaCondition.attribute?.result?.id &&
                watchTargetFacilityAreaCondition.filterOperator?.result?.id &&
                !_.isEmpty(watchTargetFacilityAreaCondition.value)
            )
          ))) &&
      !_.isEmpty(watchFacilityConditionsField) &&
      _.isArray(watchFacilityConditionsField) &&
      _.every(watchFacilityConditionsField, (watchFacilityCondition) =>
        Boolean(
          watchFacilityCondition.attribute?.result?.id &&
            watchFacilityCondition.filterOperator?.result?.id &&
            !_.isEmpty(watchFacilityCondition.value)
        )
      )
    ) {
      getSegmentFacilityAreaExpressionCalculation();
    } else {
      setValue(segmentFacilityAreaExpressionCalculationFieldName, null);
    }
  }, [
    apolloClient,
    dispatch,
    segmentFacilityAreaExpressionCalculationFieldName,
    setValue,
    watchTargetFacilityAreaConditionsField,
    watchFacilityConditionsField,
  ]);

  useEffect(() => {
    if (_.isNumber(watchSegmentFacilityAreaExpressionCalculationField?.count)) {
      setFacilityAreaTotalHits(watchSegmentFacilityAreaExpressionCalculationField?.count);
    } else {
      setFacilityAreaTotalHits(null);
    }
  }, [watchSegmentFacilityAreaExpressionCalculationField?.count]);

  const handleAppendTargetFacilityAreaCondition = () =>
    append({
      groupingOperator: defaultSegmentGroupingOperator,
    });

  const handleRemoveTargetFacilityAreaCondition = (params) => remove(params);

  return (
    <>
      {errors?.[fieldName] && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errors?.[fieldName]?.message || 'One or More Facility Area Conditions Contain Errors'}
        </Alert>
      )}

      <div>
        {_.isEmpty(fields) ? (
          <Alert severity="info" sx={{ mb: 3 }}>
            No Facility Area Conditions Added
          </Alert>
        ) : (
          <div>
            {fields.map((item, index) => (
              <SegmentDefinitionViewFormTargetFacilityAreaConditionsItem
                fieldName={fieldName}
                index={index}
                item={item}
                key={item.id}
                onRemove={handleRemoveTargetFacilityAreaCondition}
              />
            ))}
          </div>
        )}

        <div className="flex flex-row w-full">
          <div className="flex flex-col">
            <div className="flex flex-col items-center w-128" style={{ paddingRight: 42 }}>
              <div className="w-1 h-36 bg-light-blue-400" />
            </div>

            <div className="flex justify-end w-full">
              <div className="h-1 bg-light-blue-400" style={{ width: 85 }} />
            </div>
          </div>

          <div className="flex items-center w-full h-40 my-16">
            <div className="flex-1">
              <Button
                color="info"
                disabled={isSubmitting || !watchIsEditableField}
                startIcon={<AddCircleOutlineIcon />}
                variant="contained"
                onClick={handleAppendTargetFacilityAreaCondition}
              >
                Add Facility Area Condition
              </Button>
            </div>

            <div className="flex items-center mr-16">
              <Typography className="mr-6 text-15 font-500">Total Facility Areas:</Typography>

              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <Typography className="text-15 font-500">
                  {_.isNumber(facilityAreaTotalHits)
                    ? numeral(facilityAreaTotalHits).format(
                        facilityAreaTotalHits < 1000 ? '0a' : '0.0a'
                      )
                    : '-'}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SegmentDefinitionViewFormTargetFacilityAreaConditions;
