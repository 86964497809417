import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_SCHEDULE_SERVICE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const FETCH_WORK_ORDER_ISSUE_SCHEDULE_SERVICE_VISIT_DIALOG_DATA = gql`
  query FetchWorkOrderIssueScheduleServiceVisitDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueScheduleServiceVisitDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_SCHEDULE_SERVICE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const SCHEDULE_SERVICE_VISIT_WORK_ORDER_ISSUE = gql`
  mutation ScheduleServiceVisitWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueScheduleServiceVisitInput!
  ) {
    scheduleServiceVisitWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueScheduleServiceVisitDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_SCHEDULE_SERVICE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
