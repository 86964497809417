import { createSlice } from '@reduxjs/toolkit';

const stateSlice = createSlice({
  name: 'activityStreamPanel/state',
  initialState: false,
  reducers: {
    toggleActivityStreamPanel: (state, action) => !state,
    openActivityStreamPanel: (state, action) => true,
    closeActivityStreamPanel: (state, action) => false,
  },
});

export const { toggleActivityStreamPanel, openActivityStreamPanel, closeActivityStreamPanel } =
  stateSlice.actions;

export const selectActivityStreamPanelState = ({ activityStreamPanel }) =>
  activityStreamPanel.state;

export default stateSlice.reducer;
