import React, { useCallback, useRef } from 'react';
import _ from '@lodash';
import { gql, useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';

const { Autodesk } = window;
const AutodeskViewerContext = React.createContext();

const AutodeskViewerProvider = ({ children }) => {
  const apolloClient = useApolloClient();
  const autodeskViewerRef = useRef([]);
  const initialized = useRef(false);
  const user = useSelector(selectUser);

  const getAccessToken = useCallback(
    async (onTokenReady) => {
      let _autodeskViewerToken;

      if (user) {
        const { data: autodeskViewerAuthenticationData } = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: gql`
            query GetAutodeskViewerAuthenticationData(
              $where: AutodeskViewerAuthenticationWhereInput!
            ) {
              getAutodeskViewerAuthentication(where: $where) {
                id
                token
              }
            }
          `,
          variables: {
            where: {
              user: { id: user.id },
            },
          },
        });

        _autodeskViewerToken = _.get(
          autodeskViewerAuthenticationData,
          'getAutodeskViewerAuthentication.token'
        );
      }

      onTokenReady(_autodeskViewerToken, 3600);
    },
    [apolloClient, user]
  );

  const createAutodeskViewer = useCallback(
    ({ htmlElement }) => {
      if (!initialized.current) {
        Autodesk.Viewing.Initializer(
          {
            api: 'streamingV2',
            env: 'AutodeskProduction2',
            getAccessToken,
          },
          () => {
            autodeskViewerRef.current = new Autodesk.Viewing.GuiViewer3D(htmlElement);

            const startedCode = autodeskViewerRef.current.start();

            if (startedCode === 0) {
              console.log('// FIXME: Initialization complete, loading a model next...');

              var documentId = 'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGV2LXVsdHJhdmlvbGV0LWxpYnJhcmlhbi80ZmVhODA3Ny0xZTNjLTRiZjMtODVlNS1mZWZhY2VlNmViMDIuemlw';
              // var documentId = 'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXktYnVja2V0L215LWF3ZXNvbWUtZm9yZ2UtZmlsZS5ydnQ';
              Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

              function onDocumentLoadSuccess(viewerDocument) {
                  var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
                  autodeskViewerRef.current.loadDocumentNode(viewerDocument, defaultModel);
              }

              function onDocumentLoadFailure() {
                  console.error('Failed fetching Forge manifest');
              }

              initialized.current = true;
            }
          }
        );
      }
    },
    [getAccessToken]
  );

  // FIXME: Doesn't Work?
  const destroyAutodeskViewer = useCallback(() => {
    autodeskViewerRef.current?.finish();
    autodeskViewerRef.current = null;
    initialized.current = false;

    Autodesk.Viewing.shutdown();
  }, []);

  return (
    <AutodeskViewerContext.Provider
      value={{
        createAutodeskViewer,
        destroyAutodeskViewer,
      }}
    >
      {children}
    </AutodeskViewerContext.Provider>
  );
};

export const __AutodeskViewerContext = AutodeskViewerContext;
export default AutodeskViewerProvider;
