import {
  faArrowTrendUp,
  faBan,
  faCalendarCheck,
  faCalendarDay,
  faCalendarXmark,
  faClipboardCheck,
  faClipboardList,
  faClipboardQuestion,
  faFileContract,
  faFileInvoiceDollar,
  faHandHoldingDollar,
  faListCheck,
  faMagnifyingGlassChart,
  faMagnifyingGlassDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeActivityStreamPanel } from '../store/stateSlice';

const ActivityStreamPanelListItem = ({ activity }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();

  const serviceReferenceNumber = useMemo(
    () => activity?.serviceTicket?.serviceReferenceNumber,
    [activity?.serviceTicket?.serviceReferenceNumber]
  );

  const timeFormatted = useMemo(() => {
    if (!activity?.time) return null;

    const activityTimeMoment = moment(`${activity?.time}Z`);

    if (activityTimeMoment.isBefore(moment().startOf('day'))) {
      return activityTimeMoment.format('DD/MM');
    }

    return activityTimeMoment.format('HH:mm');
  }, [activity?.time]);

  const { body, buttonLink, buttonText, icon, leadIn, title } = useMemo(() => {
    const content = {};

    if (activity?.verb === 'SERVICE_INVOICE_GENERATED') {
      content.icon = faFileInvoiceDollar;
      content.title = 'Invoice Sent';
      content.leadIn = `FM Invoice Sent by ${activity?.serviceTicket?.vendor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          <div className="mb-12">
            <Typography className="text-11">Invoice Sent At:</Typography>
            <Typography className="text-13">
              {moment(activity?.serviceInvoice?.createdAt).format('MMM. DD, YYYY @ hh:mm A')}
            </Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/service-invoices/view/${activity?.serviceInvoice?.id}`;
      content.buttonText = 'View Invoice';
    } else if (activity?.verb === 'SERVICE_INVOICE_REVIEW_COMPLETED') {
      content.icon = faFileContract;
      content.title = 'Invoice Approved';
      content.leadIn = `FM Invoice Approved by ${activity?.serviceTicket?.consumer?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          <div className="mb-12">
            <Typography className="text-11">Invoice Approved At:</Typography>
            <Typography className="text-13">
              {moment(activity?.serviceInvoiceReview?.completedAt).format(
                'MMM. DD, YYYY @ hh:mm A'
              )}
            </Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/service-invoices/view/${activity?.serviceInvoice?.id}`;
      content.buttonText = 'View Invoice';
    } else if (activity?.verb === 'SERVICE_TICKET_CANCELLED') {
      content.icon = faBan;
      content.title = 'WO Request Cancelled';
      content.leadIn = `FM WO Request Cancelled by ${activity?.serviceTicket?.consumer?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = null;
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View Work Order';
    } else if (activity?.verb === 'SERVICE_TICKET_IN_PROGRESS_PHASE_ENDED') {
      content.icon = faListCheck;
      content.title = 'WO Request Closed';
      content.leadIn = `FM WO Request Closed by ${activity?.serviceTicket?.vendor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = null;
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View Work Order';
    } else if (activity?.verb === 'SERVICE_TICKET_PHASE_REVIEW_COMPLETED') {
      if (
        activity?.serviceTicket?.requestor?.id === activity?.serviceTicket?.vendor?.id &&
        activity?.serviceTicketPhaseReview?.serviceTicketPhase?.enum === 'APPROVAL'
      ) {
        content.icon = faMagnifyingGlassDollar;
        content.title = 'Vendor Proposal Decision';
        content.leadIn = `FM Vendor Proposal Decision by ${activity?.serviceTicket?.consumer?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
        content.body = (
          <>
            <div className="mb-12">
              <Typography className="text-11">Decision:</Typography>
              <Typography className="text-13">
                {activity?.serviceTicketPhaseReview?.decision?.name}
              </Typography>
            </div>
          </>
        );
        content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
        content.buttonText = 'View Work Order';
      } else if (
        activity?.serviceTicket?.requestor?.id === activity?.serviceTicket?.consumer?.id &&
        activity?.serviceTicketPhaseReview?.serviceTicketPhase?.enum === 'QUOTING'
      ) {
        content.icon = faMagnifyingGlassDollar;
        content.title = 'RFQ Quote Decision';
        content.leadIn = `FM RFQ Quote Decision by ${activity?.serviceTicket?.consumer?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
        content.body = (
          <>
            <div className="mb-12">
              <Typography className="text-11">Decision:</Typography>
              <Typography className="text-13">
                {activity?.serviceTicketPhaseReview?.decision?.name}
              </Typography>
            </div>
          </>
        );
        content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
        content.buttonText = 'View Work Order';
      }
    } else if (activity?.verb === 'SERVICE_TICKET_PHASE_REVIEW_CREATED') {
      if (
        activity?.serviceTicket?.requestor?.id === activity?.serviceTicket?.vendor?.id &&
        activity?.serviceTicketPhaseReview?.serviceTicketPhase?.enum === 'APPROVAL'
      ) {
        content.icon = faHandHoldingDollar;
        content.title = 'Vendor Proposal Submitted';
        content.leadIn = `FM Vendor Proposal Submitted by ${activity?.serviceTicket?.vendor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
        content.body = (
          <>
            <div className="mb-12">
              <Typography className="text-11">Action Required:</Typography>
              <Typography className="text-13">Record Decision on Vendor Proposal</Typography>
            </div>

            <div className="mb-12">
              <Typography className="text-11">Responsible Organization:</Typography>
              <Typography className="text-13">{activity?.serviceTicket?.consumer?.name}</Typography>
            </div>
          </>
        );
        content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
        content.buttonText = 'View Proposal';
      } else if (
        activity?.serviceTicket?.requestor?.id === activity?.serviceTicket?.consumer?.id &&
        activity?.serviceTicketPhaseReview?.serviceTicketPhase?.enum === 'QUOTING'
      ) {
        content.icon = faClipboardList;
        content.title = 'RFQ Quote Submitted';
        content.leadIn = `FM RFQ Quote Submitted by ${activity?.serviceTicket?.vendor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
        content.body = (
          <>
            <div className="mb-12">
              <Typography className="text-11">Action Required:</Typography>
              <Typography className="text-13">Record Decision on Vendor Quote</Typography>
            </div>

            <div className="mb-12">
              <Typography className="text-11">Responsible Organization:</Typography>
              <Typography className="text-13">{activity?.serviceTicket?.consumer?.name}</Typography>
            </div>
          </>
        );
        content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
        content.buttonText = 'View Quote';
      }
    } else if (activity?.verb === 'SERVICE_TICKET_QUOTING_PHASE_STARTED') {
      content.icon = faClipboardQuestion;
      content.title = 'RFQ Created';
      content.leadIn = `FM RFQ Created by ${activity?.serviceTicket?.requestor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          <div className="mb-12">
            <Typography className="text-11">Action Required:</Typography>
            <Typography className="text-13">Submit Quote</Typography>
          </div>

          <div className="mb-12">
            <Typography className="text-11">Responsible Organization:</Typography>
            <Typography className="text-13">{activity?.serviceTicket?.vendor?.name}</Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View RFQ';
    } else if (activity?.verb === 'SERVICE_TICKET_SCHEDULING_PHASE_ENDED') {
      content.icon = faCalendarCheck;
      content.title = 'WO Request Scheduled';
      content.leadIn = `FM WO Request Scheduled by ${activity?.serviceTicket?.vendor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          {activity?.serviceVisits?.map((serviceVisit) => (
            <div key={serviceVisit?.id} className="mb-12">
              <Typography className="text-11">Scheduled Date/Time:</Typography>
              <Typography className="text-13">
                {moment(serviceVisit?.windowStartAt).format('MMM. DD, YYYY @ hh:mm A')}
              </Typography>
              <Typography className="text-13">
                {moment(serviceVisit?.windowEndAt).format('MMM. DD, YYYY @ hh:mm A')}
              </Typography>

              <List sx={{ listStyleType: 'disc', pl: 2 }}>
                {serviceVisit?.workOrderIssues?.map((workOrderIssue) => (
                  <ListItem key={workOrderIssue?.id} sx={{ display: 'list-item' }}>
                    <ListItemText
                      primary={workOrderIssue?.serviceIssue?.name}
                      primaryTypographyProps={{ className: 'text-13' }}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          ))}

          <Divider className="mb-12" />

          <div className="mb-12">
            <Typography className="text-11">Action Required:</Typography>
            <Typography className="text-13">Complete Work Order</Typography>
          </div>

          <div className="mb-12">
            <Typography className="text-11">Responsible Organization:</Typography>
            <Typography className="text-13">{activity?.serviceTicket?.vendor?.name}</Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View Work Order';
    } else if (activity?.verb === 'SERVICE_TICKET_SCHEDULING_PHASE_STARTED') {
      content.icon = faCalendarDay;
      content.title = 'WO Request Created';
      content.leadIn = `FM WO Request Created by ${activity?.serviceTicket?.requestor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          <div className="mb-12">
            <Typography className="text-11">Action Required:</Typography>
            <Typography className="text-13">Schedule WO Request</Typography>
          </div>

          <div className="mb-12">
            <Typography className="text-11">Responsible Organization:</Typography>
            <Typography className="text-13">{activity?.serviceTicket?.vendor?.name}</Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View WO Request';
    } else if (activity?.verb === 'WORK_ORDER_ISSUE_CANCELLED') {
      content.icon = faBan;
      content.title = 'WO Issue Cancelled';
      content.leadIn = `FM WO Issue Cancelled by ${activity?.serviceTicket?.consumer?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          <div className="mb-12">
            <Typography className="text-11">Service Issue:</Typography>
            <Typography className="text-13">
              {activity?.workOrderIssue?.serviceIssue?.name}
            </Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View Work Order';
    } else if (activity?.verb === 'WORK_ORDER_ISSUE_COMPLETED') {
      content.icon = faClipboardCheck;
      content.title = 'WO Issue Completed';
      content.leadIn = `FM WO Issue Completed by ${activity?.serviceTicket?.vendor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          <div className="mb-12">
            <Typography className="text-11">Service Issue:</Typography>
            <Typography className="text-13">
              {activity?.workOrderIssue?.serviceIssue?.name}
            </Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View Work Order';
    } else if (activity?.verb === 'WORK_ORDER_ISSUE_RESCHEDULED') {
      content.icon = faCalendarXmark;
      content.title = 'WO Issue Rescheduled';
      content.leadIn = `FM WO Issue Rescheduled by ${activity?.serviceTicket?.vendor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          {activity?.serviceVisits?.map((serviceVisit) => (
            <div key={serviceVisit?.id} className="mb-12">
              <Typography className="text-11">Scheduled Date/Time:</Typography>
              <Typography className="text-13">
                {moment(serviceVisit?.windowStartAt).format('MMM. DD, YYYY @ hh:mm A')}
              </Typography>
              <Typography className="text-13">
                {moment(serviceVisit?.windowEndAt).format('MMM. DD, YYYY @ hh:mm A')}
              </Typography>

              <List sx={{ listStyleType: 'disc', pl: 2 }}>
                {serviceVisit?.workOrderIssues?.map((workOrderIssue) => (
                  <ListItem key={workOrderIssue?.id} sx={{ display: 'list-item' }}>
                    <ListItemText
                      primary={workOrderIssue?.serviceIssue?.name}
                      primaryTypographyProps={{ className: 'text-13' }}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          ))}

          <Divider className="mb-12" />

          <div className="mb-12">
            <Typography className="text-11">Action Required:</Typography>
            <Typography className="text-13">Complete Work Order</Typography>
          </div>

          <div className="mb-12">
            <Typography className="text-11">Responsible Organization:</Typography>
            <Typography className="text-13">{activity?.serviceTicket?.vendor?.name}</Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View Work Order';
    } else if (activity?.verb === 'WORK_ORDER_ISSUE_REVIEW_COMPLETED') {
      content.icon = faMagnifyingGlassChart;
      content.title = 'NTE Increase Decision';
      content.leadIn = `FM WO Issue NTE Increase Decision by ${activity?.serviceTicket?.consumer?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          <div className="mb-12">
            <Typography className="text-11">Decision:</Typography>
            <Typography className="text-13">
              {activity?.workOrderIssueReview?.decision?.name}
            </Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View Work Order';
    } else if (activity?.verb === 'WORK_ORDER_ISSUE_REVIEW_CREATED') {
      content.icon = faArrowTrendUp;
      content.title = 'NTE Increase Request';
      content.leadIn = `FM WO Issue NTE Increase Request by ${activity?.serviceTicket?.vendor?.name} for: ${activity?.facility?.franchiseNumber} - ${activity?.facility?.name}`;
      content.body = (
        <>
          <div className="mb-12">
            <Typography className="text-11">Action Required:</Typography>
            <Typography className="text-13">Record Decision on WO Issue NTE Increase</Typography>
          </div>

          <div className="mb-12">
            <Typography className="text-11">Responsible Organization:</Typography>
            <Typography className="text-13">{activity?.serviceTicket?.consumer?.name}</Typography>
          </div>
        </>
      );
      content.buttonLink = `/service-tickets/view/${activity?.serviceTicket?.id}/activity/work-order`;
      content.buttonText = 'View Work Order';
    }

    return content;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity?.id]);

  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    handleCloseListItemButton();
    handleCloseActivityStreamPanel();

    if (buttonLink) {
      navigate(buttonLink);
    }
  };

  const handleCloseActivityStreamPanel = () => {
    dispatch(closeActivityStreamPanel());
  };

  const handleCloseListItemButton = () => {
    setIsAnimating(true);
    setExpanded(false);
  };

  // ROADMAP: Implement Setting Activity as Read
  const handleOpenListItemButton = () => {
    setIsAnimating(true);
    setExpanded(true);
  };

  const handleToggleListItemButton = () => {
    if (expanded) {
      handleCloseListItemButton();
    } else {
      handleOpenListItemButton();
    }
  };

  return (
    <ListItemButton divider onClick={handleToggleListItemButton}>
      <div className="flex flex-col w-full">
        <div className="flex flex-1 items-center h-40">
          <ListItemAvatar>
            <Avatar src={null}>{icon && <FontAwesomeIcon icon={icon} />}</Avatar>
          </ListItemAvatar>

          <div className="flex flex-1 flex-col">
            <div className="flex flex-row">
              <div className="flex-1">
                <Typography className="text-11">{`SRN: ${serviceReferenceNumber}`}</Typography>
              </div>

              <div>
                <Typography className="text-11">{timeFormatted}</Typography>
              </div>
            </div>

            <div>
              <Typography className="text-14 font-500">{title}</Typography>
            </div>
          </div>
        </div>

        <Collapse
          collapsedSize={18}
          in={expanded}
          timeout="auto"
          unmountOnExit={false}
          onEntered={() => setIsAnimating(false)}
          onExited={() => setIsAnimating(false)}
        >
          <div className="ml-56">
            <div className="mb-24">
              <Typography className="text-13" noWrap={!expanded && !isAnimating}>
                {leadIn}
              </Typography>
            </div>

            {body && (
              <>
                <Divider className="mb-12" />

                <div>{body}</div>

                <Divider className="mb-24" />
              </>
            )}

            <div className="flex itemx-center mb-12">
              <Button color="inherit" size="small" variant="outlined" onClick={handleButtonClick}>
                {buttonText}
              </Button>
            </div>
          </div>
        </Collapse>
      </div>
    </ListItemButton>
  );
};

export default ActivityStreamPanelListItem;
