import React from 'react';
import { __StreamChatContext } from './StreamChatProvider';

const useStreamChat = () => {
  const context = React.useContext(__StreamChatContext);

  if (context === undefined) {
    throw new Error('useStreamChat must be used within a StreamChatProvider');
  }

  return context;
};

export default useStreamChat;
