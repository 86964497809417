import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_CANCEL_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CANCEL_WORK_ORDER_ISSUE = gql`
  mutation CancelWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueCancelInput!
  ) {
    cancelWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueCancelDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_CANCEL_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
