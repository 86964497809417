import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeBlockUi,
  openBlockUi,
  selectBlockUiOptions,
  selectBlockUiState,
} from 'app/store/blockUiSlice';

const ServiceProvisionManageDrawer = ({ width, onClose }) => {
  const dispatch = useDispatch();

  const mockFetch = async () => {
    dispatch(openBlockUi());

    await new Promise((resolve) =>
      setTimeout(() => {
        dispatch(closeBlockUi());
        resolve();
      }, 3000)
    );
  };

  return (
    <div className="bg-red" style={{ width }}>
      <Button onClick={onClose}>Close Drawer</Button>

      <Button onClick={mockFetch}>Mock Fetch</Button>
    </div>
  );
};

export default ServiceProvisionManageDrawer;
