import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import { useStreamChat } from 'app/providers/stream/chat';
import {
  ProgramOverviewApp,
  ProgramOverviewHeader,
  ProgramViewDrawer,
} from 'app/shared-components/Program';
import {
  ProgramCampaignViewApp,
  ProgramCampaignViewHeader,
} from 'app/shared-components/ProgramCampaign';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chat } from 'stream-chat-react';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

const LEFT_SIDEBAR_CONTENT_WIDTH = 341;
const RIGHT_SIDEBAR_CONTENT_WIDTH = 481;

const ProgramViewScreenRoot = () => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();

  const basePath = `${params?.sectionName}/${params?.viewName}`;

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const handleCloseLeftSidebar = () => setLeftSidebarOpen(false);

  const handleToggleLeftSidebar = () => setLeftSidebarOpen(!leftSidebarOpen);

  const handleCloseRightSidebar = () => navigate(`../${basePath}`);

  return (
    <Root
      header={
        <>
          {basePath === 'activity/overview' && (
            <ProgramOverviewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {basePath === 'activity/campaigns' && (
            <ProgramCampaignViewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {/* TODO: Add Rest... */}
        </>
      }
      content={
        <>
          {basePath === 'activity/overview' && <ProgramOverviewApp basePath={basePath} />}

          {/* TODO: */}
          {basePath === 'activity/campaigns' && <ProgramOverviewApp basePath={basePath} />}

          {/* TODO: */}
          {basePath === 'activity/issueTemplates' && <ProgramOverviewApp basePath={basePath} />}

          {/* TODO: */}
          {basePath === 'activity/timeline' && <ProgramOverviewApp basePath={basePath} />}

          {/* TODO: */}
          {basePath === 'activity/file-attachments' && <ProgramOverviewApp basePath={basePath} />}
        </>
      }
      leftSidebarContent={
        <ProgramViewDrawer
          basePath={basePath}
          contentWidth={LEFT_SIDEBAR_CONTENT_WIDTH}
          onToggleDrawer={handleToggleLeftSidebar}
        />
      }
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={LEFT_SIDEBAR_CONTENT_WIDTH}
      leftSidebarOnClose={handleCloseLeftSidebar}
      rightSidebarContent={
        <>
          {/* TODO: */}
          {/* {basePath === 'activity/tasks' && (
            <ProgramTaskViewDrawer
              basePath={basePath}
              contentWidth={RIGHT_SIDEBAR_CONTENT_WIDTH}
              onCloseDrawer={handleCloseRightSidebar}
            />
          )} */}

          {/* TODO: Add Rest... */}
        </>
      }
      // TODO:
      rightSidebarOpen={!!params?.viewId && basePath !== 'chat/channels'}
      rightSidebarWidth={RIGHT_SIDEBAR_CONTENT_WIDTH}
      rightSidebarOnClose={handleCloseRightSidebar}
      scroll="content"
    />
  );
};

const ProgramViewScreen = () => {
  // TODO: Remove?
  const { streamChatClient } = useStreamChat();
  const theme = useTheme();

  if (!streamChatClient) return null;

  return (
    <Chat client={streamChatClient} theme={`str-chat__theme-${theme.palette.mode}`}>
      <ProgramViewScreenRoot />
    </Chat>
  );
};

export default ProgramViewScreen;
