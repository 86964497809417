import FusePageSimple from '@fuse/core/FusePageSimple';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { AppBar, Box, Button, IconButton, InputBase, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Paper, Toolbar, Typography } from '@mui/material';
import { darken, lighten, styled, useTheme } from '@mui/material/styles';
import { useStreamChat } from 'app/providers/stream/chat';
import { CommonChatChannelHeader, CommonChatChannelListPreview } from 'app/shared-components/Common/CommonChat';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import {
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelListMessenger,
  ChannelSearch,
  Chat,
  ChatDown,
  getChannel,
  LoadingChannels,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelActionContext,
  useChannelListContext,
  useChatContext,
} from 'stream-chat-react';

import { ChatChannelCreateDialog } from 'app/shared-components/ChatChannel/ChatChannelCreateDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

const LEFT_SIDEBAR_CONTENT_WIDTH = 399; // 341
// const RIGHT_SIDEBAR_CONTENT_WIDTH = 481;

// TODO: Rename?
const ChatAppScreenHeader = (props) => {
  const user = useSelector(selectUser);

  console.log({ user });
  return (
    // <div className="flex justify-between items-center mb-16">
    //     {user && (
    //       <div
    //         className="flex items-center cursor-pointer"
    //         // onClick={() => setUserSidebarOpen(true)}
    //         // onKeyDown={() => setUserSidebarOpen(true)}
    //         role="button"
    //         tabIndex={0}
    //       >
    //         {/* <ContactAvatar className="relative" contact={user} /> */}
    //         {/* TODO: */}
    //         <Typography className="mx-16 font-medium">{user.data?.user?.name}</Typography>
    //       </div>
    //     )}

    //     {/* <MainSidebarMoreMenu className="-mx-16" /> */}
    //   </div>
    // <AppBar position="static">
    //   <Toolbar>
    //  <ChannelSearch {...props} />
    //   </Toolbar>
    // </AppBar>
    <Box
      className="border-b-1"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? lighten(theme.palette.background.default, 0.4)
            : lighten(theme.palette.background.default, 0.02),
      }}
    >
      <ChannelSearch {...props} />

      {/* TODO: */}
      {/* <IconButton
        color="inherit"
        size="large"
        onClick={() =>
          dispatch(
            openDialog({
              children: (
                <ChatChannelCreateDialog
                  // TODO:
                  // serviceTicketId={params?.serviceTicketId}
                  onClose={() => dispatch(closeDialog())}
                  // TODO: Review
                  onCreate={({ chatChannel }) => {
                    setActiveChannel(
                      client.channel(chatChannel?.type.enum.toLowerCase(), chatChannel?.id)
                    );

                    dispatch(openChatPanel());
                  }}
                />
              ),
              classes: {
                paper: 'w-full max-w-640 min-w-320 rounded-8',
              },
            })
          )
        }
      >
        <FuseSvgIcon>heroicons-outline:plus-circle</FuseSvgIcon>
      </IconButton> */}
    </Box>
  );
};

const Bob = ({ open }) => {
  const [messageText, setMessageText] = useState('');
  const { client } = useChatContext();
  const user = useSelector(selectUser);
  const [results, setResults] = useState(null);
  const { jumpToMessage } = useChannelActionContext();

  useEffect(() => {
    const bob2 = async () => {
      const filters = {
        // TODO:
        // type: 'messaging',
        members: { $in: [user?.id] },
      };

      const search = await client.search(
        filters,
        // TODO:
        messageText || '',
        // { limit: 2, offset: 0 },
     );

     console.log({ search });
     setResults(search?.results || []);
    }

    if (
      client &&
      user &&
      // FIXME:
      !!messageText.length
    ) {
      bob2();
    }
  }, [client, messageText, user]);

  const onInputChange = (ev) => {
    setMessageText(ev.target.value);
  };

  if (!open) return null;

  return (
    <div style={{ width: 408 }}>
      <AppBar
        className="border-l border-b shadow-md"
        color="default"
        position="static"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? '#ffffff' : '#17191c'),
        }}
      >
        <Toolbar className="px-4">
          <div
            className="flex flex-1 items-center space-x-12"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            Search in Chat
          </div>
          <div className="flex items-center px-4 z-20">
          {/* TODO: onClick */}
          <IconButton onClick={(ev) => null} color="inherit" size="large">
            <FuseSvgIcon>heroicons-outline:plus-circle</FuseSvgIcon>
          </IconButton>

          <div className="mx-4 w-1 h-28 bg-white" />

          <IconButton
            // onClick={(ev) => dispatch(closeChatPanel())}
            color="inherit"
            size="large"
          >
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>
        </div>
        </Toolbar>
      </AppBar>

      <div className="">
        {/* <form
          onSubmit={onMessageSubmit}
          className="pb-16 px-8 absolute bottom-0 left-0 right-0"
        > */}
          <Paper className="rounded-24 flex items-center relative shadow">
            <InputBase
              autoFocus={false}
              id="message-input"
              className="flex flex-1 grow shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-8"
              placeholder="Type your message"
              onChange={onInputChange}
              value={messageText}
            />
            <IconButton
              className="absolute ltr:right-0 rtl:left-0 top-0"
              type="submit"
              size="large"
            >
              <FuseSvgIcon className="rotate-90" color="action">
                heroicons-outline:paper-airplane
              </FuseSvgIcon>
            </IconButton>
          </Paper>
        {/* </form> */}

        {/* TODO: */}
        {results && (
          <List>
            {results?.map(({ message }) => (
              <ListItemButton onClick={() => jumpToMessage(message?.id)}>
                <ListItemText primary={message?.user?.name} secondary={message?.text} />
              </ListItemButton>
            ))}
          </List>
        )}
      </div>
    </div>
  );
};

const DEFAULT_CHANNEL_ID = 'general';
// const DEFAULT_CHANNEL_TYPE = 'messaging';

const RenameList = (props) => {
  const { channelId, channelType } = useParams();
  const navigate = useNavigate();
  const { client, channel, setActiveChannel } = useChatContext();
  const { setChannels } = useChannelListContext();

  // TODO: Review
  const init = useRef(false);
  useEffect(() => {
    let subscription;

    if (!channelId || !channelType) {
      setActiveChannel(null);
    } else if (client && (!channel?.id || channel?.id !== channelId)) {
      if (init.current) {
        setActiveChannel(client.channel(channelType, channelId));
      } else {
        subscription = client.on('channels.queried', (event) => {
          const loadedChannelData = event.queriedChannels?.channels.find(
            (response) => response.channel.id === channelId
          );

          if (loadedChannelData) {
            setActiveChannel(client.channel(channelType, channelId));
            subscription?.unsubscribe();
            return;
          }

          // FIXME:
          // eslint-disable-next-line consistent-return
          return getChannel({ client, id: channelId, type: channelType }).then((newActiveChannel) => {
            setActiveChannel(newActiveChannel);
            setChannels((channels) => {
              return [
                newActiveChannel,
                ...channels.filter((ch) => ch.data?.cid !== newActiveChannel.data?.cid),
              ];
            });
          });
        });
      }
    }

    init.current = true;

    return () => {
      subscription?.unsubscribe();
    };
  }, [channel?.id, channelId, channelType, client, setActiveChannel, setChannels]);

  return <ChannelListMessenger {...props}/>;
};

const ChatAppMenu = ({ close, ...rest }) => {

  console.log({ rest });
  const handleClosePresetMenu = (event) => close?.();

  return (
    <>
      <Menu
        // TODO:
        // anchorEl={presetMenuAnchorEl}
        id="preset-menu"
        open
        onClose={handleClosePresetMenu}
      >
        <MenuItem
          // selected={!currentRefinement}
          onClick={() => {
            handleClosePresetMenu();

            // reset();
          }}
        >
          <ListItemText>All Date Ranges</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const ChatAppScreenRoot = () => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();
  const rootRef = useRef(null);
  const theme = useTheme();

  // TODO:
  const { client, channel, setActiveChannel } = useChatContext();

  // TODO:
  const user = useSelector(selectUser);
  // const fetched = true;
  const filters = {
    // TODO:
    // type: 'messaging',
    members: { $in: [user?.id] },
  };
  // const loading = false;
  // const options = {
  //   limit: 10,
  // };
  // const sort = { last_message_at: -1 };
  // ==>

  const basePath = `${params?.sectionName}/${params?.viewName}`;

  useEffect(() => {
    // TODO: Rename
    if (rootRef?.current?.rootRef?.current) {
      rootRef.current.rootRef.current.id = 'chat-app-screen-root';
    }
  }, []);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const handleCloseLeftSidebar = () => setLeftSidebarOpen(false);

  const handleToggleLeftSidebar = () => setLeftSidebarOpen(!leftSidebarOpen);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  return (
    <Root
      ref={rootRef}
      content={
        <>
          <Channel
            // TODO:
            EmptyPlaceholder={
              <div
                className="flex flex-col flex-1 items-center justify-center p-24"
                style={{ backgroundColor: theme.palette.mode === 'dark' ? '#080707' : '#ffffff' }}
              >
                <FuseSvgIcon size={128} color="disabled">
                  heroicons-outline:chat
                </FuseSvgIcon>

                {/* TODO: */}
                <Button
                  className="mt-12"
                  onClick={() =>
                    dispatch(
                      openDialog({
                        children: (
                          <ChatChannelCreateDialog
                            // TODO:
                            // serviceTicketId={params?.serviceTicketId}
                            onClose={() => dispatch(closeDialog())}
                            // TODO: Review
                            onCreate={({ chatChannel }) => {
                              // setActiveChannel(
                              //   client.channel(
                              //     chatChannel?.type.enum.toLowerCase(),
                              //     chatChannel?.id
                              //   )
                              // );
                              navigate(`/apps/chat/${chatChannel?.type.enum.toLowerCase()}/view/${chatChannel?.id}`);
                            }}
                          />
                        ),
                        classes: {
                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                        },
                      })
                    )
                  }

                  // onClick={() =>
                  //   dispatch(
                  //     openDialog({
                  //       children: (
                  //         <ChatChannelCreateDialog
                  //           // TODO:
                  //           // serviceTicketId={params?.serviceTicketId}
                  //           onClose={() => dispatch(closeDialog())}
                  //           // FIXME:
                  //           // onCreate={({ chatChannel }) => {
                  //           //   console.log({
                  //           //     last: chatChannel,
                  //           //     one: {
                  //           //       o: chatChannel?.type.enum.toLowerCase(),
                  //           //       t: chatChannel?.id
                  //           //     }
                  //           //   })
                  //           //   setActiveChannel(
                  //           //     client.channel(
                  //           //       chatChannel?.type.enum.toLowerCase(),
                  //           //       chatChannel?.id
                  //           //     )
                  //           //   );
                  //           // }}
                  //         />
                  //       ),
                  //       classes: {
                  //         paper: 'w-full max-w-640 min-w-320 rounded-8',
                  //       },
                  //     })
                  //   )
                  // }
                >
                  Start a New Conversation
                </Button>
              </div>
            }
          >
            {/* TODO: Review `isMobile` */}
            <Window hideOnThread={isMobile}>
              {/* TODO: */}
              {/* <ChannelHeader /> */}
              <CommonChatChannelHeader onSearchClick={toggleSearch} />
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />

            <Bob open={isSearchOpen} />

          </Channel>
        </>
      }
      leftSidebarContent={
        <>
          <ChannelList
            // FIXME: Wrong
            // channelRenderFilterFn={(channels) => {
            //   return channels?.filter((channel) => {
            //     return Boolean(['messaging'].includes(channel.type));
            //   });
            // }}
            additionalChannelSearchProps={{
              // TODO:
              // AppMenu: ChatAppMenu,
              ExitSearchIcon: () => <FuseSvgIcon>heroicons-outline:arrow-left</FuseSvgIcon>,
              // TODO:
              popupResults: true,
              searchForChannels: true,
              searchQueryParams: {
                // TODO:
                channelFilters: {
                  filters: {
                    // TODO:
                    ...filters,
                  },
                  // sort: {},
                  // options: {},
                },
                // TODO:
                userFilters: {
                  filters: {
                    // TODO:
                    // team: user?.data?.team.id,
                    block: 'me',
                  },
                  // sort: {},
                  // options: {},
                },
              },
              // TODO:
              // SearchResultItem: CommonChatChannelListPreview,
            }}
            ChannelSearch={ChatAppScreenHeader}
            // customActiveChannel={'ca063e3a-2008-4ac4-9bd2-ef295f66ab78'}
            // TODO:
            filters={filters}
            // TODO:
            List={RenameList}
            // TODO:
            // options={options}
            Preview={CommonChatChannelListPreview}
            setActiveChannelOnMount={false}
            showChannelSearch
            // TODO:
            // sort={sort}
          />
        </>
      }
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={LEFT_SIDEBAR_CONTENT_WIDTH}
      leftSidebarOnClose={handleCloseLeftSidebar}
      scroll="content"
    />
  );
};

const ChatAppScreen = () => {
  const { streamChatClient } = useStreamChat();
  const theme = useTheme();

  if (!streamChatClient) return null;

  return (
    <Chat client={streamChatClient} theme={`str-chat__theme-${theme.palette.mode}`}>
      <ChatAppScreenRoot />
    </Chat>
  );
};

export default ChatAppScreen;
