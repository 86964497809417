import { lazy } from 'react';
import i18next from 'i18next';

import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

const AssetDetails = lazy(() => import('./AssetDetails'));

i18next.addResourceBundle('en', 'assetDetailsPage', en);
i18next.addResourceBundle('tr', 'assetDetailsPage', tr);
i18next.addResourceBundle('ar', 'assetDetailsPage', ar);

const AssetDetailsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'assets/:id',
      element: <AssetDetails />,
    },
  ],
};

export default AssetDetailsConfig;
