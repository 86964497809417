import { useMutation, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ServiceTicketCompleteReleasePhaseDialogWorkOrderIssues } from './components';
import {
  COMPLETE_SERVICE_TICKET_RELEASE_PHASE,
  FETCH_SERVICE_TICKET_COMPLETE_RELEASE_PHASE_DIALOG_DATA,
} from './queries';

const defaultValues = {
  checkedWorkOrderIssues: [],
  selectedWorkOrderIssues: [],
};

const schema = yup.object().shape({
  checkedWorkOrderIssues: yup
    .array()
    .test(
      'requireOneCheckedWorkOrderIssue',
      'One or more work order issues must be provided.',
      (value) => value && value.filter((el) => el).length
    ),
  selectedWorkOrderIssues: yup
    .array()
    .of(
      yup.object().shape({
        decision: yup.object().nullable().required('A decision must be provided.'),
        resumeAt: yup.mixed().when('decision', {
          is: (val) => val?.result?.enum === 'RELEASE_AND_PAUSE',
          then: yup.date().nullable().optional(),
        }),
      })
    )
    .test(
      'requireOneSelectedWorkOrderIssue',
      'One or more work order issues must be provided.',
      (value) => value && value.filter((el) => el).length
    ),
});

const ServiceTicketCompleteReleasePhaseDialog = ({
  defaultWorkOrderIssueId,
  serviceTicketId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchServiceTicketCompleteReleasePhaseDialogData,
    {
      data: serviceTicketCompleteReleasePhaseDialogData,
      loading: serviceTicketCompleteReleasePhaseDialogLoading,
      refetch: serviceTicketCompleteReleasePhaseDialogRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_COMPLETE_RELEASE_PHASE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [completeServiceTicketReleasePhase, { loading: completeServiceTicketReleasePhaseLoading }] =
    useMutation(COMPLETE_SERVICE_TICKET_RELEASE_PHASE, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Ticket Successfully Released',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Releasing Ticket', variant: 'error' }));
      },
    });

  const defaultWorkOrderIssueReleasePhaseDecision = useMemo(() => {
    const result =
      serviceTicketCompleteReleasePhaseDialogData?.workOrderIssueReleasePhaseDecisions?.find(
        ({ name }) => name === 'Release'
      );

    return (
      result && {
        result,
        label: result?.name,
        value: result?.id,
      }
    );
  }, [serviceTicketCompleteReleasePhaseDialogData?.workOrderIssueReleasePhaseDecisions]);

  const filteredWorkOrderIssues = useMemo(
    () =>
      serviceTicketCompleteReleasePhaseDialogData?.serviceTicket?.workOrder?.issues
        ?.filter(({ status }) => ['HELD'].includes(status?.enum))
        ?.map((workOrderIssues) => workOrderIssues),
    [serviceTicketCompleteReleasePhaseDialogData?.serviceTicket]
  );

  const loading = useMemo(
    () =>
      [
        completeServiceTicketReleasePhaseLoading,
        serviceTicketCompleteReleasePhaseDialogLoading,
      ].includes(true),
    [completeServiceTicketReleasePhaseLoading, serviceTicketCompleteReleasePhaseDialogLoading]
  );

  const serviceTicket = useMemo(
    () => serviceTicketCompleteReleasePhaseDialogData?.serviceTicket,
    [serviceTicketCompleteReleasePhaseDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (defaultWorkOrderIssueId && filteredWorkOrderIssues) {
      const newDefaultValues = _.cloneDeep(defaultValues);
      const defaultWorkOrderIssueIndex = filteredWorkOrderIssues?.findIndex(
        ({ id }) => id === defaultWorkOrderIssueId
      );

      if (defaultWorkOrderIssueIndex !== -1) {
        newDefaultValues.checkedWorkOrderIssues[defaultWorkOrderIssueIndex] =
          defaultWorkOrderIssueId;
      }

      reset(newDefaultValues);
    }
  }, [defaultWorkOrderIssueId, filteredWorkOrderIssues, reset]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchServiceTicketCompleteReleasePhaseDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchServiceTicketCompleteReleasePhaseDialogData, serviceTicketId]);

  const handleToggleAllWorkOrderIssues = () => {
    if (
      filteredWorkOrderIssues?.length === watchFields.checkedWorkOrderIssues?.length &&
      !_.some(watchFields.checkedWorkOrderIssues || [], (value) => !value)
    ) {
      setValue('checkedWorkOrderIssues', [], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    } else {
      setValue(
        'checkedWorkOrderIssues',
        filteredWorkOrderIssues?.map(({ id }) => id),
        { shouldDirty: true, shouldTouch: true, shouldValidate: true }
      );
    }
  };

  const onSubmit = async (data) => {
    try {
      await completeServiceTicketReleasePhase({
        variables: {
          where: { id: serviceTicketId },
          data: {
            workOrderIssues: data?.selectedWorkOrderIssues
              ?.filter((el) => el)
              ?.map((selectedWorkOrderIssue) => ({
                id: selectedWorkOrderIssue.workOrderIssueId,
                decision: { id: selectedWorkOrderIssue.decision?.value },
                scheduledResumption: selectedWorkOrderIssue.resumeAt
                  ? {
                      resumeAt: selectedWorkOrderIssue.resumeAt,
                      workOrderIssue: { id: selectedWorkOrderIssue.workOrderIssueId },
                    }
                  : undefined,
              })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Complete Release Phase
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <Alert className="mt-20" severity="warning">
          <AlertTitle>Important</AlertTitle>
          Any service issues not selected will be cancelled upon completion.
        </Alert>

        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-ticket-complete-release-phase-form"
            name="service-ticket-complete-release-phase-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="checkedWorkOrderIssues"
              render={({ field }) => (
                <>
                  <div className="mb-24">
                    <div
                      className="border-1 overflow-y-auto"
                      style={{
                        maxHeight: 290,
                        borderColor: errors.checkedWorkOrderIssues ? '#f44336' : grey[300],
                      }}
                    >
                      <List disablePadding>
                        <ListItemButton onClick={handleToggleAllWorkOrderIssues}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                filteredWorkOrderIssues?.length ===
                                  watchFields.checkedWorkOrderIssues?.length &&
                                !_.some(watchFields.checkedWorkOrderIssues || [], (value) => !value)
                              }
                              edge="start"
                              disableRipple
                              indeterminate={
                                (filteredWorkOrderIssues?.length !==
                                  watchFields.checkedWorkOrderIssues?.length &&
                                  _.some(
                                    watchFields.checkedWorkOrderIssues || [],
                                    (value) => value
                                  )) ||
                                (filteredWorkOrderIssues?.length ===
                                  watchFields.checkedWorkOrderIssues?.length &&
                                  _.some(
                                    watchFields.checkedWorkOrderIssues || [],
                                    (value) => value
                                  ) &&
                                  _.some(
                                    watchFields.checkedWorkOrderIssues || [],
                                    (value) => !value
                                  ))
                              }
                              tabIndex={-1}
                              // value={id}
                              onChange={handleToggleAllWorkOrderIssues}
                            />
                          </ListItemIcon>

                          <ListItemText primary="Select All" />
                        </ListItemButton>

                        {filteredWorkOrderIssues?.map(({ id, serviceIssue }, index) => {
                          const handleChange = () => {
                            const newFieldValue = [...field?.value];

                            newFieldValue[index] = !newFieldValue[index] ? id : null;

                            field.onChange(newFieldValue);
                          };

                          return (
                            <ListItemButton key={id} onClick={handleChange}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={!!field?.value?.[index]}
                                  edge="start"
                                  disableRipple
                                  tabIndex={-1}
                                  value={id}
                                  onChange={handleChange}
                                />
                              </ListItemIcon>

                              <ListItemText primary={serviceIssue.name} />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </div>

                    {errors.checkedWorkOrderIssues && (
                      <div className="mx-14 mt-3">
                        <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
                          {errors.checkedWorkOrderIssues?.message}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />

            <ServiceTicketCompleteReleasePhaseDialogWorkOrderIssues
              defaultWorkOrderIssueReleasePhaseDecision={defaultWorkOrderIssueReleasePhaseDecision}
              filteredWorkOrderIssues={filteredWorkOrderIssues}
              name="selectedWorkOrderIssues"
              serviceTicket={serviceTicket}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-ticket-complete-release-phase-form"
          type="submit"
          variant="contained"
        >
          Complete
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceTicketCompleteReleasePhaseDialog;
