// eslint-disable-next-line import/prefer-default-export
export const serviceIssueSearchScreenFilterDefs = [
  {
    name: 'Service Issue',
    refinements: [
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
      {
        attribute: 'serviceResponseSeverity.name',
        label: 'Response Severity',
        operator: 'or',
      },
      {
        attribute: 'tags.name',
        label: 'Tags',
        operator: 'or',
      },
      {
        attribute: 'creator.name',
        label: 'Creator',
        operator: 'or',
      },
      {
        attribute: 'submitter.name',
        label: 'Submitter',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Facility',
    refinements: [
      {
        attribute: 'facility.address.city',
        label: 'City',
        operator: 'or',
      },
      {
        attribute: 'facility.name',
        label: 'Name',
        operator: 'or',
      },
      {
        attribute: 'facility.address.region',
        label: 'Region',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Target',
    refinements: [
      {
        attribute: 'commonTargetType.name',
        label: 'Target Type',
        operator: 'or',
      },
      {
        attribute: 'targetFacilityAreas.facilityArea.name',
        label: 'Facility Area',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Budget / Planning',
    refinements: [
      // ROADMAP: Do Not Show if Vendor
      {
        attribute: 'serviceResponsibleParty.name',
        label: 'Responsible Party',
        operator: 'or',
      },
      // ROADMAP: Do Not Show if Vendor
      {
        attribute: 'plannedForYear',
        label: 'Planned For Year',
        operator: 'or',
      },
    ],
  },
];
