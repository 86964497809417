import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import { Divider, FormControlLabel, Switch, TextField } from '@mui/material';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { IssueTemplateHitsListOption } from 'app/shared-components/IssueTemplate';
import { IssueTemplateTagSelectField } from 'app/shared-components/IssueTemplateTag';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ServiceTicketCreateDialogWorkOrderIssueTemplate = ({
  workOrderIssueFieldName,
  onIssueTemplateSelect,
}) => {
  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext();

  const issueTemplateFieldName = useMemo(
    () => `${workOrderIssueFieldName}.issueTemplate`,
    [workOrderIssueFieldName]
  );

  const isSaveAsIssueTemplateFieldName = useMemo(
    () => `${workOrderIssueFieldName}.isSaveAsIssueTemplate`,
    [workOrderIssueFieldName]
  );

  const saveAsIssueTemplateFieldName = useMemo(
    () => `${workOrderIssueFieldName}.saveAsIssueTemplate`,
    [workOrderIssueFieldName]
  );

  const watchIssueTemplate = watch(issueTemplateFieldName);
  const watchIsSaveAsIssueTemplate = watch(isSaveAsIssueTemplateFieldName);
  const watchSaveAsIssueTemplate = watch(saveAsIssueTemplateFieldName);

  useDeepCompareEffect(() => {
    if (watchIssueTemplate && typeof onIssueTemplateSelect === 'function') {
      onIssueTemplateSelect({ issueTemplate: watchIssueTemplate });
    }
  }, [watchIssueTemplate || {}]);

  return (
    <>
      <div className="flex flex-1 items-center mb-24">
        <Controller
          control={control}
          name={issueTemplateFieldName}
          render={({ field }) => (
            <EntitySearchSelectField
              {...field}
              className="flex-1"
              components={{ Option: IssueTemplateHitsListOption }}
              error={_.get(errors, issueTemplateFieldName)}
              filter={[
                // ROADMAP: Expand Filter to Include FacilityAreaType
                `status.enum = 'ACTIVE'`,
              ]}
              idField="id"
              indexName="issue_templates"
              isClearable
              isDisabled={false}
              isMulti={false}
              placeholder="Select Issue Template..."
              nameField="name"
              sort={['name:asc']}
            />
          )}
        />

        <Controller
          control={control}
          name={isSaveAsIssueTemplateFieldName}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  checked={!!field.value}
                  color="info"
                  disabled={!!watchIssueTemplate}
                  onChange={(params) => {
                    setValue(`${saveAsIssueTemplateFieldName}.name`, '', {
                      shouldValidate: !!field.value,
                    });
                    setValue(`${saveAsIssueTemplateFieldName}.description`, '', {
                      shouldValidate: !!field.value,
                    });
                    setValue(`${saveAsIssueTemplateFieldName}.tags`, [], {
                      shouldValidate: !!field.value,
                    });

                    field?.onChange(params);
                  }}
                />
              }
              label="Save"
              labelPlacement="start"
              sx={{ ml: 3 }}
            />
          )}
        />
      </div>

      {watchIsSaveAsIssueTemplate && (
        <>
          <Divider sx={{ mb: 3 }} />

          <Controller
            control={control}
            name={`${saveAsIssueTemplateFieldName}.name`}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                error={_.get(errors, `${saveAsIssueTemplateFieldName}.name`)}
                fullWidth
                helperText={_.get(errors, `${saveAsIssueTemplateFieldName}.name.message`)}
                placeholder="Enter Issue Template Name..."
                required
                variant="outlined"
              />
            )}
          />

          <Controller
            control={control}
            name={`${saveAsIssueTemplateFieldName}.description`}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                error={_.get(errors, `${saveAsIssueTemplateFieldName}.description`)}
                fullWidth
                helperText={_.get(errors, `${saveAsIssueTemplateFieldName}.description.message`)}
                maxRows={4}
                minRows={4}
                multiline
                placeholder="Enter Issue Template Description..."
                required
                variant="outlined"
              />
            )}
          />

          <Controller
            control={control}
            name={`${saveAsIssueTemplateFieldName}.tags`}
            render={({ field }) => (
              <IssueTemplateTagSelectField
                {...field}
                className="mb-24"
                error={_.get(errors, `${saveAsIssueTemplateFieldName}.tags`)}
                isClearable
                isDisabled={false}
                isMulti
                placeholder="Select / Create Issue Template Tags..."
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default ServiceTicketCreateDialogWorkOrderIssueTemplate;
