import { gql } from '@apollo/client';
import { SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const COMPLETE_SERVICE_INVOICE_REVIEW = gql`
  mutation CompleteServiceInvoiceReview(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceInvoiceReviewCompleteInput!
  ) {
    completeServiceInvoiceReview(where: $where, data: $data) {
      id
      ...ServiceInvoiceReviewApprovalDialogServiceTicketFragment
    }
  }
  ${SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_DATA = gql`
  query FetchServiceInvoiceReviewApprovalDialogData {
    serviceInvoiceReviewDecisions(where: {}) {
      id
      enum
      name
    }
  }
`;
