import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const HyveIntelligenceViewPage = lazy(() => import('./HyveIntelligenceViewPage'));
const hyveIntelligenceViewPageBase = '/analytics';

// i18next.addResourceBundle('en', 'hyveIntelligenceViewPage', en);
// i18next.addResourceBundle('tr', 'hyveIntelligenceViewPage', tr);
// i18next.addResourceBundle('ar', 'hyveIntelligenceViewPage', ar);

const HyveIntelligenceViewPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${hyveIntelligenceViewPageBase}/hyve-intelligence/*`,
      element: <HyveIntelligenceViewPage />,
    },
    {
      path: hyveIntelligenceViewPageBase,
      element: <Navigate to={`${hyveIntelligenceViewPageBase}/hyve-intelligence`} />,
    },
  ],
};

export default HyveIntelligenceViewPageConfig;
