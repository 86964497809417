import { autocomplete } from '@algolia/autocomplete-js';
import { createTagsPlugin } from '@algolia/autocomplete-plugin-tags';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { ClickAwayListener, IconButton, Paper, Tooltip } from '@mui/material';
import { useMeilisearch } from 'app/providers/meilisearch';
import withReducer from 'app/store/withReducer';
import { createElement, Fragment, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createFacilityTagsPlugin, createServiceTicketsPlugin } from './plugins';
import reducer from './store';
import {
  closeGlobalSearchBar,
  openGlobalSearchBar,
  selectGlobalSearchBarState,
} from './store/stateSlice';

// TODO: Move to Global Scope
import '@algolia/autocomplete-plugin-tags/dist/theme.min.css';
import '@algolia/autocomplete-theme-classic';

const GlobalSearchBar = () => {
  const autocompleteContainer = useRef(null);
  const { axiosSearchClient } = useMeilisearch();
  const dispatch = useDispatch();
  // TODO: Review
  const isDetached = window.screen.width <= 680;
  const navigate = useNavigate();
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);
  const state = useSelector(selectGlobalSearchBarState);

  const handleHideGlobalSearchBar = useCallback(() => {
    dispatch(closeGlobalSearchBar());
  }, [dispatch]);

  const handleShowGlobalSearchBar = useCallback(
    (event) => {
      event.stopPropagation();
      dispatch(openGlobalSearchBar());
    },
    [dispatch]
  );

  const handleEscKey = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleHideGlobalSearchBar();
      }
    },
    [handleHideGlobalSearchBar]
  );

  const handleClickAway = useCallback(
    (event) => {
      return (
        state &&
        (!rootRef.current || !rootRef.current.contains(event.target)) &&
        handleHideGlobalSearchBar()
      );
    },
    [handleHideGlobalSearchBar, state]
  );

  useEffect(() => {
    if (state) {
      document.addEventListener('keydown', handleEscKey, false);
    } else {
      document.removeEventListener('keydown', handleEscKey, false);
    }
  }, [handleEscKey, state]);

  // TODO: Order Matters
  const plugins = useMemo(() => {
    const facilityTagsPlugin = createFacilityTagsPlugin({
      axiosSearchClient,
      navigate,
      limit: isDetached ? 5 : 10,
    });

    // TODO:
    // const recentSearches = createLocalStorageRecentSearchesPlugin({
    //   key: "instantsearch",
    //   limit: 3,
    //   transformSource({ source }) {
    //     return {
    //       ...source,
    //       onSelect({ item }) {
    //         setInstantSearchUiState({ query: item.name });
    //       }
    //     };
    //   }
    // });

    const serviceTicketsPlugin = createServiceTicketsPlugin({
      axiosSearchClient,
      handleHideGlobalSearchBar,
      navigate,
    });

    // TODO: Expand
    const tagsPlugin = createTagsPlugin({
      getTagsSubscribers() {
        return [
          {
            sourceId: 'facilityTagsPlugin',
            getTag({ item }) {
              return {
                ...item,
                label: item.name,
              };
            },
          },
        ];
      },
      onChange: (renameInstance, two, three, four) => {
        // TODO: Close on Last on Removed?
        console.log('// TODO: Needed?', { renameInstance });
        // renameInstance?.setQuery('');
      },
    });

    return [
      facilityTagsPlugin,
      // recentSearches,
      serviceTicketsPlugin,
      tagsPlugin,
    ];
  }, [axiosSearchClient, handleHideGlobalSearchBar, isDetached, navigate]);

  useEffect(() => {
    let autocompleteInstance;

    if (autocompleteContainer.current && plugins && state) {
      console.log('// TODO: Review Create', { autocompleteContainer, plugins, state });

      autocompleteInstance = autocomplete({
        container: autocompleteContainer.current,
        placeholder: 'Search Hyve FSM...',
        autoFocus: true,
        // TODO: Remove?
        // detachedMediaQuery="none"
        openOnFocus: true,
        plugins,
        renderer: { createElement, Fragment, render: () => {} },
        render({ children, elements }, root) {
          if (!panelRootRef.current || rootRef.current !== root) {
            rootRef.current = root;

            panelRootRef.current?.unmount();
            panelRootRef.current = createRoot(root);
          }
          const {
            // TODO: Expand
            facilityTagsPlugin,
            serviceTicketsPlugin,
            tagsPlugin,
          } = elements;

          panelRootRef.current.render(
            <div className="aa-PanelLayout aa-Panel--scrollable">
              <div className="aa-PanelSections">
                <div className="aa-PanelSection--left">
                  {tagsPlugin}
                  {facilityTagsPlugin}
                </div>
                <div className="aa-PanelSection--right">{serviceTicketsPlugin}</div>
              </div>
            </div>
          );
        },
      });
    }

    return () => {
      console.log('// TODO: Review Destroy', { autocompleteInstance });
      autocompleteInstance?.destroy();
    };
  }, [plugins, state]);

  return (
    <div className="flex">
      <Tooltip title="Global Search">
        <IconButton className="w-40 h-40" size="large" onClick={handleShowGlobalSearchBar}>
          <FuseSvgIcon>heroicons-outline:search</FuseSvgIcon>
        </IconButton>
      </Tooltip>

      {state && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className="absolute left-0 right-0 top-0 h-full z-9999 shadow-0" square>
            <div className="flex items-center w-full h-full">
              <div className="mx-12 w-full" ref={autocompleteContainer} />
            </div>
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default withReducer('globalSearchBar', reducer)(memo(GlobalSearchBar));
