import { gql, useApolloClient, useQuery } from '@apollo/client';
import _ from '@lodash';
import { Button } from '@mui/material';
import {
  ChatChannelCreateDialog,
  CHAT_CHANNEL_CREATE_DIALOG_CHAT_CHANNEL_FRAGMENT,
} from 'app/shared-components/ChatChannel';
import { CommonChatChannelListPreviewTemp } from 'app/shared-components/Common/CommonChat';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  ChannelList,
  ChannelListMessenger,
  getChannel,
  useChannelListContext,
  useChatContext,
} from 'stream-chat-react';

const ServiceTicketViewDrawerChannelListMessenger = (props) => {
  const { client, channel, setActiveChannel } = useChatContext();
  const { setChannels } = useChannelListContext();
  const init = useRef(false);
  const { channelType = 'service_ticket', viewId: channelId } = useParams();

  useEffect(() => {
    let subscription;

    if (!channelId || !channelType) {
      setActiveChannel(null);
    } else if (client && (!channel?.id || channel?.id !== channelId)) {
      if (init.current) {
        setActiveChannel(client.channel(channelType, channelId));
      } else {
        subscription = client.on('channels.queried', (event) => {
          const loadedChannelData = event.queriedChannels?.channels.find(
            (response) => response.channel.id === channelId
          );

          if (loadedChannelData) {
            setActiveChannel(client.channel(channelType, channelId));
            subscription?.unsubscribe();
            return;
          }

          // eslint-disable-next-line consistent-return
          return getChannel({ client, id: channelId, type: channelType }).then(
            (newActiveChannel) => {
              setActiveChannel(newActiveChannel);
              setChannels((channels) => {
                return [
                  newActiveChannel,
                  ...channels.filter((ch) => ch.data?.cid !== newActiveChannel.data?.cid),
                ];
              });
            }
          );
        });
      }
    }

    init.current = true;

    return () => {
      subscription?.unsubscribe();
    };
  }, [channel?.id, channelId, channelType, client, setActiveChannel, setChannels]);

  return <ChannelListMessenger {...props} />;
};

const ServiceTicketViewDrawerChannelList = ({ serviceTicket, onCreateGeneralChatChannel }) => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [serviceTicketChatChannelType, setServiceTicketChatChannelType] = useState(null);
  const user = useSelector(selectUser);

  const filters = useMemo(
    () => ({
      serviceTicketId: serviceTicket?.id,
      type: 'service_ticket',
      members: { $in: [user?.id] },
    }),
    [serviceTicket?.id, user?.id]
  );

  const options = useMemo(() => ({}), []);

  const sort = useMemo(
    () => [{ has_unread: -1 }, { last_message_at: -1 }, { last_updated: -1 }],
    []
  );

  useQuery(
    gql`
      query FetchServiceTicketViewDrawerChannelListData($where: ChatChannelTypeWhereInput!) {
        chatChannelTypes(where: $where) {
          id
          enum
          name
        }
      }
    `,
    {
      skip: !!serviceTicketChatChannelType,
      variables: { where: {} },
      onCompleted: ({ chatChannelTypes }) =>
        setServiceTicketChatChannelType(
          chatChannelTypes?.find((chatChannelType) => chatChannelType?.enum === 'SERVICE_TICKET')
        ),
      onError: ({ message }) =>
        dispatch(
          showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
        ),
    }
  );

  const handleCreateChatChannel = ({ chatChannel }) => {
    const base = `/service-tickets/view/${serviceTicket?.id}`;

    navigate(`${base}/chat/channels/view/${chatChannel?.id}`);
  };

  const handleCreateGeneralChatChannel = () => {
    setLoading(true);

    apolloClient
      .mutate({
        fetchPolicy: 'network-only',
        mutation: gql`
          mutation CreateChatChannel($data: ChatChannelCreateInput!) {
            createChatChannel(data: $data) {
              id
              ...ChatChannelCreateDialogChatChannelFragment
            }
          }
          ${CHAT_CHANNEL_CREATE_DIALOG_CHAT_CHANNEL_FRAGMENT}
        `,
        variables: {
          data: {
            name: 'General',
            serviceTicketChatChannel: {
              isCollaborative: true,
              serviceTicket: { id: serviceTicket?.id },
            },
            type: { id: serviceTicketChatChannelType?.id },
          },
        },
      })
      .then(({ data: { createChatChannel } }) => {
        handleCreateChatChannel({ chatChannel: createChatChannel });

        if (typeof onCreateGeneralChatChannel === 'function') {
          onCreateGeneralChatChannel({ chatChannel: createChatChannel });
        }
      })
      // ROADMAP: Implement Error Handling
      .catch((err) => null)
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="mb-12">
        <ChannelList
          channelRenderFilterFn={(channels) =>
            channels.filter(
              (channel) =>
                channel.data?.serviceTicketId === serviceTicket?.id &&
                channel.data?.type === 'service_ticket'
            )
          }
          filters={filters}
          List={ServiceTicketViewDrawerChannelListMessenger}
          options={options}
          Preview={CommonChatChannelListPreviewTemp}
          setActiveChannelOnMount={false}
          sort={sort}
        />
      </div>

      {_.isEmpty(
        serviceTicket?.chatChannels?.filter(({ isCollaborative }) => !!isCollaborative)
      ) && (
        <div className="px-24 mb-24">
          <Button
            fullWidth
            color="inherit"
            disabled={loading || !['OPEN'].includes(serviceTicket?.status?.enum)}
            variant="outlined"
            onClick={handleCreateGeneralChatChannel}
          >
            Create General Channel
          </Button>
        </div>
      )}

      <div className="px-24 mb-24">
        <Button
          fullWidth
          color="inherit"
          disabled={loading || !['OPEN'].includes(serviceTicket?.status?.enum)}
          variant="outlined"
          onClick={() =>
            dispatch(
              openDialog({
                children: (
                  <ChatChannelCreateDialog
                    serviceTicketId={serviceTicket?.id}
                    onClose={() => dispatch(closeDialog())}
                    onCreate={handleCreateChatChannel}
                  />
                ),
                classes: {
                  paper: 'w-full max-w-640 min-w-320 rounded-8',
                },
              })
            )
          }
        >
          Create New Channel
        </Button>
      </div>
    </>
  );
};

export default ServiceTicketViewDrawerChannelList;
