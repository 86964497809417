import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ServiceTicketPhaseReviewDecisionSelectField } from 'app/shared-components/ServiceTicketPhaseReviewDecision';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { COMPLETE_SERVICE_TICKET_PHASE_REVIEW } from './queries';

const defaultValues = {
  decision: null,
};

const schema = yup.object().shape({
  decision: yup.object().nullable().required('A decision must be provided.'),
});

const ServiceTicketPhaseReviewCompleteDialog = ({
  serviceTicketId,
  serviceTicketPhaseReviewId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [completeServiceTicketPhaseReview, { loading: completeServiceTicketPhaseReviewLoading }] =
    useMutation(COMPLETE_SERVICE_TICKET_PHASE_REVIEW, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Ticket Phase Review Successfully Completed',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Completing Service Ticket Phase Review',
            variant: 'error',
          })
        );
      },
    });

  const loading = useMemo(
    () => [completeServiceTicketPhaseReviewLoading].includes(true),
    [completeServiceTicketPhaseReviewLoading]
  );

  const onSubmit = async (data) => {
    try {
      await completeServiceTicketPhaseReview({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: serviceTicketPhaseReviewId,
            decision: {
              id: data.decision?.value,
            },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Complete Service Ticket Phase Review
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-ticket-phase-review-complete-form"
            name="service-ticket-phase-review-complete-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="decision"
              render={({ field }) => (
                <ServiceTicketPhaseReviewDecisionSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.decision}
                  filters={null}
                  isDisabled={false}
                  isClearable
                  isMulti={false}
                  placeholder="Select a Decision..."
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-ticket-phase-review-complete-form"
          type="submit"
          variant="contained"
        >
          Complete
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceTicketPhaseReviewCompleteDialog;
