import React from 'react';
import { __StreamActivityFeedsContext } from './StreamActivityFeedsProvider';

const useStreamActivityFeeds = () => {
  const context = React.useContext(__StreamActivityFeedsContext);

  if (context === undefined) {
    throw new Error('useStreamActivityFeeds must be used within a StreamActivityFeedsProvider');
  }

  return context;
};

export default useStreamActivityFeeds;
