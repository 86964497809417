import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_ISSUE_ADD_SERVICE_ISSUE_TAG_DIALOG_SERVICE_ISSUE_FRAGMENT = gql`
  fragment ServiceIssueAddServiceIssueTagDialogServiceIssueFragment on ServiceIssue {
    id
    tags {
      id
      name
    }
  }
`;
