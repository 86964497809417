import _ from '@lodash';
import { Avatar, Button, Typography } from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { selectUser } from 'app/store/userSlice';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useChatContext } from 'stream-chat-react';
import { closeChatPanel, openChatPanel, selectChatPanelState } from './store/stateSlice';

// TODO: Extract
export const getDisplayTitle = ({ channel, currentUser }) => {
  let title = channel.data?.name;
  const members = Object.values(channel.state.members);

  if (!title && members.length === 2) {
    const otherMember = members.find((member) => member.user?.id !== currentUser?.id);
    if (otherMember?.user?.name) {
      title = otherMember.user.name;
    }
  }

  return title;
};

const Root = styled(Tooltip)(({ theme, active }) => ({
  width: 70,
  minWidth: 70,
  flex: '0 0 auto',
  ...(active && {
    '&:after': {
      position: 'absolute',
      top: 8,
      right: 0,
      bottom: 8,
      content: "''",
      width: 4,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

const StyledUreadBadge = styled('div')(({ theme, value }) => ({
  position: 'absolute',
  minWidth: 18,
  height: 18,
  top: 4,
  left: 10,
  borderRadius: 9,
  padding: '0 5px',
  fontSize: 11,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
  zIndex: 10,
}));

const StyledStatus = styled('div')(({ theme, value }) => ({
  position: 'absolute',
  width: 12,
  height: 12,
  bottom: 4,
  left: 44,
  border: `2px solid ${theme.palette.background.default}`,
  borderRadius: '50%',
  zIndex: 10,

  ...(value === 'online' && {
    backgroundColor: '#4CAF50',
  }),

  ...(value === 'do-not-disturb' && {
    backgroundColor: '#F44336',
  }),

  ...(value === 'away' && {
    backgroundColor: '#FFC107',
  }),

  ...(value === 'offline' && {
    backgroundColor: '#646464',
  }),
}));

const ContactButton = ({ active, channel, setActiveChannel, unread, watchers, ...rest }) => {
  const { client } = useChatContext();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  // console.log('// TODO:', { channel, rest, user, watchers });

  const title = useMemo(
    () => getDisplayTitle({ channel, currentUser: client?.user }),
    [channel, client?.user]
  );

  // TODO: Limit to DMs and Combine
  const renameOnlineStatus = useMemo(() => {
    return _.some(
      Object.keys(channel?.state?.members || {}).filter((key) => key !== user?.id),
      (key) => channel?.state?.members?.[key]?.user?.online
    )
      ? 'online'
      : 'offline';
  }, [channel?.state?.members, user?.id]);

  const handleRename = () => {
    dispatch(openChatPanel());

    setActiveChannel(channel, watchers);
  };

  return (
    <Root
      // TODO:
      active={active ? 1 : 0}
      placement="left"
      title={
        <>
          {channel?.data?.type === 'service_ticket' ? (
            <>
              <Typography className="text-12">{title}</Typography>
              {channel?.data?.facilityFranchiseNumber && channel?.data?.facilityName && (
                <Typography className="text-12">{`${channel?.data?.facilityFranchiseNumber} ${channel?.data?.facilityName}`}</Typography>
              )}
              {channel?.data?.vendorName && (
                <Typography className="text-12">{channel?.data?.vendorName}</Typography>
              )}
            </>
          ) : (
            title
          )}
        </>
      }
    >
      <Button
        className={clsx(
          'contactButton rounded-0 py-4 h-auto min-h-auto max-h-none',
          active && 'active'
        )}
        onClick={handleRename}
      >
        {/* TODO: */}
        {!!unread && <StyledUreadBadge>{unread}</StyledUreadBadge>}

        {/* TODO: */}
        <StyledStatus value={renameOnlineStatus} />

        <Avatar
          alt={channel?.data?.name}
          src={channel?.data?.image}
          sx={{ backgroundColor: channel?.data?.color }}
        >
          {!channel?.data?.image || channel?.data?.image === '' ? title?.[0] : ''}
        </Avatar>
        {/* <Avatar src={channel?.data?.image} alt={channel?.data?.name} sx={{ backgroundColor: [red[500], blue[500], green[500]][_.random(0, 4)] }}>
          {!channel?.data?.image || channel?.data?.image === '' ? title?.[0] : ''}
        </Avatar> */}
      </Button>
    </Root>
  );
};

export default ContactButton;
