import moment from 'moment';

export default (dateValue) => {
  const dateMoment = moment(dateValue);

  let formattedDateValue;

  if (dateMoment.isBetween(moment().startOf('day'), moment().endOf('day'))) {
    formattedDateValue = dateMoment.format('h:mm A');
  } else if (dateMoment.isBetween(moment().startOf('week'), moment().endOf('week'))) {
    formattedDateValue = dateMoment.format('ddd h:mm A');
  } else if (dateMoment.isBetween(moment().subtract(1, 'month'), moment())) {
    formattedDateValue = dateMoment.format('ddd MM/DD');
  } else {
    formattedDateValue = dateMoment.format('MM/DD/YYYY');
  }

  return formattedDateValue;
};
