import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Alert, AlertTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IssueTemplateViewHeader } from 'app/shared-components/IssueTemplate';
import { useParams } from 'react-router-dom';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},

  // TODO:
  '& .FusePageSimple-rightSidebar': {
    flex: '1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
  '& .FusePageSimple-contentWrapper': {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 400,
    },
  },
}));

const IssueTemplateViewScreen = () => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const params = useParams();

  const basePath = `${params?.sectionName}/${params?.viewName}`;

  return (
    <Root
      header={
        <>
          {['tabs/overview', 'tabs/file-attachments'].includes(basePath) && (
            <IssueTemplateViewHeader basePath={basePath} />
          )}
        </>
      }
      content={
        <>
          {['tabs/overview', 'tabs/file-attachments'].includes(basePath) && (
            // ROADMAP: Implement
            <div className="flex flex-auto justify-center w-full max-w-5xl mx-auto p-24 sm:p-32">
              <div className="w-full">
                <Alert severity="info">
                  <AlertTitle>Under Development</AlertTitle>
                  This area is under active development.
                </Alert>
              </div>
            </div>
          )}
        </>
      }
      scroll="content"
    />
  );
};

export default IssueTemplateViewScreen;
