// TODO: Review / Remove
// import createGenerateClassName from '@mui/styles/createGenerateClassName';
// import jssPreset from '@mui/styles/jssPreset';
// import { create } from 'jss';
// import jssExtend from 'jss-plugin-extend';
// import rtl from 'jss-rtl';

import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReduxProvider from 'react-redux/es/components/Provider';
// import { IntercomProvider } from 'react-use-intercom';
import routes from 'app/configs/routesConfig';
import { ApolloHeartbeatProvider } from 'app/providers/apollo';
import { apiClient } from 'app/providers/apollo/clients';
// import { AutodeskViewerProvider } from 'app/providers/autodesk/viewer';
import { FirebaseMessagingProvider } from 'app/providers/firebase/messaging';
// import { IntercomIdentityProvider } from 'app/providers/intercom';
import { MeilisearchProvider } from 'app/providers/meilisearch';
// import { PositionProvider } from 'app/providers/position';
// import { PusherBeamsProvider } from 'app/providers/pusher/beams';
// import { PusherChannelsProvider } from 'app/providers/pusher/channels';
import { StreamActivityFeedsProvider } from 'app/providers/stream/activity-feeds';
import { StreamChatProvider } from 'app/providers/stream/chat';
import AppContext from './AppContext';
import store from './store';

// const { REACT_APP_INTERCOM_APP_ID } = process.env;

const withAppProviders = (Component) => (props) => {
  const WrapperComponent = () => (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReduxProvider store={store}>
          <ApolloProvider client={apiClient}>
            <ApolloHeartbeatProvider>
              {/* FIXME: */}
              {/* <AutodeskViewerProvider> */}
                {/* <IntercomProvider autoBoot appId={REACT_APP_INTERCOM_APP_ID}> */}
                  {/* <IntercomIdentityProvider> */}
                    <MeilisearchProvider>
                      {/* FIXME: Broken */}
                      {/* <PositionProvider> */}
                        {/* <PusherBeamsProvider> */}
                          {/* <PusherChannelsProvider> */}
                            {/* TODO: */}
                            <FirebaseMessagingProvider>
                              {/* TODO: */}
                              <StreamActivityFeedsProvider>
                                {/* TODO: */}
                                <StreamChatProvider>
                                  <StyledEngineProvider injectFirst>
                                    <Component {...props} />
                                  </StyledEngineProvider>
                                </StreamChatProvider>
                              </StreamActivityFeedsProvider>
                            </FirebaseMessagingProvider>
                          {/* </PusherChannelsProvider> */}
                        {/* </PusherBeamsProvider> */}
                      {/* </PositionProvider> */}
                    </MeilisearchProvider>
                  {/* </IntercomIdentityProvider>
                </IntercomProvider> */}
              {/* </AutodeskViewerProvider> */}
            </ApolloHeartbeatProvider>
          </ApolloProvider>
        </ReduxProvider>
      </LocalizationProvider>
    </AppContext.Provider>
  );

  return WrapperComponent;
};

export default withAppProviders;
