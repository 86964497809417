import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ISSUE_TEMPLATE_VIEW_HEADER_ISSUE_TEMPLATE_FRAGMENT = gql`
  fragment IssueTemplateViewHeaderIssueTemplateFragment on IssueTemplate {
    id

    # ROADMAP: Expand?
    createdAt
    description
    name
    status {
      id
      enum
      name
    }
  }
`;
