import { useLazyQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { Timeline } from '@mui/lab';
import { Paper, Typography } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { extractServiceTicketOrganizations } from 'app/utils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { ServiceTicketEventTimelineItem } from './components';
import { FETCH_SERVICE_TICKET_EVENT_VIEW_APP_DATA } from './queries';

const ServiceTicketEventViewApp = ({ basePath }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const [
    fetchServiceTicketEventViewAppData,
    {
      data: serviceTicketEventViewAppData,
      loading: serviceTicketEventViewAppLoading,
      refetch: serviceTicketEventViewAppRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_EVENT_VIEW_APP_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceTicketEventViewAppLoading].includes(true),
    [serviceTicketEventViewAppLoading]
  );

  const serviceTicket = useMemo(
    () => serviceTicketEventViewAppData?.serviceTicket,
    [serviceTicketEventViewAppData?.serviceTicket]
  );

  const serviceTicketOrganizations = useMemo(
    () => serviceTicket && extractServiceTicketOrganizations(serviceTicket),
    [serviceTicket]
  );

  useEffect(() => {
    if (params?.serviceTicketId) {
      fetchServiceTicketEventViewAppData({
        variables: { where: { id: params?.serviceTicketId } },
      });
    }
  }, [fetchServiceTicketEventViewAppData, params?.serviceTicketId]);

  return (
    <SizeMe monitorHeight monitorWidth={false}>
      {({ size }) => (
        <div className="flex flex-1 flex-row h-full">
          {(loading && !fetched) || !serviceTicket ? (
            <div className="flex w-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <Paper
              className="w-full overflow-scroll"
              elevation={0}
              square
              sx={{ height: size?.height }}
            >
              {_.isEmpty(serviceTicket.events) ? (
                <div className="flex items-center justify-center w-full h-full">
                  <Typography className="text-24">No Events Found</Typography>
                </div>
              ) : (
                <Timeline
                  className="p-24"
                  position="right"
                  sx={{
                    '& .MuiTimelineItem-root:before': {
                      display: 'none',
                    },
                  }}
                >
                  {_.map(
                    _.orderBy(serviceTicket.events || [], 'occurredAt', 'desc'),
                    (serviceTicketEvent, index) => (
                      <ServiceTicketEventTimelineItem
                        basePath={basePath}
                        key={serviceTicketEvent.id}
                        last={serviceTicket.events.length === index + 1}
                        serviceTicketEvent={serviceTicketEvent}
                        serviceTicketOrganizations={serviceTicketOrganizations}
                      />
                    )
                  )}
                </Timeline>
              )}
            </Paper>
          )}
        </div>
      )}
    </SizeMe>
  );
};

export default ServiceTicketEventViewApp;
