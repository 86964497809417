import { gql } from '@apollo/client';
import { SERVICE_TICKET_REMINDER_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_SERVICE_TICKET_REMINDER = gql`
  mutation CreateServiceTicketReminder(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketReminderCreateInput!
  ) {
    createServiceTicketReminder(where: $where, data: $data) {
      id
      ...ServiceTicketReminderCreateDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_REMINDER_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
