import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Avatar, Button, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { EntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchDrawer } from 'app/shared-components/EntitySearch/EntitySearchDrawer';
import { EntitySearchHeader } from 'app/shared-components/EntitySearch/EntitySearchHeader';
import { EntitySearchInfiniteHitsGrid } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsGrid';
import { EntitySearchInfiniteHitsList } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsList';
import { EntitySearchInfiniteHitsMap } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsMap';
import { ServiceProvisionCreateDialog } from 'app/shared-components/ServiceProvision';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { ServiceProvisionSearchAdapter } from './adapters';
import { serviceProvisionSearchScreenColumnDefs } from './columnDefs';
import { serviceProvisionSearchScreenFilterDefs } from './filterDefs';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},

  // TODO:
  '& .FusePageSimple-rightSidebar': {
    flex: '1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
  '& .FusePageSimple-contentWrapper': {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 400,
    },
  },
}));

// TODO:
const ServiceProvisionSearchCard = ({ hit }) => {
  const navigate = useNavigate();

  return (
    <ListItemButton
      divider
      onClick={() => navigate(`/service-provisions/${hit?.serviceProvision}`)}
    >
      <ListItemIcon>
        <Avatar src={hit.images?.find(({ isDefault }) => isDefault === true)?.thumbnail}>
          <FontAwesomeIcon icon={faImage} />
        </Avatar>
      </ListItemIcon>

      {/* TODO: Dot Notation? */}
      <ListItemText
        primary={hit.name}
        secondary={`${hit['productModel.organization.name']} | ${hit['productModel.category.label']}`}
      />
    </ListItemButton>
  );
};

const ServiceProvisionSearchScreen = () => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  // TODO: Based on selectedEntity
  const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile);
  // TODO: Rename
  const [selectedHitsViewMode, setSelectedHitsViewMode] = useState('grid');
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  // TODO: Remove?
  // useEffect(() => productModelSearchCache.clearCache(), []);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
    // TODO: Based on selectedEntity
    setRightSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <EntitySearchApp indexName="service_provisions" SearchAdapter={ServiceProvisionSearchAdapter}>
      <Root
        header={
          <EntitySearchHeader
            availableSearchViews={['grid', 'list', 'map']}
            initialSearchView="grid"
            rightToolbarContent={
              <>
                <Button
                  color="success"
                  disabled={
                    user?.data?.team?.isOrganizationAdmin !== true ||
                    !['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum)
                  }
                  size="small"
                  variant="contained"
                  onClick={() =>
                    dispatch(
                      // FIXME: z-index 9999 + 1
                      openDialog({
                        children: (
                          <ServiceProvisionCreateDialog onClose={() => dispatch(closeDialog())} />
                        ),
                        classes: {
                          // TODO: Move to FuseDialog?
                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                        },
                      })
                    )
                  }
                >
                  Create
                </Button>
              </>
            }
            onSearchViewChange={setSelectedHitsViewMode}
            // TODO: Rename
            leftSidebarToggle={(ev) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
            // TODO: Rename
            rightSidebarToggle={(ev) => {
              setRightSidebarOpen(!rightSidebarOpen);
            }}
          />
        }
        content={
          <SizeMe
            // TODO: Review
            // refreshMode="debounce"
            monitorHeight
            monitorWidth={false}
          >
            {({ size }) => (
              <div className="flex flex-row w-full h-full">
                {selectedHitsViewMode === 'grid' && (
                  <EntitySearchInfiniteHitsGrid
                    columnDefs={serviceProvisionSearchScreenColumnDefs}
                    indexName="service_provisions"
                    renameIdField="id"
                    onRowClicked={(id) => id && navigate(`/service-provisions/view/${id}`)}
                    {...size}
                  />
                )}

                {selectedHitsViewMode === 'list' && (
                  <>
                    {/* TODO: */}
                    <EntitySearchInfiniteHitsList
                      {...size}
                      RenameHitComponent={ServiceProvisionSearchCard}
                    />
                    {/* <div className="flex flex-1">Sidebar</div> */}
                  </>
                )}

                {selectedHitsViewMode === 'map' && (
                  <EntitySearchInfiniteHitsMap
                    {...size}
                    RenameHitComponent={ServiceProvisionSearchCard}
                  />
                )}
              </div>
            )}
          </SizeMe>
        }
        // TODO: Review
        leftSidebarWidth={341}
        leftSidebarOpen={leftSidebarOpen}
        leftSidebarOnClose={() => {
          setLeftSidebarOpen(false);
        }}
        leftSidebarContent={
          <EntitySearchDrawer
            dateRangeAttribute="createdAtTimestamp"
            filterDefs={serviceProvisionSearchScreenFilterDefs}
            title="Service Provisions"
            toggleDrawer={(ev) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
          />
        }
        // TODO:
        // rightSidebarOpen={
        //   rightSidebarOpen
        //   // && selectedHitsViewMode !== 'list'
        // }
        // rightSidebarOnClose={() => {
        //   setRightSidebarOpen(false);
        // }}
        // rightSidebarContent={(
        //   <div className="flex-1 min-w-340">
        //     Stuff
        //   </div>
        // )}
        scroll="content"
      />
    </EntitySearchApp>
  );
};

export default ServiceProvisionSearchScreen;
