import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import _ from '@lodash';
import { getChannel, useChannelStateContext, useChatContext } from 'stream-chat-react';
import { selectUser } from 'app/store/userSlice';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TODO: Extract
export const getDisplayTitle = ({ channel, currentUser }) => {
  // TODO: Add in Other Versions
  if (!channel) return null;

  let title = channel.data?.name;
  const members = Object.values(channel.state.members);

  if (!title && members.length === 2) {
    const otherMember = members.find((member) => member.user?.id !== currentUser?.id);
    if (otherMember?.user?.name) {
      title = otherMember.user.name;
    }
  }

  return title;
};

// import Statuses from './Statuses';
const Statuses = [
  {
    title: 'Online',
    value: 'online',
    color: 'rgb(76, 175, 80)',
  },
  {
    title: 'Away',
    value: 'away',
    color: 'rgb(251, 192, 45)',
  },
  {
    title: 'Do not disturb',
    value: 'do-not-disturb',
    color: 'rgb(244, 67, 54)',
  },
  {
    title: 'Offline',
    value: 'offline',
    color: 'rgb(162,162,162)',
  },
];

const StyledBadge = styled(Badge)(({ theme, ...props }) => ({
  width: 40,
  height: 40,
  fontSize: 20,
  '& .MuiAvatar-root': {
    fontSize: 'inherit',
    // TODO:
    // color: theme.palette.text.secondary,
    fontWeight: 600,
  },
  '& .MuiBadge-badge': {
    backgroundColor: props.statuscolor,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}));

function ContactAvatar({ contact, className }) {
  const { client } = useChatContext();
  const { channel: _channel, watchers } = useChannelStateContext();
  const user = useSelector(selectUser);

  // TODO: Hack
  const channel = contact || _channel;
  // console.log({ channel, contact });

  const title = useMemo(
    () => getDisplayTitle({ channel, currentUser: client?.user }),
    [channel, client?.user]
  );

  // TODO: Limit to DMs and Combine
  const renameOnlineStatus = useMemo(() => {
    return _.some(
      Object.keys(channel?.state?.members || {}).filter((key) => key !== user?.id),
      (key) => channel?.state?.members?.[key]?.user?.online
    )
      ? 'online'
      : 'offline';
  }, [channel?.state?.members, user?.id]);

  const status = useMemo(
    () => _.find(Statuses, { value: renameOnlineStatus }),
    [renameOnlineStatus]
  );

  // console.log('// TODO: fix', { channel });
  return (
    <StyledBadge
      className={className}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      statuscolor={status?.color}
    >
      {/* <Avatar src={channel?.data?.image} alt={channel?.data?.name} className="w-full h-full">
        {!channel?.data?.image || channel?.data?.image === '' ? title?.[0] : ''}
      </Avatar> */}

      <Avatar
        alt={channel?.data?.name}
        src={channel?.data?.image}
        sx={{ backgroundColor: channel?.data?.color }}
      >
        {!channel?.data?.image || channel?.data?.image === '' ? title?.[0] : ''}
      </Avatar>
    </StyledBadge>
  );
}

export default ContactAvatar;
