import { gql } from '@apollo/client';
import { SEGMENT_CREATE_DIALOG_SEGMENT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_SEGMENT = gql`
  mutation CreateSegment($data: SegmentCreateInput!) {
    createSegment(data: $data) {
      id
      ...SegmentCreateDialogSegmentFragment
    }
  }
  ${SEGMENT_CREATE_DIALOG_SEGMENT_FRAGMENT}
`;
