import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  faBars,
  faList,
  faSearch,
  faSpinner,
  faTableCellsLarge,
  faTableColumns,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  ClickAwayListener,
  IconButton,
  InputBase,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Popper,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useInfiniteHits, useInstantSearch, useSearchBox, useSortBy } from 'react-instantsearch';
import { useMeilisearch } from 'app/providers/meilisearch';
import { blue, purple } from '@mui/material/colors';
import _ from '@lodash';
import { Eventcalendar } from '@mobiscroll/js/mobiscroll.react.min';
import InfiniteScroll from 'react-infinite-scroll-component';

// const Nuts = forwardRef((props, ref) => {
//   return <div {...props} ref={ref} />;
// });

const SearchBox = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.common.black, 0.54)
      : theme.palette.common.white,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  color:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.common.black, 0.54)
      : theme.palette.common.white,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchStatusWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(32px + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchBoxRename = ({
  // anchorEl,
  // indexName,
  // ListOption,
  // nameField,
  onClose,
}) => {
  const { axiosSearchClient } = useMeilisearch();
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const searchTimer = useRef(null);

  // const { hits, isFirstPage, isLastPage, showMore } = useInfiniteHits();
  // const { status } = useInstantSearch();
  // const { clear, refine } = useSearchBox();

  // TODO: Check w/ Chris
  // const sortByItems = useMemo(
  //   () => [
  //     { label: 'None', value: 'merged_visits' },
  //     { label: 'Name (asc)', value: 'merged_visits:windowStartAtTimestamp:asc' },
  //     { label: 'Name (desc)', value: 'merged_visits:windowStartAtTimestamp:desc' },
  //   ],
  //   []
  // );

  // const { refine: sortBy } = useSortBy({ items: sortByItems });
  // useEffect(() => sortBy(sortByItems?.[1]?.value), [sortBy, sortByItems]);

  // const loading = useMemo(() => status === 'stalled', [status]);

  const [calendarEvents, setCalendarEvents] = useState([]);

  console.log({ calendarEvents });

  const clear = useCallback(() => {
    setCalendarEvents([]);
    setSearchText('');
  }, []);

  const refine = useCallback(
    async (q) => {
      try {
        // setLoading(true);

        let _calendarEvents = [];

        const {
          data: { hits },
          // TODO: indexName as Prop?
        } = await axiosSearchClient.post('/indexes/merged_visits/search', {
          limit,
          offset,
          q,
        });

        hits?.forEach((hit, index) => {
          let color;
          let title;

          // TODO: Finalize Color(s) / Title(s)
          if (hit.__typename === 'ServiceVisit') {
            // eslint-disable-next-line prefer-destructuring
            color = blue[600];
            title = 'Service Visit';
          } else if (hit.__typename === 'SiteVisit') {
            // eslint-disable-next-line prefer-destructuring
            color = purple[600];
            title = 'Site Visit';
          }

          _calendarEvents = [
            ..._calendarEvents,
            {
              allDay: false,
              color,
              end: hit.windowEndAt,
              start: hit.windowStartAt,
              title,
            },
          ];
        });

        // FIXME: Very Wrong!
        setCalendarEvents((prev) => [...(prev || []), ...(_calendarEvents || [])]);
      } catch (err) {
        // TODO:
      } finally {
        // setLoading(false);
      }
    },
    [axiosSearchClient, limit, offset]
  );

  useEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      if (!searchText.length) {
        clear();
      } else if (searchText.length > 2) {
        refine(searchText);
      }
    }, 300);

    return () => clearTimeout(searchTimer.current);
  }, [clear, refine, searchText]);

  // TODO: Rename to AgendaOption?
  const handleListOptionClick = (params) => {
    if (typeof onClose === 'function') {
      onClose();
    }

    return null;
  };

  const handleSearchTextChange = ({ target }) => setSearchText(target?.value);

  const handleSearchTextClear = useCallback(() => setSearchText(''), []);

  const handleClosePop = () => { clear(); setPop(null) };

  const handleClickAway = useCallback(
    (event) => {
      return (
        searchText &&
        (!crackle.current.contains(event.target) && !snap.current.contains(event.target)) &&
        clear()
        // state &&
        // (!rootRef.current || !rootRef.current.contains(event.target)) &&
        // handleHideGlobalSearchBar()
      );
    },
    [clear, searchText]
  );

  // const handleOpenPop = (event) => setPop(event.currentTarget);

  const snap = useRef(null);
  const crackle = useRef(null);
  const [pop, setPop] = useState(null);

  const handleFetchMore = () => setOffset((current) => current + limit);

  // if (!ListOption) return null;

  const hasMore = useState(false);
  return (
    <>
      <SearchBox ref={snap}>
        <SearchIconWrapper>
          <FontAwesomeIcon icon={faSearch} size="sm" />
        </SearchIconWrapper>

        <StyledInputBase
          disabled={loading}
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchTextChange}
        />

        <SearchStatusWrapper>
          {loading && (
            <div className="flex items-center justify-center w-32 h-32">
              <FontAwesomeIcon icon={faSpinner} size="sm" spin />
            </div>
          )}

          {!loading && !!searchText.length && (
            <IconButton
              color="default"
              sx={{ width: 32, height: 32 }}
              onClick={handleSearchTextClear}
            >
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </IconButton>
          )}
        </SearchStatusWrapper>
      </SearchBox>

      {searchText && (
        <Popper
          anchorEl={snap.current}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          classes={{
            paper: 'py-8',
          }}
          open
          ref={crackle}
          placement="bottom-end"
          // open={Boolean(pop)}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          // TODO: Needed?
          onClose={handleClosePop}
        >
          {/* TODO: 340 / 420? */}
          <Paper elevation={0} square sx={{ width: 340 }}>
            <ClickAwayListener onClickAway={handleClickAway}>
              {_.isEmpty(calendarEvents) ? (
                <div>EMPTY</div>
              ) : (
                <div id="activity-stream-panel-list" className="h-384 overflow-y-scroll">
                  <InfiniteScroll
                    dataLength={calendarEvents?.length}
                    endMessage={
                      // TODO:
                      <ListItem className="flex flex-row items-center p-16">
                        <div className="flex flex-1">End of Results</div>
                        <div className="flex">Fetched: {calendarEvents?.length}</div>
                      </ListItem>
                    }
                    hasMore={hasMore}
                    loader={
                      <ListItem className="flex flex-col px-16 py-32">
                        <LinearProgress className="w-full" color="secondary" />
                      </ListItem>
                    }
                    next={handleFetchMore}
                    // scrollableTarget="activity-stream-panel-list"
                  >
                {/* {(activities || []).map((activity) => (
                  <ActivityStreamPanelListItem activity={activity} key={activity.id} />
                ))} */}
                {/* <div className="h-384"> */}
                  <Eventcalendar
                    view={{
                      agenda: {
                        // TODO: Dafuq?
                        type: 'year',
                        size: 5,
                      },
                    }}
                    data={calendarEvents}
                    showControls={false}
                    // TOOD:
                    // onEventClick={handleEventClick}
                  />
                {/* </div> */}
              </InfiniteScroll>
              </div>
              )}
            </ClickAwayListener>
          </Paper>
        </Popper>
      )}
    </>
    // <Menu
    //   anchorEl={anchorEl}
    //   classes={{
    //     paper: 'w-420',
    //   }}
    //   open={Boolean(anchorEl)}
    //   TransitionProps={{
    //     onEntered: () => {
    //       searchInput.current.focus();
    //     },
    //     onExited: () => {
    //       handleSearchTextClear();
    //     },
    //   }}
    //   onClose={onClose}
    // >
    //   <div className="p-16 pt-8">
    //     <Input
    //       disableUnderline
    //       fullWidth
    //       inputRef={searchInput}
    //       placeholder="Search..."
    //       value={searchText}
    //       onChange={handleSearchTextChange}
    //     />
    //   </div>

    //   <Divider />

    //   <div className="max-h-384 overflow-y-auto">
    //     {isFirstPage && isLastPage && loading ? (
    //       <div className="flex items-center justify-center w-full h-384">
    //         <FontAwesomeIcon icon={faSpinner} size="xl" spin />
    //       </div>
    //     ) : (
    //       <List>
    //         {!hits?.length && isFirstPage && !loading ? (
    //           <ListItem>
    //             <ListItemText primary="No Results Found" />
    //           </ListItem>
    //         ) : (
    //           <>
    //             {hits.map((hit) => (
    //               <ListOption hit={hit} key={hit.id} onClick={handleListOptionClick} />
    //             ))}
    //           </>
    //         )}
    //       </List>
    //     )}
    //   </div>
    // </Menu>
  );
};

// TODO: Move && Export
const ENTITY_SEARCH_VIEW = {
  grid: {
    faIcon: faTableCellsLarge,
    label: 'Grid',
  },
  list: {
    faIcon: faList,
    label: 'List',
  },
  map: {
    faIcon: faMap,
    label: 'Map',
  },
};

// let timerId = undefined;
// let timeout = 200;
// const queryHook = (query, search) => {
//   if (timerId) {
//     clearTimeout(timerId);
//   }

//   timerId = setTimeout(() => search(query), timeout);
// };

// useEffect(() => {
//   const getCalendarEvents = async () => {
//     try {
//       setCalendarEventsLoading(true);

//       let _calendarEvents = [];

//       const {
//         data: { hits: serviceVisitHits },
//       } = await axiosSearchClient.post('/indexes/service_visits/search', {
//         // TODO:
//         limit: 200,
//         // q: '',
//       });

      // serviceVisitHits?.forEach((hit) => {
      //   _calendarEvents = [
      //     ..._calendarEvents,
      //     {
      //       allDay: false,
      //       // TODO:
      //       color: blue[600],
      //       end: hit.windowEndAt,
      //       start: hit.windowStartAt,
      //       // TODO:
      //       title: 'Service Visit',
      //     },
      //   ];
      // });

//       const {
//         data: { hits: siteVisitHits },
//       } = await axiosSearchClient.post('/indexes/site_visits/search', {
//         // TODO:
//         limit: 200,
//         // q: '',
//       });

//       siteVisitHits?.forEach((hit) => {
//         _calendarEvents = [
//           ..._calendarEvents,
//           {
//             allDay: false,
//             // TODO:
//             color: purple[600],
//             end: hit.windowEndAt,
//             start: hit.windowStartAt,
//             // TODO;
//             title: 'Site Visit',
//           },
//         ];
//       });

//       console.log({ _calendarEvents, serviceVisitHits, siteVisitHits });

//       setCalendarEvents(_calendarEvents);
//     } catch (err) {
//       //
//     } finally {
//       setCalendarEventsLoading(false);
//     }
//   };

//   if (axiosSearchClient) {
//     getCalendarEvents();
//   }
// }, [axiosSearchClient]);
const EntitySearchCalendarHeader = ({
  availableSearchViews,
  initialSearchView,
  rightToolbarContent,
  onSearchViewChange,

  // TODO: Rename
  leftSidebarToggle,
  rightSidebarToggle,
}) => {
  // const [searchText, setSearchText] = useState('');
  const searchTimer = useRef(null);
  const [searchView, setSearchView] = useState(initialSearchView);
  const [searchViewMenuAnchorEl, setSearchViewMenuAnchorEl] = useState(null);
  const searchViewMenuOpen = Boolean(searchViewMenuAnchorEl);
  const { status } = useInstantSearch();

  // const queryHook = useCallback((query, search) => {
  //   if (searchTimer.current) {
  //     clearTimeout(searchTimer.current);
  //     // searchTimer.current = null;
  //   }

  //   searchTimer.current = setTimeout(() => {
  //     searchTimer.current = null;
  //     search(query);
  //   }
  //   , 200);
  // }, []);

  // const { clear, query, refine } = useSearchBox({ queryHook });
  // const [searchText, setSearchText] = useState(query);

  // useEffect(() => {
  //   console.log({ searchTimer });
  //   if (!searchTimer.current && query !== searchText) {
  //     setSearchText(query);
  //   }
  // }, [query, searchText]);

  // const setQuery = (newQuery) => {
  //   setSearchText(newQuery);

  //   refine(newQuery);
  // };

  // const loading = useMemo(() => status === 'stalled', [status]);

  // useEffect(() => {
  //   if (searchTimer.current) {
  //     clearTimeout(searchTimer.current);
  //   }

  //   searchTimer.current = setTimeout(() => {
  //     if (!searchText.length) {
  //       clear();
  //     } else if (searchText.length > 2) {
  //       refine(searchText);
  //     }
  //   }, 300);

  //   return () => clearTimeout(searchTimer.current);
  // }, [clear, refine, searchText]);

  const handleCloseSearchViewMenu = (event) => setSearchViewMenuAnchorEl(null);

  const handleOpenSearchViewMenu = (event) => setSearchViewMenuAnchorEl(event.currentTarget);

  // const handleSearchTextChange = ({ target }) => setQuery(target?.value);

  // const handleSearchTextClear = () => setQuery('');

  const handleSearchViewChange = (value) => {
    // TODO:
    handleCloseSearchViewMenu();
    setSearchView(value);

    if (typeof onSearchViewChange === 'function') {
      onSearchViewChange(value);
    }
  };

  return (
    <AppBar color="inherit" elevation={0} position="static">
      <Toolbar>
        {/* TODO: Replace with UserClientView? */}
        <div className="flex flex-1">
          <Tooltip title="Toggle Menu">
            <IconButton
              color="default"
              edge="start"
              sx={{ mr: 2, width: 40, height: 40 }}
              onClick={leftSidebarToggle}
            >
              <FontAwesomeIcon icon={faBars} size="xs" />
            </IconButton>
          </Tooltip>
        </div>

        <SearchBoxRename />

        {/* TODO: */}
        {ENTITY_SEARCH_VIEW[searchView] && (
          <>
            {/* TODO: <Tooltip /> */}
            <IconButton
              color="default"
              sx={{ ml: 2, width: 40, height: 40 }}
              onClick={handleOpenSearchViewMenu}
            >
              <FontAwesomeIcon icon={ENTITY_SEARCH_VIEW[searchView].faIcon} size="xs" />
            </IconButton>

            <Menu
              anchorEl={searchViewMenuAnchorEl}
              id="search-view-menu"
              open={searchViewMenuOpen}
              onClose={handleCloseSearchViewMenu}
            >
              {Object.keys(ENTITY_SEARCH_VIEW)
                .filter((key) => availableSearchViews?.includes(key))
                .map((key) => {
                  const { faIcon, label } = ENTITY_SEARCH_VIEW[key];

                  return (
                    <MenuItem
                      selected={key === searchView}
                      onClick={() => handleSearchViewChange(key)}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faIcon} />
                      </ListItemIcon>

                      <ListItemText>{label}</ListItemText>
                    </MenuItem>
                  );
                })}
            </Menu>

            {/* TODO: */}
            {searchView === 'grid' && (
              <>
                {/* TODO: <Tooltip /> */}
                <IconButton color="default" sx={{ width: 40, height: 40 }} onClick={() => null}>
                  <FontAwesomeIcon icon={faTableColumns} size="xs" />
                </IconButton>

                {/* TODO: <Menu /> */}
              </>
            )}
          </>
        )}

        {rightToolbarContent && (
          <>
            <div className="mx-8 w-1 h-24 bg-gray-800" />

            {rightToolbarContent}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default EntitySearchCalendarHeader;
