import { gql } from '@apollo/client';
import { SERVICE_ISSUE_CREATE_DIALOG_SERVICE_ISSUE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_SERVICE_ISSUE = gql`
  mutation CreateServiceIssue($data: ServiceIssueCreateInput!) {
    createServiceIssue(data: $data) {
      id
      ...ServiceIssueCreateDialogServiceIssueFragment
    }
  }
  ${SERVICE_ISSUE_CREATE_DIALOG_SERVICE_ISSUE_FRAGMENT}
`;
