import React from 'react';
import { __AuthContext } from './AuthProvider';

const useAuth = () => {
  const context = React.useContext(__AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default useAuth;
