import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
// TODO:
// import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// TODO:
// import { ServiceProvisionVersionCreateDialog } from '../ServiceProvisionVersionCreateDialog';

const ServiceProvisionVersionViewHeader = ({ basePath, onToggleLeftSidebar }) => {
  const dispatch = useDispatch();
  const params = useParams();

  return (
    <AppBar color="inherit" elevation={0} position="static">
      <Toolbar>
        <div className="flex flex-1 items-center">
          <Tooltip title="Toggle Menu">
            <IconButton
              color="default"
              edge="start"
              sx={{ mr: 2, width: 40, height: 40 }}
              onClick={onToggleLeftSidebar}
            >
              <FontAwesomeIcon icon={faBars} size="xs" />
            </IconButton>
          </Tooltip>

          <Typography component="div" noWrap variant="h6">
            Versions
          </Typography>
        </div>

        <div className="flex items-center">
          <IconButton
            sx={{
              width: 40,
              height: 40,
              color: 'white',
              backgroundColor: green[600],
              '&:hover': {
                backgroundColor: green[800],
              },
            }}
            // TODO:
            // onClick={() =>
            //   dispatch(
            //     openDialog({
            //       children: (
            //         <ServiceProvisionVersionCreateDialog
            //           serviceProvisionId={params?.serviceProvisionId}
            //           onClose={() => dispatch(closeDialog())}
            //         />
            //       ),
            //       classes: {
            //         paper: 'w-full max-w-640 min-w-320 rounded-8',
            //       },
            //       disableEscapeKeyDown: true,
            //     })
            //   )
            // }
          >
            <FontAwesomeIcon icon={faPlus} size="xs" />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default ServiceProvisionVersionViewHeader;
