import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const ServiceTicketsSearchPage = lazy(() => import('./ServiceTicketsSearchPage'));
const serviceTicketsSearchPageBase = '/service-tickets';

// i18next.addResourceBundle('en', 'serviceTicketsSearchPage', en);
// i18next.addResourceBundle('tr', 'serviceTicketsSearchPage', tr);
// i18next.addResourceBundle('ar', 'serviceTicketsSearchPage', ar);

const serviceTicketsSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${serviceTicketsSearchPageBase}/search`,
      element: <ServiceTicketsSearchPage />,
    },
    {
      path: serviceTicketsSearchPageBase,
      element: <Navigate to={`${serviceTicketsSearchPageBase}/search`} />,
    },
  ],
};

export default serviceTicketsSearchPageConfig;
