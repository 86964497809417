import { gql } from '@apollo/client';
import {
  WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT,
  WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT,
} from './fragments';

export const FETCH_WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueNteUpdateDialogData($where: WorkOrderIssueWhereUniqueInput!) {
    workOrderIssue(where: $where) {
      id
      ...WorkOrderIssueNteUpdateDialogWorkOrderIssueFragment
    }
  }
  ${WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT}
`;

export const UPDATE_WORK_ORDER_ISSUE_NTE = gql`
  mutation UpdateWorkOrderIssueNte(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueNteUpdateInput!
  ) {
    updateWorkOrderIssueNte(where: $where, data: $data) {
      id
      ...WorkOrderIssueNteUpdateDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
