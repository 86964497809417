import { Alert, AlertTitle, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';

const ServiceProvisionOverviewApp = ({ basePath }) => {
  const params = useParams();

  return (
    <SizeMe monitorHeight monitorWidth={false}>
      {({ size }) => (
        <div className="flex flex-1 flex-row h-full">
          <Paper
            className="w-full overflow-scroll"
            elevation={0}
            square
            sx={{ height: size?.height }}
          >
            <div className="p-24">
              <Alert severity="info">
                <AlertTitle>Under Development</AlertTitle>
                This area is under active development.
              </Alert>
            </div>
          </Paper>
        </div>
      )}
    </SizeMe>
  );
};

export default ServiceProvisionOverviewApp;
