import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo } from 'react';
import { components } from 'react-select';

const ServiceVisitHitsListOption = (props) => {
  const serviceVisit = props.data?.hit;

  const option = useMemo(() => {
    if (!serviceVisit) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          <Avatar src={null}>
            <FontAwesomeIcon icon={faCalendar} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2">{`${moment(serviceVisit?.windowStartAt).format(
              'MMM. DD, YYYY @ hh:mm A'
            )} - ${moment(serviceVisit?.windowEndAt).format(
              'MMM. DD, YYYY @ hh:mm A'
            )}`}</Typography>
          }
        />
      </ListItem>
    );
  }, [serviceVisit]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default ServiceVisitHitsListOption;
