import { gql } from '@apollo/client';

export const CHAT_CHANNEL_CREATE_DIALOG_CHAT_CHANNEL_FRAGMENT = gql`
  fragment ChatChannelCreateDialogChatChannelFragment on ChatChannel {
    id
    createdAt
    updatedAt

    # TODO:
    # attachments: [ChatChannelAttachment]
    color
    # TODO:
    # creator: User!
    # TODO:
    # image: CommonStoredImage
    isDisabled
    isFrozen
    # TODO:
    # memberships: [ChatChannelMembership]
    name
    # TODO:
    # serviceTicketChatChannel: ServiceTicketChatChannel
    type {
      id
      enum
      name
    }
  }
`;

export const CHAT_CHANNEL_CREATE_DIALOG_CHAT_CHANNEL_TYPE_FRAGMENT = gql`
  fragment ChatChannelCreateDialogChatChannelTypeFragment on ChatChannelType {
    id
    enum
    name
  }
`;
