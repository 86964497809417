import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { lighten, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Toolbar from '@mui/material/Toolbar';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { selectUser } from 'app/store/userSlice';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';
import CommonChatChannelAvatar from './CommonChatChannelAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faInfoCircle, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

// TODO: Extract
export const getDisplayTitle = ({ channel, currentUser }) => {
  let title = channel.data?.name;
  const members = Object.values(channel.state.members);

  if (!title && members.length === 2) {
    const otherMember = members.find((member) => member.user?.id !== currentUser?.id);
    if (otherMember?.user?.name) {
      title = otherMember.user.name;
    }
  }

  return title;
};

// TODO: Rename Vars
const CommonChatChannelHeader = ({ onSearchClick, onToggleLeftSidebar, ...props }) => {
  const { client } = useChatContext();
  const user = useSelector(selectUser);
  const { channel, watcher_count: watcherCount } = useChannelStateContext();
  const title = useMemo(
    () => getDisplayTitle({ channel, currentUser: client?.user }),
    [channel, client?.user]
  );

  const { member_count: memberCount, subtitle } = channel?.data || {};

  // TODO:
  // console.log({ subtitle });

  return (
    <Box
      className="w-full border-b-1"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? lighten(theme.palette.background.default, 0.4)
            : lighten(theme.palette.background.default, 0.02),
      }}
    >
      <Toolbar className="flex items-center justify-between px-16 w-full">
        <div className="flex items-center">
          <IconButton
            aria-label="Open drawer"
            // TODO:
            onClick={onToggleLeftSidebar}
            className="flex lg:hidden"
            size="large"
          >
            <FuseSvgIcon>heroicons-outline:view-list</FuseSvgIcon>
          </IconButton>

          <div
            // TODO: Remove: `cursor-pointer`
            className="flex items-center"
            // onClick={() => {
            //   setContactSidebarOpen(true);
            // }}
            // onKeyDown={() => setContactSidebarOpen(true)}
            // role="button"
            // tabIndex={0}
          >
            <CommonChatChannelAvatar
              className="relative mx-8"
              // contact={user}
            />

            {/* <div className="flex flex-col"> */}
              {/* TODO: Truncate? */}
              <div>
              <Typography className="text-16 font-500 px-4" color="inherit" noWrap>
                {title}
              </Typography>

              {/* TODO: https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelHeader/ChannelHeader.tsx */}
              {/* {subtitle && <p className='str-chat__header-livestream-left--subtitle'>{subtitle}</p>} */}

              {/* TODO: Truncate? */}
              <Typography color="inherit" className="text-14 font-400 px-4">
                {`${memberCount} Members, ${watcherCount} Online`}
              </Typography>
            </div>
          </div>
        </div>

        <div className="flex items-center">
          {/* TODO: */}
          {/* <IconButton size="large" onClick={onSearchClick}>
            <FontAwesomeIcon className="text-20" icon={faMagnifyingGlass} />
          </IconButton> */}

          {/* TODO: Seperator */}

          {/* TODO: */}
          {/* <IconButton size="large" onClick={() => {
            // navigator.setAppBadge(1);

              Notification.requestPermission().then(perm => {
                if(perm === 'granted') {
                  const notification = new Notification("Example notification", {
                  body: "This is more text",
                  data: {hello: "world"}
                })

                // alert(notification.data.hello)
                }
              })
          }}>
            <FontAwesomeIcon className="text-18" icon={faGear} />
          </IconButton> */}
        </div>

        {/* <ChatMoreMenu className="-mx-8" /> */}
      </Toolbar>
    </Box>
  );
};

export default CommonChatChannelHeader;
