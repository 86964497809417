import { gql } from '@apollo/client';
import { SERVICE_TICKET_VIEW_DRAWER_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_SERVICE_TICKET_VIEW_DRAWER_DATA = gql`
  query FetchServiceTicketViewDrawerData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...ServiceTicketViewDrawerServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_VIEW_DRAWER_SERVICE_TICKET_FRAGMENT}
`;
