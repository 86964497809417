import { gql, useMutation } from '@apollo/client';
import _ from '@lodash';
import { Alert, AlertTitle, Button, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT } from '../fragments';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

const WorkOrderViewAppDraftPhaseBar = ({ creator, phase, serviceTicketId, user, workOrder }) => {
  const dispatch = useDispatch();

  const [completeServiceTicketDraftPhase, { loading: completeServiceTicketDraftPhaseLoading }] =
    useMutation(
      gql`
        mutation CompleteServiceTicketDraftPhase($where: ServiceTicketWhereUniqueInput!) {
          completeServiceTicketDraftPhase(where: $where) {
            id
            ...WorkOrderViewAppServiceTicketFragment
          }
        }
        ${WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT}
      `,
      {
        onCompleted: (data) => {
          dispatch(closeDialog());
          dispatch(
            showMessage({
              message: 'Ticket Successfully Sent',
              variant: 'success',
            })
          );
        },
        onError: (error) => {
          dispatch(showMessage({ message: 'Failed Sending Ticket', variant: 'error' }));
        },
      }
    );

  const loading = useMemo(
    () => [completeServiceTicketDraftPhaseLoading].includes(true),
    [completeServiceTicketDraftPhaseLoading]
  );

  return (
    <>
      {!_.isEmpty(workOrder?.issues) &&
        !_.some(
          workOrder?.issues || [],
          ({ status }) => !['CANCELLED', 'PENDING'].includes(status?.enum)
        ) &&
        phase.enum === 'DRAFT' &&
        creator?.id === user?.data?.organization?.id && (
          <>
            <Alert
              square
              action={
                <Button
                  className="mr-8"
                  disabled={loading}
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    completeServiceTicketDraftPhase({
                      variables: { where: { id: serviceTicketId } },
                    })
                  }
                >
                  Send Request
                </Button>
              }
              classes={{ action: 'items-center' }}
              severity="warning"
            >
              <AlertTitle>Proceed with Request</AlertTitle>
              Move ticket out of draft and proceed to next phase.
            </Alert>

            <StyledDivider />
          </>
        )}
    </>
  );
};

export default WorkOrderViewAppDraftPhaseBar;
