import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_REVIEW_COMPLETE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueReviewCompleteDialogServiceTicketFragment on ServiceTicket {
    id
    approvalPhaseEndedAt
    approvalPhaseStartedAt
    draftPhaseEndedAt
    draftPhaseStartedAt
    inProgressPhaseEndedAt
    inProgressPhaseStartedAt
    isApprovalPhaseCompleted
    isDraftPhaseCompleted
    isInProgressPhaseCompleted
    isQuotingPhaseCompleted
    isReleasePhaseCompleted
    isSiteVisitPhaseCompleted
    phase {
      id
      enum
      name
    }
    quotingPhaseEndedAt
    quotingPhaseStartedAt
    releasePhaseEndedAt
    releasePhaseStartedAt
    siteVisitPhaseEndedAt
    siteVisitPhaseStartedAt
    status {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        discountAmount
        notToExceedAmount
        serviceIssue {
          id
          description
          name
        }
        reviews {
          id
          createdAt
          assignedOrganizationType {
            id
            enum
            name
          }
          completor {
            id
            name
          }
          decision {
            id
            enum
            name
          }
          completedAt
          isCancelled
          isCompleted
          type {
            id
            enum
            name
          }
        }
        status {
          id
          enum
          name
        }
        subtotalAmount
        totalAmount
        totalAmountCheck {
          id
          enum
          name
        }
      }
    }
  }
`;
