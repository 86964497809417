import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { ServiceTicketReminderPrioritySelectField } from 'app/shared-components/ServiceTicketReminderPriority';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_SERVICE_TICKET_REMINDER_UPDATE_DIALOG_DATA,
  UPDATE_SERVICE_TICKET_REMINDER,
} from './queries';

const defaultValues = {
  dueAt: null,
  name: '',
  notes: '',
  priority: null,
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('A description must be provided.'),
});

const ServiceTicketReminderUpdateDialog = ({
  serviceTicketId,
  serviceTicketReminderId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchServiceTicketReminderUpdateDialogData,
    {
      data: serviceTicketReminderUpdateDialogData,
      loading: serviceTicketReminderUpdateDialogLoading,
      refetch: serviceTicketReminderUpdateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_REMINDER_UPDATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [updateServiceTicketReminder, { loading: updateServiceTicketReminderLoading }] =
    useMutation(UPDATE_SERVICE_TICKET_REMINDER, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Ticket Reminder Successfully Updated',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Updating Service Ticket Reminder', variant: 'error' })
        );
      },
    });

  const loading = useMemo(
    () =>
      [serviceTicketReminderUpdateDialogLoading, updateServiceTicketReminderLoading].includes(true),
    [serviceTicketReminderUpdateDialogLoading, updateServiceTicketReminderLoading]
  );

  const serviceTicketReminder = useMemo(
    () => serviceTicketReminderUpdateDialogData?.serviceTicketReminder,
    [serviceTicketReminderUpdateDialogData?.serviceTicketReminder]
  );

  useEffect(() => {
    if (serviceTicketReminder) {
      const formData = {
        dueAt: serviceTicketReminder?.dueAt || null,
        name: serviceTicketReminder?.name,
        notes: serviceTicketReminder?.notes || '',
        priority: serviceTicketReminder.priority
          ? {
              label: serviceTicketReminder.priority?.name,
              value: serviceTicketReminder.priority?.id,
              result: serviceTicketReminder.priority,
            }
          : null,
      };

      reset(formData);
    }
  }, [reset, serviceTicketReminder]);

  useEffect(() => {
    if (serviceTicketReminderId) {
      setFetched(false);
      fetchServiceTicketReminderUpdateDialogData({
        variables: { where: { id: serviceTicketReminderId } },
      });
    }
  }, [fetchServiceTicketReminderUpdateDialogData, serviceTicketReminderId]);

  const onSubmit = async (data) => {
    try {
      await updateServiceTicketReminder({
        variables: {
          where: { id: serviceTicketId },
          data: {
            ...data,
            id: serviceTicketReminderId,
            priority: data?.priority ? { id: data.priority.value } : null,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Update Service Ticket Reminder
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{
            clearErrors,
            control,
            formState,
            handleSubmit,
            reset,
            setValue,
            watch,
            ...methods,
          }}
        >
          <form
            className="w-full pt-20"
            id="service-ticket-reminder-update-form"
            name="service-ticket-reminder-update-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  required
                  variant="outlined"
                  placeholder="Enter Name..."
                />
              )}
            />

            {/* ROADMAP: Implement `assignedTeam` */}

            <Controller
              control={control}
              name="priority"
              render={({ field }) => (
                <ServiceTicketReminderPrioritySelectField
                  {...field}
                  className="mb-24"
                  error={errors?.priority}
                  filters={null}
                  isDisabled={false}
                  isClearable
                  isMulti={false}
                  placeholder="Select a Priority..."
                />
              )}
            />

            <Controller
              control={control}
              name="dueAt"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonDatepicker
                    {...field}
                    controls={['calendar']}
                    error={errors.dueAt}
                    min={moment()}
                    placeholder="Select a Due Date..."
                    select="date"
                    stepMinute={15}
                  />
                </div>
              )}
            />

            <Divider className="mb-24" />

            <Controller
              control={control}
              name="notes"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.notes}
                  fullWidth
                  helperText={errors?.notes?.message}
                  maxRows={4}
                  minRows={4}
                  multiline
                  placeholder="Enter Notes..."
                  required={false}
                  variant="outlined"
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-ticket-reminder-update-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceTicketReminderUpdateDialog;
