import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DigitsInput from "../components/DigitsImput";


export const EnterCodeStep = ({ setPhoneStep, phoneNumber }) => {
  const [codes, setCode] = useState('');
  const verifyDisabled = codes.length > 5;

  return (
    <>
      <Typography className='font-normal text-xl text-center mb-40'>We just sent a code to ({phoneNumber.slice(0, 3)}) {phoneNumber.slice(3, 6)} - {phoneNumber.slice(6, 10)}. What is it?</Typography>
      <DigitsInput onChangeCode={setCode} />
      <Typography className="mt-40 text-sm self-center" >Haven’t received the code?</Typography>
      <Button
        variant="text"
        size="small"
        sx={{
          padding: '0 0',
          "&:hover": { backgroundColor: "transparent" }
        }}>
        <Typography className="text-sm" color="#D416B9" >Click to Resend</Typography>
      </Button>
      <div className="flex flex-row space-x-20 mt-20" >
        <Button
          onClick={() => setPhoneStep(1)}
          variant="outlined"
          size="large"
          sx={{
            borderRadius: '100px'
          }}
        >
          <KeyboardBackspaceIcon />
        </Button>
        <Button
          onClick={() => setPhoneStep(3)}
          variant="contained"
          size="large"
          disabled={verifyDisabled}
          fullWidth
          sx={{
            background: '#D416B9',
            padding: '20px 0 20px 0',
            borderRadius: '67px',
            "&:hover": { backgroundColor: "#B7129F" }
          }}
        >
          <Typography className='text-lg font-semibold text-white' >Verify</Typography>
        </Button>
      </div>
    </>
  )
}
