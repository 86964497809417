import { memo } from 'react';
// import { TeamPanel } from 'app/theme-layouts/shared-components/TeamPanel';
import ChatPanel from '../../shared-components/chatPanel/ChatPanel';
// import QuickPanel from '../../shared-components/quickPanel/QuickPanel';
// TODO;
import { ActivityStreamPanel } from '../../shared-components/ActivityStreamPanel';

function RightSideLayout1(props) {
  return (
    <>
      <ChatPanel />

      {/* <QuickPanel /> */}

      <ActivityStreamPanel />

      {/* <TeamPanel /> */}
    </>
  );
}

export default memo(RightSideLayout1);
