import { faBuilding, faCircle, faCircleCheck, faSave } from '@fortawesome/free-regular-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateValueRelative } from 'app/utils';

const ServiceTicketTaskViewAppListItem = ({
  basePath,
  serviceTicketOrganizations,
  serviceTicketTask,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const assignedOrganization = useMemo(() => {
    let _assignedOrganization;

    if (serviceTicketTask?.assignedOrganizationType?.enum === 'VENDOR') {
      _assignedOrganization = serviceTicketOrganizations?.vendor;
    } else {
      _assignedOrganization = [
        serviceTicketOrganizations?.consumer,
        serviceTicketOrganizations?.vendor,
      ]
        .filter((el) => el)
        .find(({ type }) => type.enum === serviceTicketTask?.assignedOrganizationType?.enum);
    }

    return _assignedOrganization;
  }, [
    serviceTicketTask?.assignedOrganizationType?.enum,
    serviceTicketOrganizations?.consumer,
    serviceTicketOrganizations?.vendor,
  ]);

  const formattedDateValue = useMemo(
    () => serviceTicketTask?.createdAt && formatDateValueRelative(serviceTicketTask?.createdAt),
    [serviceTicketTask?.createdAt]
  );

  if (!serviceTicketTask) return null;

  return (
    <ListItemButton
      alignItems="flex-start"
      className="py-12"
      divider
      key={serviceTicketTask.id}
      selected={serviceTicketTask.id === params?.viewId}
      onClick={() => navigate(`../${basePath}/view/${serviceTicketTask.id}`)}
    >
      <ListItemIcon>
        {serviceTicketTask.isCancelled && (
          <Tooltip placement="bottom" title="Cancelled">
            <div>
              <FontAwesomeIcon className="text-18 text-red-800" icon={faBan} />
            </div>
          </Tooltip>
        )}

        {serviceTicketTask.isCompleted && (
          <Tooltip placement="bottom" title="Completed">
            <div>
              <FontAwesomeIcon className="text-18 text-green-800" icon={faCircleCheck} />
            </div>
          </Tooltip>
        )}

        {!serviceTicketTask.isCancelled && !serviceTicketTask.isCompleted && (
          <Tooltip placement="bottom" title="Pending">
            <div>
              <FontAwesomeIcon className="text-18 text-grey-800" icon={faCircle} />
            </div>
          </Tooltip>
        )}
      </ListItemIcon>

      <ListItemText
        disableTypography
        primary={<Typography>{serviceTicketTask.name}</Typography>}
        secondary={
          <div className="flex items-center mt-4">
            {serviceTicketTask.createdAt && (
              <Tooltip
                placement="bottom"
                title={`Created ${moment(serviceTicketTask.createdAt).format(
                  'MMM. DD, YYYY @ hh:mm A'
                )}`}
              >
                <div className="flex items-center mr-24">
                  <FontAwesomeIcon className="mr-8" icon={faSave} />

                  <Typography className="text-12">{formattedDateValue}</Typography>
                </div>
              </Tooltip>
            )}

            <Tooltip placement="bottom" title="Assigned Organization">
              <div className="flex items-center mr-24">
                <FontAwesomeIcon className="mr-8" icon={faBuilding} />

                <Typography className="text-12">{assignedOrganization?.name}</Typography>
              </div>
            </Tooltip>
          </div>
        }
      />
    </ListItemButton>
  );
};

export default ServiceTicketTaskViewAppListItem;
