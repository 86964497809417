import { useLazyQuery } from '@apollo/client';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { AppBar, Button, Divider, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { showMessage } from 'app/store/fuse/messageSlice';
import { extractServiceTicketOrganizations } from 'app/utils';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FETCH_SERVICE_TICKET_TASK_VIEW_DRAWER_DATA } from './queries';

const DRAWER_APP_BAR_HEIGHT = 64;

const ServiceTicketTaskViewDrawer = ({ basePath, contentWidth, onCloseDrawer }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();

  const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

  const [
    fetchServiceTicketTaskViewDrawerData,
    {
      data: serviceTicketTaskViewDrawerData,
      loading: serviceTicketTaskViewDrawerLoading,
      refetch: serviceTicketTaskViewDrawerRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_TASK_VIEW_DRAWER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceTicketTaskViewDrawerLoading].includes(true),
    [serviceTicketTaskViewDrawerLoading]
  );

  const serviceTicketTask = useMemo(
    () => serviceTicketTaskViewDrawerData?.serviceTicketTask,
    [serviceTicketTaskViewDrawerData?.serviceTicketTask]
  );

  const serviceTicketOrganizations = useMemo(
    () =>
      serviceTicketTask?.serviceTicket &&
      extractServiceTicketOrganizations(serviceTicketTask.serviceTicket),
    [serviceTicketTask?.serviceTicket]
  );

  const assignedOrganization = useMemo(() => {
    let _assignedOrganization;

    if (serviceTicketTask?.assignedOrganizationType?.enum === 'VENDOR') {
      _assignedOrganization = serviceTicketOrganizations?.vendor;
    } else {
      _assignedOrganization = [
        serviceTicketOrganizations?.consumer,
        serviceTicketOrganizations?.vendor,
      ]
        .filter((el) => el)
        .find(({ type }) => type.enum === serviceTicketTask?.assignedOrganizationType?.enum);
    }

    return _assignedOrganization;
  }, [
    serviceTicketTask?.assignedOrganizationType?.enum,
    serviceTicketOrganizations?.consumer,
    serviceTicketOrganizations?.vendor,
  ]);

  useEffect(() => {
    if (params?.viewId) {
      setFetched(false);
      fetchServiceTicketTaskViewDrawerData({ variables: { where: { id: params?.viewId } } });
    }
  }, [fetchServiceTicketTaskViewDrawerData, params?.viewId]);

  return (
    <div className="flex-1" style={{ width: contentWidth }}>
      <AppBar color="inherit" elevation={0} position="static">
        <Toolbar>
          <div className="flex flex-1">
            <Typography component="div" noWrap variant="h6">
              Task Details
            </Typography>
          </div>

          <IconButton sx={{ width: 40, height: 40 }} onClick={onCloseDrawer}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Paper
        className="flex flex-col p-24 overflow-auto border-t-1"
        elevation={0}
        square
        style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
      >
        {(loading && !fetched) || !serviceTicketTask ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <div>
                  <Typography className="text-10">Name</Typography>
                  <Typography>{serviceTicketTask.name}</Typography>
                </div>
              </Grid>

              <Grid xs={12}>
                <div>
                  <Typography className="text-10">Description</Typography>
                  <Typography>{serviceTicketTask.description}</Typography>
                </div>
              </Grid>
            </Grid>

            <Divider className="my-16" />

            <Grid container spacing={2}>
              <Grid xs={6}>
                <div>
                  <Typography className="text-10">Created At</Typography>
                  <Typography>
                    {moment(serviceTicketTask.createdAt).format('MMM. DD, YYYY @ hh:mm A')}
                  </Typography>
                </div>
              </Grid>

              <Grid xs={6}>
                <div>
                  <Typography className="text-10">Assigned Organization</Typography>
                  <Typography>{assignedOrganization?.name}</Typography>
                </div>
              </Grid>
            </Grid>

            <Divider className="my-16" />

            {serviceTicketTask.targetType?.enum === 'WORK_ORDER_ISSUE_REVIEW' && (
              <>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <div>
                      <Typography className="text-10">Work Order Issue</Typography>
                      <Typography>
                        {
                          serviceTicketTask.targetWorkOrderIssueReview?.workOrderIssue?.serviceIssue
                            .name
                        }
                      </Typography>
                    </div>
                  </Grid>

                  <Grid xs={12}>
                    <div>
                      <Typography className="text-10">Service Ticket Phase</Typography>
                      <Typography>
                        {serviceTicketTask.targetWorkOrderIssueReview?.serviceTicketPhase.name}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                <Divider className="my-16" />
              </>
            )}

            <Grid container spacing={2}>
              {serviceTicketTask.isCancelled && !serviceTicketTask.isCompleted && (
                <Grid xs={12}>
                  <div>
                    <Typography className="text-10">Status</Typography>
                    <Typography>Cancelled</Typography>
                  </div>
                </Grid>
              )}

              {!serviceTicketTask.isCancelled && serviceTicketTask.isCompleted && (
                <>
                  <Grid xs={6}>
                    <div>
                      <Typography className="text-10">Completed By</Typography>
                      <Typography>{serviceTicketTask.completor?.name}</Typography>
                    </div>
                  </Grid>

                  <Grid xs={6}>
                    <div>
                      <Typography className="text-10">Completed At</Typography>
                      <Typography>
                        {moment(serviceTicketTask.completedAt).format('MMM. DD, YYYY @ hh:mm A')}
                      </Typography>
                    </div>
                  </Grid>

                  {serviceTicketTask.targetType?.enum === 'SERVICE_INVOICE_REVIEW' && (
                    <Grid xs={12}>
                      <div>
                        <Typography className="text-10">Service Invoice Review Decision</Typography>
                        <Typography>
                          {serviceTicketTask.targetServiceInvoiceReview?.decision.name}
                        </Typography>
                      </div>
                    </Grid>
                  )}

                  {serviceTicketTask.targetType?.enum === 'SERVICE_TICKET_PHASE_REVIEW' && (
                    <Grid xs={12}>
                      <div>
                        <Typography className="text-10">
                          Service Ticket Phase Review Decision
                        </Typography>
                        <Typography>
                          {serviceTicketTask.targetServiceTicketPhaseReview?.decision.name}
                        </Typography>
                      </div>
                    </Grid>
                  )}

                  {serviceTicketTask.targetType?.enum === 'WORK_ORDER_ISSUE_REVIEW' && (
                    <Grid xs={12}>
                      <div>
                        <Typography className="text-10">
                          Work Order Issue Review Decision
                        </Typography>
                        <Typography>
                          {serviceTicketTask.targetWorkOrderIssueReview?.decision.name}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                </>
              )}

              {!serviceTicketTask.isCancelled && !serviceTicketTask.isCompleted && (
                <>
                  {['SERVICE_INVOICE_REVIEW'].includes(serviceTicketTask.targetType?.enum) && (
                    <Grid xs={12}>
                      <div className="mt-24">
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() =>
                            navigate(
                              `../activity/service-invoices/view/${serviceTicketTask.targetServiceInvoiceReview?.serviceInvoice.id}`
                            )
                          }
                        >
                          View Service Invoice
                        </Button>
                      </div>
                    </Grid>
                  )}

                  {[
                    'SERVICE_TICKET_PHASE_REVIEW',
                    'WORK_ORDER',
                    'WORK_ORDER_ISSUE_REVIEW',
                  ].includes(serviceTicketTask.targetType?.enum) && (
                    <Grid xs={12}>
                      <div className="mt-24">
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => navigate('../activity/work-order')}
                        >
                          View Work Order
                        </Button>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
      </Paper>
    </div>
  );
};

export default ServiceTicketTaskViewDrawer;
