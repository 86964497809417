import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

const EntitySearchHeaderRefreshButton = ({ className }) => {
  const intervalRef = useRef(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [refreshedAt, setRefreshedAt] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(null);
  const { setRefreshSearch } = useEntitySearchApp();

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (refreshInterval && setRefreshSearch) {
      intervalRef.current = setInterval(() => {
        setRefreshedAt(moment().format());
        setRefreshSearch(true);
      }, refreshInterval);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [refreshInterval, setRefreshSearch]);

  const handleCloseMenu = (event) => setMenuAnchorEl(null);

  const handleOpenMenu = (event) => setMenuAnchorEl(event.currentTarget);

  const handleSetRefreshInterval = (_refreshInterval) => setRefreshInterval(_refreshInterval);

  return (
    <div className={className}>
      <Tooltip
        title={
          refreshInterval
            ? `Refreshed At: ${refreshedAt ? moment(refreshedAt).format('hh:mm:ss A') : 'Pending'}`
            : 'Auto Refresh Off'
        }
      >
        <IconButton
          color={refreshInterval ? 'success' : 'default'}
          sx={{ width: 40, height: 40 }}
          onClick={handleOpenMenu}
        >
          <FontAwesomeIcon icon={faClockRotateLeft} size="xs" />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
        <MenuItem
          selected={!refreshInterval}
          onClick={() => {
            handleSetRefreshInterval(null);

            handleCloseMenu();
          }}
        >
          Off
        </MenuItem>

        {[1, 5, 10, 15, 30, 60].map((minuteInterval) => (
          <MenuItem
            key={`minute-interval-${minuteInterval}`}
            selected={minuteInterval * 60000 === refreshInterval}
            onClick={() => {
              handleSetRefreshInterval(minuteInterval * 60000);

              handleCloseMenu();
            }}
          >
            {`${minuteInterval} Minute${minuteInterval === 1 ? '' : 's'}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default EntitySearchHeaderRefreshButton;
