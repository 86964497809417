import { gql } from '@apollo/client';
import { WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT } from './fragments';

export const FETCH_WORK_ORDER_VIEW_APP_DATA = gql`
  query FetchWorkOrderViewAppData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderViewAppServiceTicketFragment
    }
  }
  ${WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT}
`;

export const UPDATE_SERVICE_TICKET_IS_REQUEST_FOR_QUOTE = gql`
  mutation UpdateServiceTicketIsRequestForQuote(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketIsRequestForQuoteUpdateInput!
  ) {
    updateServiceTicketIsRequestForQuote(where: $where, data: $data) {
      id
      isRequestForQuote
    }
  }
`;
