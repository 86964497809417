import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

// DEPRECATED: Replaced by `ServiceResponseSeveritySelectField`
const ServiceResponseSeverityHitsListOption = (props) => {
  const serviceResponseSeverity = props.data?.hit;

  const option = useMemo(() => {
    if (!serviceResponseSeverity) return null;

    return (
      <ListItem component="div" dense>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <FontAwesomeIcon icon={faCircle} style={{ color: serviceResponseSeverity.color }} />
        </ListItemIcon>

        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2">
              {`${serviceResponseSeverity.name} (${serviceResponseSeverity.value} ${serviceResponseSeverity.commonTemporalDurationUnit?.name})`}
            </Typography>
          }
        />
      </ListItem>
    );
  }, [serviceResponseSeverity]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default ServiceResponseSeverityHitsListOption;
