import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { grey, lightBlue } from '@mui/material/colors';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { IssueTemplateHitsListOption } from 'app/shared-components/IssueTemplate/IssueTemplateHitsListOption';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ProgramCreateDialogIssueTemplates = ({ name }) => {
  const [expandedField, setExpandedField] = useState(null);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const watchIssueTemplates = watch(name);

  useDeepCompareEffect(() => {
    if (_.isEmpty(watchIssueTemplates)) {
      setExpandedField(null);
    }
  }, [watchIssueTemplates]);

  const handleChangeExpandedField = (fieldId) => (event, newFieldId) => {
    setExpandedField(newFieldId ? fieldId : false);
  };

  return (
    <>
      <Box>
        <Typography sx={{ mb: 2 }}>Issue Templates</Typography>

        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <EntitySearchSelectField
              {...field}
              className="mb-24"
              components={{ Option: IssueTemplateHitsListOption }}
              controlShouldRenderValue={false}
              error={_.get(errors, name)}
              filter={[`status.enum = 'ACTIVE'`]}
              idField="id"
              indexName="issue_templates"
              isClearable
              isDisabled={false}
              isMulti
              placeholder="Select Issue Templates..."
              nameField="name"
              sort={['name:asc']}
            />
          )}
        />

        <div className="mb-24">
          {_.isEmpty(watchIssueTemplates) ? (
            <Alert severity="info" sx={{ mb: 3 }}>
              No Issue Templates Selected
            </Alert>
          ) : (
            <>
              {watchIssueTemplates?.map((watchIssueTemplate) => {
                const borderLeftColor =
                  expandedField === watchIssueTemplate.hit?.id ? lightBlue[600] : grey[600];

                return (
                  <Accordion
                    expanded={expandedField === watchIssueTemplate.hit?.id}
                    key={watchIssueTemplate.hit?.id}
                    variant="outlined"
                    onChange={handleChangeExpandedField(watchIssueTemplate.hit?.id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ borderLeft: `4px solid ${borderLeftColor}` }}
                    >
                      <div className="flex flex-1 items-center">
                        <Typography>{watchIssueTemplate.hit?.name}</Typography>
                      </div>

                      <IconButton
                        sx={{ mr: 1, padding: 0, width: '24px', height: '24px' }}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();

                          setValue(
                            name,
                            watchIssueTemplates.filter(
                              ({ value }) => value !== watchIssueTemplate.value
                            ),
                            { shouldValidate: true }
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} size="xs" />
                      </IconButton>
                    </AccordionSummary>

                    {/* TODO: */}
                    <AccordionDetails sx={{ borderLeft: `4px solid ${borderLeftColor}` }}>
                      <Alert severity="info">
                        <AlertTitle>Under Development</AlertTitle>
                        This area is under active development.
                      </Alert>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </div>
      </Box>
    </>
  );
};

export default ProgramCreateDialogIssueTemplates;
