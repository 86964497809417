import { useLazyQuery } from '@apollo/client';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { AppBar, Button, Divider, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { showMessage } from 'app/store/fuse/messageSlice';
import { CommonImageLightbox } from 'app/shared-components/Common/CommonLightbox';
import { CommonVideoJsPlayer } from 'app/shared-components/Common/CommonVideo';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_CHAT_CHANNEL_ATTACHMENT_DATA,
  FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_PROGRAM_ATTACHMENT_DATA,
  FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_ISSUE_ATTACHMENT_DATA,
  FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_TICKET_ATTACHMENT_DATA,
} from './queries';

const DRAWER_APP_BAR_HEIGHT = 64;

const ServiceTicketAttachmentViewDrawer = ({ basePath, contentWidth, onCloseDrawer }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
  const [imageLightboxSlides, setImageLightboxSlides] = useState([]);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const [videoJsPlayerOpen, setVideoJsPlayerOpen] = useState(false);
  const [videoJsPlayerOptions, setVideoJsPlayerOptions] = useState({});

  const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

  const [
    fetchServiceTicketAttachmentViewDrawerChatChannelAttachmentData,
    {
      data: serviceTicketAttachmentViewDrawerChatChannelAttachmentData,
      loading: serviceTicketAttachmentViewDrawerChatChannelAttachmentLoading,
      refetch: serviceTicketAttachmentViewDrawerChatChannelAttachmentRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_CHAT_CHANNEL_ATTACHMENT_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [
    fetchServiceTicketAttachmentViewDrawerProgramAttachmentData,
    {
      data: serviceTicketAttachmentViewDrawerProgramAttachmentData,
      loading: serviceTicketAttachmentViewDrawerProgramAttachmentLoading,
      refetch: serviceTicketAttachmentViewDrawerProgramAttachmentRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_PROGRAM_ATTACHMENT_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [
    fetchServiceTicketAttachmentViewDrawerServiceIssueAttachmentData,
    {
      data: serviceTicketAttachmentViewDrawerServiceIssueAttachmentData,
      loading: serviceTicketAttachmentViewDrawerServiceIssueAttachmentLoading,
      refetch: serviceTicketAttachmentViewDrawerServiceIssueAttachmentRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_ISSUE_ATTACHMENT_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [
    fetchServiceTicketAttachmentViewDrawerServiceTicketAttachmentData,
    {
      data: serviceTicketAttachmentViewDrawerServiceTicketAttachmentData,
      loading: serviceTicketAttachmentViewDrawerServiceTicketAttachmentLoading,
      refetch: serviceTicketAttachmentViewDrawerServiceTicketAttachmentRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_TICKET_ATTACHMENT_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () =>
      [
        serviceTicketAttachmentViewDrawerChatChannelAttachmentLoading,
        serviceTicketAttachmentViewDrawerProgramAttachmentLoading,
        serviceTicketAttachmentViewDrawerServiceIssueAttachmentLoading,
        serviceTicketAttachmentViewDrawerServiceTicketAttachmentLoading,
      ].includes(true),
    [
      serviceTicketAttachmentViewDrawerChatChannelAttachmentLoading,
      serviceTicketAttachmentViewDrawerProgramAttachmentLoading,
      serviceTicketAttachmentViewDrawerServiceIssueAttachmentLoading,
      serviceTicketAttachmentViewDrawerServiceTicketAttachmentLoading,
    ]
  );

  const attachment = useMemo(() => {
    let _attachment;

    if (params?.typeId === 'chat_channel_attachment') {
      _attachment =
        serviceTicketAttachmentViewDrawerChatChannelAttachmentData?.chatChannelAttachment;
    } else if (params?.typeId === 'program_attachment') {
      _attachment = serviceTicketAttachmentViewDrawerProgramAttachmentData?.programAttachment;
    } else if (params?.typeId === 'service_issue_attachment') {
      _attachment =
        serviceTicketAttachmentViewDrawerServiceIssueAttachmentData?.serviceIssueAttachment;
    } else if (params?.typeId === 'service_ticket_attachment') {
      _attachment =
        serviceTicketAttachmentViewDrawerServiceTicketAttachmentData?.serviceTicketAttachment;
    }

    return _attachment;
  }, [
    params?.typeId,
    serviceTicketAttachmentViewDrawerChatChannelAttachmentData?.chatChannelAttachment,
    serviceTicketAttachmentViewDrawerProgramAttachmentData?.programAttachment,
    serviceTicketAttachmentViewDrawerServiceIssueAttachmentData?.serviceIssueAttachment,
    serviceTicketAttachmentViewDrawerServiceTicketAttachmentData?.serviceTicketAttachment,
  ]);

  const { attachmentName, standardImageUrl, uploaderName } = useMemo(() => {
    let output = {};

    if (attachment?.commonStoredType?.enum === 'COMMON_STORED_CAD') {
      output = {
        attachmentName: attachment?.commonStoredCad?.name,
        standardImageUrl: attachment?.commonStoredCad?.standardImageUrl,
        uploaderName: attachment?.commonStoredCad?.commonStoredUpload?.uploader?.name,
      };
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
      output = {
        attachmentName: attachment?.commonStoredImage?.name,
        standardImageUrl: attachment?.commonStoredImage?.standardImageUrl,
        uploaderName: attachment?.commonStoredImage?.commonStoredUpload?.uploader?.name,
      };
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_PDF') {
      output = {
        attachmentName: attachment?.commonStoredPdf?.name,
        standardImageUrl: attachment?.commonStoredPdf?.standardImageUrl,
        uploaderName: attachment?.commonStoredPdf?.commonStoredUpload?.uploader?.name,
      };
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
      output = {
        attachmentName: attachment?.commonStoredVideo?.name,
        standardImageUrl: attachment?.commonStoredVideo?.standardImageUrl,
        uploaderName: attachment?.commonStoredVideo?.commonStoredUpload?.uploader?.name,
      };
    }

    return output;
  }, [
    attachment?.commonStoredCad?.commonStoredUpload?.uploader?.name,
    attachment?.commonStoredCad?.name,
    attachment?.commonStoredCad?.standardImageUrl,
    attachment?.commonStoredImage?.commonStoredUpload?.uploader?.name,
    attachment?.commonStoredImage?.name,
    attachment?.commonStoredImage?.standardImageUrl,
    attachment?.commonStoredPdf?.commonStoredUpload?.uploader?.name,
    attachment?.commonStoredPdf?.name,
    attachment?.commonStoredPdf?.standardImageUrl,
    attachment?.commonStoredType?.enum,
    attachment?.commonStoredVideo?.commonStoredUpload?.uploader?.name,
    attachment?.commonStoredVideo?.name,
    attachment?.commonStoredVideo?.standardImageUrl,
  ]);

  useEffect(() => {
    if (params?.typeId && params?.viewId) {
      setFetched(false);

      if (params?.typeId === 'chat_channel_attachment') {
        fetchServiceTicketAttachmentViewDrawerChatChannelAttachmentData({
          variables: { where: { id: params?.viewId } },
        });
      } else if (params?.typeId === 'program_attachment') {
        fetchServiceTicketAttachmentViewDrawerProgramAttachmentData({
          variables: { where: { id: params?.viewId } },
        });
      } else if (params?.typeId === 'service_issue_attachment') {
        fetchServiceTicketAttachmentViewDrawerServiceIssueAttachmentData({
          variables: { where: { id: params?.viewId } },
        });
      } else if (params?.typeId === 'service_ticket_attachment') {
        fetchServiceTicketAttachmentViewDrawerServiceTicketAttachmentData({
          variables: { where: { id: params?.viewId } },
        });
      }
    }
  }, [
    fetchServiceTicketAttachmentViewDrawerChatChannelAttachmentData,
    fetchServiceTicketAttachmentViewDrawerProgramAttachmentData,
    fetchServiceTicketAttachmentViewDrawerServiceIssueAttachmentData,
    fetchServiceTicketAttachmentViewDrawerServiceTicketAttachmentData,
    params?.typeId,
    params?.viewId,
  ]);

  const handleCloseImageLightbox = () => setImageLightboxOpen(false);

  const handleOpenImageLightbox = () => setImageLightboxOpen(true);

  const handleCloseVideoJsPlayer = () => setVideoJsPlayerOpen(false);

  const handleOpenVideoJsPlayer = () => setVideoJsPlayerOpen(true);

  return (
    <>
      <div className="flex-1" style={{ width: contentWidth }}>
        <AppBar color="inherit" elevation={0} position="static">
          <Toolbar>
            <div className="flex flex-1">
              <Typography component="div" noWrap variant="h6">
                File Attachment Details
              </Typography>
            </div>

            {/* ROADMAP: ActionMenu */}

            <IconButton sx={{ width: 40, height: 40 }} onClick={onCloseDrawer}>
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Paper
          className="flex flex-col p-24 overflow-auto border-t-1"
          elevation={0}
          square
          style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
        >
          {(loading && !fetched) || !attachment ? (
            <div className="flex w-full h-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Box
                    className="w-full rounded-8 border preview h-128 sm:h-256 file-icon flex items-center justify-center"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? lighten(theme.palette.background.default, 0.4)
                          : lighten(theme.palette.background.default, 0.02),
                      backgroundImage: `url("${standardImageUrl}")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  />
                </Grid>

                <Grid xs={12}>
                  <div>
                    <Typography className="text-10">Name</Typography>
                    <Typography>{attachmentName}</Typography>
                  </div>
                </Grid>
              </Grid>

              <Divider className="my-16" />

              <Grid container spacing={2}>
                <Grid xs={6}>
                  <div>
                    <Typography className="text-10">Created At</Typography>
                    <Typography>
                      {moment(attachment.createdAt).format('MMM. DD, YYYY @ hh:mm A')}
                    </Typography>
                  </div>
                </Grid>

                <Grid xs={6}>
                  <div>
                    <Typography className="text-10">Uploaded By</Typography>
                    <Typography>{uploaderName}</Typography>
                  </div>
                </Grid>
              </Grid>

              <Divider className="my-16" />

              <Grid container spacing={2}>
                <Grid xs={12}>
                  <div className="mt-24">
                    {attachment?.commonStoredType?.enum === 'COMMON_STORED_IMAGE' && (
                      <Button
                        fullWidth
                        disabled={!attachment?.commonStoredImage?.isProcessed}
                        variant="outlined"
                        onClick={() => {
                          setImageLightboxSlides([
                            { src: attachment?.commonStoredImage?.lightboxImageUrl },
                          ]);

                          handleOpenImageLightbox();
                        }}
                      >
                        {attachment?.commonStoredImage?.isProcessed
                          ? 'View Image'
                          : 'Processing...'}
                      </Button>
                    )}

                    {attachment?.commonStoredType?.enum === 'COMMON_STORED_PDF' && (
                      <Button
                        fullWidth
                        disabled={!attachment?.commonStoredPdf?.isProcessed}
                        variant="outlined"
                        onClick={() =>
                          window.open(
                            attachment?.commonStoredPdf?.originalPdfUrl,
                            '_blank',
                            'noreferrer'
                          )
                        }
                      >
                        {attachment?.commonStoredPdf?.isProcessed
                          ? 'Download PDF'
                          : 'Processing...'}
                      </Button>
                    )}
                    {attachment?.commonStoredType?.enum === 'COMMON_STORED_VIDEO' && (
                      <Button
                        fullWidth
                        disabled={!attachment?.commonStoredVideo?.isProcessed}
                        variant="outlined"
                        onClick={() => {
                          setVideoJsPlayerOptions({
                            autoplay: false,
                            controls: true,
                            responsive: true,
                            fluid: true,
                            poster: attachment?.commonStoredVideo?.standardImageUrl,
                            sources: [
                              {
                                src: attachment?.commonStoredVideo?.playlistFileUrl,
                                type: 'application/x-mpegURL',
                              },
                            ],
                            tracks: [
                              {
                                src: attachment?.commonStoredVideo?.captionsFileUrl,
                                kind: 'captions',
                                srclang: 'en',
                                label: 'English',
                              },
                            ],
                          });

                          handleOpenVideoJsPlayer();
                        }}
                      >
                        {attachment?.commonStoredVideo?.isProcessed
                          ? 'View Video'
                          : 'Processing...'}
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </div>

      <CommonImageLightbox
        open={imageLightboxOpen}
        slides={imageLightboxSlides}
        onClose={handleCloseImageLightbox}
      />

      <CommonVideoJsPlayer
        open={videoJsPlayerOpen}
        options={videoJsPlayerOptions}
        onClose={handleCloseVideoJsPlayer}
        onReady={null}
      />
    </>
  );
};

export default ServiceTicketAttachmentViewDrawer;
