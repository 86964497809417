import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const programSearchScreenColumnDefs = [
  {
    headerName: 'Program',
    children: [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        valueGetter: ({ data }) =>
          data?.createdAtTimestamp &&
          moment.unix(data?.createdAtTimestamp).format('MMM. DD, YYYY @ hh:mm A'),
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
      },
      {
        field: 'issueTemplates',
        headerName: 'Issue Templates',
        sortable: false,
        valueGetter: ({ data }) => data?.issueTemplates && data?.issueTemplates.length,
      },
      {
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        sortable: false,
        valueGetter: ({ data }) => data?.tags && data?.tags.map(({ name }) => name).join(', '),
      },
      {
        field: 'creator.name',
        headerName: 'Creator',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Sharing Rule',
    children: [
      {
        field: 'commonSharingRule.type.name',
        headerName: 'Type',
        sortable: true,
      },
      {
        field: 'commonSharingRule.subjectType.name',
        headerName: 'Subject Type',
        sortable: true,
      },
      {
        field: 'commonSharingRule.subjectTeams',
        headerName: 'Subject Teams',
        sortable: false,
        valueGetter: ({ data }) =>
          data?.commonSharingRule?.subjectTeams &&
          data?.commonSharingRule?.subjectTeams.map(({ team }) => team?.name).join(', '),
      },
    ],
  },
];
