import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_DATA,
  UPDATE_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION,
} from './queries';

const defaultValues = {
  resumeAt: null,
};

const schema = yup.object().shape({
  resumeAt: yup.date().nullable().required('A scheduled resumption date must be provided.'),
});

const WorkOrderIssueScheduledResumptionUpdateDialog = ({
  serviceTicketId,
  workOrderIssueId,
  workOrderIssueScheduledResumptionId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [
    fetchWorkOrderIssueScheduledResumptionUpdateDialogData,
    {
      data: workOrderIssueScheduledResumptionUpdateDialogData,
      loading: workOrderIssueScheduledResumptionUpdateDialogLoading,
      refetch: workOrderIssueScheduledResumptionUpdateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Work Order Issue Data',
          variant: 'error',
        })
      );
    },
  });

  const [
    updateWorkOrderIssueScheduledResumption,
    { loading: updateWorkOrderIssueScheduledResumptionLoading },
  ] = useMutation(UPDATE_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Work Order Issue Scheduled Resumption Successfully Updated',
          variant: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Updating Work Order Issue Scheduled Resumption',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () =>
      [
        updateWorkOrderIssueScheduledResumptionLoading,
        workOrderIssueScheduledResumptionUpdateDialogLoading,
      ].includes(true),
    [
      updateWorkOrderIssueScheduledResumptionLoading,
      workOrderIssueScheduledResumptionUpdateDialogLoading,
    ]
  );

  useEffect(() => {
    if (workOrderIssueScheduledResumptionUpdateDialogData?.workOrderIssueScheduledResumption) {
      setValue(
        'resumeAt',
        workOrderIssueScheduledResumptionUpdateDialogData?.workOrderIssueScheduledResumption
          .resumeAt
      );
    }
  }, [
    setValue,
    workOrderIssueScheduledResumptionUpdateDialogData?.workOrderIssueScheduledResumption,
  ]);

  useEffect(() => {
    if (workOrderIssueScheduledResumptionId) {
      fetchWorkOrderIssueScheduledResumptionUpdateDialogData({
        variables: { where: { id: workOrderIssueScheduledResumptionId } },
      });
    }
  }, [fetchWorkOrderIssueScheduledResumptionUpdateDialogData, workOrderIssueScheduledResumptionId]);

  const onSubmit = async (data) => {
    try {
      await updateWorkOrderIssueScheduledResumption({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: workOrderIssueScheduledResumptionId,
            resumeAt: data.resumeAt,
            workOrderIssue: { id: workOrderIssueId },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Update Work Order Issue Scheduled Resumption
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-scheduled-resumption-update-form"
            name="work-order-issue-scheduled-resumption-update-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="resumeAt"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonDatepicker
                    {...field}
                    controls={['calendar']}
                    disabled={false}
                    error={errors?.resumeAt}
                    min={moment().add(1, 'day').startOf('day')}
                    placeholder="Select Scheduled Resumption Date..."
                    select="date"
                  />
                </div>
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-scheduled-resumption-update-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueScheduledResumptionUpdateDialog;
