import { Button } from '@mui/material';
import { createSearchParams } from 'react-router-dom';

const GlobalSearchBarCommonSeeAllButton = ({
  filterSearchParams,
  handleHideGlobalSearchBar,
  querySearchParams,
  navigate,
  sectionName,
  state,
  title,
}) => {
  return (
    <Button
      color="info"
      component="a"
      disableElevation
      size="large"
      sx={{
        fontSize: 14,
        fontWeight: 500,
        textTransform: 'capitalize',
      }}
      variant="contained"
      onClick={() => {
        navigate({
          pathname: `/global-search/redirect/${sectionName}`,
          search: createSearchParams({
            ...querySearchParams,
            ...filterSearchParams,
          }).toString(),
        });

        if (handleHideGlobalSearchBar) {
          handleHideGlobalSearchBar();
        }

        // TODO: Reset Query?
        // FIXME:
        // setIsOpen(false);
      }}
    >
      See All Matching {title} ({state.context.estimatedTotalHits})
    </Button>
  );
};

export default GlobalSearchBarCommonSeeAllButton;
