import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { CommonCurrencyField } from 'app/shared-components/Common';
import { CommonSharingRuleSubjectTypeSelectField } from 'app/shared-components/CommonSharingRuleSubjectType';
import { CommonSharingRuleTypeSelectField } from 'app/shared-components/CommonSharingRuleType';
import { CommonTargetTypeSelectField } from 'app/shared-components/CommonTargetType';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { FacilityAreaTypeSelectField } from 'app/shared-components/FacilityAreaType';
import { IssueTemplateTagSelectField } from 'app/shared-components/IssueTemplateTag';
import { ServiceIssueTagSelectField } from 'app/shared-components/ServiceIssueTag';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  IssueTemplateCreateDialogWorkOrderIssueActionItems,
  IssueTemplateCreateDialogServiceIssueAttachments,
} from './components';
import { CREATE_ISSUE_TEMPLATE } from './queries';

const defaultValues = {
  activateIssueTemplateOnCreate: false,
  commonSharingRule: {
    subjectTeams: [],
    subjectType: null,
    type: null,
  },
  description: '',
  name: '',
  serviceIssue: {
    attachments: [],
    commonTargetType: null,
    description: '',
    name: '',
    serviceIssueTags: [],
    targetFacilityAreaTypes: [],
  },
  tags: [],
  workOrderIssue: {
    accountingWorkOrderType: null,
    actionItems: [],
    notToExceedAmount: null,
  },
};

const schema = yup.object().shape({
  activateIssueTemplateOnCreate: yup.boolean().optional(),
  commonSharingRule: yup.object().shape({
    subjectTeams: yup.mixed().when('subjectType', {
      is: (val) => val?.result?.enum === 'TEAM',
      then: yup.array().min(1, 'Select at least one Team').required(),
    }),
    subjectType: yup.mixed().when('type', {
      is: (val) => val?.result?.enum === 'READ_ONLY',
      then: yup.object().nullable().required('A subject type must be provided.'),
    }),
    type: yup.object().nullable().required('A type must be provided.'),
  }),
  description: yup.string().nullable().optional(),
  name: yup.string().nullable().required('A name must be provided.'),
  serviceIssue: yup.object().shape({
    attachments: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            file: yup.mixed().required(),
            commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
          })
          .required()
      )
      .optional(),
    commonTargetType: yup.object().nullable().required('A target type must be provided.'),
    description: yup.string().nullable().optional(),
    name: yup.string().nullable().required('A name must be provided.'),
    serviceIssueTags: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            __isNew__: yup.boolean().optional(),
            label: yup.string().required(),
            result: yup.object().optional(),
            value: yup.string().required(),
          })
          .required()
      )
      .optional(),
    targetFacilityAreaTypes: yup.mixed().when('commonTargetType', {
      is: (val) => val?.result?.enum === 'FACILITY_AREA',
      then: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              label: yup.string().required(),
              result: yup.object().optional(),
              value: yup.string().required(),
            })
            .required()
        )
        .min(1, 'Select at least one Target Facility Area Type')
        .required(),
    }),
  }),
  tags: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          __isNew__: yup.boolean().optional(),
          label: yup.string().required(),
          result: yup.object().optional(),
          value: yup.string().required(),
        })
        .required()
    )
    .optional(),
  workOrderIssue: yup.object().shape({
    accountingWorkOrderType: yup.object().nullable().required('A work order type must be provided'),
    actionItems: yup.array().of(
      yup.object().shape({
        csiClassification: yup
          .object()
          .nullable()
          .required('A CSI Classification must be provided.'),
        description: yup.string().nullable().optional(),
        notToExceedAmount: yup
          .number()
          .nullable()
          .min(0.01, 'A value must be provided.')
          .required('A NTE must be provided.'),
      })
    ),
    notToExceedAmount: yup.number().nullable().min(0.01, 'A value must be provided'),
  }),
});

const IssueTemplateCreateDialog = ({ onClose }) => {
  const [activateIssueTemplateOnCreate, setActivateIssueTemplateOnCreate] = useState(false);
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createIssueTemplate, { loading: createIssueTemplateLoading }] = useMutation(
    CREATE_ISSUE_TEMPLATE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Issue Template Successfully Created',
            variant: 'success',
          })
        );

        navigate(`/issue-templates/view/${data.createIssueTemplate?.id}`);
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Issue Template', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [createIssueTemplateLoading].includes(true),
    [createIssueTemplateLoading]
  );

  const onSubmit = async (data) => {
    try {
      await createIssueTemplate({
        variables: {
          data: {
            activateIssueTemplateOnCreate,
            commonSharingRule: {
              subjectTeams: data.commonSharingRule.subjectTeams?.map((subjectTeam) => ({
                id: subjectTeam?.value,
              })),
              subjectType: data.commonSharingRule.subjectType
                ? { id: data.commonSharingRule.subjectType?.value }
                : null,
              type: { id: data.commonSharingRule.type?.value },
            },
            description: data.description,
            name: data.name,
            serviceIssue: {
              attachments: data.serviceIssue.attachments?.map((attachment) => ({
                commonStoredUpload: { key: attachment?.commonStoredUpload.key },
              })),
              commonTargetType: {
                id: data.serviceIssue.commonTargetType?.value,
              },
              description: data.serviceIssue.description,
              name: data.serviceIssue.name,
              serviceIssueTags: data.serviceIssue.serviceIssueTags?.map((serviceIssueTag) => ({
                name: serviceIssueTag.__isNew__
                  ? serviceIssueTag.value
                  : serviceIssueTag.result?.name,
              })),
              targetFacilityAreaTypes: data.serviceIssue.targetFacilityAreaTypes?.map(
                (targetFacilityAreaType) => ({ id: targetFacilityAreaType.value })
              ),
            },
            tags: data.tags?.map((tag) => ({
              name: tag.__isNew__ ? tag.value : tag.result?.name,
            })),
            workOrderIssue: {
              accountingWorkOrderType: { id: data.workOrderIssue.accountingWorkOrderType?.value },
              actionItems: data.workOrderIssue.actionItems?.map((actionItem) => ({
                csiClassification: { id: actionItem.csiClassification?.value },
                description: actionItem.description,
                notToExceedAmount: actionItem.notToExceedAmount,
              })),
              notToExceedAmount: data.workOrderIssue.notToExceedAmount,
            },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Issue Template
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="issue-template-create-form"
            name="issue-template-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Issue Template Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.description}
                  fullWidth
                  helperText={errors?.description?.message}
                  maxRows={4}
                  minRows={4}
                  multiline
                  placeholder="Enter Issue Template Description..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <IssueTemplateTagSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.tags}
                  isClearable
                  isDisabled={false}
                  isMulti
                  placeholder="Select / Create Issue Template Tags..."
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <Controller
              control={control}
              name="commonSharingRule.type"
              render={({ field }) => (
                <CommonSharingRuleTypeSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.commonSharingRule?.type}
                  filters={{
                    enum: {
                      // ROADMAP: Handle Additional Common Sharing Rule Types
                      in: ['PRIVATE', 'READ_ONLY'],
                    },
                  }}
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Sharing Rule Type..."
                  onChange={(params) => {
                    setValue('commonSharingRule.subjectType', null);
                    setValue('commonSharingRule.subjectTeams', []);

                    field.onChange(params);
                  }}
                />
              )}
            />

            {watchFields?.commonSharingRule?.type?.result?.enum === 'READ_ONLY' && (
              <>
                <Controller
                  control={control}
                  name="commonSharingRule.subjectType"
                  render={({ field }) => (
                    <CommonSharingRuleSubjectTypeSelectField
                      {...field}
                      className="mb-24"
                      error={errors?.commonSharingRule?.subjectType}
                      filters={{
                        enum: {
                          // ROADMAP: Handle Additional Common Sharing Rule Subject Types
                          in: ['TEAM'],
                        },
                      }}
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Sharing Rule Subject Type..."
                      onChange={(params) => {
                        setValue('commonSharingRule.subjectTeams', []);

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                {watchFields?.commonSharingRule?.subjectType?.result?.enum === 'TEAM' && (
                  <>
                    <Controller
                      control={control}
                      name="commonSharingRule.subjectTeams"
                      render={({ field }) => (
                        <EntitySearchSelectField
                          {...field}
                          className="mb-24"
                          error={errors?.commonSharingRule?.subjectTeams}
                          filter={null}
                          idField="id"
                          indexName="teams"
                          isClearable
                          isDisabled={false}
                          isMulti
                          placeholder="Select Sharing Rule Subject Teams..."
                          sort={['name:asc']}
                        />
                      )}
                    />
                  </>
                )}
              </>
            )}

            <Divider sx={{ mb: 3 }} />

            <Controller
              control={control}
              name="serviceIssue.name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.serviceIssue?.name}
                  fullWidth
                  helperText={errors?.serviceIssue?.name?.message}
                  placeholder="Enter Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="workOrderIssue.accountingWorkOrderType"
              render={({ field }) => (
                <EntitySearchSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.workOrderIssue?.accountingWorkOrderType}
                  filter={null}
                  idField="id"
                  indexName="accounting_work_order_types"
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Work Order Type..."
                  sort={['name:asc']}
                  onChange={(params) => {
                    setValue('serviceIssue.commonTargetType', null);
                    setValue('serviceIssue.targetFacilityAreaTypes', []);

                    field.onChange(params);
                  }}
                />
              )}
            />

            {watchFields?.workOrderIssue?.accountingWorkOrderType && (
              <>
                <Controller
                  control={control}
                  name="serviceIssue.commonTargetType"
                  render={({ field }) => (
                    <CommonTargetTypeSelectField
                      {...field}
                      className="mb-24"
                      error={errors?.serviceIssue?.commonTargetType}
                      filters={{
                        ...(watchFields?.workOrderIssue?.accountingWorkOrderType?.hit
                          ?.acceptedCommonTargetTypes
                          ? {
                              enum: {
                                in: watchFields?.workOrderIssue?.accountingWorkOrderType?.hit?.acceptedCommonTargetTypes.map(
                                  (acceptedCommonTargetType) => acceptedCommonTargetType.enum
                                ),
                              },
                            }
                          : {}),
                      }}
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Target Type..."
                      onChange={(params) => {
                        setValue('serviceIssue.targetFacilityAreaTypes', []);

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                {watchFields?.serviceIssue?.commonTargetType?.result?.enum === 'FACILITY_AREA' && (
                  <Controller
                    control={control}
                    name="serviceIssue.targetFacilityAreaTypes"
                    render={({ field }) => (
                      <FacilityAreaTypeSelectField
                        {...field}
                        className="mb-24"
                        error={errors?.serviceIssue?.targetFacilityAreaTypes}
                        filters={{
                          ...(!_.isEmpty(
                            watchFields?.workOrderIssue?.accountingWorkOrderType?.hit
                              ?.targetFacilityAreaTypes
                          )
                            ? {
                                enum: {
                                  in: watchFields?.workOrderIssue?.accountingWorkOrderType?.hit?.targetFacilityAreaTypes.map(
                                    ({ facilityAreaType }) => facilityAreaType.enum
                                  ),
                                },
                              }
                            : {}),
                        }}
                        isClearable
                        isDisabled={false}
                        isMulti
                        placeholder="Select Target Facility Area Types..."
                      />
                    )}
                  />
                )}

                {!_.isEmpty(watchFields?.serviceIssue?.targetFacilityAreaTypes) && (
                  <>
                    <Controller
                      control={control}
                      name="serviceIssue.description"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          error={errors?.serviceIssue?.description}
                          fullWidth
                          helperText={errors?.serviceIssue?.description?.message}
                          maxRows={4}
                          minRows={4}
                          multiline
                          placeholder="Enter Description; Where / What..."
                          required
                          variant="outlined"
                        />
                      )}
                    />

                    {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum) && (
                      <Controller
                        control={control}
                        name="serviceIssue.serviceIssueTags"
                        render={({ field }) => (
                          <ServiceIssueTagSelectField
                            {...field}
                            className="mb-24"
                            error={errors?.serviceIssue?.serviceIssueTags}
                            isClearable
                            isDisabled={false}
                            isMulti
                            placeholder="Select / Create Service Issue Tags..."
                          />
                        )}
                      />
                    )}
                  </>
                )}
              </>
            )}

            <Controller
              control={control}
              name="workOrderIssue.notToExceedAmount"
              render={({ field }) => (
                <CommonCurrencyField
                  {...field}
                  className="mb-24"
                  control={control}
                  disabled={!!watchFields?.workOrderIssue?.actionItems?.length}
                  error={errors?.workOrderIssue?.notToExceedAmount}
                  placeholder="Enter Not To Exceed..."
                />
              )}
            />

            <IssueTemplateCreateDialogWorkOrderIssueActionItems
              accountingWorkOrderTypeFieldName="workOrderIssue.accountingWorkOrderType"
              name="workOrderIssue.actionItems"
              serviceIssueFieldName="serviceIssue"
              totalFieldName="workOrderIssue.notToExceedAmount"
            />

            <Divider sx={{ mb: 3 }} />

            <IssueTemplateCreateDialogServiceIssueAttachments name="serviceIssue.attachments" />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="issue-template-create-form"
          type="submit"
          variant="contained"
          onClick={() => setActivateIssueTemplateOnCreate(false)}
        >
          Create
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="issue-template-create-form"
          type="submit"
          variant="contained"
          onClick={() => setActivateIssueTemplateOnCreate(true)}
        >
          Create + Activate
        </Button>
      </DialogActions>
    </>
  );
};

export default IssueTemplateCreateDialog;
