import React from 'react';
import { __AutodeskViewerContext } from './AutodeskViewerProvider';

const useAutodeskViewer = () => {
  const context = React.useContext(__AutodeskViewerContext);

  if (context === undefined) {
    throw new Error('useAutodeskViewer must be used within a AutodeskViewerProvider');
  }

  return context;
};

export default useAutodeskViewer;
