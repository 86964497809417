import FusePageSimple from '@fuse/core/FusePageSimple';
import _ from '@lodash';
import { styled } from '@mui/material/styles';
import { CommonMetabaseInteractiveEmbed } from 'app/shared-components/Common/CommonMetabase';
import {
  jwtService,
  JWT_SERVICE_EVENTS,
  JWT_SERVICE_TOKEN_TYPES,
} from 'app/providers/auth/services';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},

  // TODO:
  '& .FusePageSimple-rightSidebar': {
    flex: '1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
  '& .FusePageSimple-contentWrapper': {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 400,
    },
  },
}));

// TODO:
const config = {
  METABASE_URL: 'https://dev.ultraviolet-metabase.hyvery.io',
};

// TODO:
const getAppPath = (location) => [location.pathname, location.search, location.hash].join('');

// TODO:
const useInnerHeight = () => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  useEffect(() => {
    const onResize = () => setInnerHeight(window.innerHeight);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return innerHeight;
};

const HyveIntelligenceViewScreen = () => {
  // TODO: Causes Reload on Token Refresh
  const { analyticsToken } = jwtService.getSession();
  const innerHeight = useInnerHeight();
  const location = useLocation();
  const mainTheme = useSelector(selectMainTheme);
  const navEl = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(selectUser);

  // TODO: If NOT a HInt User
  if (!analyticsToken) return null;

  return (
    <Root
      // TODO:
      // header={
      //   <>
      //     <div>Header</div>
      //   </>
      // }
      content={
        <SizeMe
          // TODO: Review
          // refreshMode="debounce"
          monitorHeight
          monitorWidth={false}
        >
          {({ size }) => (
            <div className="flex flex-row w-full h-full">
              {/* TODO: */}
              <CommonMetabaseInteractiveEmbed
                base={config.METABASE_URL}
                // TODO: Review Height was 56
                fitHeight={innerHeight - (navEl.current ? navEl.current.offsetHeight : 64)}

                // TODO:
                // getAuthUrl={(url) => `/api/auth/metabase?return_to=${encodeURIComponent(url)}`}
                getAuthUrl={(url) => `${config.METABASE_URL}/auth/sso?jwt=${analyticsToken}&return_to=${encodeURIComponent(url)}`}
                // getAuthUrl={(url) => `${config.METABASE_URL}/auth/sso?jwt=${analyticsToken}`}

                path={getAppPath(location).replace(/\/analytics\/hyve-intelligence/, '')}
                onLocationChange={(newLocation) => {
                  navigate(
                    `/analytics/hyve-intelligence${getAppPath(newLocation).replace(/^\/$/, '')}`
                  );
                }}
              />
            </div>
          )}
        </SizeMe>
      }
      // TODO:
      // leftSidebarWidth={341}
      // leftSidebarOpen={leftSidebarOpen}
      // leftSidebarOnClose={() => {
      //   setLeftSidebarOpen(false);
      // }}
      // leftSidebarContent={
      //   <div className="flex-1 min-w-340">
      //     Stuff
      //   </div>
      // }
      // TODO:
      // rightSidebarOpen={
      //   rightSidebarOpen
      //   // && selectedHitsViewMode !== 'list'
      // }
      // rightSidebarOnClose={() => {
      //   setRightSidebarOpen(false);
      // }}
      // rightSidebarContent={(
      //   <div className="flex-1 min-w-340">
      //     Stuff
      //   </div>
      // )}
      scroll="content"
    />
  );
};

export default HyveIntelligenceViewScreen;
