import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_PROVISION_VIEW_DRAWER_SERVICE_PROVISION_FRAGMENT = gql`
  fragment ServiceProvisionViewDrawerServiceProvisionFragment on ServiceProvision {
    id

    # TODO:
    name
  }
`;
