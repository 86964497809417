import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Alert, Button, IconButton, Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectFuseCurrentLayoutConfig, selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
// import AdjustFontSize from '../../shared-components/AdjustFontSize';
// import FullScreenToggle from '../../shared-components/FullScreenToggle';
// import HyveIntelligenceButton from '../../shared-components/HyveIntelligenceButton/HyveIntelligenceButton';
// import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import { ActivityStreamPanelToggleButton } from '../../shared-components/ActivityStreamPanel';
import NavigationShortcuts from '../../shared-components/NavigationShortcuts';
// import NavigationSearch from '../../shared-components/NavigationSearch';
import { GlobalSearchBar } from '../../shared-components/GlobalSearchBar';
import NavbarToggleButton from '../../shared-components/NavbarToggleButton';
// import TeamMenu from '../../shared-components/TeamMenu/TeamMenu';
// import ThemeModeButton from '../../shared-components/ThemeModeButton/ThemeModeButton';
import { UserMenu } from '../../shared-components/UserMenu';
// import QuickPanelToggleButton from '../../shared-components/quickPanel/QuickPanelToggleButton';
import ChatPanelToggleButton from '../../shared-components/chatPanel/ChatPanelToggleButton';

// TODO:
import { useFirebaseMessaging } from 'app/providers/firebase/messaging';
import { green } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

function ToolbarLayout1(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);

  const { isPermissionGranted, requestNotificationPermission } = useFirebaseMessaging();

  // TODO:
  const location = useLocation();
  const navigate = useNavigate();

  return (
    // TODO: Cleanup / Spacing
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? toolbarTheme.palette.background.paper
              : toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <div className="flex flex-1 px-16">
            {/* ROADMAP: Implement for Mobile or Remove */}
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            {/* TODO: */}
            {/* {!isPermissionGranted && (
              <Alert
                action={
                  <Button color="inherit" size="small" onClick={requestNotificationPermission}>
                    Request
                  </Button>
                }
                severity="warning"
              >
                Notification warning message.
              </Alert>
            )} */}

            {/* <TeamMenu /> */}

            {/* TODO: */}
            {/* <Tooltip title="Hyve Chat">
              <IconButton
                className={clsx('w-40 h-40', {
                  'text-blue': location.pathname.startsWith('/analytics/hyve-intelligence'),
                })}
                onClick={(ev) => navigate(`/apps/chat`)}
                size="large"
              >
                <FuseSvgIcon>heroicons-outline:chat-alt</FuseSvgIcon>
              </IconButton>
            </Tooltip> */}

            {/* TODO: */}
            <Hidden lgDown>
              <NavigationShortcuts />
            </Hidden>

            {/* TODO: */}
            {/* <IconButton
              color="success"
              sx={{
                width: 40,
                height: 40,
                // color: 'white',
                // backgroundColor: green[600],
                // '&:hover': {
                //   backgroundColor: green[800],
                // },
              }}
              onClick={() =>
                dispatch(
                  openDialog({
                    children: (
                      <ProgramCampaignCreateDialog
                        programId={params?.programId}
                        onClose={() => dispatch(closeDialog())}
                      />
                    ),
                    classes: {
                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                    },
                    disableEscapeKeyDown: true,
                  })
                )
              }
            >
              <FontAwesomeIcon icon={faPlusCircle} size="sm" />
            </IconButton> */}
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
            {/* ROADMAP: Implement or Remove */}
            {/* <LanguageSwitcher /> */}

            {/* ROADMAP: Implement or Remove */}
            {/* <AdjustFontSize /> */}

            {/* ROADMAP: Implement or Remove */}
            {/* <FullScreenToggle /> */}

            <GlobalSearchBar />

            {/* <ThemeModeButton /> */}

            {/* <HyveIntelligenceButton /> */}

            <Hidden lgUp>
              <ChatPanelToggleButton />
            </Hidden>

            {/* ROADMAP: Implement */}
            {/* <QuickPanelToggleButton /> */}

            <ActivityStreamPanelToggleButton />

            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden lgDown>
                {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
