import moment from 'moment';

const makeAdaptedValueGetter =
  ({ attribute, start, end }) =>
  (target) => {
    let adaptedValue;

    if (target === 'api') {
      adaptedValue = [start, end];
    } else if (target === 'datepicker') {
      adaptedValue = [moment.unix(start).toDate(), moment.unix(end).toDate()];
    } else if (target === 'meilisearch') {
      adaptedValue = `${attribute} > ${start} AND ${attribute} < ${end}`;
    }

    return adaptedValue;
  };

// eslint-disable-next-line import/prefer-default-export
export const makeEntitySearchDateRangeMenuPresets = ({ attribute }) => [
  {
    label: 'Within 1 Hour',
    value: 'dateRangePreset:withinOneHour',
    getAdaptedValue: makeAdaptedValueGetter({
      attribute,
      start: moment().subtract(1, 'hour').unix(),
      end: moment().unix(),
    }),
  },
  {
    label: 'Within 4 Hours',
    value: 'dateRangePreset:withinFourHours',
    getAdaptedValue: makeAdaptedValueGetter({
      attribute,
      start: moment().subtract(4, 'hours').unix(),
      end: moment().unix(),
    }),
  },
  {
    label: 'Today',
    value: 'dateRangePreset:today',
    getAdaptedValue: makeAdaptedValueGetter({
      attribute,
      start: moment().startOf('day').unix(),
      end: moment().endOf('day').unix(),
    }),
  },
  {
    label: 'Last 7 Days',
    value: 'dateRangePreset:lastSevenDays',
    getAdaptedValue: makeAdaptedValueGetter({
      attribute,
      start: moment().subtract(7, 'days').unix(),
      end: moment().unix(),
    }),
  },
  {
    label: 'This Month',
    value: 'dateRangePreset:thisMonth',
    getAdaptedValue: makeAdaptedValueGetter({
      attribute,
      start: moment().startOf('month').unix(),
      end: moment().endOf('month').unix(),
    }),
  },
  {
    label: 'Last 90 Days',
    value: 'dateRangePreset:lastNinetyDays',
    getAdaptedValue: makeAdaptedValueGetter({
      attribute,
      start: moment().subtract(90, 'days').unix(),
      end: moment().unix(),
    }),
  },
];
