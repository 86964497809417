import { gql } from '@apollo/client';
import { SERVICE_TICKET_TASK_VIEW_DRAWER_SERVICE_TICKET_TASK_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_SERVICE_TICKET_TASK_VIEW_DRAWER_DATA = gql`
  query FetchServiceTicketTaskViewDrawerData($where: ServiceTicketTaskWhereUniqueInput!) {
    serviceTicketTask(where: $where) {
      id
      ...ServiceTicketTaskViewDrawerServiceTicketTaskFragment
    }
  }
  ${SERVICE_TICKET_TASK_VIEW_DRAWER_SERVICE_TICKET_TASK_FRAGMENT}
`;
