import { useMutation, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { CREATE_SERVICE_INVOICE, FETCH_SERVICE_INVOICE_CREATE_DIALOG_DATA } from './queries';

const defaultValues = {
  vendorReferenceNumber: '',
  workOrderIssues: [],
};

const schema = yup.object().shape({
  vendorReferenceNumber: yup
    .string()
    .nullable()
    .required('A vendor reference number must be provided.'),
  workOrderIssues: yup
    .array()
    .test(
      'requireOneWorkOrderIssue',
      'One or more work order issues must be provided.',
      (value) => value.filter((el) => el).length
    ),
});

const ServiceInvoiceCreateDialog = ({ defaultWorkOrderIssueId, serviceTicketId, onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchServiceInvoiceCreateDialogData,
    {
      data: serviceInvoiceCreateDialogData,
      loading: serviceInvoiceCreateDialogLoading,
      refetch: serviceInvoiceCreateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_INVOICE_CREATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [createServiceInvoice, { loading: createServiceInvoiceLoading }] = useMutation(
    CREATE_SERVICE_INVOICE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Invoice Successfully Created',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Service Invoice', variant: 'error' }));
      },
    }
  );

  const filteredWorkOrderIssues = useMemo(
    () =>
      serviceInvoiceCreateDialogData?.serviceTicket?.workOrder?.issues
        ?.filter(({ status }) => ['COMPLETED'].includes(status?.enum))
        ?.map((workOrderIssues) => workOrderIssues),
    [serviceInvoiceCreateDialogData?.serviceTicket]
  );

  const loading = useMemo(
    () => [createServiceInvoiceLoading, serviceInvoiceCreateDialogLoading].includes(true),
    [createServiceInvoiceLoading, serviceInvoiceCreateDialogLoading]
  );

  const serviceTicket = useMemo(
    () => serviceInvoiceCreateDialogData?.serviceTicket,
    [serviceInvoiceCreateDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (defaultWorkOrderIssueId && filteredWorkOrderIssues) {
      const newDefaultValues = _.cloneDeep(defaultValues);
      const defaultWorkOrderIssueIndex = filteredWorkOrderIssues?.findIndex(
        ({ id }) => id === defaultWorkOrderIssueId
      );

      if (defaultWorkOrderIssueIndex !== -1) {
        newDefaultValues.workOrderIssues[defaultWorkOrderIssueIndex] = defaultWorkOrderIssueId;
      }

      reset(newDefaultValues);
    }
  }, [defaultWorkOrderIssueId, filteredWorkOrderIssues, reset]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchServiceInvoiceCreateDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchServiceInvoiceCreateDialogData, serviceTicketId]);

  // ROADMAP: Re-Implement or Remove
  // const handleToggleAllWorkOrderIssues = () => {
  //   if (
  //     filteredWorkOrderIssues?.length === watchFields.workOrderIssues?.length &&
  //     !_.some(watchFields.workOrderIssues || [], (value) => !value)
  //   ) {
  //     setValue('workOrderIssues', [], {
  //       shouldDirty: true,
  //       shouldTouch: true,
  //       shouldValidate: true,
  //     });
  //   } else {
  //     setValue(
  //       'workOrderIssues',
  //       filteredWorkOrderIssues?.map(({ id }) => id),
  //       { shouldDirty: true, shouldTouch: true, shouldValidate: true }
  //     );
  //   }
  // };

  const onSubmit = async (data) => {
    try {
      await createServiceInvoice({
        variables: {
          where: { id: serviceTicketId },
          data: {
            vendorReferenceNumber: data.vendorReferenceNumber,
            workOrderIssues: data.workOrderIssues?.filter((el) => el).map((id) => ({ id })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Service Invoice
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-invoice-create-form"
            name="service-invoice-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="workOrderIssues"
              render={({ field }) => (
                <>
                  <div className="mb-24">
                    <div
                      className="border-1 overflow-y-auto"
                      style={{
                        maxHeight: 290,
                        borderColor: errors.workOrderIssues ? '#f44336' : grey[300],
                      }}
                    >
                      <List disablePadding>
                        {/* ROADMAP: Re-Implement or Remove */}
                        {/* <ListItemButton onClick={handleToggleAllWorkOrderIssues}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                filteredWorkOrderIssues?.length ===
                                  watchFields.workOrderIssues?.length &&
                                !_.some(watchFields.workOrderIssues || [], (value) => !value)
                              }
                              edge="start"
                              disableRipple
                              indeterminate={
                                (filteredWorkOrderIssues?.length !==
                                  watchFields.workOrderIssues?.length &&
                                  _.some(watchFields.workOrderIssues || [], (value) => value)) ||
                                (filteredWorkOrderIssues?.length ===
                                  watchFields.workOrderIssues?.length &&
                                  _.some(watchFields.workOrderIssues || [], (value) => value) &&
                                  _.some(watchFields.workOrderIssues || [], (value) => !value))
                              }
                              tabIndex={-1}
                              // value={id}
                              onChange={handleToggleAllWorkOrderIssues}
                            />
                          </ListItemIcon>

                          <ListItemText primary="Select All" />
                        </ListItemButton> */}

                        {filteredWorkOrderIssues?.map(
                          ({ id, accountingWorkOrderType, serviceIssue }, index) => {
                            const handleChange = () => {
                              const newFieldValue = [...field?.value];

                              newFieldValue[index] = !newFieldValue[index] ? id : null;

                              field.onChange(newFieldValue);
                            };

                            const isSameWorkOrderType = _.every(
                              (watchFields.workOrderIssues || []).filter((el) => el),
                              (workOrderIssueId) => {
                                const matchedWorkOrderIssue = filteredWorkOrderIssues.find(
                                  (filteredWorkOrderIssue) =>
                                    filteredWorkOrderIssue.id === workOrderIssueId
                                );

                                return (
                                  matchedWorkOrderIssue?.accountingWorkOrderType?.id ===
                                  accountingWorkOrderType?.id
                                );
                              }
                            );

                            return (
                              <ListItemButton
                                disabled={!isSameWorkOrderType}
                                key={id}
                                onClick={handleChange}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={!!field?.value?.[index]}
                                    edge="start"
                                    disabled={!isSameWorkOrderType}
                                    disableRipple
                                    tabIndex={-1}
                                    value={id}
                                    onChange={handleChange}
                                  />
                                </ListItemIcon>

                                <ListItemText primary={serviceIssue.name} />
                              </ListItemButton>
                            );
                          }
                        )}
                      </List>
                    </div>

                    {errors.workOrderIssues && (
                      <div className="mx-14 mt-3">
                        <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
                          {errors.workOrderIssues?.message}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />

            <Controller
              control={control}
              name="vendorReferenceNumber"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.vendorReferenceNumber}
                  fullWidth
                  helperText={errors?.vendorReferenceNumber?.message}
                  placeholder="Enter Vendor Reference Number..."
                  required
                  variant="outlined"
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-invoice-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceInvoiceCreateDialog;
