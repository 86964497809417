import _ from '@lodash';
import { EntitySearchInfiniteHitsGridDefaultCellRenderer } from 'app/shared-components/EntitySearch';
import moment from 'moment';
import numeral from 'numeral';

// eslint-disable-next-line import/prefer-default-export
export const buildServiceTicketSearchScreenColumnDefs = ({ theme, user }) => ([
  {
    groupId: 'serviceTicket',
    headerName: 'Service Ticket',
    children: [
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'serviceReferenceNumber',
        headerName: 'SRN',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueGetter: ({ data, ...rest }) => {
          if (!data) return null;

          return moment.unix(data?.createdAtTimestamp).format('MMM. DD, YYYY @ hh:mm A');
        },
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'phase.name',
        headerName: 'Phase',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'workOrder.issues',
        headerName: 'Severities',
        sortable: false,
        valueGetter: ({ data }) => {
          if (!data) return null;

          return (
            data?.workOrder.issues &&
            _.uniq(
              data?.workOrder.issues.map(
                ({ serviceIssue }) => serviceIssue?.serviceResponseSeverity?.name
              )
            ).join(', ')
          );
        },
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'hasPausedIssues',
        headerName: 'Has Paused Issues',
        sortable: true,
        valueGetter: ({ data }) => {
          if (!data) return null;

          return data?.hasPausedIssues ? 'Yes' : 'No';
        },
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'hasOpenRfq',
        headerName: 'Has Open RFQ',
        sortable: true,
        valueGetter: ({ data }) => {
          if (!data) return null;

          return data?.hasOpenRfq ? 'Yes' : 'No';
        },
      },
      {
        cellClass: ({ data }) => {
          const classes = [];

          if (
            ['OPEN'].includes(data?.status.enum) &&
            data?.completeBy &&
            moment().isAfter(moment(data?.completeBy).startOf('day'))
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-red-200' : 'bg-red-800');
          } else if (
            ['OPEN'].includes(data?.status.enum) &&
            data?.completeBy &&
            moment().add(4, 'days').isAfter(moment(data?.completeBy).startOf('day'))
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-amber-200' : 'bg-amber-800');
          }

          return classes;
        },
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'completeByTimestamp',
        headerName: 'Latest Complete By',
        sortable: true,
        valueGetter: ({ data }) => {
          if (!data) return null;

          return data?.completeBy
            ? moment.unix(data?.completeByTimestamp).format('MMM. DD, YYYY')
            : '-';
        },
      },
      {
        cellClass: ({ data }) => {
          const classes = [];

          if (
            ['OPEN'].includes(data?.status.enum) &&
            !!data?.openConsumerTaskCount &&
            ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum)
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-amber-200' : 'bg-amber-800');
          }

          return classes;
        },
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'openConsumerTaskCount',
        headerName: 'Open Consumer Tasks',
        sortable: true,
      },
      {
        cellClass: ({ data }) => {
          const classes = [];

          if (
            ['OPEN'].includes(data?.status.enum) &&
            !!data?.openVendorTaskCount &&
            ['MANUFACTURER', 'VENDOR'].includes(user?.data?.organization.type.enum)
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-amber-200' : 'bg-amber-800');
          }

          return classes;
        },
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'openVendorTaskCount',
        headerName: 'Open Vendor Tasks',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'serviceProvision.organizationConnection.consumer.name',
        headerName: 'Consumer',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'serviceProvision.organizationConnection.vendor.name',
        headerName: 'Vendor',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'isRequestForQuote',
        headerName: 'RFQ',
        sortable: true,
        valueGetter: ({ data }) => {
          if (!data) return null;

          return data?.isRequestForQuote ? 'Yes' : 'No';
        },
      },
    ],
  },
  {
    headerName: 'Program',
    children: [
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'programCampaign.program.name',
        headerName: 'Program',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'programCampaign.name',
        headerName: 'Program Campaign',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Facility',
    children: [
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.franchiseNumber',
        headerName: 'Number',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.marketTeam',
        headerName: 'Market Team',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.regionalTeam',
        headerName: 'Regional Team',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.businessConsultantContact.name',
        headerName: 'Business Consultant',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.esrContact.name',
        headerName: 'ESR',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.fieldConsultantContact.name',
        headerName: 'Field Consultant',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.leadAdvisorContact.name',
        headerName: 'Lead Advisor',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.operatorContact.name',
        headerName: 'Operator',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.projectAdministratorContact.name',
        headerName: 'Project Administrator',
        sortable: true,
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'facility.regionalCoordinatorContact.name',
        headerName: 'Regional Coordinator',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Work Order',
    children: [
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        columnGroupShow: 'open',
        field: 'workOrder.discountAmount',
        headerName: 'Discount',
        sortable: true,
        valueGetter: ({ data }) =>
          data?.workOrder?.discountAmount
            ? numeral(data?.workOrder?.discountAmount).format('$0,0.00')
            : '-',
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        columnGroupShow: 'open',
        field: 'workOrder.subtotalAmount',
        headerName: 'Subtotal',
        sortable: true,
        valueGetter: ({ data }) =>
          data?.workOrder?.subtotalAmount
            ? numeral(data?.workOrder?.subtotalAmount).format('$0,0.00')
            : '-',
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'workOrder.totalAmount',
        headerName: 'Total',
        sortable: true,
        valueGetter: ({ data }) =>
          data?.workOrder?.totalAmount
            ? numeral(data?.workOrder?.totalAmount).format('$0,0.00')
            : '-',
      },
      {
        cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
        field: 'workOrder.notToExceedAmount',
        headerName: 'NTE',
        sortable: true,
        valueGetter: ({ data }) =>
          data?.workOrder?.notToExceedAmount
            ? numeral(data?.workOrder?.notToExceedAmount).format('$0,0.00')
            : '-',
      },
    ],
  },
]);
