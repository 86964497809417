import _ from '@lodash';
import i18n from 'src/i18n';

export default (languageLabels) => {
  if (_.isEmpty(languageLabels)) return null;

  const selectedLanguage = i18n.language.toUpperCase();

  const defaultValue = _.get(
    languageLabels.find((languageString) => languageString.type === 'EN'),
    'value'
  );

  const selectedValue = _.get(
    languageLabels.find((languageString) => languageString.type === selectedLanguage),
    'value'
  );

  return !_.isEmpty(selectedValue) ? selectedValue : defaultValue;
};
