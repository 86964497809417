import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_TASK_VIEW_APP_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketTaskViewAppServiceTicketFragment on ServiceTicket {
    id
    serviceProvision {
      id
      name
      organizationConnection {
        id
        consumer {
          id
          name
          type {
            id
            enum
            name
          }
        }
        vendor {
          id
          name
          type {
            id
            enum
            name
          }
        }
      }
    }
    tasks {
      id
      createdAt
      assignedOrganizationType {
        id
        enum
        name
      }
      description
      isCancelled
      isCompleted
      name
    }
  }
`;
