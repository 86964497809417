import { gql } from '@apollo/client';
import { SERVICE_TICKET_TASK_VIEW_APP_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_SERVICE_TICKET_TASK_VIEW_APP_DATA = gql`
  query FetchServiceTicketTaskViewAppData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...ServiceTicketTaskViewAppServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_TASK_VIEW_APP_SERVICE_TICKET_FRAGMENT}
`;
