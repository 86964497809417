import { gql } from '@apollo/client';
import { USER_SEARCH_VIEW_DELETE_DIALOG_USER_SEARCH_VIEW_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_USER_SEARCH_VIEW = gql`
  mutation DeleteUserSearchView($where: UserSearchViewWhereUniqueInput!) {
    deleteUserSearchView(where: $where) {
      id
      ...UserSearchViewDeleteDialogUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_DELETE_DIALOG_USER_SEARCH_VIEW_FRAGMENT}
`;
