// eslint-disable-next-line import/prefer-default-export
export const assetSearchScreenFilterDefs = [
  // {
  //   name: 'Asset',
  //   refinements: [
  //     {
  //       attribute: 'status.label',
  //       label: 'Status',
  //       operator: 'or',
  //     },
  //   ],
  // },
  // {
  //   name: 'Facility',
  //   refinements: [
  //     {
  //       attribute: 'facility.city',
  //       label: 'City',
  //       operator: 'or',
  //     },
  //     {
  //       attribute: 'facility.franchisor.name',
  //       label: 'Franchisor',
  //       operator: 'or',
  //     },
  //     {
  //       attribute: 'facility.name',
  //       label: 'Name',
  //       operator: 'or',
  //     },
  //     {
  //       attribute: 'facility.operator.name',
  //       label: 'Operator',
  //       operator: 'or',
  //     },
  //     {
  //       attribute: 'facility.region',
  //       label: 'Region',
  //       operator: 'or',
  //     },
  //   ],
  // },
  // {
  //   name: 'Product Model',
  //   refinements: [
  //     {
  //       attribute: 'productModel.category.label',
  //       label: 'Category',
  //       operator: 'or',
  //     },
  //     {
  //       attribute: 'productModel.category.classification.label',
  //       label: 'Classification',
  //       operator: 'or',
  //     },
  //     {
  //       attribute: 'productModel.organization.name',
  //       label: 'Manufacturer',
  //       operator: 'or',
  //     },
  //     {
  //       attribute: 'productModel.name',
  //       label: 'Model Number',
  //       operator: 'or',
  //     },
  //   ],
  // },
];
