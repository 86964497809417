import FuseLoading from '@fuse/core/FuseLoading';
import { Eventcalendar } from '@mobiscroll/js/mobiscroll.react.min';
import { Paper } from '@mui/material';
import { blue, purple } from '@mui/material/colors';
import { useMeilisearch } from 'app/providers/meilisearch';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import {
  Configure,
  InstantSearch,
  useHits,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch';
import { useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';

const ServiceTicketCalendarAppVirtualRename = () => {
  const params = useParams();
  const { refine } = useRefinementList({ attribute: 'serviceTicket.id' });

  useEffect(() => {
    if (params?.serviceTicketId) {
      refine(params?.serviceTicketId);
    }
  }, [refine, params?.serviceTicketId]);

  return null;
};

const ServiceTicketCalendarAppRoot = ({ basePath }) => {
  const { hits } = useHits();
  const { status } = useInstantSearch();

  const loading = useMemo(() => status === 'stalled', [status]);

  const calendarEvents = useMemo(() => {
    // console.log('// CALENDAR', { hits });

    const _calendarEvents = hits?.map((hit) => {
      let color;
      let title;

      if (hit.__typename === 'ServiceVisit') {
        // eslint-disable-next-line prefer-destructuring
        color = blue[600];
        title = 'Service Visit';
      } else if (hit.__typename === 'SiteVisit') {
        // eslint-disable-next-line prefer-destructuring
        color = purple[600];
        title = 'Site Visit';
      } else {
        return null;
      }

      return {
        allDay: false,
        color,
        end: moment(hit.windowEndAt).format(),
        start: moment(hit.windowStartAt).format(),
        title,
      };
    });

    return _calendarEvents || [];
  }, [hits]);

  return (
    <>
      <ServiceTicketCalendarAppVirtualRename />

      <SizeMe monitorHeight monitorWidth={false}>
        {({ size }) => (
          <div className="flex flex-1 flex-row h-full">
            {/* TODO: */}
            {loading ? (
              <div className="flex w-full justify-center items-center">
                <FuseLoading />
              </div>
            ) : (
              <Paper
                className="w-full overflow-scroll"
                elevation={0}
                square
                sx={{ height: size?.height }}
              >
                {/* TODO: Review */}
                <Eventcalendar
                  view={{
                    agenda: {
                      // TODO: Dafuq?
                      type: 'year',
                      size: 5,
                    },
                  }}
                  data={calendarEvents}
                  showControls={false}
                  // TODO:
                  // onEventClick={handleEventClick}
                />
              </Paper>
            )}
          </div>
        )}
      </SizeMe>
    </>
  );
};

const ServiceTicketCalendarApp = (props) => {
  const { instantSearchClient } = useMeilisearch();

  return (
    <>
      {instantSearchClient && (
        <InstantSearch indexName="merged_visits" searchClient={instantSearchClient} routing={false}>
          {/* TODO: Review */}
          <Configure hitsPerPage={200} paginationTotalHits={2000} />

          <ServiceTicketCalendarAppRoot {...props} />
        </InstantSearch>
      )}
    </>
  );
};

export default ServiceTicketCalendarApp;
