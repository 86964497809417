import { faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, InputBase } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';

const SearchBox = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.common.black, 0.54)
      : theme.palette.common.white,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  color:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.common.black, 0.54)
      : theme.palette.common.white,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchStatusWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(32px + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const EntitySearchBox = () => {
  const searchTimer = useRef(null);
  const { status } = useInstantSearch();

  const queryHook = useCallback((query, search) => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      searchTimer.current = null;
      search(query);
    }, 200);
  }, []);

  const { query, refine } = useSearchBox({ queryHook });
  const [searchText, setSearchText] = useState(query);

  useEffect(() => {
    if (!searchTimer.current && query !== searchText) {
      setSearchText(query);
    }
  }, [query, searchText]);

  const setQuery = (newQuery) => {
    setSearchText(newQuery);

    refine(newQuery);
  };

  const loading = useMemo(() => status === 'stalled', [status]);

  const handleSearchTextChange = ({ target }) => setQuery(target?.value);

  const handleSearchTextClear = () => setQuery('');

  return (
    <SearchBox>
      <SearchIconWrapper>
        <FontAwesomeIcon icon={faSearch} size="sm" />
      </SearchIconWrapper>

      <StyledInputBase
        // ROADMAP: Implement or Remove
        disabled={false}
        placeholder="Search..."
        value={searchText}
        onChange={handleSearchTextChange}
      />

      <SearchStatusWrapper>
        {loading && (
          <div className="flex items-center justify-center w-32 h-32">
            <FontAwesomeIcon icon={faSpinner} size="sm" spin />
          </div>
        )}

        {!loading && !!searchText.length && (
          <IconButton
            color="default"
            sx={{ width: 32, height: 32 }}
            onClick={handleSearchTextClear}
          >
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
        )}
      </SearchStatusWrapper>
    </SearchBox>
  );
};

export default EntitySearchBox;
