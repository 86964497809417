import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DemoSidebarContent from '@fuse/core/DemoSidebarContent';
import {
  AppBar,
  Avatar,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { EntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchDrawer } from 'app/shared-components/EntitySearch/EntitySearchDrawer';
import { EntitySearchHeader } from 'app/shared-components/EntitySearch/EntitySearchHeader';
import { EntitySearchInfiniteHitsGrid } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsGrid';
import { EntitySearchInfiniteHitsList } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsList';
import { EntitySearchInfiniteHitsMap } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsMap';
// import { ServiceRequestCreateDialog } from 'app/shared-components/ServiceRequest/ServiceRequestCreateDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
// import { AssetSearchAdapter } from './adapters';
// import { assetSearchScreenColumnDefs } from './columnDefs';
// import { assetSearchScreenFilterDefs } from './filterDefs';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import ServiceProvisionManageListProvisionListItem from './ServiceProvisionManageListProvisionListItem';

const ServiceProvisionManageListProvisionList = ({ index, organization, serviceProvisions }) => {
  const theme = useTheme();

  return (
    <List component="div" disablePadding>
      <Droppable
        droppableId={`subList-${organization.id}`}
        type={`subList-${index}`}
        direction="vertical"
      >
        {(provided, snapshot) => (
          <>
            <div
              ref={provided.innerRef}
              style={{
                backgroundColor: snapshot.isDraggingOver
                  ? green[700]
                  : theme.palette.background.default,
              }}
            >
              {serviceProvisions.map((serviceProvision, idx) => (
                <ServiceProvisionManageListProvisionListItem
                  // TODO:
                  serviceProvision={serviceProvision}
                  organization={organization}
                  index={idx}
                />
              ))}
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </List>
  )
};

export default ServiceProvisionManageListProvisionList;
