import { gql } from '@apollo/client';
import { USER_SEARCH_VIEW_CREATE_DIALOG_USER_SEARCH_VIEW_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_USER_SEARCH_VIEW = gql`
  mutation CreateUserSearchView($data: UserSearchViewCreateInput!) {
    createUserSearchView(data: $data) {
      id
      ...UserSearchViewCreateDialogUserSearchViewFragment
    }
  }
  ${USER_SEARCH_VIEW_CREATE_DIALOG_USER_SEARCH_VIEW_FRAGMENT}
`;
