import { useLazyQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  Chip,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import {} from '@mui/styles/'
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { faAngleDown, faAngleRight, faHouse, faInfoCircle, faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { amber, blue, blueGrey, green, grey, lightBlue, purple, red } from '@mui/material/colors';
import { FETCH_SERVICE_PROVISION_VERSION_VIEW_APP_DATA } from './queries';

const ServiceProvisionVersionViewApp = ({ basePath }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const mainTheme = useSelector(selectMainTheme);
  const params = useParams();

  const [
    fetchServiceProvisionVersionViewAppData,
    {
      data: serviceProvisionVersionViewAppData,
      loading: serviceProvisionVersionViewAppLoading,
      refetch: serviceProvisionVersionViewAppRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_PROVISION_VERSION_VIEW_APP_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching serviceProvision Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceProvisionVersionViewAppLoading].includes(true),
    [serviceProvisionVersionViewAppLoading]
  );

  const serviceProvision = useMemo(
    () => serviceProvisionVersionViewAppData?.serviceProvision,
    [serviceProvisionVersionViewAppData?.serviceProvision]
  );

  const orderedServiceProvisionVersions = useMemo(
    () =>
      _.orderBy(
        serviceProvisionVersionViewAppData?.serviceProvision?.versions,
        ({ version }) => version,
        ['asc']
      ),
    [serviceProvisionVersionViewAppData?.serviceProvision?.versions]
  );

  const currentServiceProvisionVersion = useMemo(() => _.last(orderedServiceProvisionVersions || []), [orderedServiceProvisionVersions]);

  useEffect(() => {
    if (params?.serviceProvisionId) {
      fetchServiceProvisionVersionViewAppData({ variables: { where: { id: params?.serviceProvisionId } } });
    }
  }, [fetchServiceProvisionVersionViewAppData, params?.serviceProvisionId]);

  const [openTables, setOpenTables] = useState({});
  const isMounted = useRef(null);

  const handleToggleTable = ({ tableId }) =>
    setOpenTables({ ...(openTables || {}), [tableId]: !openTables?.[tableId] });

  // TODO:
  useEffect(() => {
    if (!isMounted.current && typeof handleToggleTable === 'function' && currentServiceProvisionVersion) {
      handleToggleTable({ tableId: currentServiceProvisionVersion?.id });

      isMounted.current = true;
    }
  }, [handleToggleTable, currentServiceProvisionVersion?.id]);

  return (
    <SizeMe monitorHeight monitorWidth={false}>
      {({ size }) => (
        <div className="flex flex-1 flex-row h-full">
          {(loading && !fetched) || !serviceProvision ? (
            <div className="flex w-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <Paper
              className="w-full overflow-scroll"
              elevation={0}
              square
              sx={{ height: size?.height }}
            >
              {_.isEmpty(serviceProvision.versions) ? (
                <div className="flex items-center justify-center w-full h-full">
                  <Typography className="text-24">No Campaigns Found</Typography>
                </div>
              ) : (
                <div>
                  {/* TODO: */}
                  <Alert severity="warning" square>
                    <AlertTitle>Do Something!</AlertTitle>
                    Do something right now or something else will happen.
                  </Alert>

                  {/* TODO: */}
                  <List className="border-t-1" disablePadding>
                    {_.map(orderedServiceProvisionVersions, (serviceProvisionVersion) => (
                      <>
                        {/* TODO: */}
                        <ListItemButton
                          alignItems="flex-start"
                          className="h-64 px-24 py-16"
                          divider
                          key={serviceProvisionVersion.id}
                          sx={{ backgroundColor: '#0e131f' }}
                          // TODO:
                          // selected={serviceProvisionVersion.id === params?.viewId}
                          // onClick={() => navigate(`../${basePath}/view/${serviceProvisionVersion.id}`)}
                          onClick={() =>
                            handleToggleTable({ tableId: serviceProvisionVersion?.id })
                          }
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              icon={
                                openTables?.[serviceProvisionVersion?.id]
                                  ? faAngleDown
                                  : faAngleRight
                              }
                              style={{ width: 18, height: 18 }}
                            />
                          </ListItemIcon>

                          <ListItemText
                            primaryTypographyProps={{ className: 'text-16 font-600' }}
                            primary={`Version: ${serviceProvisionVersion.version}`}
                          />

                          <Chip
                            className="ml-12"
                            label={serviceProvisionVersion?.status?.name}
                            // TODO:
                            // sx={{
                            //   color: 'white',
                            //   backgroundColor:
                            //     WORK_ORDER_ISSUE_STATUS_ENUM_COLORS[workOrderIssue?.status?.enum] ||
                            //     grey[800],
                            // }}
                          />
                        </ListItemButton>

                        <Collapse
                          in={openTables?.[serviceProvisionVersion?.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div>hi!</div>
                        </Collapse>
                      </>
                    ))}
                  </List>
                </div>
              )}
            </Paper>
          )}
        </div>
      )}
    </SizeMe>
  );
};

export default ServiceProvisionVersionViewApp;
