import _ from '@lodash';
import { useEffect, useState } from 'react';
import { useChannel } from 'app/providers/pusher/channels';

const usePresenceChannel = (channelName) => {
  const channel = useChannel(channelName);
  const [state, setState] = useState();

  useEffect(() => {
    if (channel) {
      const handleSubscriptionSuccess = (members) => {
        setState({
          members: members.members,
          myID: members.myID,
          me: members.me,
          count: Object.keys(members.members).length,
        });
      };

      const handleAdd = (member) => {
        setState((_state) => {
          const __state = _.cloneDeep(_state);

          __state.members = { ...(__state?.members || {}), ...member };

          return { ...__state, count: Object.keys(__state.members).length };
        });
      };

      const handleRemove = (member) => {
        setState((_state) => {
          const __state = _.cloneDeep(_state);

          _.remove(__state?.members || [], ({ id }) => id === member?.id);

          return { ...__state, count: Object.keys(__state.members).length };
        });
      };

      channel.bind('pusher:subscription_succeeded', handleSubscriptionSuccess);
      channel.bind('pusher:member_added', handleAdd);
      channel.bind('pusher:member_removed', handleRemove);

      return () => {
        channel.unbind('pusher:subscription_succeeded', handleSubscriptionSuccess);
        channel.unbind('pusher:member_added', handleAdd);
        channel.unbind('pusher:member_removed', handleRemove);
      };
    }

    return () => {};
  }, [channel]);

  return {
    channel,
    ...state,
  };
};

export default usePresenceChannel;
