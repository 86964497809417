import FuseLoading from '@fuse/core/FuseLoading';
import { Alert, AlertTitle, Paper, Typography } from '@mui/material';
import { useStreamChat } from 'app/providers/stream/chat';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { selectUser } from 'app/store/userSlice';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  defaultRenderMessages,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';
import { CommonChatChannelHeader, CommonChatChannelListPreview } from 'app/shared-components/Common/CommonChat';

// TODO: Extract
// import 'stream-chat-react/dist/css/v2/index.css';

const customRenderMessages = (options) => {
  const elements = defaultRenderMessages(options);

  // TODO: Add Topic Here
  // elements.unshift(
  //   <Alert className="m-32 mb-0" key="todo-key" severity="info">
  //     <AlertTitle>Channel Topic</AlertTitle>
  //     Welcome to a chat channel about work order issue `Work Order Issue Name`!
  //   </Alert>
  // );

  return elements;
};

const CustomMessageList = () => <MessageList renderMessages={customRenderMessages} />;


// TODO: Rename
const ServiceTicketChannelViewApp = ({ basePath, onToggleLeftSidebar }) => {
  const { streamChatClient } = useStreamChat();
  const mainTheme = useSelector(selectMainTheme);
  const user = useSelector(selectUser);

  const themeMode = useMemo(() => mainTheme?.palette.mode, [mainTheme?.palette.mode]);

  // TODO:
  const fetched = true;
  const filters = {
    type: 'messaging',
    members: { $in: [user?.id] },
  };
  const loading = false;
  const options = {
    limit: 10,
  };
  const sort = { last_message_at: -1 };

  return (
    <SizeMe monitorHeight monitorWidth={false}>
      {({ size }) => (
        <div className="flex flex-1 flex-row h-full">
          {(loading && !fetched) || !streamChatClient ? (
            <div className="flex w-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <Paper
              // TODO:
              className="flex flex-1 flex-row"
              elevation={0}
              square
              sx={{ height: size?.height }}
            >
              {/* <Chat client={streamChatClient} theme={`str-chat__theme-${themeMode}`}> */}
                {/* <div className="hidden">
                <ChannelList
                  additionalChannelSearchProps={{ popupResults: false, searchForChannels: true }}
                  filters={filters}
                  options={options}
                  showChannelSearch
                  sort={sort}
                />
                </div> */}
                <Channel>
                  <Window>
                    {/* TODO: */}
                    <CommonChatChannelHeader onToggleLeftSidebar={onToggleLeftSidebar} />
                    {/* <ChannelHeader /> */}

                    {/* TODO: */}
                    {/* <MessageList /> */}
                    <CustomMessageList />

                    <MessageInput />
                  </Window>
                  <Thread />
                </Channel>
              {/* </Chat> */}
            </Paper>
          )}
        </div>
      )}
    </SizeMe>
  );
};

export default ServiceTicketChannelViewApp;
