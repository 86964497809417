import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const CalendarAppPage = lazy(() => import('./CalendarAppPage'));
const calendarAppPageBase = '/apps';

// i18next.addResourceBundle('en', 'calendarAppPage', en);
// i18next.addResourceBundle('tr', 'calendarAppPage', tr);
// i18next.addResourceBundle('ar', 'calendarAppPage', ar);

const CalendarAppPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${calendarAppPageBase}/calendar`,
      element: <CalendarAppPage />,
    },
    // TODO:
    // {
    //   path: `${calendarAppPageBase}/calendar/:channelType/view/:channelId`,
    //   element: <CalendarAppPage />,
    // },
    {
      path: calendarAppPageBase,
      // TODO: Wrong
      element: <Navigate to={`${calendarAppPageBase}/calendar`} />,
    },
  ],
};

export default CalendarAppPageConfig;
