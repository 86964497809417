import { useMemo, useRef } from 'react';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import { LinearProgress, List, ListItem, Paper, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch';

// TODO: memo?
const EntitySearchInfiniteHitsList = ({ RenameHitComponent, renameHitComponentProps }) => {
  const { hits, isFirstPage, isLastPage, showMore } = useInfiniteHits();
  const { status } = useInstantSearch();
  const isSearching = useMemo(() => status === 'stalled', [status]);

  const scrollableTarget = useRef(null);

  // TODO: Reset Scroll
  // useEffect(() => {
  //   if (!expanded) {
  //     scrollableTarget.current.scrollTo({
  //       behavior: 'smooth',
  //       top: 0,
  //     });
  //   }
  // }, [expanded]);

  return (
    <Paper sx={{ display: 'flex', flexGrow: 1, zIndex: 10, borderRadius: 0 }}>
      <List
        disablePadding
        id="entity-search-infinite-hits-list"
        ref={scrollableTarget}
        sx={{ flexGrow: 1, height: '100%', overflow: 'auto' }}
      >
        {isFirstPage && isLastPage && isSearching ? (
          <div className="flex w-full h-full">
            <FuseLoading />
          </div>
        ) : (
          <>
            {!hits?.length && isFirstPage && !isSearching ? (
              <div className="flex w-full h-full">
                <div className="flex flex-1 flex-col items-center justify-center p-24">
                  <FontAwesomeIcon className="text-grey-400" icon={faMagnifyingGlass} size="10x" />
                  <Typography className="mt-24 text-15 text-grey-600 font-600">
                    No Results Found
                  </Typography>
                </div>
              </div>
            ) : (
              <InfiniteScroll
                dataLength={hits?.length}
                endMessage={
                  isLastPage &&
                  !isSearching && (
                    <ListItem className="flex flex-row items-center p-16">
                      <div className="flex flex-1">End of Results</div>
                      <div className="flex">Fetched: {hits?.length}</div>
                    </ListItem>
                  )
                }
                hasMore={!isLastPage}
                loader={
                  !isLastPage &&
                  isSearching && (
                    <ListItem className="flex flex-col px-16 py-32">
                      <LinearProgress className="w-full" color="secondary" />
                    </ListItem>
                  )
                }
                next={showMore}
                scrollableTarget="entity-search-infinite-hits-list"
              >
                {(hits || []).map((hit) => (
                  <RenameHitComponent hit={hit} {...renameHitComponentProps} />
                ))}
              </InfiniteScroll>
            )}
          </>
        )}
      </List>
    </Paper>
  );
};

export default EntitySearchInfiniteHitsList;
