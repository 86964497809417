import React from 'react';
import { __PusherChannelsContext } from './PusherChannelsProvider';

const usePusherChannels = () => {
  const context = React.useContext(__PusherChannelsContext);

  if (context === undefined) {
    throw new Error('usePusherChannels must be used within a PusherChannelsProvider');
  }

  return context;
};

export default usePusherChannels;
