import { gql } from '@apollo/client';
import { SERVICE_PROVISION_VIEW_DRAWER_SERVICE_PROVISION_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_SERVICE_PROVISION_VIEW_DRAWER_DATA = gql`
  query FetchServiceProvisionViewDrawerData($where: ServiceProvisionWhereUniqueInput!) {
    serviceProvision(where: $where) {
      id
      ...ServiceProvisionViewDrawerServiceProvisionFragment
    }
  }
  ${SERVICE_PROVISION_VIEW_DRAWER_SERVICE_PROVISION_FRAGMENT}
`;
