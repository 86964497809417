import { memo } from 'react';

const EntitySearchInfiniteHitsGridDefaultCellRenderer = memo(({ value }) => {
  if (!value) {
    return (
      <div className="w-full h-full p-12 animate-pulse">
        <div className="bg-gray-200 rounded-full dark:bg-gray-700 h-full w-full" />
      </div>
    );
  }

  return value;
});

export default EntitySearchInfiniteHitsGridDefaultCellRenderer;
