import { forwardRef, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from '@lodash';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { CommonSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';

const SegmentFacilityAreaConditionAttributeSelectOption = (props) => {
  const segmentFacilityAreaConditionAttribute = props.data?.result;

  const item = useMemo(() => {
    if (!segmentFacilityAreaConditionAttribute) return null;

    return (
      <ListItem component="div" dense>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2">{segmentFacilityAreaConditionAttribute.name}</Typography>
          }
        />
      </ListItem>
    );
  }, [segmentFacilityAreaConditionAttribute]);

  return <components.Option {...props}>{item}</components.Option>;
};

const SegmentFacilityAreaConditionAttributeSelectField = forwardRef(
  ({ className, filters, ...SelectProps }, rootRef) => {
    const dispatch = useDispatch();
    const [filtersCache, setFiltersCache] = useState(null);
    const [options, setOptions] = useState(null);

    useQuery(
      gql`
        query GetSegmentFacilityAreaConditionAttributeSelectFieldData(
          $where: SegmentFacilityAreaConditionAttributeWhereInput!
        ) {
          segmentFacilityAreaConditionAttributes(where: $where) {
            id
            enum
            name
            path
          }
        }
      `,
      {
        skip: _.isEqual(filters, filtersCache) && !!options,
        variables: { where: { ...(filters || {}) } },
        onCompleted: ({ segmentFacilityAreaConditionAttributes }) => {
          setFiltersCache(filters);

          setOptions(
            segmentFacilityAreaConditionAttributes?.map((result) => ({
              result,
              label: result?.name,
              value: result?.id,
            }))
          );
        },
        onError: ({ message }) =>
          dispatch(
            showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
          ),
      }
    );

    return (
      <div className={className} ref={rootRef}>
        <CommonSelect
          {...SelectProps}
          // ROADMAP: Implement
          // components={{ Option: SegmentFacilityAreaConditionAttributeSelectOption }}
          options={options}
        />
      </div>
    );
  }
);

export default SegmentFacilityAreaConditionAttributeSelectField;
