import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_REMINDER_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketReminderCreateDialogServiceTicketFragment on ServiceTicket {
    id
    reminders {
      id
      createdAt
      assignedTeam {
        id
        name
      }
      completedAt
      completor {
        id
        name
      }
      dueAt
      isCompleted
      name
      notes
      owner {
        id
        name
      }
      priority {
        id
        enum
        name
      }
    }
  }
`;
