import { forwardRef, useMemo, useState } from 'react';
import _ from '@lodash';
import * as changeCase from 'change-case';
import convert from 'convert';
import { faFile, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import makeStyles from './makeStyles';

const CommonDropzone = forwardRef(
  ({ accept, error, disabled, multiple, placeholder, onChange, value }, rootRef) => {
    const [files, setFiles] = useState([]);
    const mainTheme = useSelector(selectMainTheme);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      accept,
      disabled,
      multiple,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: file.type?.match(/^image\/.+/) ? URL.createObjectURL(file) : null,
            })
          )
        );
      },
    });

    const helperText = useMemo(
      () => (_.isArray(error) ? error[0]?.message : error?.message),
      [error]
    );

    const removeFile = (fileIndex) =>
      setFiles((prevFiles) => {
        const _files = [...prevFiles];

        _files.splice(fileIndex, 1);

        return _files;
      });

    const style = useMemo(
      () =>
        makeStyles({ error, isFocused, isDragAccept, isDragReject, mode: mainTheme?.palette.mode }),
      [error, isFocused, isDragAccept, isDragReject, mainTheme?.palette.mode]
    );

    useDeepCompareEffect(() => {
      if (!_.isEqual(files, value)) {
        onChange(files);
      }

      return () => {
        files.forEach((file) => {
          if (file.preview) {
            URL.revokeObjectURL(file.preview);
          }
        });
      };
    }, [files, multiple, value]);

    return (
      <div ref={rootRef}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>{placeholder}</p>
        </div>

        {Boolean(files.length) && (
          <List>
            {files.map((file, fileIndex) => {
              const converted = convert(file.size, 'bytes').to('best');
              const primary = file.name;
              const secondary = [
                [
                  Math.round((converted?.quantity + Number.EPSILON) * 100) / 100,
                  converted?.unit,
                ].join(''),
                changeCase.constantCase(file.type?.split('/')[1]),
              ].join(' / ');

              return (
                <ListItem divider key={file.name}>
                  <ListItemAvatar>
                    <Avatar
                      src={file.preview}
                      onLoad={() => {
                        if (file.preview) {
                          URL.revokeObjectURL(file.preview);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faFile} />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    className="mr-12"
                    primary={primary}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={secondary}
                    secondaryTypographyProps={{ noWrap: true }}
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      sx={{ width: 40, height: 40 }}
                      onClick={() => removeFile(fileIndex)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        )}

        {helperText && (
          <div className="mx-14 mt-3">
            <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
              {helperText}
            </Typography>
          </div>
        )}
      </div>
    );
  }
);

export default CommonDropzone;
