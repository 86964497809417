import { Alert, AlertTitle, Button, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { ServiceTicketCompleteReleasePhaseDialog } from 'app/shared-components/ServiceTicket/ServiceTicketCompleteReleasePhaseDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

const WorkOrderViewAppReleasePhaseBar = ({
  consumer,
  phase,
  serviceTicketId,
  user,
  vendor,
  // workOrder,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {phase.enum === 'RELEASE' && consumer?.id === user?.data?.organization?.id && (
        <>
          <Alert
            square
            action={
              <Button
                className="mr-8"
                disabled={false}
                size="small"
                variant="outlined"
                onClick={() =>
                  dispatch(
                    openDialog({
                      children: (
                        <ServiceTicketCompleteReleasePhaseDialog
                          defaultWorkOrderIssueId={null}
                          serviceTicketId={serviceTicketId}
                          onClose={() => dispatch(closeDialog())}
                        />
                      ),
                      classes: {
                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                      },
                    })
                  )
                }
              >
                Release
              </Button>
            }
            classes={{ action: 'items-center' }}
            severity="warning"
          >
            <AlertTitle>Release Service Issue(s)</AlertTitle>
            Once ready, release service issue(s) to proceed to next phase.
          </Alert>

          <StyledDivider />
        </>
      )}

      {phase.enum === 'RELEASE' && vendor?.id === user?.data?.organization?.id && (
        <>
          <Alert square classes={{ action: 'items-center' }} severity="info">
            <AlertTitle>Service Issue(s) Held</AlertTitle>
            Awaiting release of service issue(s) by consumer in order to proceed to next phase.
          </Alert>

          <StyledDivider />
        </>
      )}
    </>
  );
};

export default WorkOrderViewAppReleasePhaseBar;
