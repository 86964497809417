import { gql } from '@apollo/client';
import { SERVICE_INVOICE_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const CREATE_SERVICE_INVOICE = gql`
  mutation CreateServiceInvoice(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceInvoiceCreateInput!
  ) {
    createServiceInvoice(where: $where, data: $data) {
      id
      ...ServiceInvoiceCreateDialogServiceTicketFragment
    }
  }
  ${SERVICE_INVOICE_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_SERVICE_INVOICE_CREATE_DIALOG_DATA = gql`
  query FetchServiceInvoiceCreateDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...ServiceInvoiceCreateDialogServiceTicketFragment
    }
  }
  ${SERVICE_INVOICE_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
