import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_WORK_ORDER_COMMENTS_UPDATE_DIALOG_DATA,
  UPDATE_WORK_ORDER_COMMENTS,
} from './queries';

const defaultValues = {
  comments: null,
};

const schema = yup.object().shape({
  comments: yup.string().optional(),
});

const WorkOrderCommentsUpdateDialog = ({ serviceTicketId, workOrderId, onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [
    fetchWorkOrderCommentsUpdateDialogData,
    {
      data: workOrderCommentsUpdateDialogData,
      loading: workOrderCommentsUpdateDialogLoading,
      refetch: workOrderCommentsUpdateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_COMMENTS_UPDATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Work Order Data',
          variant: 'error',
        })
      );
    },
  });

  const [updateWorkOrderComments, { loading: updateWorkOrderCommentsLoading }] = useMutation(
    UPDATE_WORK_ORDER_COMMENTS,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Successfully Updated',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Updating Work Order', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [updateWorkOrderCommentsLoading, workOrderCommentsUpdateDialogLoading].includes(true),
    [updateWorkOrderCommentsLoading, workOrderCommentsUpdateDialogLoading]
  );

  useEffect(() => {
    if (workOrderCommentsUpdateDialogData?.workOrder) {
      setValue('comments', workOrderCommentsUpdateDialogData?.workOrder.comments);
    }
  }, [setValue, workOrderCommentsUpdateDialogData?.workOrder]);

  useEffect(() => {
    if (workOrderId) {
      fetchWorkOrderCommentsUpdateDialogData({
        variables: { where: { id: workOrderId } },
      });
    }
  }, [fetchWorkOrderCommentsUpdateDialogData, workOrderId]);

  const onSubmit = async (data) => {
    try {
      await updateWorkOrderComments({
        variables: {
          where: { id: serviceTicketId },
          data: { id: workOrderId, ...data },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>Update Work Order</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-comments-update-form"
            name="work-order-comments-update-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="comments"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.comments}
                  fullWidth
                  helperText={errors?.comments?.message}
                  maxRows={4}
                  minRows={4}
                  multiline
                  placeholder="Enter Comments..."
                  required={false}
                  variant="outlined"
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-comments-update-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderCommentsUpdateDialog;
