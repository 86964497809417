import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'calendar',
    title: 'Calendar',
    type: 'item',
    icon: 'heroicons-outline:calendar',
    url: '/apps/calendar',
  },
  {
    id: 'hyve-analytics',
    title: 'Hyve Analytics',
    type: 'item',
    icon: 'heroicons-outline:chart-bar',
    // TODO: Rename / Move
    url: '/analytics/hyve-intelligence',
  },
  {
    id: 'chat',
    title: 'Chat',
    type: 'item',
    icon: 'heroicons-outline:chat-alt',
    url: '/apps/chat',
  },
  {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'group',
    icon: 'material-outline:dashboard_customize',
    children: [
      {
        id: 'dashboards.issue-templates-dashboard',
        title: 'Issue Templates Dashboard',
        type: 'item',
        icon: 'material-outline:dashboard',
        url: '/issue-templates/dashboard',
      },
      {
        id: 'dashboards.programs-dashboard',
        title: 'Programs Dashboard',
        type: 'item',
        icon: 'material-outline:dashboard',
        url: '/programs/dashboard',
      },
      {
        id: 'dashboards.service-issues-dashboard',
        title: 'Service Issues Dashboard',
        type: 'item',
        icon: 'material-outline:dashboard',
        url: '/service-issues/dashboard',
      },
      {
        id: 'dashboards.service-tickets-dashboard',
        title: 'Service Tickets Dashboard',
        type: 'item',
        icon: 'material-outline:dashboard',
        url: '/service-tickets/dashboard',
      },
    ],
  },
  {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    icon: 'material-outline:corporate_fare',
    children: [
      {
        id: 'admin.accounting-projects',
        title: 'Accounting Projects',
        type: 'item',
        // TODO:
        icon: 'material-outline:business_center',
        url: '/accounting-projects/search',
      },
      {
        id: 'admin.accounting-task-codes',
        title: 'Accounting Task Codes',
        type: 'item',
        // TODO:
        icon: 'material-outline:business_center',
        url: '/accounting-task-codes/search',
      },
      {
        id: 'admin.accounting-work-order-types',
        title: 'Accounting WO Types',
        type: 'item',
        // TODO:
        icon: 'material-outline:folder',
        url: '/accounting-work-order-types/search',
      },
      {
        id: 'admin.csi-classifications',
        title: 'CSI Classifications',
        type: 'item',
        // TODO:
        icon: 'material-outline:category',
        url: '/csi-classifications/search',
      },
      {
        id: 'admin.facility-groups',
        title: 'Facility Groups',
        type: 'item',
        // TODO:
        icon: 'material-outline:holiday_village',
        url: '/facility-groups/search',
      },
      {
        id: 'admin.segments',
        title: 'Segments',
        type: 'item',
        // TODO:
        icon: 'heroicons-outline:chart-pie',
        url: '/segments/search',
      },
    ],
  },
  {
    id: 'catalog',
    title: 'Catalog',
    type: 'group',
    icon: 'material-outline:auto_stories',
    children: [
      {
        id: 'ecosystem.product-models',
        title: 'Product Models',
        type: 'item',
        // TODO:
        icon: 'material-outline:view_in_ar',
        url: '/product-models/search',
      },
      {
        id: 'ecosystem.product-parts',
        title: 'Product Parts',
        type: 'item',
        // TODO:
        icon: 'material-outline:miscellaneous_services',
        url: '/product-parts/search',
      },
    ],
  },
  {
    id: 'ecosystem',
    title: 'Ecosystem',
    type: 'group',
    icon: 'material-outline:public',
    children: [
      {
        id: 'ecosystem.assets',
        title: 'Assets',
        type: 'item',
        icon: 'material-outline:kitchen',
        url: '/assets/search',
      },
      {
        id: 'ecosystem.facilities',
        title: 'Facilities',
        type: 'item',
        icon: 'material-outline:storefront',
        url: '/facilities/search',
      },
      {
        id: 'ecosystem.facility-areas',
        title: 'Facility Areas',
        type: 'item',
        icon: 'material-outline:border_style',
        url: '/facility-areas/search',
      },
    ],
  },
  {
    id: 'service',
    title: 'Service',
    type: 'group',
    icon: 'material-outline:build',
    children: [
      {
        id: 'service.issue-templates',
        title: 'Issue Templates',
        type: 'item',
        icon: 'heroicons-outline:document-duplicate',
        url: '/issue-templates/search',
      },
      {
        id: 'service.programs',
        title: 'Programs',
        type: 'item',
        icon: 'heroicons-outline:bookmark',
        url: '/programs/search',
      },
      {
        id: 'service.service-issues',
        title: 'Service Issues',
        type: 'item',
        icon: 'heroicons-outline:clipboard-list',
        url: '/service-issues/search',
      },
      {
        id: 'service.service-provisions',
        title: 'Service Provisions',
        type: 'item',
        icon: 'heroicons-outline:user-group',
        url: '/service-provisions/search',
      },
      {
        id: 'service.service-tickets',
        title: 'Service Tickets',
        type: 'item',
        icon: 'heroicons-outline:inbox',
        url: '/service-tickets/search',
      },
    ],
  },
];

export default navigationConfig;
