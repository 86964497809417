import React, { useEffect, useRef, useState } from 'react';
import _ from '@lodash';
import * as turf from '@turf/turf';
import axios from 'axios';
import moment from 'moment';
import { gql, useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';

const { REACT_APP_MOZILLA_LOCATION_SERVICES_URL } = process.env;

const mozillaLocationServicesClient = axios.create({
  baseURL: REACT_APP_MOZILLA_LOCATION_SERVICES_URL,
});

const PositionContext = React.createContext();

const PositionProvider = ({ children }) => {
  const apolloClient = useApolloClient();
  const [checkedAt, setCheckedAt] = useState(null);
  const [checkInterval] = useState(30000);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [geolocationError, setGeolocationError] = useState(null);
  const [syncDistanceDelta] = useState(5);
  const [syncedAt, setSyncedAt] = useState(null);
  const syncedPosition = useRef(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    const checkPosition = async () => {
      if (navigator.geolocation && user) {
        try {
          if (!geolocationError) {
            navigator.geolocation.getCurrentPosition(
              ({ coords }) => {
                if (
                  _.isNumber(_.get(coords, 'accuracy')) &&
                  _.isNumber(_.get(coords, 'latitude')) &&
                  _.isNumber(_.get(coords, 'longitude'))
                ) {
                  setCurrentPosition({
                    accuracy: _.get(coords, 'accuracy'),
                    location: {
                      type: 'Point',
                      coordinates: [_.get(coords, 'longitude'), _.get(coords, 'latitude')],
                    },
                  });
                }
              },
              (error) => setGeolocationError(error),
              {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 5000,
              }
            );
          } else {
            const { data } = await mozillaLocationServicesClient.get('/v1/geolocate', {
              params: { key: 'test' },
            });

            if (
              _.isNumber(_.get(data, 'accuracy')) &&
              _.isNumber(_.get(data, 'location.lat')) &&
              _.isNumber(_.get(data, 'location.lng'))
            ) {
              setCurrentPosition({
                accuracy: _.get(data, 'accuracy'),
                location: {
                  type: 'Point',
                  coordinates: [_.get(data, 'location.lng'), _.get(data, 'location.lat')],
                },
              });
            }
          }
        } catch (err) {
          setCurrentPosition(null);
        } finally {
          setCheckedAt(moment().format());
        }
      }
    };

    checkPosition();

    const interval = setInterval(checkPosition, checkInterval);

    return () => clearInterval(interval);
  }, [checkInterval, geolocationError, user]);

  useEffect(() => {
    const getUser = async () => {
      // TODO:
      // const response = await apolloClient.query({
      //   query: gql`
      //     query GetUser($where: UserWhereUniqueInput!) {
      //       user(where: $where) {
      //         id
      //         createdAt
      //         updatedAt
      //         name
      //       }
      //     }
      //   `,
      //   variables: {
      //     where: {
      //       id: '1234567890',
      //     },
      //   },
      // });

      // console.log('// FIXME:', { response });
    };

    if (apolloClient) {
      getUser();
    }
  }, [apolloClient]);

  useEffect(() => {
    const syncPosition = async () => {
      try {
        // const response = await apolloClient.mutate({
        //   mutation: gql`
        //     mutation UpsertUserCurrentPosition($data: UserCurrentPositionUpsertInput!) {
        //       upsertUserCurrentPosition(data: $data) {
        //         id
        //         updatedAt
        //       }
        //     }
        //   `,
        //   variables: {
        //     data: { ...currentPosition },
        //   },
        // });

        // syncedPosition.current = currentPosition;
        // setSyncedAt(_.get(response, 'data.upsertUserCurrentPosition.updatedAt'));
      } catch (err) {
        //
      }
    };

    if (apolloClient && currentPosition && user) {
      const distanceDelta = syncedPosition.current
        ? turf.distance(
            turf.point(_.get(currentPosition, 'location.coordinates')),
            turf.point(_.get(syncedPosition.current, 'location.coordinates')),
            { units: 'meters' }
          )
        : null;

      if (!syncedPosition.current || distanceDelta >= syncDistanceDelta) {
        syncPosition();
      }
    }
  }, [apolloClient, currentPosition, user, syncDistanceDelta]);

  return (
    <PositionContext.Provider
      value={{
        checkedAt,
        currentPosition,
        syncedAt,
      }}
    >
      {children}
    </PositionContext.Provider>
  );
};

export const __PositionContext = PositionContext;
export default PositionProvider;
