import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { IssueTemplateHitsListOption } from 'app/shared-components/IssueTemplate/IssueTemplateHitsListOption';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ProgramCampaignCreateDialogFacilities } from './components';
import { CREATE_PROGRAM_CAMPAIGN, FETCH_PROGRAM_CAMPAIGN_CREATE_DIALOG_DATA } from './queries';

const selectableFacilitiesFieldName = 'selectableFacilities';

const defaultValues = {
  deferredUntil: null,
  description: '',
  facilities: [],
  issueTemplates: [],
  name: '',
  [selectableFacilitiesFieldName]: [],
};

const schema = yup.object().shape({
  deferredUntil: yup.date().nullable().optional(),
  description: yup.string().nullable().optional(),
  facilities: yup
    .array()
    .of(
      yup.object().shape({
        facility: yup.object().nullable().required('A facility must be provided.'),
        isNotToExceedAmountZeroedOut: yup.boolean().required(),
        isRequestForQuote: yup.boolean().required(),
        serviceProvision: yup.object().nullable().required('A service provision must be provided.'),
        serviceResponseSeverity: yup
          .object()
          .nullable()
          .required('A response severity must be provided.'),
      })
    )
    .min(1, 'Select at least one campaign facility.'),
  issueTemplates: yup.array().min(1, 'Select at least one issue template.').required(),
  name: yup.string().nullable().required('A name must be provided.'),
  [selectableFacilitiesFieldName]: yup
    .array()
    .min(1, 'Select at least one campaign facility.')
    .required(),
});

const ProgramCampaignCreateDialog = ({ programId, onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchProgramCampaignCreateDialogData,
    {
      data: programCampaignCreateDialogData,
      loading: programCampaignCreateDialogLoading,
      refetch: programCampaignCreateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_PROGRAM_CAMPAIGN_CREATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Program Data',
          variant: 'error',
        })
      );
    },
  });

  const [createProgramCampaign, { loading: createProgramCampaignLoading }] = useMutation(
    CREATE_PROGRAM_CAMPAIGN,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Program Campaign Successfully Created',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Program Campaign', variant: 'error' }));
      },
    }
  );

  const defaultServiceResponseSeverity = useMemo(() => {
    const result = programCampaignCreateDialogData?.serviceResponseSeverities?.find(
      ({ name }) => name === 'Standard'
    );

    return (
      result && {
        result,
        label: result?.name,
        value: result?.id,
      }
    );
  }, [programCampaignCreateDialogData?.serviceResponseSeverities]);

  const loading = useMemo(
    () => [createProgramCampaignLoading, programCampaignCreateDialogLoading].includes(true),
    [createProgramCampaignLoading, programCampaignCreateDialogLoading]
  );

  const program = useMemo(
    () => programCampaignCreateDialogData?.program,
    [programCampaignCreateDialogData?.program]
  );

  // ROADMAP: Implement or Remove
  // useEffect(() => {
  //   if (program) {
  //     const formData = {};

  //     reset(formData);
  //   }
  // }, [reset, program]);

  useEffect(() => {
    if (programId) {
      fetchProgramCampaignCreateDialogData({
        variables: { where: { id: programId } },
      });
    }
  }, [fetchProgramCampaignCreateDialogData, programId]);

  const onSubmit = async (data) => {
    try {
      await createProgramCampaign({
        variables: {
          where: { id: programId },
          data: {
            deferredUntil: data.deferredUntil && moment(data.deferredUntil).format(),
            description: data.description,
            facilities: data.facilities?.map((campaignFacility) => ({
              facility: { id: campaignFacility?.facility?.value },
              isNotToExceedAmountZeroedOut: campaignFacility?.isNotToExceedAmountZeroedOut,
              isRequestForQuote: campaignFacility?.isRequestForQuote,
              serviceProvision: { id: campaignFacility?.serviceProvision?.value },
              serviceResponseSeverity: { id: campaignFacility?.serviceResponseSeverity?.value },
            })),
            issueTemplates: data.issueTemplates?.map((issueTemplate) => ({
              id: issueTemplate?.value,
            })),
            name: data.name,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Program Campaign
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="program-campaign-create-form"
            name="program-campaign-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.description}
                  fullWidth
                  helperText={errors?.description?.message}
                  maxRows={4}
                  minRows={4}
                  multiline
                  placeholder="Enter Description..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="issueTemplates"
              render={({ field }) => (
                <EntitySearchSelectField
                  {...field}
                  className="mb-24"
                  components={{ Option: IssueTemplateHitsListOption }}
                  error={errors?.issueTemplates}
                  filter={[`id IN [${program?.issueTemplates.map(({ id }) => `'${id}'`)}]`]}
                  idField="id"
                  indexName="issue_templates"
                  isClearable
                  isDisabled={false}
                  isMulti
                  placeholder="Select Issue Templates..."
                  nameField="name"
                  sort={['name:asc']}
                  onChange={(params) => {
                    setValue(selectableFacilitiesFieldName, []);

                    field.onChange(params);
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="deferredUntil"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonDatepicker
                    {...field}
                    controls={['calendar']}
                    disabled={false}
                    error={errors?.deferredUntil}
                    min={moment().add(1, 'day').startOf('day')}
                    placeholder="Select Deferred Until Date..."
                    select="date"
                  />
                </div>
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <ProgramCampaignCreateDialogFacilities
              defaultServiceResponseSeverity={defaultServiceResponseSeverity}
              deferredUntilFieldName="deferredUntil"
              issueTemplatesFieldName="issueTemplates"
              name="facilities"
              program={program}
              selectableFacilitiesFieldName={selectableFacilitiesFieldName}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="program-campaign-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default ProgramCampaignCreateDialog;
