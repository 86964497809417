import React, { useEffect, useState } from 'react';
import _ from '@lodash';
import {
  jwtService,
  JWT_SERVICE_EVENTS,
  JWT_SERVICE_TOKEN_TYPES,
} from 'app/providers/auth/services';
import * as streamActivityFeeds from 'getstream';

const { REACT_APP_STREAM_ACTIVITY_FEEDS_API_KEY } = process.env;

// FIXME:
window.process = { env: {} };

const StreamActivityFeedsContext = React.createContext();

const streamActivityFeedsClient = streamActivityFeeds.connect(
  REACT_APP_STREAM_ACTIVITY_FEEDS_API_KEY,
  null,
  // TODO: ENV
  '1292176'
);

const StreamActivityFeedsProvider = ({ children }) => {
  // const [streamActivityFeedsClient, setStreamActivityFeedsClient] = useState(null);
  const [activityFeedsToken, setActivityFeedsToken] = useState(null);

  useEffect(() => {
    jwtService.on(
      [
        JWT_SERVICE_EVENTS.ON_COMPLETE_SIGN_IN,
        JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION,
        JWT_SERVICE_EVENTS.ON_REFRESH_SESSION,
      ],
      ({ data }) => {
        if (_.get(data, JWT_SERVICE_TOKEN_TYPES.ACTIVITY_FEEDS_TOKEN)) {
          console.log('// TODO: SET', { activityFeedsToken: _.get(data, JWT_SERVICE_TOKEN_TYPES.ACTIVITY_FEEDS_TOKEN) });
          setActivityFeedsToken(_.get(data, JWT_SERVICE_TOKEN_TYPES.ACTIVITY_FEEDS_TOKEN));
        }
      }
    );

    jwtService.on(
      [JWT_SERVICE_EVENTS.ON_CLIENT_RESPONSE_ERROR, JWT_SERVICE_EVENTS.ON_REVOKE_SESSION],
      () => setActivityFeedsToken(null)
    );
  }, []);

  // useEffect(() => {
  //   if (activityFeedsToken) {
  //     console.log('// TODO: INIT', { activityFeedsToken });
  //     setStreamActivityFeedsClient(
  //       streamActivityFeeds.connect(
  //         REACT_APP_STREAM_ACTIVITY_FEEDS_API_KEY,
  //         activityFeedsToken,
  //         // TODO: ENV
  //         '1292176'
  //       )
  //     );
  //   } else {
  //     setStreamActivityFeedsClient(null);
  //   }
  // }, [activityFeedsToken]);

  console.log('// TODO: INIT', { streamActivityFeedsClient });

  // TODO: MOVE
  // useEffect(() => {
  //   let subscription;

  //   if (activityFeedsToken) {
  //     const userNotificationFeed = streamActivityFeedsClient.feed(
  //       'notification',
  //       // TODO: user.id
  //       '9db3a2c3-19ac-431a-b2ed-ca6a05771f97',
  //       activityFeedsToken
  //     );

  //     // TODO:
  //     // userNotificationFeed.follow('team', 'fc460d62-5e4a-4715-b331-6b159f7147b8');

  //     // TODO:
  //     // const notifications = userNotificationFeed
  //     //   .get({ limit: 10 })
  //     //   .then((data) => console.log('// FIXME: FML', { data }))
  //     //   .catch((err) => console.log('// FIXME: FML', { err }));
  //     // console.log({ userNotificationFeed, notifications });

  //     subscription = userNotificationFeed.subscribe((data) =>
  //       console.log('// TODO: Subscribe => New Activity', { data })
  //     );

  //     subscription.then(
  //       () => console.log('// Subscribe => Success'),
  //       (err) => console.log('// Subscribe => Failed', { err })
  //     );
  //   }

  //   // TODO: Fudge
  //   return () => {
  //     console.log('// FIXME: Cancel', { subscription });
  //     subscription?.cancel();
  //   };
  // }, [activityFeedsToken]);

  return (
    <StreamActivityFeedsContext.Provider
      value={{
        activityFeedsToken,
        streamActivityFeedsClient,
      }}
    >
      {children}
    </StreamActivityFeedsContext.Provider>
  );
};

export const __StreamActivityFeedsContext = StreamActivityFeedsContext;
export default StreamActivityFeedsProvider;
