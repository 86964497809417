import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const serviceProvisionSearchScreenColumnDefs = [
  {
    headerName: 'Service Provision',
    children: [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        valueGetter: ({ data }) =>
          data?.createdAtTimestamp &&
          moment.unix(data?.createdAtTimestamp).format('MMM. DD, YYYY @ hh:mm A'),
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
      },
      {
        field: 'currentVersion.version',
        headerName: 'Version',
        sortable: true,
      },
      {
        field: 'currentVersion.status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        sortable: false,
        valueGetter: ({ data }) => data?.tags && data?.tags.map(({ name }) => name).join(', '),
      },
      {
        field: 'owner.name',
        headerName: 'Owner',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Organization Connection',
    children: [
      {
        field: 'organizationConnection.consumer.name',
        headerName: 'Consumer',
        sortable: true,
      },
      {
        field: 'organizationConnection.vendor.name',
        headerName: 'Vendor',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Coverage Counts',
    children: [
      {
        field: 'coverageCounts.facilities',
        headerName: 'Facilities',
        sortable: true,
      },
      {
        field: 'coverageCounts.assets',
        headerName: 'Assets',
        sortable: true,
      },
      {
        field: 'coverageCounts.facilityAreas',
        headerName: 'Facility Areas',
        sortable: true,
      },
    ],
  },
];
