import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const serviceIssueSearchScreenColumnDefs = [
  {
    headerName: 'Service Issue',
    children: [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        valueGetter: ({ data }) =>
          data?.createdAtTimestamp &&
          moment.unix(data?.createdAtTimestamp).format('MMM. DD, YYYY @ hh:mm A'),
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
      },
      {
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        field: 'serviceResponseSeverity.name',
        headerName: 'Response Severity',
        sortable: true,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        sortable: false,
        valueGetter: ({ data }) => data?.tags && data?.tags.map(({ name }) => name).join(', '),
      },
      {
        field: 'creator.name',
        headerName: 'Creator',
        sortable: true,
      },
      {
        field: 'submitter.name',
        headerName: 'Submitter',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Facility',
    children: [
      {
        field: 'facility.franchiseNumber',
        headerName: 'Number',
        sortable: true,
      },
      {
        field: 'facility.name',
        headerName: 'Name',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Target',
    children: [
      {
        field: 'commonTargetType.name',
        headerName: 'Target Type',
        sortable: true,
      },
      {
        field: 'targetFacilityAreas',
        headerName: 'Facility Areas',
        sortable: true,
        valueGetter: ({ data }) =>
          data?.targetFacilityAreas &&
          data?.targetFacilityAreas.map(({ facilityArea }) => facilityArea?.name).join(', '),
      },
    ],
  },
  {
    headerName: 'Budget / Planning',
    children: [
      // ROADMAP: Do Not Show if Vendor
      {
        field: 'serviceResponsibleParty.name',
        headerName: 'Responsible Party',
        sortable: true,
      },
      // ROADMAP: Do Not Show if Vendor
      {
        field: 'plannedForYear',
        headerName: 'Planned For Year',
        sortable: true,
      },
      // ROADMAP: Do Not Show if Vendor
      {
        field: 'estimatedAmount',
        headerName: 'Estimated Amount',
        sortable: true,
      },
    ],
  },
];
