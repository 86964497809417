import React, { useEffect, useState } from 'react';
import _ from '@lodash';
import moment from 'moment';
import { gql, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';

const ApolloHeartbeatContext = React.createContext();

const ApolloHeartbeatProvider = ({ children }) => {
  const { t } = useTranslation('ApolloHeartbeatProvider');
  const apolloClient = useApolloClient();
  const [isOnline, setIsOnline] = useState(false);
  const [checkedAt, setCheckedAt] = useState(null);
  const [checkInterval] = useState(60000);
  const user = useSelector(selectUser);

  useEffect(() => {
    const checkHeartbeat = async () => {
      if (apolloClient && user) {
        try {
          const response = await apolloClient.query({
            query: gql`
              query GetHeartbeat {
                heartbeat {
                  ping
                  status
                }
              }
            `,
            fetchPolicy: 'network-only',
          });

          setIsOnline(_.get(response, 'data.heartbeat.status') === 'ONLINE');
        } catch (err) {
          setIsOnline(false);
        } finally {
          setCheckedAt(moment().format());
        }
      }
    };

    checkHeartbeat();

    const interval = setInterval(checkHeartbeat, checkInterval);

    return () => clearInterval(interval);
  }, [apolloClient, checkInterval, user]);

  return (
    <ApolloHeartbeatContext.Provider
      value={{
        checkedAt,
        isOnline,
      }}
    >
      <div
        className="absolute z-99999 flex flex-row items-center w-full h-64 px-20 text-white font-700 bg-red-700"
        style={{
          marginTop: checkedAt && !isOnline ? 0 : -64,
          transition: 'margin-top 200ms ease-in-out',
        }}
      >
        <div className="flex-1">{t('ApolloHeartbeatProvider:offlineNotice', 'Offline Notice')}</div>

        {moment(checkedAt).isValid() && (
          <div className="">
            {`${t('ApolloHeartbeatProvider:nextCheck', 'Next Check')}: ${moment(checkedAt)
              .add(checkInterval, 'milliseconds')
              .format('hh:mm:ss A')}`}
          </div>
        )}
      </div>

      {children}
    </ApolloHeartbeatContext.Provider>
  );
};

export const __ApolloHeartbeatContext = ApolloHeartbeatContext;
export default ApolloHeartbeatProvider;
