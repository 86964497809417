import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderViewAppServiceTicketFragment on ServiceTicket {
    id
    approvalPhaseEndedAt
    approvalPhaseStartedAt
    completeBy
    creatorOrganizationType {
      id
      enum
      name
    }
    deferredUntil
    draftPhaseEndedAt
    draftPhaseStartedAt
    inProgressPhaseEndedAt
    inProgressPhaseStartedAt
    isApprovalPhaseCompleted
    isDraftPhaseCompleted
    isInProgressPhaseCompleted
    isQuotingPhaseCompleted
    isRequestForQuote
    isReleasePhaseCompleted
    isSiteVisitPhaseCompleted
    phase {
      id
      enum
      name
    }
    phaseReviews {
      id
      assignedOrganizationType {
        id
        enum
        name
      }
      isCancelled
      isCompleted
      serviceTicketPhase {
        id
        enum
        name
      }
    }
    quotingPhaseEndedAt
    quotingPhaseStartedAt
    releasePhaseEndedAt
    releasePhaseStartedAt
    serviceProvision {
      id
      name
      organizationConnection {
        id
        consumer {
          id
          name
          type {
            id
            enum
            name
          }
        }
        vendor {
          id
          name
          type {
            id
            enum
            name
          }
        }
      }
    }
    siteVisitPhaseEndedAt
    siteVisitPhaseStartedAt
    status {
      id
      enum
      name
    }
    workOrder {
      id
      comments
      discountAmount
      issues {
        id
        accountingWorkOrderType {
          id
          accountingBudgetOrganization {
            id
            name
            number
          }
          name
        }
        actionItems {
          id
          commonStandardFlatRate {
            id
            quantity
          }
          commonStandardHourlyRate {
            id
            hours
            rateAmount
            technicians
          }
          commonStandardRateType {
            id
            enum
            name
          }
          csiClassification {
            id
            accountingTaskCode {
              id
              name
              number
            }
            attachments {
              id
              createdAt
              commonStoredCad {
                id
                name
              }
              commonStoredImage {
                id
                lightboxImageUrl
                name
                thumbnailImageUrl
              }
              commonStoredPdf {
                id
                name
                originalPdfUrl
                thumbnailImageUrl
              }
              commonStoredType {
                id
                enum
                name
              }
              commonStoredVideo {
                id
                captionsFileUrl
                name
                playlistFileUrl
                standardImageUrl
                thumbnailImageUrl
              }
            }
            name
            number
            title
          }
          description
          discountAmount
          isCapitalExpense
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        completeBy
        discountAmount
        issueTemplateWorkOrderIssue {
          id
          issueTemplates {
            id
            name
          }
        }
        notToExceedAmount
        reviews {
          id
          createdAt
          assignedOrganizationType {
            id
            enum
            name
          }
          completor {
            id
            name
          }
          decision {
            id
            enum
            name
          }
          completedAt
          isCancelled
          isCompleted
          type {
            id
            enum
            name
          }
        }
        scheduledResumptions {
          id
          createdAt
          isProcessed
          resumeAt
        }
        serviceInvoice {
          id
          createdAt
          isApproved
          vendorReferenceNumber
        }
        serviceIssue {
          id
          attachments {
            id
            createdAt
            commonStoredCad {
              id
              name
            }
            commonStoredImage {
              id
              commonStoredUpload {
                id
                uploader {
                  id
                  name
                }
              }
              lightboxImageUrl
              name
              thumbnailImageUrl
            }
            commonStoredPdf {
              id
              name
              originalPdfUrl
              thumbnailImageUrl
            }
            commonStoredType {
              id
              enum
              name
            }
            commonStoredVideo {
              id
              captionsFileUrl
              name
              playlistFileUrl
              standardImageUrl
              thumbnailImageUrl
            }
            isCompletionAttachment
          }
          description
          name
          serviceResponseSeverity {
            id
            color
            commonTemporalDurationUnit {
              id
              enum
              name
            }
            ordinal
            name
            value
          }
          tags {
            id
            name
          }
          targetFacilityAreas {
            id
            name
          }
        }
        serviceVisits {
          id
          windowEndAt
          windowStartAt
        }
        siteVisits {
          id
          windowEndAt
          windowStartAt
        }
        status {
          id
          enum
          name
        }
        subtotalAmount
        totalAmount
        totalAmountCheck {
          id
          enum
          name
        }
      }
      notToExceedAmount
      subtotalAmount
      totalAmount
    }
  }
`;
