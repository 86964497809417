import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { CommonSharingRuleSubjectTypeSelectField } from 'app/shared-components/CommonSharingRuleSubjectType';
import { CommonSharingRuleTypeSelectField } from 'app/shared-components/CommonSharingRuleType';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { ProgramTagSelectField } from 'app/shared-components/ProgramTag';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  ProgramCreateDialogIssueTemplates,
  ProgramCreateDialogProgramAttachments,
} from './components';
import { CREATE_PROGRAM } from './queries';

const defaultValues = {
  activateProgramOnCreate: false,
  attachments: [],
  commonSharingRule: {
    subjectTeams: [],
    subjectType: null,
    type: null,
  },
  description: '',
  issueTemplates: [],
  name: '',
  tags: [],
};

const schema = yup.object().shape({
  activateProgramOnCreate: yup.boolean().optional(),
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  commonSharingRule: yup.object().shape({
    subjectTeams: yup.mixed().when('subjectType', {
      is: (val) => val?.result?.enum === 'TEAM',
      then: yup.array().min(1, 'Select at least one Team').required(),
    }),
    subjectType: yup.mixed().when('type', {
      is: (val) => val?.result?.enum === 'READ_ONLY',
      then: yup.object().nullable().required('A subject type must be provided.'),
    }),
    type: yup.object().nullable().required('A type must be provided.'),
  }),
  description: yup.string().nullable().optional(),
  issueTemplates: yup.array().min(1, 'Select at least one Issue Template').required(),
  name: yup.string().nullable().required('A name must be provided.'),
  tags: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          __isNew__: yup.boolean().optional(),
          label: yup.string().required(),
          result: yup.object().optional(),
          value: yup.string().required(),
        })
        .required()
    )
    .optional(),
});

const ProgramCreateDialog = ({ onClose }) => {
  const [activateProgramOnCreate, setActivateProgramOnCreate] = useState(false);
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createProgram, { loading: createProgramLoading }] = useMutation(CREATE_PROGRAM, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Program Successfully Created',
          variant: 'success',
        })
      );

      navigate(`/programs/view/${data.createProgram?.id}`);
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Creating Program', variant: 'error' }));
    },
  });

  const loading = useMemo(() => [createProgramLoading].includes(true), [createProgramLoading]);

  const onSubmit = async (data) => {
    try {
      await createProgram({
        variables: {
          data: {
            activateProgramOnCreate,
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            commonSharingRule: {
              subjectTeams: data.commonSharingRule.subjectTeams?.map((subjectTeam) => ({
                id: subjectTeam?.value,
              })),
              subjectType: data.commonSharingRule.subjectType
                ? { id: data.commonSharingRule.subjectType?.value }
                : null,
              type: { id: data.commonSharingRule.type?.value },
            },
            description: data.description,
            issueTemplates: data.issueTemplates?.map((issueTemplate) => ({
              id: issueTemplate?.value,
            })),
            name: data.name,
            tags: data.tags?.map((tag) => ({
              name: tag.__isNew__ ? tag.value : tag.result?.name,
            })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>Create Program</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="program-create-form"
            name="program-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Program Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.description}
                  fullWidth
                  helperText={errors?.description?.message}
                  maxRows={4}
                  minRows={4}
                  multiline
                  placeholder="Enter Program Description..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <ProgramTagSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.tags}
                  isClearable
                  isDisabled={false}
                  isMulti
                  placeholder="Select / Create Program Tags..."
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <Controller
              control={control}
              name="commonSharingRule.type"
              render={({ field }) => (
                <CommonSharingRuleTypeSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.commonSharingRule?.type}
                  filters={{
                    enum: {
                      // ROADMAP: Handle Additional Common Sharing Rule Types
                      in: ['PRIVATE', 'READ_ONLY'],
                    },
                  }}
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Sharing Rule Type..."
                  onChange={(params) => {
                    setValue('commonSharingRule.subjectType', null);
                    setValue('commonSharingRule.subjectTeams', []);

                    field.onChange(params);
                  }}
                />
              )}
            />

            {watchFields?.commonSharingRule?.type?.result?.enum === 'READ_ONLY' && (
              <>
                <Controller
                  control={control}
                  name="commonSharingRule.subjectType"
                  render={({ field }) => (
                    <CommonSharingRuleSubjectTypeSelectField
                      {...field}
                      className="mb-24"
                      error={errors?.commonSharingRule?.subjectType}
                      filters={{
                        enum: {
                          // ROADMAP: Handle Additional Common Sharing Rule Subject Types
                          in: ['TEAM'],
                        },
                      }}
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Sharing Rule Subject Type..."
                      onChange={(params) => {
                        setValue('commonSharingRule.subjectTeams', []);

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                {watchFields?.commonSharingRule?.subjectType?.result?.enum === 'TEAM' && (
                  <>
                    <Controller
                      control={control}
                      name="commonSharingRule.subjectTeams"
                      render={({ field }) => (
                        <EntitySearchSelectField
                          {...field}
                          className="mb-24"
                          error={errors?.commonSharingRule?.subjectTeams}
                          filter={null}
                          idField="id"
                          indexName="teams"
                          isClearable
                          isDisabled={false}
                          isMulti
                          placeholder="Select Sharing Rule Subject Teams..."
                          sort={['name:asc']}
                        />
                      )}
                    />
                  </>
                )}
              </>
            )}

            <Divider sx={{ mb: 3 }} />

            <ProgramCreateDialogIssueTemplates name="issueTemplates" />

            <Divider sx={{ mb: 3 }} />

            <ProgramCreateDialogProgramAttachments name="attachments" />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="program-create-form"
          type="submit"
          variant="contained"
          onClick={() => setActivateProgramOnCreate(false)}
        >
          Create
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="program-create-form"
          type="submit"
          variant="contained"
          onClick={() => setActivateProgramOnCreate(true)}
        >
          Create + Activate
        </Button>
      </DialogActions>
    </>
  );
};

export default ProgramCreateDialog;
