import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_ACTION_ITEM_DELETE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_WORK_ORDER_ISSUE_ACTION_ITEM = gql`
  mutation DeleteWorkOrderIssueActionItem(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueActionItemDeleteInput!
  ) {
    deleteWorkOrderIssueActionItem(where: $where, data: $data) {
      id
      ...WorkOrderIssueActionItemDeleteDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_ACTION_ITEM_DELETE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
