import { gql } from '@apollo/client';
import { SEGMENT_VIEW_SCREEN_SEGMENT_FRAGMENT } from './fragments';

export const FETCH_SEGMENT_VIEW_SCREEN_DATA = gql`
  query FetchSegmentViewScreenData($where: SegmentWhereUniqueInput!) {
    segment(where: $where) {
      id
      ...SegmentViewScreenSegmentFragment
    }
  }
  ${SEGMENT_VIEW_SCREEN_SEGMENT_FRAGMENT}
`;

export const UPDATE_SEGMENT = gql`
  mutation UpdateSegment($where: SegmentWhereUniqueInput!, $data: SegmentUpdateInput!) {
    updateSegment(where: $where, data: $data) {
      id
      ...SegmentViewScreenSegmentFragment
    }
  }
  ${SEGMENT_VIEW_SCREEN_SEGMENT_FRAGMENT}
`;
