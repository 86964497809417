import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SEGMENT_VIEW_SCREEN_SEGMENT_FRAGMENT = gql`
  fragment SegmentViewScreenSegmentFragment on Segment {
    id
    createdAt
    updatedAt

    commonSharingRule {
      id
      # TODO:
    }
    commonTargetType {
      id
      enum
      name
    }
    creator {
      id
      name
    }
    description
    facilityConditions {
      id
      # TODO:
    }
    isEditable
    name
    tags {
      id
      # TODO:
    }
    targetEquipmentConditions {
      id
      # TODO:
    }
    targetFacilityAreaConditions {
      id
      # TODO:
    }
  }
`;
