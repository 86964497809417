import { Avatar, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Dashboard, DashboardModal } from '@uppy/react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
// import { useUppy } from 'app/providers/uppy';

import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';

import Uppy from '@uppy/core';
import ImageEditor from '@uppy/image-editor';
import Tus from '@uppy/tus';
import Webcam from '@uppy/webcam';
import { jwtService } from 'app/providers/auth/services';

const createUppy = (id, options) =>
  new Uppy({ id, ...options })
    .use(ImageEditor, { id: `${id}-image-editor` })
    .use(Tus, {
      // TODO:
      endpoint: 'https://dev.ultraviolet-uploader.hyvery.io/uploads',
      async onBeforeRequest(req) {
        let session = jwtService.getSession();

        if (!jwtService.isTokenValid(session.accessToken)) {
          session = await jwtService.refreshSession();
        }

        req.setHeader('Authorization', `Bearer ${session.accessToken}`);
      },
    })
    .use(Webcam);

const UserProfileImageAvatar = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  // const { uppy } = useUppy();

  const [src, setSrc] = useState(null);

  // TODO:
  const [uppy] = useState(() =>
    createUppy('UserProfileImageAvatar', {
      restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['image/*'] },
      // TODO: Use TUS Config Instead
      // onBeforeFileAdded: () => true,
    })
  );
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => setModalOpen(false);
  const handleOpen = () => setModalOpen(true);

  useEffect(() => {
    uppy.getPlugin('react:DashboardModal').setOptions({ meta: { userId: user.id }, theme: theme?.palette.mode });
  }, [theme?.palette.mode, uppy, user]);

  useEffect(() => {
    uppy.on('upload-success', (file, response) => {
      console.log('upload-success', { file, response });
      setSrc(file?.preview);
      // console.log(file.name, response.uploadURL);
      // const img = new Image();
      // img.width = 300;
      // img.alt = file.id;
      // img.src = response.uploadURL;
      // document.body.appendChild(img);
    });
  }, [uppy]);

  // TODO: Verify userId === user.id
  // useEffect(() => {
  //   if (user) {
  //     // uppy.setOptions({ restrictions: props.restrictions });
  //   } else {
  //     // uppy.setOptions({ restrictions: props.restrictions });
  //   }
  // }, [user]);

  return (
    <>
      <div className="-mt-96 lg:-mt-88 rounded-full">
        <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.1 } }}>
          <IconButton onClick={handleOpen}>
            <Avatar
              sx={{ borderColor: 'background.paper' }}
              className="w-128 h-128 border-4"
              src={src}
              alt="User avatar"
            >
              {user.data.displayName[0]}
            </Avatar>
          </IconButton>
        </motion.div>

        <DashboardModal
          theme={theme?.palette.mode}
          closeModalOnClickOutside
          // autoOpenFileEditor
          // TODO:
          plugins={
            [
              'ImageEditor',
              'Webcam'
            ]
          }

          uppy={uppy}
          open={modalOpen}
          onRequestClose={handleClose}
        />

        {/* <Dashboard
          theme={theme?.palette.mode}
          uppy={uppy}
          plugins={
            [
              'ImageEditor',
              'Webcam'
            ]
          }
        /> */}
      </div>
    </>
  );
};

export default UserProfileImageAvatar;
