import { forwardRef, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from '@lodash';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { CommonSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';

const FacilityAreaTypeSelectOption = (props) => {
  const facilityAreaType = props.data?.result;

  const item = useMemo(() => {
    if (!facilityAreaType) return null;

    return (
      <ListItem component="div" dense>
        <ListItemText
          disableTypography
          primary={<Typography variant="body2">{facilityAreaType.name}</Typography>}
        />
      </ListItem>
    );
  }, [facilityAreaType]);

  return <components.Option {...props}>{item}</components.Option>;
};

const FacilityAreaTypeSelectField = forwardRef(
  ({ className, filters, ...SelectProps }, rootRef) => {
    const dispatch = useDispatch();
    const [filtersCache, setFiltersCache] = useState(null);
    const [options, setOptions] = useState(null);

    useQuery(
      gql`
        query GetFacilityAreaTypeSelectFieldData($where: FacilityAreaTypeWhereInput!) {
          facilityAreaTypes(where: $where) {
            id
            enum
            name
          }
        }
      `,
      {
        skip: _.isEqual(filters, filtersCache) && !!options,
        variables: { where: { ...(filters || {}) } },
        onCompleted: ({ facilityAreaTypes }) => {
          setFiltersCache(filters);

          setOptions(
            facilityAreaTypes?.map((result) => ({
              result,
              label: result?.name,
              value: result?.id,
            }))
          );
        },
        onError: ({ message }) =>
          dispatch(
            showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
          ),
      }
    );

    return (
      <div className={className} ref={rootRef}>
        <CommonSelect
          {...SelectProps}
          // ROADMAP: Implement
          // components={{ Option: FacilityAreaTypeSelectOption }}
          options={options}
        />
      </div>
    );
  }
);

export default FacilityAreaTypeSelectField;
