import { forwardRef, useMemo, useRef, useState } from 'react';
import _ from '@lodash';
import moment from 'moment';
import { useDeepCompareEffect, useThemeMediaQuery } from '@fuse/hooks';
import { Datepicker } from '@mobiscroll/js/mobiscroll.react.min';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';

const CommonDatepicker = forwardRef(
  ({ error, name, placeholder, value, onChange, ...props }, rootRef) => {
    const datepickerRef = useRef(null);
    const [formattedValue, setFormattedValue] = useState('');
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
    const mainTheme = useSelector(selectMainTheme);

    const clearField = () => {
      onChange(props?.select === 'range' ? [] : null);

      datepickerRef.current.close();
    };

    const helperText = useMemo(
      () => (_.isArray(error) ? error[0]?.message : error?.message),
      [error]
    );

    useDeepCompareEffect(() => {
      const dateFormat = [
        _.some(props?.controls || [], (control) =>
          ['calendar', 'date', 'datetime'].includes(control)
        ) && 'MMM. DD, YYYY',
        _.some(props?.controls || [], (control) => ['datetime', 'time'].includes(control)) &&
          'hh:mm A',
      ]
        .filter((el) => el)
        .join(' @ ');

      let _formattedValue = '';

      if (_.isArray(value) && value.length === 2 && _.every(value, (date) => _.isDate(date))) {
        // eslint-disable-next-line prettier/prettier
        _formattedValue = `${moment(value[0]).format(dateFormat)} - ${moment(value[1]).format(dateFormat)}`;
      } else if (_.isDate(value)) {
        _formattedValue = moment(value).format(dateFormat);
      }

      setFormattedValue(_formattedValue);
    }, [props?.controls, value]);

    return (
      <div ref={rootRef}>
        <Datepicker
          {...props}
          buttons={[
            'cancel',
            {
              handler: clearField,
              text: 'Clear',
            },
            'set',
          ]}
          display={isMobile ? 'bottom' : 'anchored'}
          focusOnClose={false}
          inputComponent={TextField}
          inputProps={{
            defaultValue: undefined,
            error: !!error,
            disabled: props?.disabled,
            fullWidth: true,
            helperText,
            InputProps: {
              placeholder,
              readOnly: true,
            },
            name,
            variant: 'outlined',
            value: formattedValue,
          }}
          ref={datepickerRef}
          touchUi={isMobile}
          themeVariant={mainTheme?.palette.mode}
          value={value}
          onChange={(event) => onChange(event?.value)}
        />
      </div>
    );
  }
);

export default CommonDatepicker;
