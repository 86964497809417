import { Box, Modal } from '@mui/material';
import { useEffect, useRef } from 'react';
import videojs from 'video.js';

const VideoJs = ({ options, onReady }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = videojs(videoElement, options, () => {
        videojs.log('player is ready');

        if (typeof onReady === 'function') {
          onReady(player);
        }
      });

      playerRef.current = player;
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef, onReady]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

const CommonVideoJsPlayer = ({ open, options, onClose, onReady }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1280,
        }}
      >
        <VideoJs options={options} onReady={onReady} />
      </Box>
    </Modal>
  );
};

export default CommonVideoJsPlayer;
