import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const FacilitiesSearchPage = lazy(() => import('./FacilitiesSearchPage'));
const facilitiesSearchPageBase = '/facilities';

// i18next.addResourceBundle('en', 'facilitiesSearchPage', en);
// i18next.addResourceBundle('tr', 'facilitiesSearchPage', tr);
// i18next.addResourceBundle('ar', 'facilitiesSearchPage', ar);

const facilitiesSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${facilitiesSearchPageBase}/search`,
      element: <FacilitiesSearchPage />,
    },
    {
      path: facilitiesSearchPageBase,
      element: <Navigate to={`${facilitiesSearchPageBase}/search`} />,
    },
  ],
};

export default facilitiesSearchPageConfig;
