import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

// TODO: ENV File
const firebaseConfig = {
  apiKey: 'AIzaSyDQ9jsk5qI1knNTaLBdfXX-8SHK2qfi_7A',
  authDomain: 'hyve-ultraviolet.firebaseapp.com',
  projectId: 'hyve-ultraviolet',
  storageBucket: 'hyve-ultraviolet.appspot.com',
  messagingSenderId: '740931907897',
  appId: '1:740931907897:web:b4feaf1605ce3181c738e8',
};

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseMessaging = getMessaging(firebaseApp);
export default firebaseApp;
