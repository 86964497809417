import Uppy from '@uppy/core';
import ImageEditor from '@uppy/image-editor';
import { DashboardModal } from '@uppy/react';
import Tus from '@uppy/tus';
import Webcam from '@uppy/webcam';
import { jwtService } from 'app/providers/auth/services';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const { REACT_APP_ULTRAVIOLET_UPLOADER_BASE_URL } = process.env;

const createUppy = ({ id, options = {} }) =>
  new Uppy({
    id,
    ...options,
  })
    .use(ImageEditor, {})
    .use(Tus, {
      endpoint: `${REACT_APP_ULTRAVIOLET_UPLOADER_BASE_URL}/uploads`,
      async onBeforeRequest(req) {
        let session = jwtService.getSession();

        if (!jwtService.isTokenValid(session.accessToken)) {
          session = await jwtService.refreshSession();
        }

        req.setHeader('Authorization', `Bearer ${session.accessToken}`);
      },
    })
    .use(Webcam);

const CommonUppyDashboardModal = forwardRef(
  ({ dashboardNote, open, uppyId, uppyOptions, onRequestClose, onUploadSuccess }, rootRef) => {
    const mainTheme = useSelector(selectMainTheme);
    const themeMode = useMemo(() => mainTheme?.palette.mode, [mainTheme?.palette.mode]);

    const [uppy] = useState(() => createUppy({ id: uppyId, options: uppyOptions }));

    useEffect(() => {
      const handleUploadSuccess = (file, response) => {
        // PLACEHOLDER

        if (typeof onUploadSuccess === 'function') {
          onUploadSuccess({ file, response });
        }
      };

      uppy.on('upload-success', handleUploadSuccess);

      return () => uppy.off('upload-success', handleUploadSuccess);
    }, [uppy, onUploadSuccess]);

    return (
      <div ref={rootRef}>
        <DashboardModal
          closeModalOnClickOutside
          note={dashboardNote}
          open={open}
          plugins={['ImageEditor', 'Webcam']}
          proudlyDisplayPoweredByUppy={false}
          theme={themeMode || 'light'}
          uppy={uppy}
          onRequestClose={onRequestClose}
        />
      </div>
    );
  }
);

export default CommonUppyDashboardModal;
