import { gql } from '@apollo/client';
import { SERVICE_PROVISION_CREATE_DIALOG_SERVICE_PROVISION_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_SERVICE_PROVISION = gql`
  mutation CreateServiceProvision($data: ServiceProvisionCreateInput!) {
    createServiceProvision(data: $data) {
      id
      ...ServiceProvisionCreateDialogServiceProvisionFragment
    }
  }
  ${SERVICE_PROVISION_CREATE_DIALOG_SERVICE_PROVISION_FRAGMENT}
`;
