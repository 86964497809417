import { jwtService } from 'app/providers/auth/services';
import { selectUser } from 'app/store/userSlice';
import { getToken } from 'firebase/messaging';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { firebaseMessaging } from '../core';

const FirebaseMessagingContext = React.createContext();

const FirebaseMessagingProvider = ({ children }) => {
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [isSystemDeviceInitialized, setIsSystemDeviceInitialized] = useState(false);
  // const [token, setToken] = useState(null);
  const user = useSelector(selectUser);

  const initializeSystemDevice = useCallback(() => {
    getToken(firebaseMessaging, {
      vapidKey:
        // TODO: ENV
        'BHEU4tAu2cOUB9Tctop4eZ6yOryfACr0CK2EuHLWr69UabLQMY0LKzkz0_Hm44PHMjuir9nDsjGNo67zNAvF6GE',
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          // TODO:
          setIsSystemDeviceInitialized(true);
          // TODO:
          // setToken(currentToken);
          jwtService.setFcmToken({ fcmToken: currentToken });
          console.log({ currentToken });
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
  }, []);

  // TODO: Rename
  const requestNotificationPermission = useCallback(() => {
    if (!('Notification' in window)) {
      console.log('// TODO: This browser does not support desktop notification.');
    } else if (Notification.permission === 'granted') {
      // TODO:
      setIsPermissionGranted(true);
      initializeSystemDevice();
    } else if (Notification.permission !== 'denied') {
      console.log('// TODO: Requesting permission...');
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          // TODO:
          setIsPermissionGranted(true);
          initializeSystemDevice();
        } else {
          console.log('Unable to get permission to notify.');
        }
      });
    }
  }, [initializeSystemDevice]);

  useEffect(() => {
    if (user) {
      requestNotificationPermission();
    }
  }, [requestNotificationPermission, user]);

  return (
    <FirebaseMessagingContext.Provider
      value={{
        firebaseMessaging,
        isPermissionGranted,
        isSystemDeviceInitialized,
        requestNotificationPermission,
      }}
    >
      {/* TODO: */}
      {/* {token && <div>{token}</div>} */}
      {children}
    </FirebaseMessagingContext.Provider>
  );
};

export const __FirebaseMessagingContext = FirebaseMessagingContext;
export default FirebaseMessagingProvider;
