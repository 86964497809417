import { useEffect, useState } from 'react';
import { usePusherChannels } from 'app/providers/pusher/channels';

const useChannel = (channelName) => {
  const [channel, setChannel] = useState(null);
  const { pusherChannelsClient } = usePusherChannels();

  useEffect(() => {
    if (pusherChannelsClient && channelName) {
      setChannel(pusherChannelsClient.subscribe(channelName));
    }

    return () => {
      pusherChannelsClient?.unsubscribe(channelName);
    };
  }, [channelName, pusherChannelsClient]);

  return channel;
};

export default useChannel;
