import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItemButton, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useState } from 'react';

const ServiceTicketViewDrawerSectionItem = memo(
  ({ children, disabled, isInitiallyOpen = true, title }) => {
    const [open, setOpen] = useState(isInitiallyOpen);
    const theme = useTheme();

    const toggleSection = () => setOpen(!open);

    return (
      <>
        <ListItemButton className="mb-4 px-24" disabled={disabled} onClick={toggleSection}>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              className: 'font-600 uppercase',
              color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : 'secondary.main',
            }}
          />

          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </>
    );
  }
);

export default ServiceTicketViewDrawerSectionItem;
