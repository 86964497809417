// eslint-disable-next-line import/prefer-default-export
export const programSearchScreenFilterDefs = [
  {
    name: 'Program',
    refinements: [
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
      {
        attribute: 'tags.name',
        label: 'Tags',
        operator: 'or',
      },
      {
        attribute: 'creator.name',
        label: 'Creator',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Issue Template',
    refinements: [
      {
        attribute: 'issueTemplates.issueTemplate.tags.name',
        label: 'Tags',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Sharing Rule',
    refinements: [
      {
        attribute: 'commonSharingRule.type.name',
        label: 'Type',
        operator: 'or',
      },
      {
        attribute: 'commonSharingRule.subjectType.name',
        label: 'Subject Type',
        operator: 'or',
      },
      {
        attribute: 'commonSharingRule.subjectTeams.team.name',
        label: 'Subject Teams',
        operator: 'or',
      },
    ],
  },
];
