import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_SCHEDULE_REQUEST_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketScheduleRequestDialogServiceTicketFragment on ServiceTicket {
    id
    isInReview
    phase {
      id
      enum
      name
    }
    serviceVisits {
      id
      windowEndAt
      windowStartAt
    }
  }
`;
