import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { PAUSE_WORK_ORDER_ISSUE } from './queries';

const defaultValues = {
  resumeAt: null,
};

const schema = yup.object().shape({
  resumeAt: yup.date().nullable().optional(),
});

const WorkOrderIssuePauseDialog = ({ serviceTicketId, workOrderIssueId, onClose }) => {
  const dispatch = useDispatch();
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [pauseWorkOrderIssue, { loading: pauseWorkOrderIssueLoading }] = useMutation(
    PAUSE_WORK_ORDER_ISSUE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Successfully Paused',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Pausing Work Order Issue', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [pauseWorkOrderIssueLoading].includes(true),
    [pauseWorkOrderIssueLoading]
  );

  const onSubmit = async (data) => {
    try {
      await pauseWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: workOrderIssueId,
            scheduledResumption: data.resumeAt
              ? {
                  resumeAt: data.resumeAt,
                  workOrderIssue: { id: workOrderIssueId },
                }
              : undefined,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Pause Work Order Issue
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-pause-form"
            name="work-order-issue-pause-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Alert className="mb-24" severity="info">
              Once paused, no further action can be taken on this issue until resumed.
            </Alert>

            <Controller
              control={control}
              name="resumeAt"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonDatepicker
                    {...field}
                    controls={['calendar']}
                    disabled={false}
                    error={errors?.resumeAt}
                    min={moment().add(1, 'day').startOf('day')}
                    placeholder="Select Scheduled Resumption Date..."
                    select="date"
                  />
                </div>
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={!isValid || loading}
          form="work-order-issue-pause-form"
          type="submit"
          variant="contained"
        >
          Pause
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssuePauseDialog;
