import { useEffect, useState } from 'react';
import { useDeepCompareEffect } from '@fuse/hooks';
import { Configure, InstantSearch } from 'react-instantsearch';
import { useMeilisearch } from 'app/providers/meilisearch';

// TODO: memo?
const EntitySearchApp = ({
  children,
  // TODO:
  hitsPerPage = 20,
  indexName,
  // TODO:
  routing = true,
  SearchAdapter = {},

  filters
}) => {
  const { instantSearchClient } = useMeilisearch();
  const [searchState, setSearchState] = useState({});
  const [searchClient, setSearchClient] = useState(null);

  useEffect(() => {
    console.log('// TODO: TEST => useEffect');
  }, [instantSearchClient, SearchAdapter]);

  // TODO: Extra Rendering? Switch to `useEffect`?
  useDeepCompareEffect(() => {
    console.log('// TODO: TEST => useDeepCompareEffect');
    if (instantSearchClient) {
      setSearchClient(
        typeof SearchAdapter === 'function'
          ? { ...instantSearchClient, search: SearchAdapter(instantSearchClient) }
          : instantSearchClient
      );
    }
  }, [instantSearchClient, SearchAdapter]);

  // TODO: This is Wrong!
  useDeepCompareEffect(() => {
    console.log('// TODO:', { searchState });
  }, [searchState]);

  return (
    <>
      {searchClient && (
        <InstantSearch
          indexName={indexName}
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={setSearchState}
          // TODO; Review
          // routing={routing}
        >
          {/* TODO: Review */}
          <Configure filters={filters} hitsPerPage={hitsPerPage} paginationTotalHits={2000} />

          {children}
        </InstantSearch>
      )}
    </>
  );
};

export default EntitySearchApp;
