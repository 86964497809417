import { createSlice } from '@reduxjs/toolkit';

const blockUiSlice = createSlice({
  name: 'blockUi',
  initialState: {
    state: false,
    options: {},
  },
  reducers: {
    openBlockUi: (state, action) => {
      state.state = true;
      state.options = action.payload;
    },
    closeBlockUi: (state, action) => {
      state.state = false;
    },
  },
});

export const { openBlockUi, closeBlockUi } = blockUiSlice.actions;

export const selectBlockUiState = ({ blockUi }) => blockUi.state;

export const selectBlockUiOptions = ({ blockUi }) => blockUi.options;

export default blockUiSlice.reducer;
