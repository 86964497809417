import { gql, useQuery } from '@apollo/client';
import _ from '@lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const SegmentGroupingOperatorSelectField = forwardRef(
  ({ className, disabled, filters, value, onChange, ...props }, rootRef) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const [filtersCache, setFiltersCache] = useState(null);
    const open = Boolean(anchorEl);
    const [options, setOptions] = useState(null);

    useQuery(
      gql`
        query GetSegmentGroupingOperatorSelectFieldData(
          $where: SegmentGroupingOperatorWhereInput!
        ) {
          segmentGroupingOperators(where: $where) {
            id
            enum
            name
          }
        }
      `,
      {
        skip: _.isEqual(filters, filtersCache) && !!options,
        variables: { where: { ...(filters || {}) } },
        onCompleted: ({ segmentGroupingOperators }) => {
          setFiltersCache(filters);

          setOptions(
            segmentGroupingOperators?.map((result) => ({
              result,
              label: result?.name,
              value: result?.id,
            }))
          );
        },
        onError: ({ message }) =>
          dispatch(
            showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
          ),
      }
    );

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return (
      <div className={className} ref={rootRef}>
        <Button
          color="info"
          disabled={disabled}
          disableElevation
          endIcon={<KeyboardArrowDownIcon />}
          variant="contained"
          onClick={handleClick}
        >
          {value?.label?.toUpperCase()}
        </Button>

        <Menu
          anchorEl={anchorEl}
          id="segment-grouping-operator-select-field-menu"
          open={open}
          onClose={handleClose}
        >
          {options?.map((option) => (
            <MenuItem
              key={option?.result?.id}
              selected={option?.result?.id === value?.result?.id}
              onClick={() => {
                onChange(option);

                handleClose();
              }}
            >
              {option?.label?.toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
);

export default SegmentGroupingOperatorSelectField;
