import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_RESUME_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const RESUME_WORK_ORDER_ISSUE = gql`
  mutation ResumeWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueResumeInput!
  ) {
    resumeWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueResumeDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RESUME_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
