import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PROGRAM_CAMPAIGN_VIEW_APP_PROGRAM_FRAGMENT = gql`
  fragment ProgramCampaignViewAppProgramFragment on Program {
    id

    # TODO: Copy Over to "Create"
    campaigns {
      id
      name
      serviceTickets {
        id
        serviceReferenceNumber
      }
    }
    # TODO: Remove?
    issueTemplates {
      id
      name
    }
    name
  }
`;
