import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { FilterList, GridViewOutlined } from '@mui/icons-material';
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { EntitySearchDateRangeMenu } from 'app/shared-components/EntitySearch/EntitySearchDateRangeMenu';
import { EntitySearchRefinementSection } from 'app/shared-components/EntitySearch/EntitySearchRefinementSection';
import { UserSearchViewControl } from 'app/shared-components/UserSearchView/UserSearchViewControl';
import clsx from 'clsx';
import objectHash from 'object-hash';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useEntitySearchApp } from '../EntitySearchApp';
import { EntitySearchInfiniteHitsGridColumnTree } from '../EntitySearchInfiniteHitsGridColumnTree';

const DRAWER_APP_BAR_HEIGHT = 128;

const DrawerAppBar = styled(AppBar)(({ theme }) => ({
  height: DRAWER_APP_BAR_HEIGHT,
  borderStyle: 'solid',
  borderColor: theme.palette.mode === 'dark' ? '#353e4f' : grey[400],
  borderBottomWidth: 1,
}));

const EntitySearchDrawer = memo(
  ({ dateRangeAttribute, filterDefs, title, toggleDrawer, width }) => {
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
    const [panelMode, setPanelMode] = useState('filters');
    const panelRef = useRef(null);
    const { status } = useInstantSearch();
    const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);
    const { viewMode } = useEntitySearchApp();

    const isSearching = useMemo(() => status === 'stalled', [status]);

    useEffect(() => {
      if (panelRef.current) {
        panelRef.current.scrollTop = 0;
      }
    }, [panelMode]);

    useEffect(() => {
      if (panelMode === 'columns' && viewMode !== 'grid') {
        setPanelMode('filters');
      }
    }, [panelMode, viewMode]);

    return (
      <div className="flex-1" style={{ width }}>
        <DrawerAppBar color="inherit" elevation={0} position="static">
          <Toolbar>
            <div className="flex flex-col w-full">
              <div className="flex items-center h-64 py-16">
                <UserSearchViewControl title={title} />

                {isMobile && toggleDrawer && (
                  <IconButton className="ml-4" size="small" onClick={toggleDrawer}>
                    <FontAwesomeIcon
                      icon={faXmarkCircle}
                      style={{ width: '18px', height: '18px' }}
                    />
                  </IconButton>
                )}
              </div>

              {dateRangeAttribute && <EntitySearchDateRangeMenu attribute={dateRangeAttribute} />}
            </div>
          </Toolbar>
        </DrawerAppBar>

        <div className="flex flex-row" style={{ height: `calc(100vh - ${viewportOverflow}px)` }}>
          <Box
            className="w-40 h-full border-r-1"
            component="div"
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === 'light' ? '#f5f5f5' : '#1b2535'),
            }}
          >
            <div className="flex items-center h-40 rotate-90">
              <Button
                className="rotate-180 rounded-0"
                color={panelMode === 'filters' ? 'secondary' : 'primary'}
                disabled={isSearching}
                disableElevation
                startIcon={<FilterList sx={{ fontSize: '16x!important' }} />}
                sx={{
                  minWidth: 'auto',
                }}
                variant="contained"
                onClick={() => setPanelMode('filters')}
              >
                Filters
              </Button>

              <Button
                className="rotate-180 rounded-0"
                color={panelMode === 'columns' ? 'secondary' : 'primary'}
                disabled={isSearching}
                disableElevation
                startIcon={<GridViewOutlined sx={{ fontSize: '16x!important' }} />}
                sx={{
                  minWidth: 'auto',
                }}
                variant="contained"
                onClick={() => setPanelMode('columns')}
              >
                Columns
              </Button>
            </div>
          </Box>

          <>
            <div ref={panelRef} className="flex-1 h-full overflow-auto">
              {/* NOTE: Refinements Must Render or Search will Reset on State Change */}
              <div className={clsx({ hidden: panelMode !== 'filters' })}>
                {filterDefs?.map((filterDef) => (
                  <EntitySearchRefinementSection key={objectHash(filterDef)} {...filterDef} />
                ))}
              </div>

              <EntitySearchInfiniteHitsGridColumnTree shouldRender={panelMode === 'columns'} />
            </div>
          </>
        </div>
      </div>
    );
  }
);

export default EntitySearchDrawer;
