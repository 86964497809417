import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Typography } from '@mui/material';
import { amber, blue, green, red } from '@mui/material/colors';
import { lighten, styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const CommonStoredTypeBadge = styled(Box)(({ theme, ...props }) => ({
  backgroundColor: {
    COMMON_STORED_CAD: blue[600],
    COMMON_STORED_IMAGE: amber[600],
    COMMON_STORED_PDF: red[600],
    COMMON_STORED_VIDEO: green[600],
  }[props.enum],
}));

const ServiceTicketAttachmentViewAppFileItemIcon = ({ commonStoredType }) => {
  const name = useMemo(() => {
    let _name;

    if (commonStoredType?.enum === 'COMMON_STORED_CAD') {
      _name = 'CAD';
    } else if (commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
      _name = 'IMG';
    } else if (commonStoredType?.enum === 'COMMON_STORED_PDF') {
      _name = 'PDF';
    } else if (commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
      _name = 'VID';
    }

    return _name;
  }, [commonStoredType?.enum]);

  return (
    <div className="relative">
      <FuseSvgIcon className="" size={56} color="disabled">
        heroicons-outline:document
      </FuseSvgIcon>

      <CommonStoredTypeBadge
        className="absolute left-0 bottom-0 px-6 rounded text-12 font-semibold leading-20 text-white"
        enum={commonStoredType?.enum}
      >
        {name}
      </CommonStoredTypeBadge>
    </div>
  );
};

const ServiceTicketAttachmentViewAppFileItem = ({
  attachment,
  basePath,
  serviceTicketOrganizations,
  type,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const name = useMemo(() => {
    let _name;

    if (attachment?.commonStoredType?.enum === 'COMMON_STORED_CAD') {
      _name = attachment?.commonStoredCad?.name;
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
      _name = attachment?.commonStoredImage?.name;
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_PDF') {
      _name = attachment?.commonStoredPdf?.name;
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
      _name = attachment?.commonStoredVideo?.name;
    }

    return _name;
  }, [
    attachment?.commonStoredCad?.name,
    attachment?.commonStoredImage?.name,
    attachment?.commonStoredPdf?.name,
    attachment?.commonStoredType?.enum,
    attachment?.commonStoredVideo?.name,
  ]);

  const standardImageUrl = useMemo(() => {
    let _standardImageUrl;

    if (attachment?.commonStoredType?.enum === 'COMMON_STORED_CAD') {
      _standardImageUrl = attachment?.commonStoredCad?.standardImageUrl;
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
      _standardImageUrl = attachment?.commonStoredImage?.standardImageUrl;
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_PDF') {
      _standardImageUrl = attachment?.commonStoredPdf?.standardImageUrl;
    } else if (attachment?.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
      _standardImageUrl = attachment?.commonStoredVideo?.standardImageUrl;
    }

    return _standardImageUrl;
  }, [
    attachment?.commonStoredCad?.standardImageUrl,
    attachment?.commonStoredImage?.standardImageUrl,
    attachment?.commonStoredPdf?.standardImageUrl,
    attachment?.commonStoredType?.enum,
    attachment?.commonStoredVideo?.standardImageUrl,
  ]);

  return (
    <Box
      sx={{ backgroundColor: 'background.paper' }}
      className="flex flex-col relative w-full sm:w-160 h-160 m-8 p-16 shadow rounded-16 cursor-pointer"
      onClick={() => navigate(`../${basePath}/type/${type}/view/${attachment.id}`)}
    >
      {attachment?.isCompletionAttachment && (
        <div
          className="absolute flex items-center justify-center bg-green-600"
          style={{
            top: 8,
            right: 8,
            width: 20,
            height: 20,
            borderRadius: '50%',
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={faCheck}
            style={{ width: 10, height: 10 }}
          />
        </div>
      )}

      {standardImageUrl ? (
        <Box
          className="flex flex-auto w-full mb-8"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
            backgroundImage: `url("${standardImageUrl}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      ) : (
        <div className="flex flex-auto w-full items-center justify-center">
          <ServiceTicketAttachmentViewAppFileItemIcon
            commonStoredType={attachment.commonStoredType}
          />
        </div>
      )}

      <div className="flex shrink flex-col justify-center text-center">
        <Typography className="truncate text-12 font-medium">{name}</Typography>
      </div>
    </Box>
  );
};

export default ServiceTicketAttachmentViewAppFileItem;
