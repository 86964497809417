import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_PROVISION_VERSION_VIEW_APP_SERVICE_PROVISION_FRAGMENT = gql`
  fragment ServiceProvisionVersionViewAppServiceProvisionFragment on ServiceProvision {
    id

    # TODO:
    name
    versions {
      id
      # effectivePeriodEndDate: DateTime
      # effectivePeriodStartDate: DateTime
      # isProcessed: Boolean!
      # TODO:
      # laborRateCard: ServiceProvisionVersionLaborRateCard
      # serviceProvision: ServiceProvision!
      # TODO:
      # serviceProvisionCoverageFilters: [ServiceProvisionCoverageFilter]
      # serviceProvisionReviews: [ServiceProvisionReview]
      # TODO:
      # serviceProvisionTargetFilters: [ServiceProvisionTargetFilter]
      status {
        id
        enum
        name
      }
      version
    }
  }
`;
