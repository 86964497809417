import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Drawer, SwipeableDrawer } from '@mui/material';
import FusePageSimpleSidebarContent from './FusePageSimpleSidebarContent';

const FusePageSimpleSidebar = forwardRef((props, ref) => {
  const { hiddenImplementation, open, position, variant, rootRef } = props;

  const [isOpen, setIsOpen] = useState(open);

  useImperativeHandle(ref, () => ({
    toggleSidebar: handleToggleDrawer,
  }));

  const handleToggleDrawer = useCallback((val) => {
    setIsOpen(val);
  }, []);

  useEffect(() => {
    handleToggleDrawer(open);
  }, [handleToggleDrawer, open]);

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <>
      {(hiddenImplementation !== 'js' || (isMobile && variant === 'permanent')) && (
        <SwipeableDrawer
          anchor={position}
          BackdropProps={{
            classes: {
              root: 'FusePageSimple-backdrop',
            },
          }}
          classes={{
            paper: clsx(
              'FusePageSimple-sidebar',
              variant,
              position === 'left' ? 'FusePageSimple-leftSidebar' : 'FusePageSimple-rightSidebar'
            ),
            root: clsx('FusePageSimple-sidebarWrapper', variant),
          }}
          // container={rootRef.current}
          disableSwipeToOpen
          // NOTE: No Longer Supported by MUI
          // keepMounted
          ModalProps={{
            keepMounted: true,
          }}
          open={isOpen}
          sx={{
            display: { xs: 'block', md: 'block', lg: 'none', xl: 'none' },
            position: 'absolute',
          }}
          variant="temporary"
          onClose={() => props?.onClose()}
          onOpen={(ev) => {}}
        >
          <FusePageSimpleSidebarContent {...props} />
        </SwipeableDrawer>
      )}

      {(hiddenImplementation !== 'js' || (!isMobile && variant === 'permanent')) && (
        <Drawer
          anchor={position}
          classes={{
            paper: clsx('FusePageSimple-sidebar border-0', variant),
          }}
          className={clsx(
            'FusePageSimple-sidebarWrapper',
            variant,
            isOpen ? 'opened' : 'closed',
            position === 'left' ? 'FusePageSimple-leftSidebar' : 'FusePageSimple-rightSidebar'
          )}
          // NOTE: No Longer Supported by MUI
          // keepMounted
          open={isOpen}
          sx={{ display: { xs: 'none', md: 'none', lg: 'block', xl: 'block' } }}
          variant="permanent"
          onClose={props?.onClose}
        >
          <FusePageSimpleSidebarContent {...props} />
        </Drawer>
      )}
    </>
  );
});

FusePageSimpleSidebar.defaultProps = {
  open: true,
};

export default FusePageSimpleSidebar;
