import { forwardRef, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { CommonCreatableSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';

const ProgramTagSelectOption = (props) => {
  const programTag = props.data?.result;

  const item = useMemo(() => {
    if (!programTag) return null;

    return (
      <ListItem component="div" dense>
        <ListItemText
          disableTypography
          primary={<Typography variant="body2">{programTag.name}</Typography>}
        />
      </ListItem>
    );
  }, [programTag]);

  return <components.Option {...props}>{item}</components.Option>;
};

const ProgramTagSelectField = forwardRef(({ className, filters, ...SelectProps }, rootRef) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(null);

  useQuery(
    gql`
      query GetProgramTagSelectFieldData($where: ProgramTagWhereInput!) {
        programTags(where: $where) {
          id
          name
        }
      }
    `,
    {
      skip: !!options,
      variables: { where: {} },
      onCompleted: ({ programTags }) =>
        setOptions(
          programTags?.map((result) => ({
            result,
            label: result?.name,
            value: result?.id,
          }))
        ),
      onError: ({ message }) =>
        dispatch(
          showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
        ),
    }
  );

  return (
    <div className={className} ref={rootRef}>
      <CommonCreatableSelect
        {...SelectProps}
        // ROADMAP: Implement
        // components={{ Option: ProgramTagSelectOption }}
        options={options || []}
      />
    </div>
  );
});

export default ProgramTagSelectField;
