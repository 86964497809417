import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueNteUpdateDialogServiceTicketFragment on ServiceTicket {
    id

    workOrder {
      id
      approvalCheck {
        id
        enum
        name
      }
      issues {
        id
        actionItems {
          id
          notToExceedAmount
        }
        approvalCheck {
          id
          enum
          name
        }
        notToExceedAmount
      }
      notToExceedAmount
    }
  }
`;

export const WORK_ORDER_ISSUE_NTE_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT = gql`
  fragment WorkOrderIssueNteUpdateDialogWorkOrderIssueFragment on WorkOrderIssue {
    id
    notToExceedAmount
  }
`;
