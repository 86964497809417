import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const FacilityAreasSearchPage = lazy(() => import('./FacilityAreasSearchPage'));
const facilityAreasSearchPageBase = '/facility-areas';

// i18next.addResourceBundle('en', 'facilityAreasSearchPage', en);
// i18next.addResourceBundle('tr', 'facilityAreasSearchPage', tr);
// i18next.addResourceBundle('ar', 'facilityAreasSearchPage', ar);

const facilityAreasSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${facilityAreasSearchPageBase}/search`,
      element: <FacilityAreasSearchPage />,
    },
    {
      path: facilityAreasSearchPageBase,
      element: <Navigate to={`${facilityAreasSearchPageBase}/search`} />,
    },
  ],
};

export default facilityAreasSearchPageConfig;
