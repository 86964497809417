import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueCreateDialogServiceTicketFragment on ServiceTicket {
    id
    completeBy
    facility {
      id
    }
    phase {
      id
      enum
      name
    }
    serviceProvision {
      id
    }
    serviceVisits {
      id
      windowEndAt
      windowStartAt
    }
    siteVisits {
      id
      windowEndAt
      windowStartAt
    }
    workOrder {
      id
      approvalCheck {
        id
        enum
        name
      }
      discountAmount
      issues {
        id
        accountingWorkOrderType {
          id
          acceptedCommonStandardRateTypes {
            id
            enum
            name
          }
          targetCsiClassifications {
            id
            name
            number
            title
          }
        }
        actionItems {
          id
          commonDiscountRate {
            id
            type {
              id
              enum
              name
            }
            value
          }
          commonStandardFlatRate {
            id
            quantity
          }
          commonStandardHourlyRate {
            id
            hours
            rateAmount
            technicians
          }
          commonStandardRateType {
            id
            enum
            name
          }
          csiClassification {
            id
            name
            number
          }
          description
          discountAmount
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        approvalCheck {
          id
          enum
          name
        }
        completeBy
        discountAmount
        notToExceedAmount
        serviceIssue {
          id
          commonTargetType {
            id
            enum
            name
          }
          targetFacilityAreas {
            id
            type {
              id
            }
          }
        }
        serviceVisits {
          id
          windowEndAt
          windowStartAt
        }
        siteVisits {
          id
          windowEndAt
          windowStartAt
        }
        status {
          id
          enum
          name
        }
        subtotalAmount
        totalAmount
        totalAmountCheck {
          id
          enum
          name
        }
      }
      notToExceedAmount
      subtotalAmount
      totalAmount
    }
  }
`;
