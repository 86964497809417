import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { RESUME_WORK_ORDER_ISSUE } from './queries';

const defaultValues = {
  resumeAt: null,
};

const schema = yup.object().shape({
  resumeAt: yup.date().nullable().optional(),
});

const WorkOrderIssueResumeDialog = ({
  serviceTicketId,
  showScheduledResumption,
  workOrderIssueId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [resumeWorkOrderIssue, { loading: resumeWorkOrderIssueLoading }] = useMutation(
    RESUME_WORK_ORDER_ISSUE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Successfully Resumed',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Resuming Work Order Issue', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [resumeWorkOrderIssueLoading].includes(true),
    [resumeWorkOrderIssueLoading]
  );

  const onSubmit = async (data) => {
    try {
      await resumeWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: workOrderIssueId,
            scheduledResumption: data.resumeAt
              ? {
                  resumeAt: data.resumeAt,
                  workOrderIssue: { id: workOrderIssueId },
                }
              : undefined,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Resume Work Order Issue
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-resume-form"
            name="work-order-issue-resume-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Alert className="mb-24" severity="info">
              Once resumed, actions will be able to be taken against this issue.
            </Alert>

            {showScheduledResumption && (
              <Controller
                control={control}
                name="resumeAt"
                render={({ field }) => (
                  <div className="mb-24">
                    <CommonDatepicker
                      {...field}
                      controls={['calendar']}
                      disabled={false}
                      error={errors?.resumeAt}
                      min={moment().add(1, 'day').startOf('day')}
                      placeholder="Select Scheduled Resumption Date..."
                      select="date"
                    />
                  </div>
                )}
              />
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={!isValid || loading}
          form="work-order-issue-resume-form"
          type="submit"
          variant="contained"
        >
          Resume
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueResumeDialog;
