import { ServiceIssueViewScreen } from 'app/screens/ServiceIssueViewScreen';
import { Navigate, Route, Routes } from 'react-router-dom';

const ServiceIssuesViewPage = () => {
  return (
    <Routes>
      <Route element={<ServiceIssueViewScreen />} path=":sectionName/:viewName" />
      <Route element={<ServiceIssueViewScreen />} path=":sectionName/:viewName/view/:viewId" />

      <Route path="*" element={<Navigate to="tabs/overview" replace />} />
    </Routes>
  );
};

export default ServiceIssuesViewPage;
