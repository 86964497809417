import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DemoSidebarContent from '@fuse/core/DemoSidebarContent';
import {
  AppBar,
  Avatar,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { EntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchDrawer } from 'app/shared-components/EntitySearch/EntitySearchDrawer';
import { EntitySearchHeader } from 'app/shared-components/EntitySearch/EntitySearchHeader';
import { EntitySearchInfiniteHitsGrid } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsGrid';
import { EntitySearchInfiniteHitsList } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsList';
import { EntitySearchInfiniteHitsMap } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsMap';
// import { ServiceRequestCreateDialog } from 'app/shared-components/ServiceRequest/ServiceRequestCreateDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
// import { AssetSearchAdapter } from './adapters';
// import { assetSearchScreenColumnDefs } from './columnDefs';
// import { assetSearchScreenFilterDefs } from './filterDefs';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

const ServiceProvisionManageListProvisionListItem = ({
  serviceProvision,
  organization,
  index,
}) => {
  const theme = useTheme();

  return (
    <Draggable
      draggableId={`sub-data-${organization.id}-${serviceProvision.id}`}
      index={index}
      type={`subList-${organization.id}`}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <ListItem
            divider
            className={clsx(snapshot.isDragging ? 'shadow-lg' : 'shadow', 'px-40 h-64 group')}
            sx={{ bgcolor: 'background.paper' }}
            button
            // TODO: useNavigation + onClick?
            component={NavLinkAdapter}
            to={`/service-provisions/manage/${serviceProvision.id}`}
            // ref={provided5.innerRef}
            // {...provided5.draggableProps}
          >
            <div
              className="md:hidden absolute flex items-center justify-center inset-y-0 left-0 w-32 cursor-move md:group-hover:flex"
              {...provided.dragHandleProps}
            >
              <FuseSvgIcon sx={{ color: 'text.disabled' }} size={20}>
                heroicons-solid:menu
              </FuseSvgIcon>
            </div>
            <ListItemIcon className="min-w-40 -ml-10 mr-8">
              <IconButton
                // sx={{ color: data.completed ? 'secondary.main' : 'text.disabled' }}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  // dispatch(updateTask({ ...data, completed: !data.completed }));
                }}
              >
                <FuseSvgIcon>heroicons-outline:check-circle</FuseSvgIcon>
              </IconButton>
            </ListItemIcon>
            <ListItemText
              classes={{ root: 'm-0', primary: 'truncate' }}
              primary={serviceProvision.name}
            />
            {/* <div className="flex items-center">
              <div>
                {data.priority === 0 && (
                  <FuseSvgIcon className="text-green icon-size-16 mx-12">
                    heroicons-outline:arrow-narrow-down
                  </FuseSvgIcon>
                )}
                {data.priority === 2 && (
                  <FuseSvgIcon className="text-red icon-size-16 mx-12">
                    heroicons-outline:arrow-narrow-up
                  </FuseSvgIcon>
                )}
              </div>

              {data.dueDate && (
                <Typography className="text-12 whitespace-nowrap" color="text.secondary">
                  {format(new Date(data.dueDate), 'LLL dd')}
                </Typography>
              )}
            </div> */}
          </ListItem>
          {/* <Divider /> */}
        </div>
      )}
      </Draggable>
  )
};

export default ServiceProvisionManageListProvisionListItem;
