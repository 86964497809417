import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_DELETE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_WORK_ORDER_ISSUE = gql`
  mutation DeleteWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueDeleteInput!
  ) {
    deleteWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueDeleteDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_DELETE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
