import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export const VerifiedStep = ({ verified }) => {

  const navigate = useNavigate();

  return (
    <>
      <Typography className='font-normal text-xl text-center '>You have successfully verified your {verified}.</Typography>
      <img className="w-90 h-90 self-center mt-28 mb-48" src="assets/icons/successIcon.svg" alt="logo" />
      <Button
        className="self-center mt-4 mb-52"
        variant="contained"
        size="large"
        fullWidth
        onClick={()=> navigate('/')}
        sx={{
          background: '#D416B9',
          padding: '20px 0 20px 0',
          borderRadius: '67px',
          "&:hover": { backgroundColor: "#B7129F" }
        }}
      >
        <Typography className='text-lg font-semibold text-white' >Next</Typography>
      </Button>
    </>
  )
}