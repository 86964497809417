import { faAngleDown, faAngleRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { EntitySearchRefinementList } from 'app/shared-components/EntitySearch/EntitySearchRefinementList';
import objectHash from 'object-hash';
import { memo, useMemo, useState } from 'react';
import { useClearRefinements, useCurrentRefinements, useInstantSearch } from 'react-instantsearch';

const SectionToggleButton = styled(Button)(({ theme }) => {
  const color = theme.palette.mode === 'dark' ? '#18212f' : grey[100];
  const hover = theme.palette.mode === 'dark' ? '#151d29' : grey[200];

  return {
    padding: 12,
    color: theme.palette.getContrastText(color),
    height: 48,
    minHeight: 48,
    maxHeight: 48,
    borderRadius: 0,
    backgroundColor: color,
    '&:hover': {
      backgroundColor: hover,
    },
  };
});

const Root = styled('div')(({ theme }) => ({
  borderStyle: 'solid',
  borderColor: theme.palette.mode === 'dark' ? '#353e4f' : grey[400],
  borderBottomWidth: 1,
}));

const EntitySearchRefinementSection = memo(({ name, refinements }) => {
  const [openSections, setOpenSections] = useState([]);
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: refinements?.map(({ attribute }) => attribute),
  });
  const { items: currentRefinementItems } = useCurrentRefinements({
    includedAttributes: refinements?.map(({ attribute }) => attribute),
  });
  const { status } = useInstantSearch();

  const count = useMemo(
    () =>
      currentRefinementItems?.reduce(
        (acc, { refinements: _refinements }) => acc + _refinements?.length,
        0
      ),
    [currentRefinementItems]
  );

  const isSearching = useMemo(() => status === 'stalled', [status]);

  return (
    <Root>
      <SectionToggleButton
        disableElevation
        fullWidth
        onClick={() =>
          setOpenSections((prevOpenSections) => {
            if (prevOpenSections?.includes(name)) {
              return prevOpenSections?.filter((p) => p !== name);
            }

            return [...(prevOpenSections || []), name];
          })
        }
      >
        <div className="flex pl-12 w-36">
          <FontAwesomeIcon
            icon={openSections.includes(name) ? faAngleDown : faAngleRight}
            size="1x"
          />
        </div>

        <div className="flex-1 pr-8 text-left truncate">{name}</div>

        {Boolean(count) && (
          <>
            <Tooltip title="Applied Filters">
              <Chip label={count} size="small" variant="outlined" />
            </Tooltip>

            <Tooltip title="Clear Filters">
              <IconButton
                className="ml-4"
                component="div"
                disabled={isSearching}
                size="small"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  clearRefinements();
                }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </SectionToggleButton>

      {refinements?.map((refinement) => (
        <EntitySearchRefinementList
          key={objectHash(refinement)}
          {...refinement}
          open={openSections.includes(name)}
        />
      ))}
    </Root>
  );
});

export default EntitySearchRefinementSection;
