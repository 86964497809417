import { gql } from '@apollo/client';
import { SERVICE_ISSUE_ATTACHMENT_CREATE_DIALOG_SERVICE_ISSUE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_SERVICE_ISSUE_ATTACHMENT = gql`
  mutation CreateServiceIssueAttachment(
    $where: ServiceIssueWhereUniqueInput!
    $data: ServiceIssueAttachmentCreateInput!
  ) {
    createServiceIssueAttachment(where: $where, data: $data) {
      id
      ...ServiceIssueAttachmentCreateDialogServiceIssueFragment
    }
  }
  ${SERVICE_ISSUE_ATTACHMENT_CREATE_DIALOG_SERVICE_ISSUE_FRAGMENT}
`;
