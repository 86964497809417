import { useLazyQuery, useMutation } from '@apollo/client';
import { faEllipsisV, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ServiceTicketReminderDeleteDialog } from 'app/shared-components/ServiceTicketReminder/ServiceTicketReminderDeleteDialog';
import { ServiceTicketReminderUpdateDialog } from 'app/shared-components/ServiceTicketReminder/ServiceTicketReminderUpdateDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  COMPLETE_SERVICE_TICKET_REMINDER,
  FETCH_SERVICE_TICKET_REMINDER_VIEW_DRAWER_DATA,
  UNCOMPLETE_SERVICE_TICKET_REMINDER,
} from './queries';

const DRAWER_APP_BAR_HEIGHT = 64;

const ServiceTicketReminderViewDrawer = ({ basePath, contentWidth, onCloseDrawer }) => {
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState();
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const params = useParams();
  const user = useSelector(selectUser);

  const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

  const [completeServiceTicketReminder, { loading: completeServiceTicketReminderLoading }] =
    useMutation(COMPLETE_SERVICE_TICKET_REMINDER, {
      variables: {
        where: { id: params?.serviceTicketId },
        data: { id: params?.viewId },
      },
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Service Ticket Reminder Successfully Completed',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Completing Service Ticket Reminder', variant: 'error' })
        );
      },
    });

  const [
    fetchServiceTicketReminderViewDrawerData,
    {
      data: serviceTicketReminderViewDrawerData,
      loading: serviceTicketReminderViewDrawerLoading,
      refetch: serviceTicketReminderViewDrawerRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_REMINDER_VIEW_DRAWER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [uncompleteServiceTicketReminder, { loading: uncompleteServiceTicketReminderLoading }] =
    useMutation(UNCOMPLETE_SERVICE_TICKET_REMINDER, {
      variables: {
        where: { id: params?.serviceTicketId },
        data: { id: params?.viewId },
      },
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Service Ticket Reminder Successfully Uncompleted',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Un-Completing Service Ticket Reminder', variant: 'error' })
        );
      },
    });

  const loading = useMemo(
    () =>
      [
        completeServiceTicketReminderLoading,
        serviceTicketReminderViewDrawerLoading,
        uncompleteServiceTicketReminderLoading,
      ].includes(true),
    [
      completeServiceTicketReminderLoading,
      serviceTicketReminderViewDrawerLoading,
      uncompleteServiceTicketReminderLoading,
    ]
  );

  const serviceTicketReminder = useMemo(
    () => serviceTicketReminderViewDrawerData?.serviceTicketReminder,
    [serviceTicketReminderViewDrawerData?.serviceTicketReminder]
  );

  const canComplete = useMemo(
    () =>
      Boolean(
        !serviceTicketReminder?.isCompleted &&
          serviceTicketReminder?.serviceTicket?.status.enum === 'OPEN' &&
          serviceTicketReminder?.owner?.id === user?.data?.organization?.id
      ),
    [serviceTicketReminder, user]
  );

  const canDelete = useMemo(
    () =>
      Boolean(
        !serviceTicketReminder?.isCompleted &&
          serviceTicketReminder?.serviceTicket?.status.enum === 'OPEN' &&
          serviceTicketReminder?.owner?.id === user?.data?.organization?.id
      ),
    [serviceTicketReminder, user]
  );

  const canEdit = useMemo(
    () =>
      Boolean(
        !serviceTicketReminder?.isCompleted &&
          serviceTicketReminder?.serviceTicket?.status.enum === 'OPEN' &&
          serviceTicketReminder?.owner?.id === user?.data?.organization?.id
      ),
    [serviceTicketReminder, user]
  );

  const canUncomplete = useMemo(
    () =>
      Boolean(
        serviceTicketReminder?.isCompleted &&
          serviceTicketReminder?.serviceTicket?.status.enum === 'OPEN' &&
          serviceTicketReminder?.owner?.id === user?.data?.organization?.id
      ),
    [serviceTicketReminder, user]
  );

  useEffect(() => {
    if (params?.viewId) {
      setFetched(false);
      fetchServiceTicketReminderViewDrawerData({ variables: { where: { id: params?.viewId } } });
    }
  }, [fetchServiceTicketReminderViewDrawerData, params?.viewId]);

  const handleCloseActionMenu = () => {
    setActionMenuAnchorEl(null);
  };

  const handleOpenActionMenu = (event) => {
    setActionMenuAnchorEl(event.currentTarget);
  };

  return (
    <div className="flex-1" style={{ width: contentWidth }}>
      <AppBar color="inherit" elevation={0} position="static">
        <Toolbar>
          <div className="flex flex-1">
            <Typography component="div" noWrap variant="h6">
              Reminder Details
            </Typography>
          </div>

          <IconButton sx={{ width: 40, height: 40 }} onClick={handleOpenActionMenu}>
            <FontAwesomeIcon icon={faEllipsisV} size="xs" />
          </IconButton>

          <Menu
            anchorEl={actionMenuAnchorEl}
            id="action-menu"
            open={!!actionMenuAnchorEl}
            onClose={handleCloseActionMenu}
          >
            <MenuItem
              disabled={!canEdit}
              onClick={() => {
                handleCloseActionMenu();

                dispatch(
                  openDialog({
                    children: (
                      <ServiceTicketReminderUpdateDialog
                        serviceTicketId={params?.serviceTicketId}
                        serviceTicketReminderId={params?.viewId}
                        onClose={() => dispatch(closeDialog())}
                      />
                    ),
                    classes: {
                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                    },
                  })
                );
              }}
            >
              Edit Reminder
            </MenuItem>

            <MenuItem
              disabled={!canDelete}
              onClick={() => {
                handleCloseActionMenu();

                dispatch(
                  openDialog({
                    children: (
                      <ServiceTicketReminderDeleteDialog
                        serviceTicketId={params?.serviceTicketId}
                        serviceTicketReminderId={params?.viewId}
                        onClose={() => dispatch(closeDialog())}
                        onSuccess={onCloseDrawer}
                      />
                    ),
                    classes: {
                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                    },
                  })
                );
              }}
            >
              Delete Reminder
            </MenuItem>
          </Menu>

          <IconButton sx={{ width: 40, height: 40 }} onClick={onCloseDrawer}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Paper
        className="flex flex-col p-24 overflow-auto border-t-1"
        elevation={0}
        square
        style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
      >
        {(loading && !fetched) || !serviceTicketReminder ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <div>
                  <Typography className="text-10">Name</Typography>
                  <Typography>{serviceTicketReminder.name}</Typography>
                </div>
              </Grid>

              <Grid xs={6}>
                <div>
                  <Typography className="text-10">Priority</Typography>
                  <Typography>{serviceTicketReminder?.priority?.name || 'Not Set'}</Typography>
                </div>
              </Grid>

              <Grid xs={6}>
                <div>
                  <Typography className="text-10">Due Date/Time</Typography>
                  <Typography>
                    {serviceTicketReminder.dueAt
                      ? moment(serviceTicketReminder.dueAt).format('MMM. DD, YYYY')
                      : 'Not Set'}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Divider className="my-16" />

            <Grid container spacing={2}>
              <Grid xs={12}>
                <div>
                  <Typography className="text-10">Notes</Typography>
                  <Typography>{serviceTicketReminder.notes || 'Not Set'}</Typography>
                </div>
              </Grid>
            </Grid>

            <Divider className="my-16" />

            <Grid container spacing={2}>
              {serviceTicketReminder.isCompleted && (
                <>
                  <Grid xs={6}>
                    <div>
                      <Typography className="text-10">Completed By</Typography>
                      <Typography>{serviceTicketReminder.completor?.name}</Typography>
                    </div>
                  </Grid>

                  <Grid xs={6}>
                    <div>
                      <Typography className="text-10">Completed At</Typography>
                      <Typography>
                        {moment(serviceTicketReminder.completedAt).format(
                          'MMM. DD, YYYY @ hh:mm A'
                        )}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid xs={12}>
                    <div className="mt-24">
                      <Button
                        disabled={!canUncomplete || loading}
                        fullWidth
                        variant="outlined"
                        onClick={uncompleteServiceTicketReminder}
                      >
                        Mark As Uncomplete
                      </Button>
                    </div>
                  </Grid>
                </>
              )}

              {!serviceTicketReminder.isCompleted && (
                <>
                  <Grid xs={12}>
                    <div className="mt-24">
                      <Button
                        disabled={!canComplete || loading}
                        fullWidth
                        variant="outlined"
                        onClick={completeServiceTicketReminder}
                      >
                        Mark As Complete
                      </Button>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </Paper>
    </div>
  );
};

export default ServiceTicketReminderViewDrawer;
