import { Button, Typography } from '@mui/material';
import { WorkOrderCommentsUpdateDialog } from 'app/shared-components/WorkOrder';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const WorkOrderViewAppComments = ({ serviceTicketId, workOrder }) => {
  const dispatch = useDispatch();

  const content = useMemo(() => {
    return (
      <div className="flex flex-row my-16 px-24">
        <div className="flex flex-1 flex-col">
          <Typography className="mb-1 text-12 text-grey-700 font-600 uppercase">
            Comments
          </Typography>

          {/* ROADMAP: Conflicts with Cache? */}
          <Typography>{workOrder?.comments || 'Not Set'}</Typography>

          <div className="flex items-center mt-16">
            <Button
              className="text-12 mr-3"
              // ROADMAP: Lock to Vendor
              disabled={false}
              size="small"
              variant="outlined"
              onClick={() =>
                dispatch(
                  openDialog({
                    children: (
                      <WorkOrderCommentsUpdateDialog
                        serviceTicketId={serviceTicketId}
                        workOrderId={workOrder.id}
                        onClose={() => dispatch(closeDialog())}
                      />
                    ),
                    classes: {
                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                    },
                  })
                )
              }
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
    )
  }, [dispatch, serviceTicketId, workOrder?.comments, workOrder.id]);

  return content;
};

export default WorkOrderViewAppComments;
