import { memo } from 'react';
import QuickPanel from '../../shared-components/quickPanel/QuickPanel';
// TODO:
// import { ActivityStreamPanel } from '../../shared-components/ActivityStreamPanel';

function RightSideLayout2() {
  return (
    <>
      <QuickPanel />

      {/* <NotificationPanel /> */}
    </>
  );
}

export default memo(RightSideLayout2);
