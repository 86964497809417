import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import {
  EntitySearchApp,
  EntitySearchBox,
  EntitySearchInfiniteHitsGrid,
} from 'app/shared-components/EntitySearch';
import * as changeCase from 'change-case';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Configure } from 'react-instantsearch';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';

// TODO: Extract and Complete
export const serviceIssueSearchScreenColumnDefs = [
  {
    headerName: 'Service Issue',
    children: [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
    ],
  },
];

const SegmentPreviewApp = ({ basePath }) => {
  const [indexSelectMenuAnchorEl, setIndexSelectMenuAnchorEl] = useState();
  const navigate = useNavigate();
  // const [previewIndexName, setPreviewIndexName] = useState(null);
  // const [previewMeilisearchFilter, setPreviewMeilisearchFilter] = useState(null);

  const [previewRename, setPreviewRename] = useState(null);

  const commonTargetTypeFieldName = useMemo(() => `commonTargetType`, []);
  const segmentFacilityAreaExpressionCalculationFieldName = useMemo(
    () => 'segmentFacilityAreaExpressionCalculation',
    []
  );
  const segmentFacilityExpressionCalculationFieldName = useMemo(
    () => 'segmentFacilityExpressionCalculation',
    []
  );

  const watchCommonTargetTypeField = useWatch({ name: commonTargetTypeFieldName });
  const watchSegmentFacilityAreaExpressionCalculationField = useWatch({
    name: segmentFacilityAreaExpressionCalculationFieldName,
  });
  const watchSegmentFacilityExpressionCalculationField = useWatch({
    name: segmentFacilityExpressionCalculationFieldName,
  });

  useEffect(() => {
    if (watchCommonTargetTypeField?.result?.enum === 'EQUIPMENT' && 1 === 2) {
      // TODO:
    } else if (
      watchCommonTargetTypeField?.result?.enum === 'FACILITY_AREA' &&
      !_.isEmpty(watchSegmentFacilityAreaExpressionCalculationField?.meilisearchFilter)
    ) {
      setPreviewRename({
        indexName: 'facility_areas',
        meilisearchFilter: watchSegmentFacilityAreaExpressionCalculationField?.meilisearchFilter,
      });
      // setPreviewIndexName('facility_areas');
      // setPreviewMeilisearchFilter(
      //   watchSegmentFacilityAreaExpressionCalculationField?.meilisearchFilter
      // );
    } else if (!_.isEmpty(watchSegmentFacilityExpressionCalculationField?.meilisearchFilter)) {
      setPreviewRename({
        indexName: 'facilities',
        meilisearchFilter: watchSegmentFacilityExpressionCalculationField?.meilisearchFilter,
      });
      // setPreviewIndexName('facilities');
      // setPreviewMeilisearchFilter(
      //   watchSegmentFacilityExpressionCalculationField?.meilisearchFilter
      // );
    } else {
      // setPreviewIndexName(null);
      // setPreviewMeilisearchFilter(null);

      navigate('../tabs/definition');
    }
  }, [
    basePath,
    navigate,
    watchCommonTargetTypeField,
    watchSegmentFacilityAreaExpressionCalculationField,
    watchSegmentFacilityExpressionCalculationField,
  ]);

  const handleCloseIndexSelectMenu = () => {
    setIndexSelectMenuAnchorEl(null);
  };

  const handleOpenIndexSelectMenu = (event) => {
    setIndexSelectMenuAnchorEl(event.currentTarget);
  };

  const handleChangeIndexSelectMenu = ({ indexName, meilisearchFilter }) => {
    handleCloseIndexSelectMenu();

    setPreviewRename({ indexName, meilisearchFilter });
    // setPreviewMeilisearchFilter(meilisearchFilter);
    // setPreviewIndexName(indexName);
  };

  if (!previewRename?.indexName) return null;

  return (
    <EntitySearchApp filters={previewRename?.meilisearchFilter} indexName={previewRename?.indexName}>
      {/* <Configure filters={previewRename?.meilisearchFilter} /> */}

      <div className="flex flex-col w-full h-full">
        <div className="w-full" style={{ backgroundColor: '#111827' }}>
          <div className="flex flex-col w-full max-w-5xl mx-auto p-16 sm:p-24">
            <div className="flex flex-row w-full">
              <div className="flex flex-1 items-center">
                <IconButton className="mr-6" onClick={handleOpenIndexSelectMenu}>
                  <FontAwesomeIcon icon={faChevronCircleDown} />
                </IconButton>

                <Menu
                  anchorEl={indexSelectMenuAnchorEl}
                  id="index-select-menu"
                  open={!!indexSelectMenuAnchorEl}
                  onClose={handleCloseIndexSelectMenu}
                >
                  <MenuItem
                    selected={previewRename?.indexName === 'facilities'}
                    onClick={() =>
                      handleChangeIndexSelectMenu({
                        indexName: 'facilities',
                        meilisearchFilter:
                          watchSegmentFacilityExpressionCalculationField?.meilisearchFilter,
                      })
                    }
                  >
                    facilities
                  </MenuItem>

                  {/* TODO: */}
                  {watchCommonTargetTypeField?.result?.enum === 'EQUIPMENT' && (
                    <MenuItem selected={previewRename?.indexName === 'equipments'}>Equipment</MenuItem>
                  )}

                  {watchCommonTargetTypeField?.result?.enum === 'FACILITY_AREA' && (
                    <MenuItem
                      selected={previewRename?.indexName === 'facility_areas'}
                      onClick={() =>
                        handleChangeIndexSelectMenu({
                          indexName: 'facility_areas',
                          meilisearchFilter:
                            watchSegmentFacilityAreaExpressionCalculationField?.meilisearchFilter,
                        })
                      }
                    >
                      Facility Areas
                    </MenuItem>
                  )}
                </Menu>

                <Typography className="text-24 font-500">
                  {changeCase.capitalCase(previewRename?.indexName)}
                </Typography>
              </div>

              <div className="flex items-center">
                <EntitySearchBox />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-full overflow-scroll">
          <SizeMe
            // TODO: Review
            // refreshMode="debounce"
            monitorHeight
            monitorWidth={false}
          >
            {({ size }) => (
              <div className="flex flex-row w-full h-full">
                <EntitySearchInfiniteHitsGrid
                  // TODO:
                  columnDefs={serviceIssueSearchScreenColumnDefs}
                  indexName={previewRename?.indexName}
                  renameIdField="id"
                  // TODO:
                  // onRowClicked={(id) => id && navigate(`/service-issues/view/${id}`)}
                  {...size}
                />
              </div>
            )}
          </SizeMe>
        </div>
      </div>
    </EntitySearchApp>
  );
};

export default SegmentPreviewApp;
