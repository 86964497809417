import { gql, useLazyQuery, useMutation } from '@apollo/client';
// import FuseLoading from '@fuse/core/FuseLoading';
import { faCheck, faChevronCircleDown, faChevronCircleRight, faChevronCircleUp, faCircleXmark, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { useDeepCompareEffect } from '@fuse/hooks';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Alert, Button, Checkbox, Collapse, FormControlLabel, IconButton, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonSelect } from 'app/shared-components/Common';
import { CommonTargetTypeSelectField } from 'app/shared-components/CommonTargetType';
import { SegmentDefinitionViewForm, SegmentDefinitionViewHeader, SegmentPreviewApp, SegmentViewHeader, SegmentViewTabs } from 'app/shared-components/Segment';
import { SegmentFacilityConditionAttributeSelectField } from 'app/shared-components/SegmentFacilityConditionAttribute';
import { SegmentFilterOperatorSelectField } from 'app/shared-components/SegmentFilterOperator';
import { SegmentGroupingOperatorSelectField } from 'app/shared-components/SegmentGroupingOperator';
import * as changeCase from 'change-case';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import * as yup from 'yup';
import { components } from 'react-select';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { FETCH_SEGMENT_VIEW_SCREEN_DATA } from './queries';

// TODO: Implement
const schema = yup.object().shape({
  name: yup
    .string()
    .required('You must enter a product name')
    .min(5, 'The product name must be at least 5 characters'),
});

const SegmentViewScreen = () => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const params = useParams();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const segmentId = useMemo(() => params?.segmentId, [params?.segmentId]);

  const [
    fetchSegmentViewScreenData,
    {
      data: segmentViewScreenData,
      loading: segmentViewScreenLoading,
      refetch: segmentViewScreenRefetch,
    },
  ] = useLazyQuery(FETCH_SEGMENT_VIEW_SCREEN_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Segment Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [segmentViewScreenLoading].includes(true),
    [segmentViewScreenLoading]
  );

  const segment = useMemo(() => segmentViewScreenData?.segment, [segmentViewScreenData?.segment]);

  useEffect(() => {
    if (methods?.reset && segment) {
      const formData = {
        // TODO:
        commonTargetType: segment.commonTargetType
          ? {
              label: segment.commonTargetType?.name,
              value: segment.commonTargetType?.id,
              result: segment.commonTargetType,
            }
          : null,
        // TODO:
        isEditable: segment.isEditable,
        name: segment.name,
      };

      methods?.reset(formData);
    }
  }, [methods, segment]);

  useEffect(() => {
    if (segmentId) {
      fetchSegmentViewScreenData({
        variables: { where: { id: segmentId } },
      });
    }
  }, [fetchSegmentViewScreenData, segmentId]);

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 10000));

      // await createSegment({
      //   variables: {
      //     data: {
      //       commonSharingRule: {
      //         subjectTeams: data.commonSharingRule.subjectTeams?.map((subjectTeam) => ({
      //           id: subjectTeam?.value,
      //         })),
      //         subjectType: data.commonSharingRule.subjectType
      //           ? { id: data.commonSharingRule.subjectType?.value }
      //           : null,
      //         type: { id: data.commonSharingRule.type?.value },
      //       },
      //       commonTargetType: {
      //         id: data.commonTargetType?.value,
      //       },
      //       description: data.description,
      //       name: data.name,
      //       tags: data.tags?.map((tag) => ({
      //         name: tag.__isNew__ ? tag.value : tag.result?.name,
      //       })),
      //     },
      //   },
      // });
    } catch (err) {
      console.log({ err });
      //
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="w-full h-full"
        id="segment-update-form"
        name="segment-update-form"
        noValidate
        onSubmit={methods?.handleSubmit(onSubmit)}
      >
        <FusePageCarded
          header={
            <>
              {!fetched || loading ? (
                <FuseLoading />
              ) : (
                <div className="flex flex-col w-full">
                  {['tabs/definition', 'tabs/sharing-rule', 'tabs/preview'].includes(basePath) && (
                    <SegmentViewHeader basePath={basePath} />
                  )}

                  <SegmentViewTabs basePath={basePath} />

                  {['tabs/definition', 'tabs/sharing-rule'].includes(basePath) && (
                    <SegmentDefinitionViewHeader basePath={basePath} />
                  )}
                </div>
              )}
            </>
          }
          content={
            <>
              {!fetched || loading ? (
                <FuseLoading className="h-full" />
              ) : (
                <>
                  {basePath === 'tabs/definition' && (
                    <SegmentDefinitionViewForm basePath={basePath} />
                  )}

                  {/* TODO: */}
                  {basePath === 'tabs/sharing-rule' && <div>Sharing Rule</div>}

                  {/* TODO: */}
                  {basePath === 'tabs/preview' && <SegmentPreviewApp basePath={basePath} />}
                </>
              )}
            </>
          }
          scroll={isMobile ? 'normal' : 'content'}
        />
      </form>
    </FormProvider>
  );
};

export default SegmentViewScreen;
