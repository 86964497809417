import { forwardRef, useMemo } from 'react';
import _ from '@lodash';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import makeStyles from './makeStyles';

const CommonCreatableSelect = forwardRef(({ error, ...props }, rootRef) => {
  const mainTheme = useSelector(selectMainTheme);

  const helperText = useMemo(
    () => (_.isArray(error) ? error[0]?.message : error?.message),
    [error]
  );

  const styles = useMemo(
    () => makeStyles({ error, mode: mainTheme?.palette.mode }),
    [error, mainTheme?.palette.mode]
  );

  return (
    <div ref={rootRef}>
      <CreatableSelect
        menuPlacement="auto"
        menuPortalTarget={document.body}
        {...props}
        styles={styles}
      />

      {helperText && (
        <div className="mx-14 mt-3">
          <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
            {helperText}
          </Typography>
        </div>
      )}
    </div>
  );
});

export default CommonCreatableSelect;
