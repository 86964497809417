import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

const OrganizationHitsListOption = (props) => {
  const organization = props.data?.hit;

  const option = useMemo(() => {
    if (!organization) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          <Avatar src={null}>
            <FontAwesomeIcon icon={faCopy} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={<Typography variant="body2">{`${organization.name}`}</Typography>}
          secondary={
            <div className="flex flex-1">
              <div className="flex items-center">
                <Typography variant="body2">{organization.type?.name || 'No Name Set'}</Typography>
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }, [organization]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default OrganizationHitsListOption;
