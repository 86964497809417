import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PROGRAM_SELECTABLE_FACILITY_SELECT_FIELD_PROGRAM_SELECTABLE_FACILITY_FRAGMENT = gql`
  fragment ProgramSelectableFacilitySelectFieldProgramSelectableFacilityFragment on ProgramSelectableFacility {
    id
    facility {
      id
      franchiseNumber
      name
    }
    matchingFacilityAreas {
      id
      name
    }
    matchingServiceProvisions {
      id
      name
      organizationConnection {
        id
        vendor {
          id
          name
        }
      }
    }
  }
`;
