import { useLazyQuery } from '@apollo/client';
import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { ServiceTicketAttachmentCreateDialog } from 'app/shared-components/ServiceTicketAttachment/ServiceTicketAttachmentCreateDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_HEADER_DATA } from './queries';

const ServiceTicketAttachmentViewHeader = ({ basePath, onToggleLeftSidebar }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const [
    fetchServiceTicketAttachmentViewHeaderData,
    {
      data: serviceTicketAttachmentViewHeaderData,
      loading: serviceTicketAttachmentViewHeaderLoading,
      refetch: serviceTicketAttachmentViewHeaderRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_HEADER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceTicketAttachmentViewHeaderLoading].includes(true),
    [serviceTicketAttachmentViewHeaderLoading]
  );

  const serviceTicket = useMemo(
    () => serviceTicketAttachmentViewHeaderData?.serviceTicket,
    [serviceTicketAttachmentViewHeaderData?.serviceTicket]
  );

  useEffect(() => {
    if (params?.serviceTicketId) {
      fetchServiceTicketAttachmentViewHeaderData({
        variables: { where: { id: params?.serviceTicketId } },
      });
    }
  }, [fetchServiceTicketAttachmentViewHeaderData, params?.serviceTicketId]);

  return (
    <AppBar color="inherit" elevation={0} position="static">
      <Toolbar>
        <div className="flex flex-1 items-center">
          <Tooltip title="Toggle Menu">
            <IconButton
              color="default"
              edge="start"
              sx={{ mr: 2, width: 40, height: 40 }}
              onClick={onToggleLeftSidebar}
            >
              <FontAwesomeIcon icon={faBars} size="xs" />
            </IconButton>
          </Tooltip>

          <Typography component="div" noWrap variant="h6">
            File Attachments
          </Typography>
        </div>

        <div className="flex items-center">
          <IconButton
            color="default"
            disabled={loading || !['OPEN'].includes(serviceTicket?.status?.enum)}
            sx={{ width: 40, height: 40 }}
            onClick={() =>
              dispatch(
                openDialog({
                  children: (
                    <ServiceTicketAttachmentCreateDialog
                      serviceTicketId={params?.serviceTicketId}
                      onClose={() => dispatch(closeDialog())}
                    />
                  ),
                  classes: {
                    paper: 'w-full max-w-640 min-w-320 rounded-8',
                  },
                })
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} size="xs" />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default ServiceTicketAttachmentViewHeader;
