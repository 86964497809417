import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { memo } from 'react';

const ServiceProvisionViewDrawerListItem = memo(
  ({ count, disabled, icon, selected, title, onViewChange }) => {
    const handleViewChange = (evt) => {
      if (typeof onViewChange === 'function') {
        onViewChange(evt);
      }

      return null;
    };

    return (
      <ListItemButton
        className="mb-4 px-32"
        disabled={disabled}
        selected={selected}
        onClick={handleViewChange}
      >
        {icon && (
          <ListItemIcon className="min-w-32">
            <FontAwesomeIcon icon={icon} size="1x" />
          </ListItemIcon>
        )}

        <ListItemText primary={title} />

        {_.isNumber(count) && count > 0 && <div className="ml-12">{count}</div>}
      </ListItemButton>
    );
  }
);

export default ServiceProvisionViewDrawerListItem;
