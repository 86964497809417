import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_ISSUE_VIEW_HEADER_SERVICE_ISSUE_FRAGMENT = gql`
  fragment ServiceIssueViewHeaderServiceIssueFragment on ServiceIssue {
    id

    # ROADMAP: Expand?
    createdAt
    serviceResponseSeverity {
      id
      color
      commonTemporalDurationUnit {
        id
        enum
        name
      }
      name
      value
    }
    status {
      id
      enum
      name
    }
  }
`;
