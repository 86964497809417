import { useMutation } from '@apollo/client';
import { faFile, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Alert,
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonUppyDashboardModal } from 'app/shared-components/Common/CommonUppy';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { CREATE_SERVICE_ISSUE_ATTACHMENT } from './queries';

const defaultValues = {
  attachments: [],
};

const schema = yup.object().shape({
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .min(1, 'Add at least one Service Issue Attachment')
    .required(),
});

const ServiceIssueAttachmentCreateDialog = ({ serviceIssueId, onClose }) => {
  const dispatch = useDispatch();
  const [dashboardModalOpen, setDashboardModalOpen] = useState(false);
  const fieldCount = useRef(0);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { fields, append, prepend, remove, swap, move, insert, replace, update } = useFieldArray({
    control,
    name: 'attachments',
  });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors('attachments');
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  const [createServiceIssueAttachment, { loading: createServiceIssueAttachmentLoading }] =
    useMutation(CREATE_SERVICE_ISSUE_ATTACHMENT, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Issue Attachment(s) Successfully Created',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Creating Service Issue Attachment(s)', variant: 'error' })
        );
      },
    });

  const loading = useMemo(
    () => [createServiceIssueAttachmentLoading].includes(true),
    [createServiceIssueAttachmentLoading]
  );

  const onSubmit = async (data) => {
    try {
      await Promise.all(
        data?.attachments.map(({ commonStoredUpload }) =>
          createServiceIssueAttachment({
            variables: {
              where: { id: serviceIssueId },
              data: {
                commonStoredUpload: { key: commonStoredUpload.key },
              },
            },
          })
        )
      );
    } catch (err) {
      //
    }
  };

  const handleDashboardModalRequestClose = () => setDashboardModalOpen(false);

  const handleDashboardModalRequestOpen = () => setDashboardModalOpen(true);

  const handleDashboardModalUploadSuccess = ({ file, response }) => {
    append({
      file,
      commonStoredUpload: { key: _.last(response.uploadURL?.split('/')) },
    });
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Service Issue Attachment(s)
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{
            clearErrors,
            control,
            formState,
            handleSubmit,
            reset,
            setValue,
            watch,
            ...methods,
          }}
        >
          <form
            className="w-full pt-20"
            id="service-issue-attachment-create-form"
            name="service-issue-attachment-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            {errors?.attachments && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {errors?.attachments?.message || 'One or More Attachments Contain Errors'}
              </Alert>
            )}

            {_.isEmpty(fields) ? (
              <Alert severity="info" sx={{ mb: 3 }}>
                No Service Issue Attachments Added
              </Alert>
            ) : (
              <List disablePadding className="border-t-1">
                {fields.map((field, index) => (
                  <ListItem divider key={field.id}>
                    <ListItemAvatar>
                      <Avatar src={field.file.preview}>
                        <FontAwesomeIcon icon={faFile} />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      className="mr-12"
                      primary={field.file.name}
                      primaryTypographyProps={{ noWrap: true }}
                      // ROADMAP: Implement or Remove
                      // secondary={field.file.type}
                      // secondaryTypographyProps={{ noWrap: true }}
                    />

                    <ListItemSecondaryAction>
                      <IconButton sx={{ width: 40, height: 40 }} onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}

            <div className="flex w-full items-center justify-end my-24">
              <Button
                color="inherit"
                disabled={false}
                variant="outlined"
                onClick={handleDashboardModalRequestOpen}
              >
                Add Attachment
              </Button>
            </div>
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-issue-attachment-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>

      <CommonUppyDashboardModal
        dashboardNote="File Size: 20MB; File Types: JPEG/PNG, PDF, and MP4"
        open={dashboardModalOpen}
        uppyId="service-issue-attachment-create-uppy"
        uppyOptions={{
          restrictions: {
            allowedFileTypes: ['application/pdf', 'image/jpeg', 'image/png', 'video/mp4'],
            maxFileSize: 20 * 1000000,
          },
        }}
        onRequestClose={handleDashboardModalRequestClose}
        onUploadSuccess={handleDashboardModalUploadSuccess}
      />
    </>
  );
};

export default ServiceIssueAttachmentCreateDialog;
