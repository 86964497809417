import { faMagnifyingGlass, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import _ from '@lodash';
import {
  AppBar,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Paper,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useStreamActivityFeeds } from 'app/providers/stream/activity-feeds';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import withReducer from 'app/store/withReducer';
import clsx from 'clsx';
import moment from 'moment';
import { memo, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityStreamPanelListItem } from './components';
import reducer from './store';
import {
  closeActivityStreamPanel,
  selectActivityStreamPanelState,
  toggleActivityStreamPanel,
} from './store/stateSlice';
import { incrementUnseenCount, setUnseenCount } from './store/unseenSlice';

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.default,
    width: 320,
  },
}));

const ActivityStreamPanel = () => {
  const [activities, setActivities] = useState(null);
  const { activityFeedsToken, streamActivityFeedsClient } = useStreamActivityFeeds();
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(false);
  const [limit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const scrollableTarget = useRef(null);
  const state = useSelector(selectActivityStreamPanelState);
  const [subscribed, setSubscribed] = useState(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (
      activityFeedsToken &&
      dispatch &&
      _.isNumber(limit) &&
      _.isNumber(offset) &&
      _.isBoolean(state) &&
      streamActivityFeedsClient &&
      user?.id
    ) {
      if (!state && offset !== 0) {
        setOffset(0);
      } else {
        setLoading(true);

        streamActivityFeedsClient
          .feed('notification', user?.id, activityFeedsToken)
          .get({ offset, limit, mark_seen: state })
          .then((data) => {
            if (state) {
              setActivities((_activities) =>
                _.orderBy(
                  _.uniqBy(
                    [
                      ...(_activities || []),
                      ...(_.flatten(
                        data?.results?.map((activityGroup) => activityGroup.activities?.[0])
                      ) || []),
                    ],
                    'id'
                  ),
                  ({ time }) => moment(time).unix(),
                  'desc'
                )
              );

              setHasMore(!!data?.next);
            } else {
              dispatch(setUnseenCount(data?.unseen));

              setActivities(
                _.flatten(data?.results?.map((activityGroup) => activityGroup.activities?.[0]))
              );

              setHasMore(false);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [activityFeedsToken, dispatch, limit, offset, state, streamActivityFeedsClient, user?.id]);

  useEffect(() => {
    let subscription;

    if (
      activityFeedsToken &&
      dispatch &&
      _.isBoolean(state) &&
      streamActivityFeedsClient &&
      user?.id
    ) {
      subscription = streamActivityFeedsClient
        .feed('notification', user?.id, activityFeedsToken)
        .subscribe((data) => {
          if (state) {
            setActivities((_activities) =>
              _.orderBy(
                _.uniqBy([...(data?.new || []), ...(_activities || [])], 'id'),
                ({ time }) => moment(time).unix(),
                'desc'
              )
            );

            streamActivityFeedsClient
              .feed('notification', user?.id, activityFeedsToken)
              .get({ mark_seen: true });
          } else {
            dispatch(incrementUnseenCount());
          }
        });

      subscription.then(
        () => {
          setSubscribed(true);
        },
        (err) => {
          setSubscribed(false);

          dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
        }
      );
    }

    return () => {
      subscription?.cancel();
    };
  }, [activityFeedsToken, dispatch, state, streamActivityFeedsClient, user?.id]);

  const handleCloseActivityStreamPanel = () => {
    dispatch(closeActivityStreamPanel());
  };

  const handleFetchMore = () => setOffset((current) => current + limit);

  return (
    <StyledSwipeableDrawer
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleActivityStreamPanel())}
      disableSwipeToOpen
    >
      <AppBar
        className="border-l border-b shadow-md"
        color="default"
        position="static"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? '#ffffff' : '#151c2b'),
        }}
      >
        <Toolbar className="px-4">
          <div
            className="flex flex-1 items-center space-x-12"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <IconButton
              className=""
              color="inherit"
              size="medium"
              sx={{
                width: 40,
                height: 40,
                border: (theme) =>
                  `2px solid ${theme.palette.mode === 'light' ? '#4c525c' : '#b4b7bb'}`,
              }}
              onClick={(ev) => dispatch(toggleActivityStreamPanel())}
            >
              <FuseSvgIcon size={24}>heroicons-outline:bell</FuseSvgIcon>
            </IconButton>

            <Typography className="ml-16 text-16" color="inherit">
              Activity Stream
            </Typography>
          </div>

          <div className="flex items-center px-4 z-20">
            <Tooltip title={subscribed ? 'Subscribed' : 'Unsubscribed'}>
              <div className="flex items-center justify-center w-48 h-48">
                <FontAwesomeIcon
                  className={clsx({ 'text-green-600': subscribed, 'text-red-600': !subscribed })}
                  icon={faSync}
                  size="xl"
                />
              </div>
            </Tooltip>

            <Divider className="mx-4 my-6" flexItem orientation="vertical" />

            <IconButton onClick={handleCloseActivityStreamPanel} color="inherit" size="large">
              <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Paper sx={{ display: 'flex', flexGrow: 1, zIndex: 10, borderRadius: 0, overflow: 'hidden' }}>
        <List
          disablePadding
          id="activity-stream-panel-list"
          ref={scrollableTarget}
          sx={{ flexGrow: 1, height: '100%', overflow: 'auto' }}
        >
          {!activities || !activities.length ? (
            <div className="flex w-full h-full">
              <div className="flex flex-1 flex-col items-center justify-center p-24">
                <FontAwesomeIcon className="text-grey-400" icon={faMagnifyingGlass} size="10x" />
                <Typography className="mt-24 text-15 text-grey-600 font-600">
                  No Results Found
                </Typography>
              </div>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={activities?.length}
              endMessage={
                <ListItem className="flex flex-row items-center p-16">
                  <div className="flex flex-1">End of Results</div>
                  <div className="flex">Fetched: {activities?.length}</div>
                </ListItem>
              }
              hasMore={hasMore}
              loader={
                <ListItem className="flex flex-col px-16 py-32">
                  <LinearProgress className="w-full" color="secondary" />
                </ListItem>
              }
              next={handleFetchMore}
              scrollableTarget="activity-stream-panel-list"
            >
              {(activities || []).map((activity) => (
                <ActivityStreamPanelListItem activity={activity} key={activity.id} />
              ))}
            </InfiniteScroll>
          )}
        </List>
      </Paper>
    </StyledSwipeableDrawer>
  );
};

export default withReducer('activityStreamPanel', reducer)(memo(ActivityStreamPanel));
