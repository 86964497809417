import { forwardRef, useMemo, useRef } from 'react';
import _ from '@lodash';
import debounce from 'debounce-promise';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import makeStyles from './makeStyles';

const CommonAsyncCreatableSelect = forwardRef(
  ({ error, loadOptions: _loadOptions, ...props }, rootRef) => {
    const mainTheme = useSelector(selectMainTheme);
    const selectRef = useRef(null);

    const helperText = useMemo(
      () => (_.isArray(error) ? error[0]?.message : error?.message),
      [error]
    );

    const loadOptions = useMemo(() => debounce(_loadOptions, 300), [_loadOptions]);

    const styles = useMemo(
      () => makeStyles({ error, mode: mainTheme?.palette.mode }),
      [error, mainTheme?.palette.mode]
    );

    return (
      <div ref={rootRef}>
        <AsyncCreatableSelect
          cacheOptions
          defaultOptions
          menuPlacement="auto"
          menuPortalTarget={document.body}
          {...props}
          loadOptions={loadOptions}
          ref={selectRef}
          styles={styles}
        />

        {helperText && (
          <div className="mx-14 mt-3">
            <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
              {helperText}
            </Typography>
          </div>
        )}
      </div>
    );
  }
);

export default CommonAsyncCreatableSelect;
