import { gql } from '@apollo/client';
import {
  CHAT_CHANNEL_CREATE_DIALOG_CHAT_CHANNEL_FRAGMENT,
  CHAT_CHANNEL_CREATE_DIALOG_CHAT_CHANNEL_TYPE_FRAGMENT,
} from './fragments';

export const CREATE_CHAT_CHANNEL = gql`
  mutation CreateChatChannel($data: ChatChannelCreateInput!) {
    createChatChannel(data: $data) {
      id
      ...ChatChannelCreateDialogChatChannelFragment
    }
  }
  ${CHAT_CHANNEL_CREATE_DIALOG_CHAT_CHANNEL_FRAGMENT}
`;

export const FETCH_CHAT_CHANNEL_CREATE_DIALOG_DATA = gql`
  query FetchChatChannelCreateDialogData($chatChannelTypeWhere: ChatChannelTypeWhereInput!) {
    chatChannelTypes(where: $chatChannelTypeWhere) {
      id
      ...ChatChannelCreateDialogChatChannelTypeFragment
    }
  }
  ${CHAT_CHANNEL_CREATE_DIALOG_CHAT_CHANNEL_TYPE_FRAGMENT}
`;
