import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonCurrencyField, CommonSelect } from 'app/shared-components/Common';
import { CommonTargetTypeSelectField } from 'app/shared-components/CommonTargetType';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { FacilityHitsListOption } from 'app/shared-components/Facility';
import { ServiceIssueTagSelectField } from 'app/shared-components/ServiceIssueTag';
import { ServiceResponseSeveritySelectField } from 'app/shared-components/ServiceResponseSeverity';
import { ServiceResponsiblePartySelectField } from 'app/shared-components/ServiceResponsibleParty';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ServiceIssueCreateDialogServiceIssueAttachments } from './components';
import { CREATE_SERVICE_ISSUE } from './queries';

const defaultValues = {
  attachments: [],
  commonTargetType: null,
  description: '',
  estimatedAmount: null,
  facility: null,
  name: '',
  plannedForYear: null,
  serviceResponseSeverity: null,
  serviceResponsibleParty: null,
  tags: [],
  targetFacilityAreas: [],
};

const schema = yup.object().shape({
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  commonTargetType: yup.object().nullable().required('A target type must be provided.'),
  description: yup.string().nullable().optional(),
  estimatedAmount: yup
    .number()
    .nullable()
    .min(0.01, 'A value greater than zero must be provided.')
    .optional(),
  facility: yup.object().nullable().required('A facility must be provided.'),
  name: yup.string().nullable().required('A name must be provided.'),
  plannedForYear: yup.object().nullable().optional(),
  serviceResponseSeverity: yup
    .object()
    .nullable()
    .required('A response severity must be provided.'),
  serviceResponsibleParty: yup.object().nullable().optional(),
  tags: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          __isNew__: yup.boolean().optional(),
          label: yup.string().required(),
          result: yup.object().optional(),
          value: yup.string().required(),
        })
        .required()
    )
    .optional(),
  targetFacilityAreas: yup.mixed().when('commonTargetType', {
    is: (val) => val?.result?.enum === 'FACILITY_AREA',
    then: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            hit: yup.object().optional(),
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .required()
      )
      .min(1, 'Select at least one Target Facility Area')
      .required(),
  }),
});

const ServiceIssueCreateDialog = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createServiceIssue, { loading }] = useMutation(CREATE_SERVICE_ISSUE, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Service Issue Successfully Created',
          variant: 'success',
        })
      );

      navigate(`/service-issues/view/${data.createServiceIssue?.id}`);
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Creating Service Issue', variant: 'error' }));
    },
  });

  const onSubmit = async (data) => {
    try {
      await createServiceIssue({
        variables: {
          data: {
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            commonTargetType: {
              id: data.commonTargetType?.value,
            },
            description: data.description,
            estimatedAmount: data.estimatedAmount,
            facility: { id: data.facility?.value },
            name: data.name,
            plannedForYear: data.plannedForYear && parseInt(data.plannedForYear.value, 10),
            serviceResponseSeverity: { id: data.serviceResponseSeverity?.value },
            serviceResponsibleParty: data.serviceResponsibleParty && {
              id: data.serviceResponsibleParty.value,
            },
            tags: data.tags?.map((tag) => ({
              name: tag.__isNew__ ? tag.value : tag.result?.name,
            })),
            // ROADMAP: Implement Additional Targets
            // targetAsset: data.targetAsset && {
            //   id: data.targetAsset.value,
            // },
            targetFacilityAreas: data.targetFacilityAreas?.map((targetFacilityArea) => ({
              id: targetFacilityArea.value,
            })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Service Issue
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-issue-create-form"
            name="service-issue-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="facility"
              render={({ field }) => (
                <EntitySearchSelectField
                  {...field}
                  className="mb-24"
                  components={{ Option: FacilityHitsListOption }}
                  error={errors?.facility}
                  idField="id"
                  indexName="facilities"
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  nameField="name"
                  placeholder="Select Facility..."
                  sort={['name:asc']}
                  onChange={(params) => {
                    setValue('commonTargetType', null);
                    setValue('targetFacilityAreas', []);

                    field.onChange(params);
                  }}
                />
              )}
            />

            {watchFields?.facility && (
              <>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      error={errors?.name}
                      fullWidth
                      helperText={errors?.name?.message}
                      placeholder="Enter Name..."
                      required
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="commonTargetType"
                  render={({ field }) => (
                    <CommonTargetTypeSelectField
                      {...field}
                      className="mb-24"
                      error={errors?.commonTargetType}
                      filters={null}
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Target Type..."
                      onChange={(params) => {
                        setValue('targetFacilityAreas', []);

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                {watchFields?.commonTargetType?.result?.enum === 'FACILITY_AREA' && (
                  <Controller
                    control={control}
                    name="targetFacilityAreas"
                    render={({ field }) => (
                      <EntitySearchSelectField
                        {...field}
                        className="mb-24"
                        error={errors?.targetFacilityAreas}
                        filter={[`facility.id = '${watchFields?.facility?.value}'`]}
                        idField="id"
                        indexName="facility_areas"
                        isClearable
                        isDisabled={false}
                        isMulti
                        placeholder="Select Target Facility Areas..."
                        sort={['name:asc']}
                      />
                    )}
                  />
                )}

                {!_.isEmpty(watchFields?.targetFacilityAreas) && (
                  <>
                    <Controller
                      control={control}
                      name="serviceResponseSeverity"
                      render={({ field }) => (
                        <ServiceResponseSeveritySelectField
                          {...field}
                          className="mb-24"
                          error={errors?.serviceResponseSeverity}
                          filters={null}
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          placeholder="Select Response Severity..."
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          error={errors?.description}
                          fullWidth
                          helperText={errors?.description?.message}
                          maxRows={4}
                          minRows={4}
                          multiline
                          placeholder="Enter Description; Where / What..."
                          required
                          variant="outlined"
                        />
                      )}
                    />

                    {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum) && (
                      <Controller
                        control={control}
                        name="tags"
                        render={({ field }) => (
                          <ServiceIssueTagSelectField
                            {...field}
                            className="mb-24"
                            error={errors?.tags}
                            isClearable
                            isDisabled={false}
                            isMulti
                            placeholder="Select / Create Service Issue Tags..."
                          />
                        )}
                      />
                    )}

                    {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum) && (
                      <>
                        <Divider sx={{ mb: 3 }} />

                        <Controller
                          control={control}
                          name="serviceResponsibleParty"
                          render={({ field }) => (
                            <ServiceResponsiblePartySelectField
                              {...field}
                              className="mb-24"
                              error={errors?.serviceResponsibleParty}
                              filters={null}
                              isClearable
                              isDisabled={false}
                              isMulti={false}
                              placeholder="Select Responsible Party..."
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="plannedForYear"
                          render={({ field }) => (
                            <div className="mb-24">
                              <CommonSelect
                                {...field}
                                error={errors?.plannedForYear}
                                isClearable
                                isDisabled={false}
                                isMulti={false}
                                options={[...Array(10).keys()].map((offset) => {
                                  const year = moment().add(offset, 'years').format('YYYY');

                                  return {
                                    label: year,
                                    value: year,
                                  };
                                })}
                                placeholder="Select Planned For Year..."
                              />
                            </div>
                          )}
                        />

                        <Controller
                          control={control}
                          name="estimatedAmount"
                          render={({ field }) => (
                            <CommonCurrencyField
                              {...field}
                              className="mb-24"
                              control={control}
                              disabled={false}
                              error={errors?.estimatedAmount}
                              placeholder="Enter Estimated Amount..."
                            />
                          )}
                        />
                      </>
                    )}

                    <Divider sx={{ mb: 3 }} />

                    <ServiceIssueCreateDialogServiceIssueAttachments name="attachments" />
                  </>
                )}
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-issue-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceIssueCreateDialog;
