import { faSquareCaretDown, faSquareCaretUp } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleRight,
  faSpinner,
  faTimes,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  useClearRefinements,
  useCurrentRefinements,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch';

const formatRefinementListItemLabel = (label) => {
  let formattedLabel;

  if (['false', 'true'].includes(label)) {
    formattedLabel = label === 'true' ? 'Yes' : 'No';
  } else {
    formattedLabel = label;
  }

  return formattedLabel;
};

const ClearRefinementsButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
}));

const ContentRoot = styled('div')(({ theme }) => ({
  margin: '12px 0 12px 52px',
  padding: '12px 18px',
  borderStyle: 'dashed',
  borderColor: theme.palette.mode === 'dark' ? '#353e4f' : grey[600],
  borderLeftWidth: 1,
}));

const RefinementToggleButton = styled(Button)(({ theme }) => {
  const color = theme.palette.mode === 'dark' ? '#121923' : grey[200];
  const hover = theme.palette.mode === 'dark' ? '#0f151e' : grey[300];

  return {
    padding: 12,
    color: theme.palette.getContrastText(color),
    height: 48,
    minHeight: 48,
    maxHeight: 48,
    borderRadius: 0,
    backgroundColor: color,
    '&:hover': {
      backgroundColor: hover,
    },
  };
});

const EntitySearchRefinementList = memo(({ attribute, label, open, operator }) => {
  const { refine: clearRefinements } = useClearRefinements({ includedAttributes: [attribute] });
  const { items: currentRefinementItems } = useCurrentRefinements({
    includedAttributes: [attribute],
  });
  const [expanded, setExpanded] = useState(false);
  const searchTimer = useRef(null);
  const [searchText, setSearchText] = useState('');
  const { status } = useInstantSearch();
  const { canToggleShowMore, isShowingMore, items, refine, searchForItems, toggleShowMore } =
    useRefinementList({
      attribute,
      limit: 20,
      operator,
      showMore: true,
      showMoreLimit: 50,
      sortBy: ['isRefined:desc', 'count:desc', 'name:asc'],
    });

  const count = useMemo(
    () =>
      currentRefinementItems?.reduce(
        (acc, { refinements: _refinements }) => acc + _refinements?.length,
        0
      ),
    [currentRefinementItems]
  );

  const isSearching = useMemo(() => status === 'stalled', [status]);

  useEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      searchForItems(searchText);
    }, 300);

    return () => clearTimeout(searchTimer.current);
  }, [searchForItems, searchText]);

  const handleSearchTextChange = ({ target }) => setSearchText(target?.value || '');

  const handleToggleExpanded = () => setExpanded((prevExpanded) => !prevExpanded);

  if (!open) return null;

  return (
    <div>
      <RefinementToggleButton disableElevation fullWidth onClick={handleToggleExpanded}>
        <div className="flex pl-36 w-60">
          <FontAwesomeIcon icon={expanded ? faAngleDown : faAngleRight} size="1x" />
        </div>

        <div className="flex-1 pr-8 text-left truncate">{label}</div>

        {Boolean(count) && (
          <>
            <Tooltip title="Applied Filters">
              <Chip label={count} size="small" variant="outlined" />
            </Tooltip>

            <Tooltip title="Clear Filters">
              <IconButton
                className="ml-4"
                component="div"
                disabled={isSearching}
                size="small"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  clearRefinements();
                }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </RefinementToggleButton>

      {expanded && (
        <ContentRoot>
          <TextField
            className="mb-12"
            disabled={isSearching}
            fullWidth
            InputProps={{
              endAdornment: !_.isEmpty(searchText) && (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isSearching}
                    className="w-24 h-24"
                    size="small"
                    onClick={handleSearchTextChange}
                  >
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Search..."
            size="small"
            value={searchText}
            variant="outlined"
            onChange={handleSearchTextChange}
          />

          <div className="mb-24 h-200 overflow-auto">
            {isSearching ? (
              <div className="flex w-full h-200 items-center justify-center">
                <FontAwesomeIcon className="opacity-50" icon={faSpinner} spinPulse size="4x" />
              </div>
            ) : (
              <List className="py-0">
                {_.isEmpty(items) ? (
                  <div className="flex w-full h-200 items-center justify-center">
                    <Typography>No Results</Typography>
                  </div>
                ) : (
                  <>
                    {items?.map((item) => (
                      <ListItem disablePadding key={item.value}>
                        <ListItemButton
                          className="px-12 py-6"
                          dense
                          divider={false}
                          onClick={() => refine(item.value)}
                        >
                          <ListItemIcon className="pl-12 min-w-32">
                            <Checkbox
                              checked={item.isRefined}
                              className="p-0"
                              disableRipple
                              edge="start"
                              tabIndex={-1}
                            />
                          </ListItemIcon>

                          <ListItemText primary={formatRefinementListItemLabel(item.label)} />

                          <div className="ml-12">{item.count}</div>
                        </ListItemButton>
                      </ListItem>
                    ))}

                    {canToggleShowMore && (
                      <>
                        <Divider />
                        <ListItem disablePadding>
                          <ListItemButton
                            className="px-12 py-6"
                            dense
                            divider={false}
                            onClick={toggleShowMore}
                          >
                            <ListItemIcon className="min-w-32" style={{ paddingLeft: 2 }}>
                              <FontAwesomeIcon
                                icon={isShowingMore ? faSquareCaretUp : faSquareCaretDown}
                                size="xl"
                              />
                            </ListItemIcon>

                            <ListItemText primary={isShowingMore ? 'Show Less' : 'Show More'} />
                          </ListItemButton>
                        </ListItem>
                      </>
                    )}
                  </>
                )}
              </List>
            )}
          </div>

          <ClearRefinementsButton
            color="secondary"
            disabled={isSearching || !count}
            fullWidth
            size="small"
            variant="contained"
            onClick={clearRefinements}
          >
            Clear Filters
          </ClearRefinementsButton>
        </ContentRoot>
      )}
    </div>
  );
});

export default EntitySearchRefinementList;
