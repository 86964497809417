import { gql } from '@apollo/client';

export const SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_UPLOAD_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewDrawerCommonStoredUploadFragment on CommonStoredUpload {
    id
    uploader {
      id
      name
    }
  }
`;

export const SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_IMAGE_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewDrawerCommonStoredImageFragment on CommonStoredImage {
    id
    commonStoredUpload {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredUploadFragment
    }
    isProcessed
    lightboxImageUrl
    name
    standardImageUrl
    thumbnailImageUrl
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_UPLOAD_FRAGMENT}
`;

export const SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_PDF_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewDrawerCommonStoredPdfFragment on CommonStoredPdf {
    id
    commonStoredUpload {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredUploadFragment
    }
    isProcessed
    lightboxImageUrl
    name
    originalPdfUrl
    standardImageUrl
    thumbnailImageUrl
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_UPLOAD_FRAGMENT}
`;

export const SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_VIDEO_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewDrawerCommonStoredVideoFragment on CommonStoredVideo {
    id
    captionsFileUrl
    commonStoredUpload {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredUploadFragment
    }
    isProcessed
    lightboxImageUrl
    name
    playlistFileUrl
    standardImageUrl
    thumbnailImageUrl
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_UPLOAD_FRAGMENT}
`;

export const SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_CHAT_CHANNEL_ATTACHMENT_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewDrawerChatChannelAttachmentFragment on ChatChannelAttachment {
    id
    createdAt
    commonStoredImage {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredImageFragment
    }
    commonStoredType {
      id
      enum
      name
    }
    commonStoredPdf {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredPdfFragment
    }
    commonStoredVideo {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredVideoFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_IMAGE_FRAGMENT}
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_PDF_FRAGMENT}
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_VIDEO_FRAGMENT}
`;

export const SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_PROGRAM_ATTACHMENT_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewDrawerProgramAttachmentFragment on ProgramAttachment {
    id
    createdAt
    commonStoredImage {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredImageFragment
    }
    commonStoredType {
      id
      enum
      name
    }
    commonStoredPdf {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredPdfFragment
    }
    commonStoredVideo {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredVideoFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_IMAGE_FRAGMENT}
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_PDF_FRAGMENT}
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_VIDEO_FRAGMENT}
`;

export const SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_ISSUE_ATTACHMENT_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewDrawerServiceIssueAttachmentFragment on ServiceIssueAttachment {
    id
    createdAt
    commonStoredImage {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredImageFragment
    }
    commonStoredType {
      id
      enum
      name
    }
    commonStoredPdf {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredPdfFragment
    }
    commonStoredVideo {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredVideoFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_IMAGE_FRAGMENT}
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_PDF_FRAGMENT}
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_VIDEO_FRAGMENT}
`;

export const SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_TICKET_ATTACHMENT_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewDrawerServiceTicketAttachmentFragment on ServiceTicketAttachment {
    id
    createdAt
    commonStoredImage {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredImageFragment
    }
    commonStoredType {
      id
      enum
      name
    }
    commonStoredPdf {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredPdfFragment
    }
    commonStoredVideo {
      id
      ...ServiceTicketAttachmentViewDrawerCommonStoredVideoFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_IMAGE_FRAGMENT}
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_PDF_FRAGMENT}
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_COMMON_STORED_VIDEO_FRAGMENT}
`;
