import { useMemo, useState } from 'react';
import moment from 'moment';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import { Datepicker } from '@mobiscroll/js/mobiscroll.react.min';
import { Button, ListItemText, Menu, MenuItem, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  useClearRefinements,
  useCurrentRefinements,
  useInstantSearch,
  useMenu,
} from 'react-instantsearch';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { makeEntitySearchDateRangeMenuPresets } from './presets';

const DatepickerMenuButton = styled(Button)(({ theme }) => ({
  borderLeft: 0,
  borderRadius: 0,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  '&.Mui-disabled': {
    borderLeft: 0,
  },
}));

const PresetMenuButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
}));

const PopoverActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
}));

const EntitySearchDateRangeMenu = ({ attribute }) => {
  const [currentRefinement, setCurrentRefinement] = useState(null);
  const [datepickerMenuAnchorEl, setDatepickerMenuAnchorEl] = useState(null);
  const datepickerMenuOpen = Boolean(datepickerMenuAnchorEl);
  const [datepickerValue, setDatepickerValue] = useState(null);
  const dateRangeMenuPresets = makeEntitySearchDateRangeMenuPresets({ attribute });
  const mainTheme = useSelector(selectMainTheme);
  const [presetMenuAnchorEl, setPresetMenuAnchorEl] = useState(null);
  const presetMenuOpen = Boolean(presetMenuAnchorEl);
  const { status } = useInstantSearch();
  const { refine } = useMenu({ attribute, limit: 1 });
  const { refine: reset } = useClearRefinements({ includedAttributes: [attribute] });
  const { items: currentRefinementItems = [] } = useCurrentRefinements({
    includedAttributes: [attribute],
  });

  const loading = useMemo(() => status === 'stalled', [status]);

  useDeepCompareEffect(() => {
    const _currentRefinement = currentRefinementItems[0]?.refinements[0] || null;

    let _datepickerValue;

    if (_currentRefinement?.value.includes('dateRangePreset')) {
      const dateRangePreset = dateRangeMenuPresets.find(
        ({ value }) => _currentRefinement.value === value
      );

      _currentRefinement.label = dateRangePreset?.label;

      _datepickerValue = dateRangePreset?.getAdaptedValue('datepicker');
    } else if (_currentRefinement?.value.includes('dateRangeCustom')) {
      const [, dateRangeString] = _currentRefinement.value.split(':');
      const [start, end] = dateRangeString.split('|');

      _currentRefinement.label = 'Custom Range';
      _datepickerValue = [moment.unix(start).toDate(), moment.unix(end).toDate()];
    }

    setCurrentRefinement(_currentRefinement);
    setDatepickerValue(_datepickerValue);
  }, [currentRefinementItems]);

  const handleCloseDatepickerMenu = (event) => setDatepickerMenuAnchorEl(null);

  const handleClosePresetMenu = (event) => setPresetMenuAnchorEl(null);

  const handleOpenDatepickerMenu = (event) => setDatepickerMenuAnchorEl(event.currentTarget);

  const handleOpenPresetMenu = (event) => setPresetMenuAnchorEl(event.currentTarget);

  const handleDatepickerValueApply = () => {
    if (datepickerValue?.filter((el) => el).length === 2) {
      refine(
        `dateRangeCustom:${moment(datepickerValue[0]).unix()}|${moment(datepickerValue[1]).unix()}`
      );
    }
  };

  const handleDatepickerValueChange = (event) => {
    setDatepickerValue(event.value);
  };

  return (
    <div className="flex items-center">
      <PresetMenuButton
        disabled={loading}
        color="inherit"
        fullWidth
        variant="outlined"
        onClick={handleOpenPresetMenu}
      >
        <div className="w-full">
          <Typography noWrap>{currentRefinement?.label || 'All Date Ranges'}</Typography>
        </div>

        <FontAwesomeIcon icon={faAngleDown} size="1x" />
      </PresetMenuButton>

      <Menu
        anchorEl={presetMenuAnchorEl}
        id="preset-menu"
        open={presetMenuOpen}
        onClose={handleClosePresetMenu}
      >
        <MenuItem
          selected={!currentRefinement}
          onClick={() => {
            handleClosePresetMenu();

            reset();
          }}
        >
          <ListItemText>All Date Ranges</ListItemText>
        </MenuItem>

        {dateRangeMenuPresets.map(({ label, value }) => (
          <MenuItem
            key={value}
            selected={currentRefinement?.value === value}
            onClick={() => {
              handleClosePresetMenu();

              refine(value);
            }}
          >
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      <DatepickerMenuButton
        disabled={loading}
        color="inherit"
        variant="outlined"
        onClick={handleOpenDatepickerMenu}
      >
        <FontAwesomeIcon icon={faCalendar} size="1x" />
      </DatepickerMenuButton>

      <Popover
        anchorEl={datepickerMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={datepickerMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleCloseDatepickerMenu}
      >
        <Datepicker
          controls={['calendar', 'time']}
          display="inline"
          select="range"
          touchUi
          themeVariant={mainTheme?.palette.mode}
          value={datepickerValue}
          onChange={handleDatepickerValueChange}
        />

        <div
          className="flex items-center justify-between p-12"
          style={{ backgroundColor: mainTheme?.palette.mode === 'dark' ? '#1c1c1c' : '#ffffff' }}
        >
          <PopoverActionButton onClick={handleCloseDatepickerMenu}>Cancel</PopoverActionButton>

          <PopoverActionButton
            disabled={datepickerValue?.filter((el) => el).length !== 2}
            onClick={() => {
              handleCloseDatepickerMenu();

              handleDatepickerValueApply();
            }}
          >
            Apply
          </PopoverActionButton>
        </div>
      </Popover>
    </div>
  );
};

export default EntitySearchDateRangeMenu;
