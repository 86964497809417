import { memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import objectHash from 'object-hash';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { blue, grey, purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { EntitySearchDateRangeMenu } from 'app/shared-components/EntitySearch/EntitySearchDateRangeMenu';
import { EntitySearchRefinementSection } from 'app/shared-components/EntitySearch/EntitySearchRefinementSection';

import moment from 'moment';
import { useMeilisearch } from 'app/providers/meilisearch';
import { useInstantSearch, useRefinementList, useRange, useStats, useCurrentRefinements, useClearRefinements, useHits } from 'react-instantsearch';
import {
  CalendarNav,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  Datepicker,
} from '@mobiscroll/js/mobiscroll.react.min';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { selectCalendarAppScreenDates, setDatesDrawerDateRange, setDatesSelectedDate } from 'app/screens/CalendarAppScreen/store/datesSlice';
import { useDispatch, useSelector } from 'react-redux';

// TODO: Review
const drawerAppBarHeight = 412;
const DrawerAppBar = styled(AppBar)(({ theme }) => ({
  height: drawerAppBarHeight,
  borderStyle: 'solid',
  borderColor: theme.palette.mode === 'dark' ? '#353e4f' : grey[400],
  borderBottomWidth: 1,
}));

const EntitySearchCalendarDrawer = memo(
  ({ dateRangeAttribute, filterDefs, title, toggleDrawer, width, setCalendarDates }) => {
    console.log('// FIXME: RENDER => EntitySearchCalendarDrawer');
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
    const viewportOverflow = useMemo(() => drawerAppBarHeight + (isMobile ? 0 : 64), [isMobile]);

    const dates = useSelector(selectCalendarAppScreenDates);
    const { hits } = useHits();

    // marked={[
    //   {
    //       date: '2024-06-02T00:00',
    //       color: '#46c4f3',

    const calendarHeader = () => {
      return (
        <>
          <div className="flex w-full items-center">
            <div className="flex-1">
              <CalendarNav />
            </div>

            <CalendarPrev />
            <CalendarToday />
            <CalendarNext />
          </div>
        </>
      );
    };

    // TODO: Rename?
    const markedEvents = useMemo(() => {
      console.log('// MARKED_EVENTS', { hits });

      const _markedEvents = hits?.map((hit) => {
        let color;

        if (hit.__typename === 'ServiceVisit') {
          // eslint-disable-next-line prefer-destructuring
          color = blue[600];
        } else if (hit.__typename === 'SiteVisit') {
          // eslint-disable-next-line prefer-destructuring
          color = purple[600];
        } else {
          return null;
        }

        return {
          color,
          date: moment(hit.windowStartAt).format(),
        };
      });

      return _markedEvents || [];
    }, [hits]);

    // const { refine: reset } = useClearRefinements({ includedAttributes: ['windowStartAtTimestamp'] });
    // const {
    //   // TODO:
    //   // items: refinementListItems,
    //   canToggleShowMore,
    //   isFromSearch,
    //   isShowingMore,
    //   toggleShowMore,
    //   items,
    //   refine,
    //   searchForItems,
    // } = useRefinementList({
    //   attribute: 'windowStartAtTimestamp',
    //   operator: 'or',
    //   // TODO:
    //   // limit: 20,
    //   // showMore: true,
    //   // TODO:
    //   // showMoreLimit: 50,
    //   // TODO:
    //   // sortBy: ['isRefined:desc', 'count:desc', 'name:asc'],
    // });

    // const {
    //   start,
    //   range,
    //   canRefine,
    //   refine,
    // } = useRange({
    //   attribute: 'createdAtTimestamp',
    //   // TODO: Limit Calendar to Match
    //   max: moment().add(5, 'years').unix(),
    //   min: moment().subtract(5, 'years').unix(),
    // });
    // const { items: currentRefinementItems } = useCurrentRefinements();

    // console.log({ range, currentRefinementItems });

    const mainTheme = useSelector(selectMainTheme);
    const dispatch = useDispatch();

    // const { axiosSearchClient } = useMeilisearch();
    // const indexName = 'AssetFacetData';
    // TODO:
    // const { status } = useInstantSearch();
    // const { nbHits, page, processingTimeMS, query, ...bob } = useStats();

    const timer = useRef(null);
    const handlePageLoading = useCallback(
      (args) => {
        console.log('// LOADING 01');

        if (timer.current) {
          clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
          console.log('// LOADING 02');
          // dispatch(
          //   setDatesDrawerDateRange({
          //     firstDay: moment(args?.firstDay).format(),
          //     lastDay: moment(args?.lastDay).format(),
          //   })
          // );

          if (args?.firstDay && args?.lastDay) {
            console.log('// LOADING 03');
            // refine([moment(args?.firstDay).unix(), moment(args?.lastDay).unix()]);
            // reset();
            // refine(
            //   `dateRangeCustom:${moment(args?.firstDay).unix()}|${moment(args?.lastDay).unix()}`
            // );
            dispatch(
              setDatesDrawerDateRange({
                firstDay: args?.firstDay,
                lastDay: args?.lastDay,
              })
            );
          }
        }, 200);

        // dispatch(
        //   setDatesDrawerDateRange({
        // setCalendarDates({
        //   firstDay: moment(args?.firstDay).format(),
        //   lastDay: moment(args?.lastDay).format(),
        // });
        // );
      },
      // [setCalendarDates]
      // [dispatch]
      // [refine]
      [dispatch]
    );
    const handleDateChange = useCallback(
      (args) => {
        dispatch(setDatesSelectedDate(args.value));
      },
      [dispatch]
    );

    // useEffect(() => {
    //   console.log('// FIXME: isLastPage', { nbHits, page });
    //   // TODO: This is Dumb...
    //   if (nbHits === 1000 && page === 4) {
    //     console.log('// FIXME: isLastPage', {});
    //   }
    // }, [nbHits, page]);

    return (
      <div className="flex-1" style={{ width }}>
        <DrawerAppBar color="inherit" elevation={0} position="static">
          <Toolbar disableGutters>
            <div className="flex flex-col w-full">
              <div className="flex items-center h-64 px-24 py-16">
                <div className="flex-1">
                  <Typography component="div" noWrap variant="h6">
                    {title}
                  </Typography>
                </div>

                {isMobile && toggleDrawer && (
                  <IconButton className="w-32 h-32" onClick={toggleDrawer}>
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                  </IconButton>
                )}
              </div>

              <Datepicker
                display="inline"
                marked={markedEvents}
                renderCalendarHeader={calendarHeader}
                themeVariant={mainTheme?.palette.mode}
                value={dates?.selectedDate}
                onChange={handleDateChange}
                onPageLoading={handlePageLoading}
              />
            </div>
          </Toolbar>
        </DrawerAppBar>

        <div
          className="flex flex-col overflow-auto"
          style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
        >
          {filterDefs?.map((filterDef) => (
            <EntitySearchRefinementSection key={objectHash(filterDef)} {...filterDef} />
          ))}
        </div>
      </div>
    );
  }
);

export default EntitySearchCalendarDrawer;
