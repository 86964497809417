import { gql, useApolloClient } from '@apollo/client';
import {
  faChevronCircleDown,
  faChevronCircleRight,
  faCircleXmark,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Collapse, IconButton, Typography } from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { EntitySearchFacetSelectField } from 'app/shared-components/EntitySearch';
import { SegmentFacilityConditionAttributeSelectField } from 'app/shared-components/SegmentFacilityConditionAttribute';
import { SegmentFilterOperatorSelectField } from 'app/shared-components/SegmentFilterOperator';
import { SegmentGroupingOperatorSelectField } from 'app/shared-components/SegmentGroupingOperator';
import { showMessage } from 'app/store/fuse/messageSlice';
import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const SegmentDefinitionViewFormFacilityConditionsItem = ({
  className,
  fieldName,
  index,
  item,
  onRemove,
}) => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const [facilityTotalHits, setFacilityTotalHits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const theme = useTheme();

  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useFormContext();

  const attributeFieldName = useMemo(() => `${fieldName}[${index}].attribute`, [fieldName, index]);
  const filterOperatorFieldName = useMemo(
    () => `${fieldName}[${index}].filterOperator`,
    [fieldName, index]
  );
  const groupingOperatorFieldName = useMemo(
    () => `${fieldName}[${index}].groupingOperator`,
    [fieldName, index]
  );
  const valueFieldName = useMemo(() => `${fieldName}[${index}].value`, [fieldName, index]);

  const watchAttributeField = useWatch({ name: attributeFieldName });
  const watchFilterOperatorField = useWatch({ name: filterOperatorFieldName });
  const watchIsEditableField = useWatch({ name: 'isEditable' });
  const watchValueField = useWatch({ name: valueFieldName });

  useEffect(() => {
    const getFacilityTotalHits = async () => {
      try {
        setLoading(true);

        const {
          data: { calculateSegmentFacilityCondition: segmentFacilityConditionCalculation },
        } = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: gql`
            query CalculateSegmentFacilityCondition(
              $data: SegmentFacilityConditionCalculateInput!
            ) {
              calculateSegmentFacilityCondition(data: $data) {
                id
                createdAt

                count
                meilisearchFilter
                sqlQuery
              }
            }
          `,
          variables: {
            data: {
              segmentFacilityCondition: {
                attribute: { id: watchAttributeField?.result?.id },
                filterOperator: { id: watchFilterOperatorField?.result?.id },
                groupingOperator: null,
                ordinal: index,
                value: _.isArray(watchValueField)
                  ? JSON.stringify(watchValueField.map(({ value }) => value))
                  : watchValueField.value,
              },
            },
          },
        });

        if (_.isNumber(segmentFacilityConditionCalculation?.count)) {
          setFacilityTotalHits(segmentFacilityConditionCalculation?.count);
        }
      } catch (err) {
        dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
      } finally {
        setLoading(false);
      }
    };

    if (
      apolloClient &&
      dispatch &&
      _.isNumber(index) &&
      watchAttributeField?.result?.id &&
      watchFilterOperatorField?.result?.id &&
      (_.isString(watchValueField?.value) ||
        (_.isArray(watchValueField) && !_.isEmpty(watchValueField)))
    ) {
      getFacilityTotalHits();
    } else {
      setFacilityTotalHits(null);
    }
  }, [
    apolloClient,
    dispatch,
    index,
    watchAttributeField?.result?.id,
    watchFilterOperatorField?.result?.id,
    watchValueField,
  ]);

  const handleRemove = () => {
    if (typeof onRemove === 'function') {
      onRemove(index);
    }

    return null;
  };

  const handleToggleOpen = () => setOpen(!open);

  return (
    <div className={className}>
      <div className="flex flex-row w-full">
        <div className="flex flex-col" style={{ marginTop: index === 0 ? 46 : 0 }}>
          {index === 0 && (
            <div className="flex justify-end w-full">
              <div className="h-1 bg-light-blue-400" style={{ width: 85 }} />
            </div>
          )}

          <div className="flex flex-col items-center w-128 h-full" style={{ paddingRight: 42 }}>
            <div className="w-1 h-full bg-light-blue-400" />

            {index > 0 && (
              <Controller
                control={control}
                name={groupingOperatorFieldName}
                render={({ field }) => (
                  <SegmentGroupingOperatorSelectField
                    {...field}
                    disabled={isSubmitting || !watchIsEditableField}
                    error={_.get(errors, groupingOperatorFieldName)}
                    filters={null}
                  />
                )}
              />
            )}

            <div className="w-1 h-full bg-light-blue-400" />
          </div>
        </div>

        <div
          className="w-full my-16"
          style={{ backgroundColor: theme.palette.mode === 'light' ? '#f1f5f9' : '#111827' }}
        >
          <div className="flex flex-row items-center w-full h-64 px-16">
            <IconButton className="mr-6" onClick={handleToggleOpen}>
              <FontAwesomeIcon icon={open ? faChevronCircleDown : faChevronCircleRight} />
            </IconButton>

            <div className="flex-1">
              <Typography className="text-16 font-500">
                {`Facility Condition ${index + 1}`}
              </Typography>
            </div>

            <IconButton disabled={isSubmitting || !watchIsEditableField} onClick={handleRemove}>
              <FontAwesomeIcon icon={faCircleXmark} />
            </IconButton>
          </div>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <div
              className="p-16"
              style={{
                borderTop: `1px solid ${
                  theme.palette.mode === 'light' ? '#c1c4c7' : lightBlue[900]
                }`,
              }}
            >
              <div className="flex flex-col w-full">
                <div className="flex flex-col sm:flex-row items-center w-full mb-0 sm:mb-24">
                  <Controller
                    control={control}
                    name={attributeFieldName}
                    render={({ field }) => (
                      <SegmentFacilityConditionAttributeSelectField
                        {...field}
                        className="w-full sm:flex-1 mb-24 sm:mb-0 sm:mr-24"
                        error={_.get(errors, attributeFieldName)}
                        filters={null}
                        isClearable
                        isDisabled={isSubmitting || !watchIsEditableField}
                        isMulti={false}
                        placeholder="Select Attribute..."
                        onChange={(params) => {
                          setValue(valueFieldName, null);

                          field.onChange(params);
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={filterOperatorFieldName}
                    render={({ field }) => (
                      <SegmentFilterOperatorSelectField
                        {...field}
                        className="w-full sm:w-200 mb-24 sm:mb-0"
                        error={_.get(errors, filterOperatorFieldName)}
                        filters={null}
                        isClearable
                        isDisabled={isSubmitting || !watchIsEditableField}
                        isMulti={false}
                        placeholder="Select Operator..."
                        onChange={(params) => {
                          if (
                            !params ||
                            (['EQUAL', 'NOT_EQUAL'].includes(params?.result?.enum) &&
                              _.isArray(watchValueField)) ||
                            (['IN', 'NOT_IN'].includes(params?.result?.enum) &&
                              _.isPlainObject(watchValueField))
                          ) {
                            setValue(valueFieldName, null);
                          }

                          field.onChange(params);
                        }}
                      />
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name={valueFieldName}
                  render={({ field }) => (
                    <EntitySearchFacetSelectField
                      {...field}
                      className="w-full mb-24 sm:mb-0"
                      error={_.get(errors, valueFieldName)}
                      facetName={watchAttributeField?.result?.path}
                      filters={null}
                      indexName="facilities"
                      isClearable
                      isDisabled={isSubmitting || !watchIsEditableField}
                      isMulti={['IN', 'NOT_IN'].includes(watchFilterOperatorField?.result?.enum)}
                      maxToShow={3}
                      placeholder="Select Value(s)..."
                    />
                  )}
                />
              </div>
            </div>
          </Collapse>

          <div
            className="flex items-center w-full px-16 py-12"
            style={{ backgroundColor: theme.palette.mode === 'light' ? '#d9dde0' : '#013d6d' }}
          >
            <div className="flex-1" />

            <div className="flex items-center">
              <Typography className="mr-6 text-15 font-500">Matching Facilities:</Typography>

              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <Typography className="text-15 font-500">
                  {_.isNumber(facilityTotalHits)
                    ? numeral(facilityTotalHits).format(facilityTotalHits < 1000 ? '0a' : '0.0a')
                    : '-'}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentDefinitionViewFormFacilityConditionsItem;
