import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_ISSUE_REMOVE_SERVICE_ISSUE_TAG_DIALOG_SERVICE_ISSUE_FRAGMENT = gql`
  fragment ServiceIssueRemoveServiceIssueTagDialogServiceIssueFragment on ServiceIssue {
    id
    tags {
      id
      name
    }
  }
`;
