import { faCalendarAlt, faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDoubleUp,
  faAngleUp,
  faBan,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

const ServiceTicketReminderViewAppListItem = ({
  basePath,
  serviceTicketOrganizations,
  serviceTicketReminder,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  if (!serviceTicketReminder) return null;

  return (
    <ListItemButton
      alignItems="flex-start"
      className="py-12"
      divider
      key={serviceTicketReminder.id}
      selected={serviceTicketReminder.id === params?.viewId}
      onClick={() => navigate(`../${basePath}/view/${serviceTicketReminder.id}`)}
    >
      <ListItemIcon>
        {serviceTicketReminder.isCancelled && (
          <Tooltip placement="bottom" title="Cancelled">
            <div>
              <FontAwesomeIcon className="text-18 text-red-800" icon={faBan} />
            </div>
          </Tooltip>
        )}

        {serviceTicketReminder.isCompleted && (
          <Tooltip placement="bottom" title="Completed">
            <div>
              <FontAwesomeIcon className="text-18 text-green-800" icon={faCircleCheck} />
            </div>
          </Tooltip>
        )}

        {!serviceTicketReminder.isCancelled && !serviceTicketReminder.isCompleted && (
          <Tooltip placement="bottom" title="Pending">
            <div>
              <FontAwesomeIcon className="text-18 text-grey-800" icon={faCircle} />
            </div>
          </Tooltip>
        )}
      </ListItemIcon>

      <ListItemText
        disableTypography
        primary={<Typography>{serviceTicketReminder.name}</Typography>}
      />

      {serviceTicketReminder?.dueAt && (
        <Tooltip
          placement="bottom"
          title={`Due ${moment(serviceTicketReminder.dueAt).format('MMM. DD, YYYY')}`}
        >
          <div className="flex items-center ml-16">
            <div
              className={clsx('flex items-center px-8 h-28 rounded-4', {
                'bg-amber-800': moment(serviceTicketReminder?.completedAt || undefined)
                  .startOf('day')
                  .isSame(moment(serviceTicketReminder.dueAt).startOf('day')),
                'bg-grey-800': moment(serviceTicketReminder?.completedAt || undefined)
                  .startOf('day')
                  .isBefore(moment(serviceTicketReminder.dueAt).startOf('day')),
                'bg-red-800': moment(serviceTicketReminder?.completedAt || undefined)
                  .startOf('day')
                  .isAfter(moment(serviceTicketReminder.dueAt).startOf('day')),
              })}
            >
              <FontAwesomeIcon className="pr-6 text-white" icon={faCalendarAlt} />

              <Typography className="text-12 text-white font-600 uppercase">
                {moment(serviceTicketReminder.dueAt).format('MMM/DD')}
              </Typography>
            </div>
          </div>
        </Tooltip>
      )}

      {serviceTicketReminder?.priority && (
        <Tooltip placement="bottom" title={`${serviceTicketReminder.priority.name} Priority`}>
          <div className="flex items-center ml-16">
            {serviceTicketReminder?.priority.enum === 'HIGH' && (
              <Avatar className="w-28 h-28 bg-red-800" variant="rounded">
                <FontAwesomeIcon className="text-16 text-white" icon={faExclamation} />
              </Avatar>
            )}

            {serviceTicketReminder?.priority.enum === 'MEDIUM' && (
              <Avatar className="w-28 h-28 bg-amber-800" variant="rounded">
                <FontAwesomeIcon className="text-16 text-white" icon={faAngleDoubleUp} />
              </Avatar>
            )}

            {serviceTicketReminder?.priority.enum === 'LOW' && (
              <Avatar className="w-28 h-28 bg-blue-800" variant="rounded">
                <FontAwesomeIcon className="text-16 text-white" icon={faAngleUp} />
              </Avatar>
            )}
          </div>
        </Tooltip>
      )}
    </ListItemButton>
  );
};

export default ServiceTicketReminderViewAppListItem;
