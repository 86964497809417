import { gql } from '@apollo/client';
import {
  WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT,
  WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_FRAGMENT,
} from './fragments';

export const FETCH_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueScheduledResumptionUpdateDialogData(
    $where: WorkOrderIssueScheduledResumptionWhereUniqueInput!
  ) {
    workOrderIssueScheduledResumption(where: $where) {
      id
      ...WorkOrderIssueScheduledResumptionUpdateDialogWorkOrderIssueScheduledResumptionFragment
    }
  }
  ${WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_FRAGMENT}
`;

export const UPDATE_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION = gql`
  mutation UpdateWorkOrderIssueScheduledResumption(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueScheduledResumptionUpdateInput!
  ) {
    updateWorkOrderIssueScheduledResumption(where: $where, data: $data) {
      id
      ...WorkOrderIssueScheduledResumptionUpdateDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
