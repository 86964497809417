import * as React from 'react';
import _ from '@lodash';
import jwtDecode from 'jwt-decode';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import history from '@history';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { apiClient } from 'app/providers/apollo/clients';
import { logoutUser, setUser } from 'app/store/userSlice';
import {
  jwtService,
  JWT_SERVICE_EVENTS,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES,
  JWT_SERVICE_TOKEN_TYPES,
} from './services';

const { REACT_APP_ULTRAVIOLET_SSO_TOKEN_STORAGE_PREFIX } = process.env;

export const AUTH_ROLES = {};

AUTH_ROLES.ADMINISTRATION = [
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.ACCOUNT_ADMINISTRATOR,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.ACCOUNT_OWNER,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.ECOSYSTEM_ADMIN,
];

AUTH_ROLES.FACILITY_OPERATIONS = [
  ...AUTH_ROLES.ADMINISTRATION,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.FACILITY_APPROVER,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.KITCHEN_TECHNICIAN,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.FACILITY_MEMBER,
];

AUTH_ROLES.FRANCHISOR_OPERATIONS = [
  ...AUTH_ROLES.ADMINISTRATION,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.PROJECT_SPECIALIST,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.ASSET_MANAGER,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.PROGRAM_MANAGER,
];

AUTH_ROLES.MANUFACTURER_OPERATIONS = [
  ...AUTH_ROLES.ADMINISTRATION,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.ASSEMBLY_LINE_WORKER,
];

AUTH_ROLES.SERVICE = [
  ...AUTH_ROLES.ADMINISTRATION,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.SERVICE_TECHNICIAN,
  JWT_SERVICE_TEAM_MEMBERSHIP_ROLES.SERVICE_MANAGER,
];

AUTH_ROLES.UNAUTHENTICATED = [];

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);

  useEffect(() => {
    const storageWatchKey = [
      REACT_APP_ULTRAVIOLET_SSO_TOKEN_STORAGE_PREFIX,
      JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN,
    ].join('.');

    const reloadPage = (event) => {
      if (event.storageArea !== localStorage) {
        return;
      }

      if (
        (event.key === storageWatchKey && event.newValue && !isAuthenticated) ||
        (event.key === storageWatchKey && !event.newValue && isAuthenticated)
      ) {
        history.go(0);
      }
    };

    window.addEventListener('storage', reloadPage);

    return () => {
      window.removeEventListener('storage', reloadPage);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    jwtService.on([JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION_START], () => {
      // TODO: Review
      setIsAuthenticated(false);
      setWaitAuthCheck(true);
    });

    jwtService.on(
      [
        JWT_SERVICE_EVENTS.ON_COMPLETE_SIGN_IN,
        // TODO: setWaitAuthCheck(true) on Reassign
        // TODO: apolloClient.resetStore()
        JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION,
        JWT_SERVICE_EVENTS.ON_REFRESH_SESSION,
      ],
      ({ data: tokens }) => {
        if (
          _.every(
            [
              // 'accessToken',
              JWT_SERVICE_TOKEN_TYPES.ACCESS_TOKEN,

              // TODO: 'analyticsToken',
              // JWT_SERVICE_TOKEN_TYPES.ANALYTICS_TOKEN,

              // TODO: Remove?
              // 'refreshToken',

              // TODO: 'searchToken',
              // JWT_SERVICE_TOKEN_TYPES.SEARCH_TOKEN,
            ],
            (tokenType) => !_.isEmpty(_.get(tokens, tokenType))
          )
        ) {
          const accessTokenData = jwtDecode(_.get(tokens, 'accessToken'));

          let shortcuts;

          try {
            shortcuts =
              window.localStorage &&
              window.localStorage.getItem(
                [process.env.REACT_APP_ULTRAVIOLET_SSO_TOKEN_STORAGE_PREFIX, 'shortcuts'].join('.')
              );

            if (shortcuts) {
              shortcuts = JSON.parse(shortcuts);
            }
          } catch (err) {
            shortcuts = null;
          }

          const themePreference =
            window.localStorage &&
            window.localStorage.getItem(
              [process.env.REACT_APP_ULTRAVIOLET_SSO_TOKEN_STORAGE_PREFIX, 'themePreference'].join(
                '.'
              )
            );

          const user = {
            id: _.get(accessTokenData, 'user.id'),

            // TODO: Used by Theme
            uuid: _.get(accessTokenData, 'user.id'),
            // from: "string",
            // password: "string",
            // TODO: Fix or Remove
            role: 'admin',
            data: {
              displayName: _.get(accessTokenData, 'user.name'),
              photoURL: null,
              // TODO: Rename
              email: accessTokenData?.user?.emailAddress?.emailAddress,
              settings: {
                layout: {},
                theme: {},
              },
              shortcuts,
              themePreference,

              // TODO: Clean Up
              ...accessTokenData,
              // name: _.get(accessTokenData, 'user.name'),
              // organization: _.get(accessTokenData, 'organization'),
              // team: _.get(accessTokenData, 'team'),
            },
          };

          // TODO: Should Show Toast Send Message

          // TODO: Review
          Promise.all([dispatch(setUser(user))]).then((values) => {
            setIsAuthenticated(true);
            setWaitAuthCheck(false);
          });
        } else {
          // TODO: Review
          setIsAuthenticated(false);
          setWaitAuthCheck(false);
        }
      }
    );

    jwtService.on(
      [
        // TODO: Review
        JWT_SERVICE_EVENTS.ON_CLIENT_RESPONSE_ERROR,
        JWT_SERVICE_EVENTS.ON_REVOKE_SESSION,
      ],
      () => {
        // TODO: Review
        Promise.all([dispatch(logoutUser())]).then((values) => {
          setIsAuthenticated(false);
          setWaitAuthCheck(false);
        });
      }
    );

    jwtService.once(JWT_SERVICE_EVENTS.ON_INIT_SUCCESS, () => {
      jwtService.refreshSession();
    });
  }, [dispatch]);

  // useEffect(() => {
  //   jwtService.on('onCompleteSignIn', ({ data }) => {
  //     if (_.get(data, 'accessToken')) {
  //       const accessTokenData = jwtDecode(_.get(data, 'accessToken'));

  //       // TODO:
  //       const user = {
  //         uuid: _.get(accessTokenData, 'user.id'),
  //         // from: "string",
  //         // password: "string",
  //         // role: "string",
  //         role: 'admin',
  //         data: {
  //           displayName: _.get(accessTokenData, 'user.name'),
  //           photoURL: null,
  //           email: _.get(accessTokenData, 'user.email'),
  //           settings: {
  //             layout: {},
  //             theme: {},
  //           },
  //           shortcuts: [],
  //         },
  //       };

  //       Promise.all([
  //         // TODO:
  //         authVar({ ...authVar(), user }),

  //         // dispatch(setUser(user)),
  //         // You can receive data in here before app initialization
  //       ]).then((values) => {
  //         setWaitAuthCheck(false);
  //       });
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   console.log('// TODO:', { authState });
  // }, [authState]);

  // useEffect(() => {
  //   jwtService.on('onAutoLogin', () => {
  //     // TODO:
  //     dispatch(showMessage({ message: 'Signing in with JWT' }));

  //     jwtService
  //       .signInWithToken()
  //       .then((session) => null)
  //       .catch((error) => {
  //         pass(error.message);
  //       });
  //   });

  //   jwtService.on('onAutoReassign', ({ teamId }) => {
  //     setWaitAuthCheck(true);

  //     apiClient
  //       .resetStore()
  //       .then(() => {
  //         jwtService
  //           .reassignToken({ teamId })
  //           .then((session) => null)
  //           .catch((error) => {
  //             pass(error.message);
  //           });
  //       })
  //       .catch((error) => {
  //         pass(error.message);
  //       });
  //   });

  //   jwtService.on('onReassign', (session) => {
  //     console.log('// FIXME: onReassign', { session });
  //     success(session, 'Reassigned');
  //   });

  //   jwtService.on('onLogin', (session) => {
  //     console.log('// FIXME: onLogin', { session });
  //     success(session, 'Signed In');
  //   });

  //   jwtService.on('onLogout', () => {
  //     pass('Signed out');

  //     dispatch(logoutUser());
  //   });

  //   jwtService.on('onAutoLogout', (message) => {
  //     pass(message);

  //     dispatch(logoutUser());
  //   });

  //   jwtService.on('onNoAccessToken', () => {
  //     pass();
  //   });

  //   jwtService.init();

  //   function success({ accessToken }, message) {
  //     // TODO: Clear Apollo Cache Here IF ORG Change?

  //     if (message) {
  //       dispatch(showMessage({ message }));
  //     }

  //     const accessTokenData = jwtDecode(accessToken);

  //     // TODO:
  //     const user = {
  //       uuid: _.get(accessTokenData, 'user.id'),
  //       // from: "string",
  //       // password: "string",
  //       // role: "string",
  //       role: 'admin',
  //       data: {
  //         displayName: _.get(accessTokenData, 'user.name'),
  //         photoURL: null,
  //         email: _.get(accessTokenData, 'user.email'),
  //         settings: {
  //           layout: {},
  //           theme: {},
  //         },
  //         shortcuts: [],
  //       },
  //     };

  //     console.log('// FIXME:', { accessTokenData, user });

  //     Promise.all([
  //       // TODO:
  //       authSessionVar({ ...authSessionVar(), user }),

  //       dispatch(setUser(user)),
  //       // You can receive data in here before app initialization
  //     ]).then((values) => {
  //       setWaitAuthCheck(false);
  //     });
  //   }

  //   function pass(message) {
  //     if (message) {
  //       dispatch(showMessage({ message }));
  //     }

  //     setWaitAuthCheck(false);
  //   }
  // }, [dispatch]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
  ) : (
    <AuthContext.Provider value={{ jwtService }}>{children}</AuthContext.Provider>
  );
}

export const __AuthContext = AuthContext;
export default AuthProvider;
