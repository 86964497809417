import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CommonMetabaseStaticEmbed } from 'app/shared-components/Common/CommonMetabase';
import { ProgramCreateDialog } from 'app/shared-components/Program';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';
import { SizeMe } from 'react-sizeme';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},

  // TODO:
  '& .FusePageSimple-rightSidebar': {
    flex: '1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
  '& .FusePageSimple-contentWrapper': {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 400,
    },
  },
}));

const ProgramDashboardScreen = () => {
  const dispatch = useDispatch();

  return (
    <Root
      header={
        // TODO: Move?
        <AppBar color="inherit" elevation={0} position="static">
          <Toolbar>
            <div className="flex flex-1 items-center">
              <div className="mr-24">
                <FontAwesomeIcon icon={faBookmark} size="lg" />
              </div>

              <Typography component="div" noWrap variant="h6">
                Programs
              </Typography>
            </div>

            <div className="flex items-center">
              <Button
                color="success"
                size="small"
                variant="contained"
                onClick={() =>
                  dispatch(
                    // FIXME: z-index 9999 + 1
                    openDialog({
                      children: <ProgramCreateDialog onClose={() => dispatch(closeDialog())} />,
                      classes: {
                        // TODO: Move to FuseDialog?
                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                      },
                    })
                  )
                }
              >
                Create
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      }
      content={
        <SizeMe
          // TODO: Review
          // refreshMode="debounce"
          monitorHeight
          monitorWidth={false}
        >
          {({ size }) => (
            <div className="flex flex-row w-full h-full">
              {/* TODO: */}
              <CommonMetabaseStaticEmbed className="pb-24" dashboard={15} height={size?.height} />
            </div>
          )}
        </SizeMe>
      }
      // TODO:
      // leftSidebarWidth={341}
      // leftSidebarOpen={leftSidebarOpen}
      // leftSidebarOnClose={() => {
      //   setLeftSidebarOpen(false);
      // }}
      // leftSidebarContent={
      //   <div className="flex-1 min-w-340">
      //     Stuff
      //   </div>
      // }
      // TODO:
      // rightSidebarOpen={
      //   rightSidebarOpen
      //   // && selectedHitsViewMode !== 'list'
      // }
      // rightSidebarOnClose={() => {
      //   setRightSidebarOpen(false);
      // }}
      // rightSidebarContent={(
      //   <div className="flex-1 min-w-340">
      //     Stuff
      //   </div>
      // )}
      scroll="content"
    />
  );
};

export default ProgramDashboardScreen;
