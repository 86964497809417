import { useEffect, useMemo, useRef, useState } from 'react';
import _ from '@lodash';
import * as turf from '@turf/turf';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import {
  Box,
  // List,
  // ListItem,
  // ListItemText,
  // Paper,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useClearRefinements, useInfiniteHits, useInstantSearch, useSortBy } from 'react-instantsearch';
import { useSelector } from 'react-redux';
import { EntitySearchInfiniteHitsList } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsList';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';

import { useGeoSearch } from 'app/providers/meilisearch';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  // TODO:
  width: 420,
  display: 'flex',
  marginLeft: 0,
  transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
}));

const ToggleListTabButton = styled('div')(({ theme }) => ({
  width: 24,
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.common.black, 0.54)
      : theme.palette.common.white,
  borderRadius: '0 8px 8px 0',
  backgroundColor: theme.palette.background.paper,
}));

// TODO: memo?
const EntitySearchInfiniteHitsMap = ({ RenameHitComponent, height, width, onMapLoad }) => {
  const mapContainer = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const map = useRef(null);
  const mapStyle = useMemo(() => ({ height, width: '100%' }), [height]);

  const {
    // items,
    // position,
    // currentRefinement,
    refine,
    // sendEvent,
    // clearMapRefinement,
    // isRefinedWithMap,
    // toggleRefineOnMapMove,
    // isRefineOnMapMove,
    // setMapMoveSinceLastRefine,
    // hasMapMoveSinceLastRefine,
  } = useGeoSearch();

  // console.log('// FIXME:', {
  //   items,
  //   position,
  //   currentRefinement,
  //   refine,
  //   sendEvent,
  //   clearMapRefinement,
  //   isRefinedWithMap,
  //   toggleRefineOnMapMove,
  //   isRefineOnMapMove,
  //   setMapMoveSinceLastRefine,
  //   hasMapMoveSinceLastRefine,
  // });

  const mainTheme = useSelector(selectMainTheme);

  // FIXME:
  const [listTabOpen, setListTabOpen] = useState(true);

  // FIXME:
  const { hits, isFirstPage, isLastPage, showMore } = useInfiniteHits();
  const { status } = useInstantSearch();
  const loading = useMemo(() => status === 'stalled', [status]);

  const mapBoundsRef = useRef(null);
  const mapCenterRef = useRef(null);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      // style: 'mapbox://styles/mapbox/dark-v11',
      // style: 'mapbox://styles/mapbox/light-v11',
      style: 'mapbox://styles/mapbox/navigation-day-v1',
      // style: 'mapbox://styles/mapbox/navigation-night-v1',
      // style: 'mapbox://styles/mapbox/streets-v11',
      center: [-100.874, 42.76],
      zoom: 4,
    });

    map.current.on('load', async () => {
      await Promise.all(
        [
          // NOTE: Additional Markers Available in `/public/assets/images/markers`.
          {
            id: 'red-marker',
            url: 'assets/images/markers/mapbox-marker-icon-20px-red.png',
          },
        ].map(({ id, url }) => {
          return new Promise((resolveImage) => {
            map.current.loadImage(url, (error, image) => {
              if (!error) {
                map.current.addImage(id, image);
              }

              resolveImage();
            });
          });
        })
      );

      map.current.addControl(new mapboxgl.NavigationControl());

      // if (typeof onMapLoad === 'function') {
      //   onMapLoad({ map: map.current });
      // }

      map.current.addSource('position-source', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      map.current.addLayer({
        id: 'position-layer',
        type: 'symbol',
        source: 'position-source',
        layout: {
          'icon-image': 'red-marker',
        },
      });

      setMapLoaded(true);
    });

    map.current.on('moveend', async () => {
      // TODO: if !option return null;

      const mapBounds = map.current.getBounds();
      const mapCenter = map.current.getCenter();

      if (!mapBoundsRef.current) {
        mapBoundsRef.current = { ...mapBounds };
      }

      if (!mapCenterRef.current) {
        mapCenterRef.current = { ...mapCenter };
      }

      const mapBoundsDistance = turf.distance(
        turf.point([mapBounds._ne.lng, mapBounds._ne.lat]),
        turf.point([mapBounds._sw.lng, mapBounds._sw.lat]),
        { units: 'meters' }
      );

      const mapCenterDistanceDelta = turf.distance(
        turf.point([mapCenter.lng, mapCenter.lat]),
        turf.point([mapCenterRef.current.lng, mapCenterRef.current.lat]),
        { units: 'meters' }
      );

      if (mapCenterDistanceDelta > (mapBoundsDistance || 0) * 0.1) {
        console.log('// FIXME MOVED!');

        mapBoundsRef.current = null;
        mapCenterRef.current = null;
      }

      refine({
        northEast: mapBounds._ne,
        southWest: mapBounds._sw,
      });

      console.log('// FIXME: moveend', { mapCenterDistanceDelta, mapBoundsDistance, mapBounds, mapCenter });
    });

    // TODO: Review
    // return () => map.current.remove();
  }, [onMapLoad]);

  useEffect(() => {
    if (map.current) {
      map.current.setStyle(
        mainTheme?.palette.mode === 'dark'
          ? 'mapbox://styles/mapbox/navigation-night-v1'
          : 'mapbox://styles/mapbox/navigation-day-v1'
      );
    }
  }, [mainTheme?.palette.mode]);

  useDeepCompareEffect(() => {
    if (map.current) {
      map.current.resize();
    }
  }, [mapStyle]);

  useDeepCompareEffect(() => {
    if (_.isArray(hits) && mapLoaded) {
      map.current?.getSource('position-source')?.setData({
        type: 'FeatureCollection',
        features: hits.map((hit) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [hit._geoloc?.lng, hit._geoloc?.lat],
          },
        })),
      });
    }
  }, [hits, mapLoaded]);

  const handleToggleListTab = () => setListTabOpen(!listTabOpen);

  // console.log('// FIXME:', { isFirstPage, isLastPage, isSearching });
  return (
    <div className="w-full relative">
      <div ref={mapContainer} style={mapStyle}>
        {/* TODO: Loading Indicator */}
        {!mapLoaded && <div>Loading...</div>}
      </div>

      <StyledBox style={{ marginLeft: listTabOpen ? 0 : -396 }}>
        <EntitySearchInfiniteHitsList RenameHitComponent={RenameHitComponent} />

        <div className="flex items-center">
          <ToggleListTabButton className="shadow-1" onClick={handleToggleListTab}>
            <FontAwesomeIcon icon={listTabOpen ? faCaretLeft : faCaretRight} size="lg" />
          </ToggleListTabButton>
        </div>
      </StyledBox>
    </div>
  );
};

export default EntitySearchInfiniteHitsMap;
