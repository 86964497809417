import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_RESCHEDULE_SERVICE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const FETCH_WORK_ORDER_ISSUE_RESCHEDULE_SERVICE_VISIT_DIALOG_DATA = gql`
  query FetchWorkOrderIssueRescheduleServiceVisitDialogData(
    $where: ServiceTicketWhereUniqueInput!
  ) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueRescheduleServiceVisitDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RESCHEDULE_SERVICE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const RESCHEDULE_SERVICE_VISIT_WORK_ORDER_ISSUE = gql`
  mutation RescheduleServiceVisitWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueRescheduleServiceVisitInput!
  ) {
    rescheduleServiceVisitWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueRescheduleServiceVisitDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RESCHEDULE_SERVICE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
