import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_REMINDER_VIEW_APP_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketReminderViewAppServiceTicketFragment on ServiceTicket {
    id
    reminders {
      id
      createdAt
      assignedTeam {
        id
        name
      }
      completedAt
      completor {
        id
        name
      }
      dueAt
      isCompleted
      name
      notes
      owner {
        id
        name
      }
      priority {
        id
        enum
        name
      }
    }
    serviceProvision {
      id
      name
      organizationConnection {
        id
        consumer {
          id
          name
          type {
            id
            enum
            name
          }
        }
        vendor {
          id
          name
          type {
            id
            enum
            name
          }
        }
      }
    }
  }
`;
