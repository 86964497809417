import { useMutation, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker, CommonSelect } from 'app/shared-components/Common';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch/EntitySearchSelectField';
import { SiteVisitHitsListOption } from 'app/shared-components/SiteVisit';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_WORK_ORDER_ISSUE_SCHEDULE_SITE_VISIT_DIALOG_DATA,
  SCHEDULE_SITE_VISIT_WORK_ORDER_ISSUE,
} from './queries';

const defaultValues = {
  mutation: null,
  siteVisit: null,
  workOrderIssues: [],
};

const schema = yup.object().shape({
  mutation: yup.object().nullable().required('A site visit connection type must be provided.'),
  siteVisit: yup
    .mixed()
    .when('mutation', {
      is: (val) => val?.value === 'CONNECT',
      then: yup.object().shape({
        connect: yup.object().nullable().required('A site visit must be provided.'),
      }),
    })
    .when('mutation', {
      is: (val) => val?.value === 'CREATE',
      then: yup.object().shape({
        create: yup.object().shape({
          window: yup
            .array()
            .test(
              'requireValidArrivalWindow',
              'An arrival window must be provided.',
              (value) =>
                value?.length === 2 && !_.some(value || [], (date) => !moment(date).isValid())
            ),
        }),
      }),
    }),
  workOrderIssues: yup
    .array()
    .test(
      'requireOneWorkOrderIssue',
      'One or more work order issues must be provided.',
      (value) => value.filter((el) => el).length
    ),
});

const WorkOrderIssueScheduleSiteVisitDialog = ({
  defaultWorkOrderIssueId,
  serviceTicketId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueScheduleSiteVisitDialogData,
    {
      data: workOrderIssueScheduleSiteVisitDialogData,
      loading: workOrderIssueScheduleSiteVisitDialogLoading,
      refetch: workOrderIssueScheduleSiteVisitDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_SCHEDULE_SITE_VISIT_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [scheduleSiteVisitWorkOrderIssue, { loading: scheduleSiteVisitWorkOrderIssueLoading }] =
    useMutation(SCHEDULE_SITE_VISIT_WORK_ORDER_ISSUE, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Site Visit Successfully Scheduled',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Scheduling Work Order Issue Site Visit',
            variant: 'error',
          })
        );
      },
    });

  const filteredWorkOrderIssues = useMemo(
    () =>
      workOrderIssueScheduleSiteVisitDialogData?.serviceTicket?.workOrder?.issues
        ?.filter(({ status }) => ['PENDING', 'QUOTED'].includes(status?.enum))
        ?.map((workOrderIssues) => workOrderIssues),
    [workOrderIssueScheduleSiteVisitDialogData?.serviceTicket]
  );

  const loading = useMemo(
    () =>
      [
        scheduleSiteVisitWorkOrderIssueLoading,
        workOrderIssueScheduleSiteVisitDialogLoading,
      ].includes(true),
    [scheduleSiteVisitWorkOrderIssueLoading, workOrderIssueScheduleSiteVisitDialogLoading]
  );

  const serviceTicket = useMemo(
    () => workOrderIssueScheduleSiteVisitDialogData?.serviceTicket,
    [workOrderIssueScheduleSiteVisitDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (defaultWorkOrderIssueId && filteredWorkOrderIssues) {
      const newDefaultValues = _.cloneDeep(defaultValues);
      const defaultWorkOrderIssueIndex = filteredWorkOrderIssues?.findIndex(
        ({ id }) => id === defaultWorkOrderIssueId
      );

      if (defaultWorkOrderIssueIndex !== -1) {
        newDefaultValues.workOrderIssues[defaultWorkOrderIssueIndex] = defaultWorkOrderIssueId;
      }

      reset(newDefaultValues);
    }
  }, [defaultWorkOrderIssueId, filteredWorkOrderIssues, reset]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchWorkOrderIssueScheduleSiteVisitDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchWorkOrderIssueScheduleSiteVisitDialogData, serviceTicketId]);

  const handleToggleAllWorkOrderIssues = () => {
    if (
      filteredWorkOrderIssues?.length === watchFields.workOrderIssues?.length &&
      !_.some(watchFields.workOrderIssues || [], (value) => !value)
    ) {
      setValue('workOrderIssues', [], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    } else {
      setValue(
        'workOrderIssues',
        filteredWorkOrderIssues?.map(({ id }) => id),
        { shouldDirty: true, shouldTouch: true, shouldValidate: true }
      );
    }
  };

  const onSubmit = async (data) => {
    try {
      await scheduleSiteVisitWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            siteVisit: {
              connect: data.siteVisit?.connect && {
                id: data.siteVisit?.connect.value,
              },
              create: data.siteVisit?.create && {
                windowEndAt: _.last(data.siteVisit.create?.window || []),
                windowStartAt: _.first(data.siteVisit.create?.window || []),
              },
            },
            workOrderIssues: data.workOrderIssues?.filter((el) => el).map((id) => ({ id })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Schedule Work Order Issue Site Visit
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-schedule-site-visit-form"
            name="work-order-issue-schedule-site-visit-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="workOrderIssues"
              render={({ field }) => (
                <>
                  <div className="mb-24">
                    <div
                      className="border-1 overflow-y-auto"
                      style={{
                        maxHeight: 290,
                        borderColor: errors.workOrderIssues ? '#f44336' : grey[300],
                      }}
                    >
                      <List disablePadding>
                        <ListItemButton onClick={handleToggleAllWorkOrderIssues}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                filteredWorkOrderIssues?.length ===
                                  watchFields.workOrderIssues?.length &&
                                !_.some(watchFields.workOrderIssues || [], (value) => !value)
                              }
                              edge="start"
                              disableRipple
                              indeterminate={
                                (filteredWorkOrderIssues?.length !==
                                  watchFields.workOrderIssues?.length &&
                                  _.some(watchFields.workOrderIssues || [], (value) => value)) ||
                                (filteredWorkOrderIssues?.length ===
                                  watchFields.workOrderIssues?.length &&
                                  _.some(watchFields.workOrderIssues || [], (value) => value) &&
                                  _.some(watchFields.workOrderIssues || [], (value) => !value))
                              }
                              tabIndex={-1}
                              // value={id}
                              onChange={handleToggleAllWorkOrderIssues}
                            />
                          </ListItemIcon>

                          <ListItemText primary="Select All" />
                        </ListItemButton>

                        {filteredWorkOrderIssues?.map(({ id, serviceIssue }, index) => {
                          const handleChange = () => {
                            const newFieldValue = [...field?.value];

                            newFieldValue[index] = !newFieldValue[index] ? id : null;

                            field.onChange(newFieldValue);
                          };

                          return (
                            <ListItemButton key={id} onClick={handleChange}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={!!field?.value?.[index]}
                                  edge="start"
                                  disableRipple
                                  tabIndex={-1}
                                  value={id}
                                  onChange={handleChange}
                                />
                              </ListItemIcon>

                              <ListItemText primary={serviceIssue.name} />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </div>

                    {errors.workOrderIssues && (
                      <div className="mx-14 mt-3">
                        <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
                          {errors.workOrderIssues?.message}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />

            <Controller
              control={control}
              name="mutation"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonSelect
                    {...field}
                    error={errors?.mutation}
                    isClearable
                    isDisabled={false}
                    isMulti={false}
                    options={[
                      {
                        label: 'Connect to Existing Site Visit',
                        value: 'CONNECT',
                      },
                      {
                        label: 'Create New Site Visit',
                        value: 'CREATE',
                      },
                    ]}
                    placeholder="Select Site Visit Connection..."
                    onChange={(params) => {
                      setValue('siteVisit', null);

                      field.onChange(params);
                    }}
                  />
                </div>
              )}
            />

            {watchFields?.mutation?.value === 'CONNECT' && (
              <>
                <Controller
                  control={control}
                  name="siteVisit.connect"
                  render={({ field }) => (
                    <EntitySearchSelectField
                      {...field}
                      className="mb-24"
                      components={{ Option: SiteVisitHitsListOption }}
                      error={errors?.siteVisit?.connect}
                      filter={[
                        `serviceTicket.id = '${serviceTicketId}'`,
                        `serviceTicket.status.enum = 'OPEN'`,
                      ]}
                      idField="id"
                      indexName="site_visits"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      labelFn={(hit) =>
                        `${moment(hit?.windowStartAt).format('MMM. DD, YYYY @ hh:mm A')} - ${moment(
                          hit?.windowEndAt
                        ).format('MMM. DD, YYYY @ hh:mm A')}`
                      }
                      placeholder="Select Site Visit..."
                      nameField="name"
                      sort={['windowStartAtTimestamp:asc']}
                    />
                  )}
                />
              </>
            )}

            {watchFields?.mutation?.value === 'CREATE' && (
              <>
                <Controller
                  control={control}
                  name="siteVisit.create.window"
                  render={({ field }) => (
                    <div className="mb-24">
                      <CommonDatepicker
                        {...field}
                        controls={['calendar', 'time']}
                        error={errors?.siteVisit?.create?.window}
                        min={
                          serviceTicket?.deferredUntil
                            ? moment(serviceTicket?.deferredUntil).toDate()
                            : moment().add(1, 'days').startOf('day').toDate()
                        }
                        placeholder="Select an Arrival Window..."
                        select="range"
                        stepMinute={15}
                      />
                    </div>
                  )}
                />
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-schedule-site-visit-form"
          type="submit"
          variant="contained"
        >
          Schedule
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueScheduleSiteVisitDialog;
