import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const SegmentsViewPage = lazy(() => import('./SegmentsViewPage'));
const segmentsViewPageBase = '/segments';

// i18next.addResourceBundle('en', 'segmentsViewPage', en);
// i18next.addResourceBundle('tr', 'segmentsViewPage', tr);
// i18next.addResourceBundle('ar', 'segmentsViewPage', ar);

const segmentsViewPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${segmentsViewPageBase}/view/:segmentId/*`,
      element: <SegmentsViewPage />,
    },
    {
      path: segmentsViewPageBase,
      element: <Navigate to={`${segmentsViewPageBase}/search`} />,
    },
  ],
};

export default segmentsViewPageConfig;
