import { Button, ButtonGroup, IconButton } from '@mui/material';
import { blue, purple } from '@mui/material/colors';
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  // momentTimezone,
} from '@mobiscroll/js/mobiscroll.react.min';
import { useMeilisearch } from 'app/providers/meilisearch';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Configure, Index, InstantSearch, useClearRefinements, useHits, useInfiniteHits, useRefinementList } from 'react-instantsearch';

import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { styled } from '@mui/material/styles';
import {
  EntitySearchApp,
  EntitySearchCalendarDrawer,
  EntitySearchCalendarHeader,
  EntitySearchCalendarHits,
} from 'app/shared-components/EntitySearch';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';

import _ from '@lodash';
// TODO: Review
import moment from 'moment';
// import moment from 'moment-timezone';
import { faCalendarDay, faCalendarDays, faCalendarWeek, faTableList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withReducer from 'app/store/withReducer';
import reducer from './store';
import { CalendarAppAdapter } from './adapters';
import { calendarAppScreenFilterDefs } from './filterDefs';
import { selectCalendarAppScreenDates, setDatesContentDateRange, setDatesSelectedDate } from './store/datesSlice';

// momentTimezone.moment = moment;

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},

  // TODO:
  '& .FusePageSimple-rightSidebar': {
    flex: '1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
  '& .FusePageSimple-contentWrapper': {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 400,
    },
  },
}));

// TODO:
const CalendarAppScreenRename = () => {
  const { refine: reset } = useClearRefinements({ includedAttributes: ['windowStartAtTimestamp'] });
  const {
    // TODO:
    // items: refinementListItems,
    canToggleShowMore,
    isFromSearch,
    isShowingMore,
    toggleShowMore,
    items,
    refine,
    searchForItems,
  } = useRefinementList({
    attribute: 'windowStartAtTimestamp',
    operator: 'or',
    // TODO:
    // limit: 20,
    // showMore: true,
    // TODO:
    // showMoreLimit: 50,
    // TODO:
    // sortBy: ['isRefined:desc', 'count:desc', 'name:asc'],
  });

  const dates = useSelector(selectCalendarAppScreenDates);

  useEffect(() => {
    if (
      _.isDate(dates?.contentDateRange?.firstDay) &&
      _.isDate(dates?.contentDateRange?.lastDay) &&
      _.isDate(dates?.drawerDateRange?.firstDay) &&
      _.isDate(dates?.drawerDateRange?.lastDay) &&
      _.isDate(dates?.selectedDate)
    ) {
      console.log('// TODO: DATES => TRIGGERED', {
        contentDateRange: {
          firstDay: dates?.contentDateRange?.firstDay,
          lastDay: dates?.contentDateRange?.lastDay,
        },
        drawerDateRange: {
          firstDay: dates?.drawerDateRange?.firstDay,
          lastDay: dates?.drawerDateRange?.lastDay,
        },
        selectedDate: dates?.selectedDate,
      });

      reset();

      if (
        moment(dates?.contentDateRange?.firstDay).isBetween(
          moment(dates?.drawerDateRange?.firstDay),
          moment(dates?.drawerDateRange?.lastDay)
        ) &&
        moment(dates?.contentDateRange?.lastDay).isBetween(
          moment(dates?.drawerDateRange?.firstDay),
          moment(dates?.drawerDateRange?.lastDay)
        )
      ) {
        refine(
          `dateRangeCustom:${moment(dates?.drawerDateRange?.firstDay).utc().unix()}|${moment(
            dates?.drawerDateRange?.lastDay
          ).utc().unix()}`
        );
      } else {
        const bob = [
          `${moment(dates?.contentDateRange?.firstDay).utc().unix()}|${moment(
            dates?.contentDateRange?.lastDay
          ).utc().unix()}`,
          `${moment(dates?.drawerDateRange?.firstDay).utc().unix()}|${moment(
            dates?.drawerDateRange?.lastDay
          ).utc().unix()}`,
        ].join(',');

        refine(`dateRangeCustomMultiple:${bob}`);
      }
    }
  }, [
    dates?.contentDateRange?.firstDay,
    dates?.contentDateRange?.lastDay,
    dates?.drawerDateRange?.firstDay,
    dates?.drawerDateRange?.lastDay,
    dates?.selectedDate,
    refine,
    reset,
  ]);

  return null;
};

// TODO:
const CalendarAppScreen = () => {
  // TODO:
  const { axiosSearchClient } = useMeilisearch();
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [calendarEventsLoading, setCalendarEventsLoading] = useState(false);
  const [calendarView, setCalendarView] = useState({ calendar: { labels: true } });
  const [view, setView] = useState('calendar');
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  // TODO: Based on selectedEntity
  const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile);
  // TODO: Rename
  const [selectedHitsViewMode, setSelectedHitsViewMode] = useState('grid');
  const navigate = useNavigate();

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
    // TODO: Based on selectedEntity
    setRightSidebarOpen(!isMobile);
  }, [isMobile]);

  // TODO: Rename and/or Move
  const changeView = useCallback((event) => {
    let calView;

    // console.log({ event });
    switch (event.target.value) {
      case 'year':
        calView = {
          calendar: { type: 'year' },
        };
        break;
      case 'month':
        calView = {
          // TODO:
          calendar: { labels: true, count: false },
        };
        break;
      case 'week':
        calView = {
          schedule: { type: 'week' },
        };
        break;
      case 'day':
        calView = {
          schedule: { type: 'day' },
        };
        break;
      case 'agenda':
        calView = {
          calendar: { type: 'week' },
          agenda: { type: 'week' },
        };
        break;
      // FIXME:
      default: {
        throw new Error();
      }
    }

    setView(event.target.value);
    setCalendarView(calView);
  }, []);

  return (
    <EntitySearchApp
      hitsPerPage={2000}
      indexName="merged_visits"
      routing={false}
      SearchAdapter={CalendarAppAdapter}
    >
      {/* TODO: */}
      <CalendarAppScreenRename />

      {/* `${attribute} > ${start} AND ${attribute} < ${end}` */}
      {/* refine(
        `dateRangeCustom:${moment(datepickerValue[0]).unix()}|${moment(datepickerValue[1]).unix()}`
      ); */}
      <Root
        header={
          // null
          // TODO: Rename?
          // <Index indexName="merged_visits" indexId="merged_visits_all_time">
          <EntitySearchCalendarHeader
            availableSearchViews={['grid', 'list', 'map']}
            initialSearchView="grid"
            rightToolbarContent={
              <>
                {/* <Button value="year" onClick={changeView}>Year</Button> */}
                <IconButton
                  color="default"
                  sx={{ width: 40, height: 40 }}
                  value="month"
                  onClick={changeView}
                >
                  <FontAwesomeIcon icon={faCalendarDays} size="xs" />
                </IconButton>
                <IconButton
                  color="default"
                  sx={{ width: 40, height: 40 }}
                  value="week"
                  onClick={changeView}
                >
                  <FontAwesomeIcon icon={faCalendarWeek} size="xs" />
                </IconButton>
                <IconButton
                  color="default"
                  sx={{ width: 40, height: 40 }}
                  value="day"
                  onClick={changeView}
                >
                  <FontAwesomeIcon icon={faCalendarDay} size="xs" />
                </IconButton>
                <IconButton
                  color="default"
                  sx={{ width: 40, height: 40 }}
                  value="agenda"
                  onClick={changeView}
                >
                  <FontAwesomeIcon icon={faTableList} size="xs" />
                </IconButton>

                {/* <Button
                  color="success"
                  size="small"
                  variant="contained"
                  onClick={() =>
                    dispatch(
                      // FIXME: z-index 9999 + 1
                      openDialog({
                        children: (
                          <ServiceTicketCreateDialog onClose={() => dispatch(closeDialog())} />
                        ),
                        classes: {
                          // TODO: Move to FuseDialog?
                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                        },
                      })
                    )
                  }
                >
                  Create
                </Button> */}
              </>
            }
            onSearchViewChange={setSelectedHitsViewMode}
            // TODO: Rename
            leftSidebarToggle={(ev) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
            // TODO: Rename
            rightSidebarToggle={(ev) => {
              setRightSidebarOpen(!rightSidebarOpen);
            }}
          />
          // </Index>
        }
        content={
          <SizeMe
            // TODO: Review
            // refreshMode="debounce"
            monitorHeight
            monitorWidth={false}
          >
            {({ size }) => (
              <div className="flex flex-row w-full h-full">
                {/* TODO: */}
                <EntitySearchCalendarHits
                  // TODO: Expand?

                  // TODO:
                  // calendarEvents={calendarEvents}
                  calendarView={calendarView}
                  // customWithNavButtons={customWithNavButtons}
                />
              </div>
            )}
          </SizeMe>
        }
        // TODO: Why Max Width when Mobile?
        leftSidebarWidth={341}
        leftSidebarOpen={leftSidebarOpen}
        leftSidebarOnClose={() => {
          setLeftSidebarOpen(false);
        }}
        leftSidebarContent={
          // TODO:
          <EntitySearchCalendarDrawer
            // dateRangeAttribute="createdAtTimestamp"
            // TODO:
            filterDefs={calendarAppScreenFilterDefs}
            title="Calendar"
            toggleDrawer={(ev) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
            // TODO: Rename?
            // setCalendarDates={setCalendarDates}
          />
        }
        // TODO:
        // rightSidebarOpen={
        //   rightSidebarOpen
        //   // && selectedHitsViewMode !== 'list'
        // }
        // rightSidebarOnClose={() => {
        //   setRightSidebarOpen(false);
        // }}
        // rightSidebarContent={(
        //   <div className="flex-1 min-w-340">
        //     Stuff
        //   </div>
        // )}
        scroll="content"
      />
    </EntitySearchApp>
  );
};

export default withReducer('calendarAppScreen', reducer)(memo(CalendarAppScreen));
