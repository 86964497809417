import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Typography } from '@mui/material';
import { grey, lightBlue, red } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { WorkOrderIssueReleasePhaseDecisionSelectField } from 'app/shared-components/WorkOrderIssueReleasePhaseDecision';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const ServiceTicketCompleteReleasePhaseDialogWorkOrderIssues = ({
  defaultWorkOrderIssueReleasePhaseDecision,
  filteredWorkOrderIssues,
  name,
  serviceTicket,
}) => {
  const dispatch = useDispatch();
  const [expandedField, setExpandedField] = useState(null);
  const user = useSelector(selectUser);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const { append, fields, insert, move, prepend, remove, replace, swap, update } = useFieldArray({
    control,
    name,
  });

  const fieldCount = useRef(0);
  const watchCheckedWorkOrderIssues = watch('checkedWorkOrderIssues');
  const watchFields = watch(name);

  useEffect(() => {
    const checkedWorkOrderIssues = watchCheckedWorkOrderIssues
      .filter((el) => el)
      .map((workOrderIssueId) => ({ workOrderIssueId }));

    if (checkedWorkOrderIssues?.length > fields?.length) {
      const diff = _.differenceBy(checkedWorkOrderIssues, fields, 'workOrderIssueId');

      diff?.forEach(({ workOrderIssueId }) => {
        const workOrderIssue = filteredWorkOrderIssues.find(({ id }) => id === workOrderIssueId);

        append({
          decision: defaultWorkOrderIssueReleasePhaseDecision,
          resumeAt: null,
          workOrderIssueId,
        });
      });
    } else if (checkedWorkOrderIssues?.length < fields?.length) {
      const diff = _.differenceBy(fields, checkedWorkOrderIssues, 'workOrderIssueId');

      diff?.forEach(({ workOrderIssueId }) => {
        const removeIndex = fields.findIndex(
          (field) => field.workOrderIssueId === workOrderIssueId
        );

        remove(removeIndex);
      });
    }
  }, [
    append,
    defaultWorkOrderIssueReleasePhaseDecision,
    fields,
    filteredWorkOrderIssues,
    remove,
    watchCheckedWorkOrderIssues,
  ]);

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    if (fields?.length > fieldCount.current) {
      setExpandedField(_.get(_.last(fields || []), 'id'));
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  const handleChangeExpandedField = (fieldId) => (event, newFieldId) => {
    setExpandedField(newFieldId ? fieldId : false);
  };

  return (
    <>
      {errors?.[name] && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More Service Issues Contain Errors'}
        </Alert>
      )}

      <div className="mb-24">
        {fields.map((item, index) => {
          let borderLeftColor = grey[600];

          if (_.isArray(_.get(errors, name)) && errors[name][index]) {
            // eslint-disable-next-line prefer-destructuring
            borderLeftColor = red[800];
          } else if (expandedField === item.id) {
            // eslint-disable-next-line prefer-destructuring
            borderLeftColor = lightBlue[600];
          }

          const workOrderIssue = filteredWorkOrderIssues.find(
            ({ id }) => id === item.workOrderIssueId
          );

          return (
            <Accordion
              expanded={expandedField === item.id}
              key={item.id}
              variant="outlined"
              onChange={handleChangeExpandedField(item.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ borderLeft: `4px solid ${borderLeftColor}` }}
              >
                <div className="flex flex-1 items-center">
                  {_.isArray(_.get(errors, name)) &&
                    _.isPlainObject(_.get(errors, name)[index]) &&
                    _.some(Object.keys(_.get(errors, name)[index]), (key) =>
                      _.isPlainObject(_.get(errors, name)[index][key])
                    ) && (
                      <FontAwesomeIcon
                        className="mr-8 text-red-800"
                        icon={faCircleXmark}
                        size="lg"
                      />
                    )}

                  <Typography>{workOrderIssue?.serviceIssue?.name}</Typography>
                </div>
              </AccordionSummary>

              <AccordionDetails sx={{ borderLeft: `4px solid ${borderLeftColor}` }}>
                <Controller
                  control={control}
                  name={`${name}[${index}].decision`}
                  render={({ field }) => (
                    <WorkOrderIssueReleasePhaseDecisionSelectField
                      {...field}
                      className="mb-24"
                      error={_.get(errors, `${name}[${index}].decision`)}
                      filters={null}
                      isDisabled={false}
                      isClearable
                      isMulti={false}
                      placeholder="Select a Decision..."
                    />
                  )}
                />

                {watchFields?.[index]?.decision?.result?.enum === 'RELEASE_AND_PAUSE' && (
                  <>
                    <Controller
                      control={control}
                      name={`${name}[${index}].resumeAt`}
                      render={({ field }) => (
                        <div className="mb-24">
                          <CommonDatepicker
                            {...field}
                            controls={['calendar']}
                            disabled={false}
                            error={_.get(errors, `${name}[${index}].resumeAt`)}
                            min={moment().add(1, 'day').startOf('day')}
                            placeholder="Select Scheduled Resumption Date..."
                            select="date"
                          />
                        </div>
                      )}
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </>
  );
};

export default ServiceTicketCompleteReleasePhaseDialogWorkOrderIssues;
