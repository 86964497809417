import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_TASK_VIEW_DRAWER_SERVICE_TICKET_TASK_FRAGMENT = gql`
  fragment ServiceTicketTaskViewDrawerServiceTicketTaskFragment on ServiceTicketTask {
    id
    createdAt
    assignedOrganizationType {
      id
      enum
      name
    }
    completor {
      id
      name
    }
    completedAt
    description
    isCancelled
    isCompleted
    name
    serviceTicket {
      id
      serviceProvision {
        id
        name
        organizationConnection {
          id
          consumer {
            id
            name
            type {
              id
              enum
              name
            }
          }
          vendor {
            id
            name
            type {
              id
              enum
              name
            }
          }
        }
      }
    }
    targetServiceInvoiceReview {
      id
      decision {
        id
        enum
        name
      }
      serviceInvoice {
        id
      }
    }
    targetServiceTicketPhaseReview {
      id
      decision {
        id
        enum
        name
      }
    }
    targetType {
      id
      enum
      name
    }
    targetWorkOrder {
      id
    }
    targetWorkOrderIssueReview {
      id
      decision {
        id
        enum
        name
      }
      serviceTicketPhase {
        id
        enum
        name
      }
      workOrderIssue {
        id
        serviceIssue {
          id
          name
        }
      }
    }
    type {
      id
      enum
      name
    }
  }
`;
