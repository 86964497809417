// import { faStar as faRegStar } from '@fortawesome/free-regular-svg-icons';
import {
  faFingerprint,
  // faStar as faSolidStar,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import _ from '@lodash';
import {
  Avatar,
  // IconButton,
  ListItemAvatar,
  ListItemButton,
  // ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { jwtService } from 'app/providers/auth/services';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

const UserMenuTeamHitsListOption = ({ hit: team, onClick }) => {
  const user = useSelector(selectUser);

  const isActiveTeam = useMemo(
    () => user.data?.team?.id === team?.id,
    [user.data?.team?.id, team?.id]
  );

  // const isDefaultTeam = useMemo(
  //   () => team?.users?.find(({ user: _user }) => _user?.id === user?.id)?.isUserDefault,
  //   [team?.users, user?.id]
  // );

  // const isValidTeam = useMemo(
  //   () => !_.isEmpty(team?.users?.find(({ user: _user }) => _user?.id === user?.id)),
  //   [team?.users, user?.id]
  // );

  const handleChangeTeam = useCallback(
    ({ teamId }) => {
      if (typeof onClick === 'function') {
        onClick({ teamId });
      }

      setTimeout(() => jwtService.reassignSession({ teamId }), 250);
    },
    [onClick]
  );

  const ListOption = useMemo(() => {
    if (!team?.id) return null;

    return (
      <ListItemButton
        key={team.id}
        selected={isActiveTeam}
        onClick={() => {
          if (!isActiveTeam) {
            handleChangeTeam({ teamId: team.id });
          }
        }}
      >
        <ListItemAvatar>
          {isActiveTeam ? (
            <Avatar className="bg-light-blue-600">
              <FontAwesomeIcon icon={faFingerprint} size="sm" />
            </Avatar>
          ) : (
            <Avatar>
              <FontAwesomeIcon icon={faUsers} size="sm" />
            </Avatar>
          )}
        </ListItemAvatar>

        <ListItemText
          primary={team.name}
          primaryTypographyProps={{ className: 'mr-40', noWrap: true }}
          secondary={team.owner?.name}
          secondaryTypographyProps={{ className: 'mr-40', noWrap: true }}
        />

        {/* ROADMAP: Implement or Remove */}
        {/* {isValidTeam && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (!isDefaultTeam) {
                  jwtService.setDefaultUserTeam({ teamId: team?.id });
                }
              }}
            >
              {isDefaultTeam ? (
                <FontAwesomeIcon className="text-amber-600" icon={faSolidStar} size="sm" />
              ) : (
                <FontAwesomeIcon className="text-amber-600" icon={faRegStar} size="sm" />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        )} */}
      </ListItemButton>
    );
  }, [
    handleChangeTeam,
    isActiveTeam,
    // isDefaultTeam,
    // isValidTeam,
    team?.id,
    team?.name,
    team?.owner?.name,
  ]);

  return ListOption;
};

export default UserMenuTeamHitsListOption;
