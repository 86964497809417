import { forwardRef, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { CommonSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';

const WorkOrderIssueReviewDecisionSelectOption = (props) => {
  const workOrderIssueReviewDecision = props.data?.result;

  const item = useMemo(() => {
    if (!workOrderIssueReviewDecision) return null;

    return (
      <ListItem component="div" dense>
        <ListItemText
          disableTypography
          primary={<Typography variant="body2">{workOrderIssueReviewDecision.name}</Typography>}
        />
      </ListItem>
    );
  }, [workOrderIssueReviewDecision]);

  return <components.Option {...props}>{item}</components.Option>;
};

const WorkOrderIssueReviewDecisionSelectField = forwardRef(
  ({ className, filters, serviceTicketPhase, ...SelectProps }, rootRef) => {
    const dispatch = useDispatch();
    const [options, setOptions] = useState(null);

    useQuery(
      gql`
        query GetWorkOrderIssueReviewDecisionSelectFieldData(
          $where: WorkOrderIssueReviewDecisionWhereInput!
        ) {
          workOrderIssueReviewDecisions(where: $where) {
            id
            enum
            name
          }
        }
      `,
      {
        skip: !!options,
        variables: { where: {} },
        onCompleted: ({ workOrderIssueReviewDecisions }) =>
          setOptions(
            workOrderIssueReviewDecisions
              ?.filter((result) => {
                if (!['IN_PROGRESS'].includes(serviceTicketPhase?.enum)) {
                  return !['DECLINE_AND_PAUSE'].includes(result?.enum);
                }

                return true;
              })
              ?.map((result) => ({
                result,
                label: result?.name,
                value: result?.id,
              }))
          ),
        onError: ({ message }) =>
          dispatch(
            showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
          ),
      }
    );

    return (
      <div className={className} ref={rootRef}>
        <CommonSelect
          {...SelectProps}
          // ROADMAP: Implement
          // components={{ Option: WorkOrderIssueReviewDecisionSelectOption }}
          options={options}
        />
      </div>
    );
  }
);

export default WorkOrderIssueReviewDecisionSelectField;
