import _ from '@lodash';
import { Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const SegmentViewTabs = ({ basePath }) => {
  const [isPreviewable, setIsPreviewable] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const watchCommonTargetTypeField = useWatch({ name: 'commonTargetType' });
  const watchSegmentFacilityAreaExpressionCalculationField = useWatch({
    name: 'segmentFacilityAreaExpressionCalculation',
  });

  useEffect(() => {
    if (watchCommonTargetTypeField?.result?.enum === 'EQUIPMENT' && 1 === 2) {
      // TODO:
    } else if (
      watchCommonTargetTypeField?.result?.enum === 'FACILITY_AREA' &&
      !_.isEmpty(watchSegmentFacilityAreaExpressionCalculationField?.meilisearchFilter)
    ) {
      setIsPreviewable(true);
    } else {
      setIsPreviewable(false);
    }
  }, [
    watchCommonTargetTypeField?.result?.enum,
    watchSegmentFacilityAreaExpressionCalculationField?.meilisearchFilter,
  ]);

  const handleTabChange = (event, value) => navigate(value);

  return (
    <div
      className="w-full h-64 border-b-1"
      style={{ backgroundColor: theme.palette.mode === 'light' ? 'white' : '#1e293b' }}
    >
      <Tabs
        classes={{ root: 'w-full h-64 max-w-5xl mx-auto' }}
        indicatorColor="secondary"
        scrollButtons="auto"
        textColor="inherit"
        value={`../${basePath}`}
        variant="scrollable"
        onChange={handleTabChange}
      >
        <Tab className="h-64" label="Definition" value="../tabs/definition" />
        <Tab className="h-64" label="Sharing Rule" value="../tabs/sharing-rule" />
        <Tab className="h-64" disabled={!isPreviewable} label="Preview" value="../tabs/preview" />
      </Tabs>
    </div>
  );
};

export default SegmentViewTabs;
