import { useLazyQuery } from '@apollo/client';
import { faArrowLeft, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import { Avatar, Box, Chip, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { amber, blue, green, grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { showMessage } from 'app/store/fuse/messageSlice';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FETCH_SERVICE_ISSUE_VIEW_HEADER_DATA } from './queries';

const ServiceIssueViewHeader = ({ basePath }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();

  const [
    fetchServiceIssueViewHeaderData,
    {
      data: serviceIssueViewHeaderData,
      loading: serviceIssueViewHeaderLoading,
      refetch: serviceIssueViewHeaderRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_ISSUE_VIEW_HEADER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Issue Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceIssueViewHeaderLoading].includes(true),
    [serviceIssueViewHeaderLoading]
  );

  const serviceIssue = useMemo(
    () => serviceIssueViewHeaderData?.serviceIssue,
    [serviceIssueViewHeaderData?.serviceIssue]
  );

  const derivedStatus = useMemo(() => {
    let _derivedStatus;

    if (serviceIssue?.status?.enum === 'COMPLETED') {
      _derivedStatus = {
        color: grey[800],
        name: 'Completed',
      };
    } else if (serviceIssue?.status?.enum === 'IGNORED') {
      _derivedStatus = {
        color: amber[800],
        name: 'Ignored',
      };
    } else if (serviceIssue?.status?.enum === 'IN_PROGRESS') {
      _derivedStatus = {
        color: green[800],
        name: 'In Progress',
      };
    } else if (serviceIssue?.status?.enum === 'OPEN') {
      _derivedStatus = {
        color: blue[800],
        name: 'Open',
      };
    }

    return _derivedStatus;
  }, [serviceIssue]);

  useEffect(() => {
    if (params?.serviceIssueId) {
      fetchServiceIssueViewHeaderData({
        variables: { where: { id: params?.serviceIssueId } },
      });
    }
  }, [fetchServiceIssueViewHeaderData, params?.serviceIssueId]);

  const handleTabChange = (event, value) => navigate(value);

  return (
    <>
      {(loading && !fetched) || !serviceIssue ? (
        <div className="flex w-full justify-center items-center h-160">
          <FuseLoading />
        </div>
      ) : (
        <div className="flex flex-col">
          <div
            className="h-160"
            style={{ backgroundColor: theme.palette.mode === 'light' ? grey[600] : '#0a0e17' }}
          />

          <div className="flex flex-col flex-0 lg:flex-row items-center max-w-5xl w-full mx-auto px-32 lg:h-72">
            <div className="-mt-96 lg:-mt-88 rounded-full">
              <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.1 } }}>
                <Avatar
                  className="w-128 h-128 border-4"
                  sx={{ borderColor: 'background.paper' }}
                  // ROADMAP: Implement
                  src={null}
                >
                  <FontAwesomeIcon icon={faClipboardList} size="3x" />
                </Avatar>
              </motion.div>
            </div>

            <div className="flex flex-col items-center lg:items-start mt-16 lg:mt-0 lg:ml-32">
              <div className="flex items-center">
                <IconButton
                  size="small"
                  style={{ marginLeft: -4, marginRight: 4 }}
                  onClick={() => navigate('/service-issues/search')}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="xs" />
                </IconButton>

                <Typography className="text-lg font-bold leading-none">Service Issue</Typography>
              </div>

              <Typography color="text.secondary">
                Created: {moment(serviceIssue?.createdAt).format('MMM. DD, YYYY')}
              </Typography>
            </div>

            <div className="hidden lg:flex h-32 mx-32 border-l-2" />

            <div className="flex items-center mt-24 lg:mt-0 space-x-24">
              {derivedStatus && (
                <Chip
                  className="font-600 capitalize"
                  label={derivedStatus.name}
                  sx={{ color: 'white', bgcolor: derivedStatus.color }}
                />
              )}

              {serviceIssue?.serviceResponseSeverity && (
                <Chip
                  className="font-600 capitalize"
                  label={[
                    serviceIssue?.serviceResponseSeverity.name,
                    `(${serviceIssue?.serviceResponseSeverity.value} ${serviceIssue?.serviceResponseSeverity.commonTemporalDurationUnit?.name})`,
                  ].join(' ')}
                  sx={{
                    color: 'white',
                    bgcolor: serviceIssue?.serviceResponseSeverity.color,
                  }}
                />
              )}
            </div>

            <div className="flex flex-1 justify-end my-16 lg:my-0">
              <Tabs
                classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
                className="-mx-4 min-h-40"
                indicatorColor="primary"
                scrollButtons={false}
                TabIndicatorProps={{
                  children: (
                    <Box
                      sx={{ bgcolor: 'text.disabled' }}
                      className="w-full h-full rounded-full opacity-20"
                    />
                  ),
                }}
                textColor="inherit"
                value={`../${basePath}`}
                variant="scrollable"
                onChange={handleTabChange}
              >
                <Tab
                  className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                  disableRipple
                  label="Overview"
                  value="../tabs/overview"
                />
                <Tab
                  className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                  disableRipple
                  label="File Attachments"
                  value="../tabs/file-attachments"
                />
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceIssueViewHeader;
