import _ from '@lodash';
import { forwardRef, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonAsyncPaginateSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';

const EntitySearchSelectField = forwardRef(
  (
    {
      className,
      filter,
      idField,
      indexName,
      labelFn,
      nameField = 'name',
      skipInitialOptions,
      sort,
      ...SelectProps
    },
    rootRef
  ) => {
    const { axiosSearchClient } = useMeilisearch();
    const dispatch = useDispatch();
    const { getFieldState, setValue } = useFormContext();
    const initialized = useRef(false);

    const fieldState = getFieldState(SelectProps?.name);

    useEffect(() => {
      const getInitialOptions = async () => {
        const initialValue = _.isArray(SelectProps?.value)
          ? [...SelectProps?.value]
          : [SelectProps?.value];

        await Promise.all(
          (initialValue || []).map(
            (id) =>
              // eslint-disable-next-line no-async-promise-executor
              new Promise(async (resolveInitialOption, rejectInitialOption) => {
                try {
                  const {
                    data: { hits },
                  } = await axiosSearchClient.post(`/indexes/${indexName}/search`, {
                    filter: `${idField} = '${id}'`,
                  });

                  const hit = hits?.[0];

                  const option = hit
                    ? {
                        hit,
                        label: typeof labelFn === 'function' ? labelFn(hit) : _.get(hit, nameField),
                        value: _.get(hit, idField),
                      }
                    : null;

                  setValue(SelectProps?.name, option);

                  resolveInitialOption(option);
                } catch (err) {
                  rejectInitialOption(err);
                }
              })
          )
        );

        initialized.current = true;
      };

      if (
        axiosSearchClient &&
        !fieldState?.isDirty &&
        idField &&
        indexName &&
        !initialized.current &&
        nameField &&
        !_.isEmpty(SelectProps?.value) &&
        !skipInitialOptions
      ) {
        getInitialOptions();
      }
    }, [
      axiosSearchClient,
      fieldState?.isDirty,
      idField,
      indexName,
      labelFn,
      nameField,
      SelectProps?.name,
      SelectProps?.value,
      setValue,
      skipInitialOptions,
    ]);

    const loadOptions = async (match, loadedOptions) => {
      let output = {
        options: [],
        hasMore: false,
      };

      try {
        const {
          data: { estimatedTotalHits, hits },
        } = await axiosSearchClient.post(`/indexes/${indexName}/search`, {
          filter,
          limit: 20,
          offset: loadedOptions.length,
          q: match,
          sort,
        });

        const options = (hits || []).map((hit) => ({
          hit,
          label: typeof labelFn === 'function' ? labelFn(hit) : _.get(hit, nameField),
          value: _.get(hit, idField),
        }));

        output = {
          options,
          hasMore: estimatedTotalHits > loadedOptions.length,
        };
      } catch (err) {
        dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
      }

      return output;
    };

    return (
      <div className={className} ref={rootRef}>
        <CommonAsyncPaginateSelect
          {...SelectProps}
          key={JSON.stringify({ filter, idField, indexName, nameField, sort })}
          loadOptions={loadOptions}
        />
      </div>
    );
  }
);

export default EntitySearchSelectField;
