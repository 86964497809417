// eslint-disable-next-line import/prefer-default-export
export const facilitySearchScreenFilterDefs = [
  {
    name: 'Facility',
    refinements: [
      {
        attribute: 'name',
        label: 'Name',
        operator: 'or',
      },
      {
        attribute: 'city',
        label: 'City',
        operator: 'or',
      },
      {
        attribute: 'region',
        label: 'Region',
        operator: 'or',
      },
      {
        attribute: 'franchisor.name',
        label: 'Franchisor',
        operator: 'or',
      },
      {
        attribute: 'operator.name',
        label: 'Operator',
        operator: 'or',
      },
    ],
  },
];
