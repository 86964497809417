// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import history from '@history';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import { matchPath } from 'react-router-dom';
import routes from 'app/configs/routesConfig';
import App from './app/App';
import './i18n';
import '@mobiscroll/css/mobiscroll.react.min.css';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-simple-keyboard/build/css/index.css';
import 'video.js/dist/video-js.css';
import 'yet-another-react-lightbox/styles.css';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';

// DEPRECATED:
// import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';

// TODO: Review
Sentry.init({
  // TODO: Replace with .env
  dsn: 'https://4b819a2c02304dcb915bf2b17566ef2f@sentry.hyvery.cloud/11',
  // TODO: Replace with .env
  environment: 'development',
  integrations: [
    new BrowserTracing({
      beforeNavigate: (context) => {
        console.log({ context });
        return {
          ...context,
          // You could use your UI's routing library to find the matching
          // route template here. We don't have one right now, so do some basic
          // parameter replacements.
          // name: location.pathname
          //   .replace(/\/[a-f0-9]{32}/g, "/<hash>")
          //   .replace(/\/\d+/g, "/<digits>"),
        };
      },
      shouldCreateSpanForRequest: (url) => {
        console.log({ url });
        // Do not create spans for outgoing requests to a `/health/` endpoint
        return !url.match(/\/health\/?$/);
      },

      // eslint-disable-next-line prettier/prettier
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, (a, b) => matchPath(b, a)),

      tracingOrigins: [
      // tracePropagationTargets: [
        'localhost',
        /^https:\/\/dev.ultraviolet-api\.hyvery\.io.+/,
        /^https:\/\/dev.ultraviolet-sso\.hyvery\.io.+/,
      ],
    }),
  ],

  // TODO: Replace with .env
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// DEPRECATED:
// reportWebVitals();

// ROADMAP: Integrate Workbox or Remove
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// DEPRECATED:
// serviceWorker.unregister();
