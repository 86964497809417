import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DemoSidebarContent from '@fuse/core/DemoSidebarContent';
import {
  AppBar,
  Avatar,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { EntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchDrawer } from 'app/shared-components/EntitySearch/EntitySearchDrawer';
import { EntitySearchHeader } from 'app/shared-components/EntitySearch/EntitySearchHeader';
import { EntitySearchInfiniteHitsGrid } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsGrid';
import { EntitySearchInfiniteHitsList } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsList';
import { EntitySearchInfiniteHitsMap } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsMap';
// import { ServiceRequestCreateDialog } from 'app/shared-components/ServiceRequest/ServiceRequestCreateDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
// import { AssetSearchAdapter } from './adapters';
// import { assetSearchScreenColumnDefs } from './columnDefs';
// import { assetSearchScreenFilterDefs } from './filterDefs';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import ServiceProvisionManageListProvisionList from './ServiceProvisionManageListProvisionList';

const ServiceProvisionManageListOrganizationItem = ({
  index,
  organization,
  renameOpen,
  serviceProvisions,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const toggleOpen = () => setOpen(!open);

  return (
    <Draggable draggableId={`data-${organization.id}`} index={index} type="list">
      {(provided, snapshot) => {
        // PLACEHOLDER

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              backgroundColor: theme.palette.background.default,
            }}
          >
            <ListItem
              divider
              // TODO: Shadow like ProvisionItem
              className="px-40 h-64 group"
              sx={{ bgcolor: 'background.default' }}
              button
              onClick={toggleOpen}
              // component={NavLinkAdapter}
              // to={`/apps/tasks/${data.id}`}
              // ref={provided2.innerRef}
              // {...provided2.draggableProps}
            >
              <div
                className="md:hidden absolute flex items-center justify-center inset-y-0 left-0 w-32 cursor-move md:group-hover:flex"
                {...provided.dragHandleProps}
              >
                <FuseSvgIcon sx={{ color: 'text.disabled' }} size={20}>
                  heroicons-solid:menu
                </FuseSvgIcon>
              </div>
              <ListItemText
                classes={{ root: 'm-0', primary: 'font-semibold text-16 truncate' }}
                // primary={data.title}
                primary={organization.name}
              />
            </ListItem>
            {/* <Divider /> */}

            {/* TODO: unmountOnExit / timeout */}
            <Collapse
              // TODO:
              in={renameOpen && open}
              timeout={renameOpen ? 'auto' : 0}
              unmountOnExit={false}
            >
              <ServiceProvisionManageListProvisionList
                // TODO;
                organization={organization}
                serviceProvisions={serviceProvisions}
                index={index}
              />
            </Collapse>
          </div>
        );
      }}
    </Draggable>
  );
};

export default ServiceProvisionManageListOrganizationItem;
