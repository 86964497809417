import { gql } from '@apollo/client';

export const SERVICE_TICKET_REMINDER_UPDATE_DIALOG_SERVICE_TICKET_REMINDER_FRAGMENT = gql`
  fragment ServiceTicketReminderUpdateDialogServiceTicketReminderFragment on ServiceTicketReminder {
    id
    createdAt
    assignedTeam {
      id
      name
    }
    completedAt
    completor {
      id
      name
    }
    dueAt
    isCompleted
    name
    notes
    owner {
      id
      name
    }
    priority {
      id
      enum
      name
    }
    serviceTicket {
      id
      status {
        id
        enum
        name
      }
    }
  }
`;

export const SERVICE_TICKET_REMINDER_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketReminderUpdateDialogServiceTicketFragment on ServiceTicket {
    id
    reminders {
      id
      ...ServiceTicketReminderUpdateDialogServiceTicketReminderFragment
    }
  }
  ${SERVICE_TICKET_REMINDER_UPDATE_DIALOG_SERVICE_TICKET_REMINDER_FRAGMENT}
`;
