// eslint-disable-next-line import/prefer-default-export
export const serviceProvisionSearchScreenFilterDefs = [
  {
    name: 'Service Provision',
    refinements: [
      {
        attribute: 'currentVersion.status.name',
        label: 'Status',
        operator: 'or',
      },
      {
        attribute: 'tags.name',
        label: 'Tags',
        operator: 'or',
      },
      {
        attribute: 'owner.name',
        label: 'Owner',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Organization Connection',
    refinements: [
      {
        attribute: 'organizationConnection.consumer.name',
        label: 'Consumer',
        operator: 'or',
      },
      {
        attribute: 'organizationConnection.vendor.name',
        label: 'Vendor',
        operator: 'or',
      },
    ],
  },
];
