import { gql } from '@apollo/client';
import {
  SERVICE_TICKET_REMINDER_VIEW_DRAWER_SERVICE_TICKET_FRAGMENT,
  SERVICE_TICKET_REMINDER_VIEW_DRAWER_SERVICE_TICKET_REMINDER_FRAGMENT,
} from './fragments';

export const COMPLETE_SERVICE_TICKET_REMINDER = gql`
  mutation CompleteServiceTicketReminder(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketReminderCompleteInput!
  ) {
    completeServiceTicketReminder(where: $where, data: $data) {
      id
      ...ServiceTicketReminderViewDrawerServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_REMINDER_VIEW_DRAWER_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_SERVICE_TICKET_REMINDER_VIEW_DRAWER_DATA = gql`
  query FetchServiceTicketReminderViewDrawerData($where: ServiceTicketReminderWhereUniqueInput!) {
    serviceTicketReminder(where: $where) {
      id
      ...ServiceTicketReminderViewDrawerServiceTicketReminderFragment
    }
  }
  ${SERVICE_TICKET_REMINDER_VIEW_DRAWER_SERVICE_TICKET_REMINDER_FRAGMENT}
`;

export const UNCOMPLETE_SERVICE_TICKET_REMINDER = gql`
  mutation UncompleteServiceTicketReminder(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketReminderUncompleteInput!
  ) {
    uncompleteServiceTicketReminder(where: $where, data: $data) {
      id
      ...ServiceTicketReminderViewDrawerServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_REMINDER_VIEW_DRAWER_SERVICE_TICKET_FRAGMENT}
`;
