import { Eventcalendar } from '@mobiscroll/js/mobiscroll.react.min';
import { blue, purple } from '@mui/material/colors';
import {
  selectCalendarAppScreenDates,
  setDatesContentDateRange,
  setDatesSelectedDate,
} from 'app/screens/CalendarAppScreen/store/datesSlice';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import moment from 'moment';
import { useCallback, useMemo, useRef } from 'react';
import { useHits } from 'react-instantsearch';
import { useDispatch, useSelector } from 'react-redux';

const EntitySearchCalendarHits = ({
  // TODO: Expand?

  // TODO:
  // calendarEvents,
  calendarView,
  // customWithNavButtons,
}) => {
  const dispatch = useDispatch();
  const mainTheme = useSelector(selectMainTheme);
  // const {
  //   hits,
  //   currentPageHits,
  //   results,
  //   isFirstPage,
  //   isLastPage,
  //   showPrevious,
  //   showMore,
  //   sendEvent,
  // } = useInfiniteHits();
  const { hits } = useHits();

  const dates = useSelector(selectCalendarAppScreenDates);

  const handleSelectedDateChange = useCallback(
    (args) => {
      dispatch(setDatesSelectedDate(args.date));
    },
    [dispatch]
  );

  const timer = useRef(null);
  const handlePageLoading = useCallback(
    (args) => {
      console.log('// LOADING 01');

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        console.log('// LOADING 02');
        // dispatch(
        //   setDatesDrawerDateRange({
        //     firstDay: moment(args?.firstDay).format(),
        //     lastDay: moment(args?.lastDay).format(),
        //   })
        // );

        if (args?.firstDay && args?.lastDay) {
          console.log('// LOADING 03');
          // refine([moment(args?.firstDay).unix(), moment(args?.lastDay).unix()]);
          // reset();
          // refine(
          //   `dateRangeCustom:${moment(args?.firstDay).unix()}|${moment(args?.lastDay).unix()}`
          // );
          dispatch(
            setDatesContentDateRange({
              firstDay: args?.firstDay,
              lastDay: args?.lastDay,
            })
          );
        }
      }, 200);

      // dispatch(
      //   setDatesDrawerDateRange({
      // setCalendarDates({
      //   firstDay: moment(args?.firstDay).format(),
      //   lastDay: moment(args?.lastDay).format(),
      // });
      // );
    },
    // [setCalendarDates]
    // [dispatch]
    // [refine]
    [dispatch]
  );
  // console.log('// TODO', { currentPageHits, hits });
  // useEffect(() => {
  //   let events = [];

  //   if (_.isArray(hits)) {
  //     hits

  //   }
  // }, [hits]);

  const calendarEvents = useMemo(() => {
    console.log('// CALENDAR', { hits });

    const _calendarEvents = hits?.map((hit) => {
      let color;
      let title;

      if (hit.__typename === 'ServiceVisit') {
        // eslint-disable-next-line prefer-destructuring
        color = blue[600];
        title = 'Service Visit';
      } else if (hit.__typename === 'SiteVisit') {
        // eslint-disable-next-line prefer-destructuring
        color = purple[600];
        title = 'Site Visit';
      } else {
        return null;
      }

      return {
        allDay: false,
        color,
        end: moment(hit.windowEndAt).format(),
        start: moment(hit.windowStartAt).format(),
        title,
      };
    });

    return _calendarEvents || [];
  }, [hits]);

  return (
    <div className="w-full h-full">
      <Eventcalendar
        // TODO:
        // cssClass="md-custom-header"
        // height={750}
        // theme

        // dataTimezone="utc"
        // displayTimezone="local"
        // timezonePlugin={momentTimezone}

        // TODO:
        maxEventStack={2}

        clickToCreate={false}
        dragToCreate={false}
        dragToMove={false}
        dragToResize={false}
        eventDelete={false}
        // renderHeader={customWithNavButtons}
        view={calendarView}
        data={calendarEvents || []}
        themeVariant={mainTheme?.palette.mode}
        selectedDate={dates?.selectedDate}
        onPageLoading={handlePageLoading}
        onSelectedDateChange={handleSelectedDateChange}
      />
    </div>
  );
};

export default EntitySearchCalendarHits;
