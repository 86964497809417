import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_VIEW_DRAWER_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketViewDrawerServiceTicketFragment on ServiceTicket {
    id
    approvalPhaseEndedAt
    approvalPhaseStartedAt
    chatChannels {
      id
      isCollaborative
    }
    completeBy
    deferredUntil
    draftPhaseEndedAt
    draftPhaseStartedAt
    facility {
      id
      franchiseNumber
      name
    }
    inProgressPhaseEndedAt
    inProgressPhaseStartedAt
    isApprovalPhaseCompleted
    isDraftPhaseCompleted
    isInProgressPhaseCompleted
    isQuotingPhaseCompleted
    isReleasePhaseCompleted
    isSiteVisitPhaseCompleted
    phase {
      id
      enum
      name
    }
    programCampaign {
      id
      name
    }
    quotingPhaseEndedAt
    quotingPhaseStartedAt
    releasePhaseEndedAt
    releasePhaseStartedAt
    serviceProvision {
      id
      name
      organizationConnection {
        id
        consumer {
          id
          name
          type {
            id
            enum
            name
          }
        }
        vendor {
          id
          name
          type {
            id
            enum
            name
          }
        }
      }
    }
    serviceReferenceNumber
    serviceResponseSeverity {
      id
      color
      commonTemporalDurationUnit {
        id
        enum
        name
      }
      name
      ordinal
      value
    }
    serviceVisits {
      id
      windowEndAt
      windowStartAt
    }
    siteVisitPhaseEndedAt
    siteVisitPhaseStartedAt
    siteVisits {
      id
      windowEndAt
      windowStartAt
    }
    status {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        serviceIssue {
          id
          serviceResponseSeverity {
            id
            color
            commonTemporalDurationUnit {
              id
              enum
              name
            }
            name
            ordinal
            value
          }
        }
      }
    }
  }
`;
