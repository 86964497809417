import _ from '@lodash';
import { GlobalSearchBarCommonHeader, GlobalSearchBarCommonSeeAllButton } from '../components';

const createServiceTicketsPlugin = ({
  axiosSearchClient,
  handleHideGlobalSearchBar,
  indexName = 'service_tickets',
  limit = 10,
  navigate,
  sectionName = 'service-tickets',
  title = 'Service Tickets',
}) => ({
  getSources: ({ query: upperQuery, state: upperState }) => {
    if (!upperQuery && _.isEmpty(upperState?.context.tagsPlugin?.tags)) {
      return [];
    }

    return [
      {
        sourceId: 'serviceTicketsPlugin',
        getItems: async ({ query, setContext, state }) => {
          try {
            const facilityTags = state.context.tagsPlugin?.tags?.filter(
              (tag) => tag.__typename === 'Facility'
            );

            const { data } = await axiosSearchClient.post(`/indexes/${indexName}/search`, {
              // TODO: Expand
              attributesToHighlight: [
                'facility.franchiseNumber',
                'facility.name',
                'serviceReferenceNumber',
              ],
              // TODO: Expand
              filter: [
                ...(!_.isEmpty(facilityTags)
                  ? [`facility.id IN [${facilityTags?.map((tag) => `'${tag?.id}'`)}]`]
                  : []),
              ],
              highlightPreTag: '<mark>',
              highlightPostTag: '</mark>',
              limit,
              q: query,
            });

            setContext({
              estimatedTotalHits: data.estimatedTotalHits,
            });

            return (data?.hits || []).map((hit) => ({ ...hit, __typename: 'ServiceTicket' }));
          } catch (err) {
            return [];
          }
        },
        getItemUrl: ({ item }) => {
          return `/${sectionName}/view/${item?.id}`;
        },
        templates: {
          header: (props) => <GlobalSearchBarCommonHeader title={title} {...props} />,
          // TODO:
          item: ({ item, ...props }) => {
            return (
              <div>
                <div
                  dangerouslySetInnerHTML={{ __html: item?._formatted.serviceReferenceNumber }}
                />
                <div className="flex items-center">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?._formatted.facility?.franchiseNumber,
                    }}
                  />

                  <div className="mx-1">-</div>

                  <div dangerouslySetInnerHTML={{ __html: item?._formatted.facility?.name }} />
                </div>
              </div>
            );
          },
          footer: ({ state, ...props }) => {
            const filterSearchParams = {};
            const querySearchParams = { [`${indexName}[query]`]: state.query };

            state.context.tagsPlugin?.tags
              ?.filter((tag) => tag.__typename === 'Facility')
              ?.forEach((tag, index) => {
                filterSearchParams[`${indexName}[refinementList][${'facility.name'}][${index}]`] =
                  tag.name;
              });

            return (
              state.context.estimatedTotalHits > limit && (
                <div className="flex flex-1 justify-center py-12">
                  <GlobalSearchBarCommonSeeAllButton
                    filterSearchParams={filterSearchParams}
                    handleHideGlobalSearchBar={handleHideGlobalSearchBar}
                    querySearchParams={querySearchParams}
                    navigate={navigate}
                    sectionName={sectionName}
                    state={state}
                    title={title}
                    {...props}
                  />
                </div>
              )
            );
          },
        },
        onSelect: ({ item, itemUrl, setIsOpen }) => {
          if (itemUrl) {
            navigate(itemUrl);
          }

          if (handleHideGlobalSearchBar) {
            handleHideGlobalSearchBar();
          }

          // TODO: Reset Query?
          setIsOpen(false);
        },
      },
    ];
  },
});

export default createServiceTicketsPlugin;
