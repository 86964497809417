import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { AccountingProjectCreateDialog } from 'app/shared-components/AccountingProject/AccountingProjectCreateDialog';
import {
  EntitySearchAppProvider,
  makeEntitySearchCommonClientAdapter,
  useEntitySearchApp,
} from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchDrawer } from 'app/shared-components/EntitySearch/EntitySearchDrawer';
import { EntitySearchHeader } from 'app/shared-components/EntitySearch/EntitySearchHeader';
import { EntitySearchInfiniteHitsGrid } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsGrid';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { buildAccountingProjectSearchScreenColumnDefs } from './columnDefs';
import { accountingProjectSearchScreenFilterDefs } from './filterDefs';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

const AccountingProjectSearchScreenRoot = () => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  // TODO: Based on selectedEntity
  const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile);
  const { setRefreshSearch, viewMode } = useEntitySearchApp();

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
    // TODO: Based on selectedEntity
    setRightSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <Root
      header={
        <EntitySearchHeader
          dateRangeAttribute="createdAtTimestamp"
          leftSidebarToggle={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
          rightSidebarToggle={(ev) => {
            setRightSidebarOpen(!rightSidebarOpen);
          }}
          rightToolbarContent={
            <>
              <Button
                color="success"
                size="small"
                variant="contained"
                onClick={() =>
                  dispatch(
                    // FIXME: z-index 9999 + 1
                    openDialog({
                      children: (
                        <AccountingProjectCreateDialog
                          onClose={() => dispatch(closeDialog())}
                          onSuccess={({ createAccountingProject }) =>
                            navigate(`/accounting-projects/view/${createAccountingProject.id}`)
                          }
                        />
                      ),
                      classes: {
                        // TODO: Move to FuseDialog?
                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                      },
                    })
                  )
                }
              >
                Create
              </Button>
            </>
          }
        />
      }
      content={
        <SizeMe
          // TODO: Review
          // refreshMode="debounce"
          monitorHeight
          monitorWidth={false}
        >
          {({ size }) => (
            <div className="flex flex-row w-full h-full">
              {viewMode === 'grid' && (
                <EntitySearchInfiniteHitsGrid
                  onRowClicked={({ id }) => id && navigate(`/accounting-projects/view/${id}`)}
                  {...size}
                />
              )}
            </div>
          )}
        </SizeMe>
      }
      // TODO: Review
      leftSidebarWidth={341}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarContent={
        <EntitySearchDrawer
          dateRangeAttribute="createdAtTimestamp"
          filterDefs={accountingProjectSearchScreenFilterDefs}
          title="Accounting Projects"
          toggleDrawer={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
        />
      }
      // TODO:
      // rightSidebarOpen={
      //   rightSidebarOpen
      //   // && selectedHitsViewMode !== 'list'
      // }
      // rightSidebarOnClose={() => {
      //   setRightSidebarOpen(false);
      // }}
      // rightSidebarContent={(
      //   <div className="flex-1 min-w-340">
      //     Stuff
      //   </div>
      // )}
      scroll="content"
    />
  );
};

const AccountingProjectSearchScreen = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const accountingProjectSearchScreenColumnDefs = buildAccountingProjectSearchScreenColumnDefs({
    theme,
    user,
  });

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      indexName="accounting_projects"
      initialColumnDefs={accountingProjectSearchScreenColumnDefs}
      initialViewMode="grid"
      makeSearchAdapter={makeEntitySearchCommonClientAdapter}
      makeSearchAdapterParams={{ dateRangeAttribute: 'createdAtTimestamp' }}
    >
      <AccountingProjectSearchScreenRoot />
    </EntitySearchAppProvider>
  );
};

export default AccountingProjectSearchScreen;
