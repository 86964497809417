import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PROGRAM_VIEW_DRAWER_PROGRAM_FRAGMENT = gql`
  fragment ProgramViewDrawerProgramFragment on Program {
    id

    # TODO:
    name
  }
`;
