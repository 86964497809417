import { useLazyQuery, useMutation } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonCurrencyField } from 'app/shared-components/Common';
import { CommonDiscountRateTypeSelectField } from 'app/shared-components/CommonDiscountRateType';
import { CommonStandardRateTypeSelectField } from 'app/shared-components/CommonStandardRateType';
import { CsiClassificationHitsListOption } from 'app/shared-components/CsiClassification';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_DATA,
  UPDATE_WORK_ORDER_ISSUE_ACTION_ITEM,
} from './queries';

const defaultValues = {
  commonStandardRateType: null,
  commonStandardFlatRate: null,
  commonStandardHourlyRate: null,
  commonDiscountRate: null,
  csiClassification: null,
  description: '',
  notToExceedAmount: null,
};

const schema = yup.object().shape({
  commonStandardRateType: yup.mixed().when('serviceTicketPhaseEnum', {
    is: (val) => val === 'IN_PROGRESS' || val === 'QUOTING',
    then: yup.object().nullable().required('A standard rate type must be provided.'),
  }),
  commonStandardFlatRate: yup.mixed().when('commonStandardRateType', {
    is: (val) => val?.result.enum === 'FLAT_RATE',
    then: yup.object().shape({
      quantity: yup
        .number()
        .nullable()
        .min(0.01, 'A value must be provided.')
        .required('An amount must be provided.'),
    }),
  }),
  commonStandardHourlyRate: yup.mixed().when('commonStandardRateType', {
    is: (val) => val?.result.enum === 'HOURLY_RATE',
    then: yup.object().shape({
      technicians: yup
        .number()
        .nullable()
        .min(1, 'A value must be provided.')
        .required('A value must be provided.'),
      hours: yup
        .number()
        .nullable()
        .min(0.01, 'A value must be provided.')
        .required('A value must be provided.'),
      rateAmount: yup
        .number()
        .nullable()
        .min(0.01, 'A value must be provided.')
        .required('A value must be provided.'),
    }),
  }),
  // ROADMAP: Doesn't Clear Properly When Switching `commonDiscountRate.type`
  commonDiscountRate: yup.object().shape({
    type: yup.object().nullable().optional(),
    value: yup.mixed().when('type', {
      is: (val) => val,
      then: yup
        .number()
        .nullable()
        .min(0.01, 'A value must be provided.')
        .required('A value must be provided.'),
    }),
  }),
  csiClassification: yup.object().nullable().required('A CSI Classification must be provided.'),
  description: yup.string().nullable().optional(),
  notToExceedAmount: yup.mixed().when('serviceTicketPhaseEnum', {
    is: (val) => val === 'DRAFT',
    then: yup
      .number()
      .nullable()
      .min(0.01, 'A value must be provided.')
      .required('A NTE must be provided.'),
  }),
});

const WorkOrderIssueActionItemUpdateDialog = ({
  serviceTicketId,
  workOrderIssueActionItemId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueActionItemUpdateDialogData,
    {
      data: workOrderIssueActionItemUpdateDialogData,
      loading: workOrderIssueActionItemUpdateDialogLoading,
      refetch: workOrderIssueActionItemUpdateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Work Order Issue Action Item Data',
          variant: 'error',
        })
      );
    },
  });

  const [updateWorkOrderIssueActionItem, { loading: updateWorkOrderIssueActionItemLoading }] =
    useMutation(UPDATE_WORK_ORDER_ISSUE_ACTION_ITEM, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Action Item Successfully Updated',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Updating Work Order Issue Action Item', variant: 'error' })
        );
      },
    });

  const loading = useMemo(
    () =>
      [updateWorkOrderIssueActionItemLoading, workOrderIssueActionItemUpdateDialogLoading].includes(
        true
      ),
    [updateWorkOrderIssueActionItemLoading, workOrderIssueActionItemUpdateDialogLoading]
  );

  const workOrderIssueActionItem = useMemo(
    () => workOrderIssueActionItemUpdateDialogData?.workOrderIssueActionItem,
    [workOrderIssueActionItemUpdateDialogData?.workOrderIssueActionItem]
  );

  const csiClassificationFilter = useMemo(() => {
    let _csiClassificationFilter = [];

    const workOrderIssue = workOrderIssueActionItem?.workOrderIssue;

    if (!_.isEmpty(workOrderIssue?.accountingWorkOrderType?.targetCsiClassifications)) {
      _csiClassificationFilter = [
        ..._csiClassificationFilter,
        `id IN [${workOrderIssue?.accountingWorkOrderType?.targetCsiClassifications
          .map((targetCsiClassification) => `'${targetCsiClassification.id}'`)
          .join(',')}]`,
      ];
    }

    if (workOrderIssue?.serviceIssue.commonTargetType.enum === 'FACILITY_AREA') {
      _csiClassificationFilter = [
        ..._csiClassificationFilter,
        `targetFacilityAreaTypes.facilityAreaType.id IN [${workOrderIssue.serviceIssue.targetFacilityAreas
          ?.map((targetFacilityArea) => {
            return `'${targetFacilityArea?.type?.id}'`;
          })
          .join(',')}]`,
      ];
    }

    _csiClassificationFilter = [
      ..._csiClassificationFilter,
      `id NOT IN [${workOrderIssue?.actionItems?.map(
        ({ csiClassification }) => `'${csiClassification?.id}'`
      )}]`,
    ];

    return _csiClassificationFilter;
  }, [workOrderIssueActionItem?.workOrderIssue]);

  useEffect(() => {
    if (workOrderIssueActionItem) {
      const formData = {
        commonDiscountRate: {
          // ROADMAP: Auto-Select At Component
          type: workOrderIssueActionItem.commonDiscountRate?.type
            ? {
                label: workOrderIssueActionItem.commonDiscountRate?.type?.name,
                value: workOrderIssueActionItem.commonDiscountRate?.type?.id,
                result: workOrderIssueActionItem.commonDiscountRate?.type,
              }
            : null,
          value: workOrderIssueActionItem.commonDiscountRate?.value,
        },
        commonStandardFlatRate: {
          quantity: workOrderIssueActionItem.commonStandardFlatRate?.quantity,
        },
        commonStandardHourlyRate: {
          hours: workOrderIssueActionItem.commonStandardHourlyRate?.hours,
          rateAmount: workOrderIssueActionItem.commonStandardHourlyRate?.rateAmount,
          technicians: workOrderIssueActionItem.commonStandardHourlyRate?.technicians,
        },
        // ROADMAP: Auto-Select At Component
        commonStandardRateType: workOrderIssueActionItem.commonStandardRateType
          ? {
              label: workOrderIssueActionItem.commonStandardRateType?.name,
              value: workOrderIssueActionItem.commonStandardRateType?.id,
              result: workOrderIssueActionItem.commonStandardRateType,
            }
          : null,
        csiClassification: workOrderIssueActionItem.csiClassification?.id,
        description: workOrderIssueActionItem.description,
        notToExceedAmount: workOrderIssueActionItem.notToExceedAmount,
        serviceTicketPhaseEnum:
          workOrderIssueActionItem.workOrderIssue?.workOrder?.serviceTicket?.phase.enum,
      };

      reset(formData);
    }
  }, [reset, workOrderIssueActionItem]);

  useEffect(() => {
    if (workOrderIssueActionItemId) {
      fetchWorkOrderIssueActionItemUpdateDialogData({
        variables: { where: { id: workOrderIssueActionItemId } },
      });
    }
  }, [fetchWorkOrderIssueActionItemUpdateDialogData, workOrderIssueActionItemId]);

  const onSubmit = async (data) => {
    try {
      const updateData = {
        id: workOrderIssueActionItemId,
        csiClassification: { id: data?.csiClassification?.value },
        description: data?.description,
      };

      if (data?.serviceTicketPhaseEnum === 'DRAFT') {
        updateData.notToExceedAmount = data?.notToExceedAmount;
      } else if (
        data?.serviceTicketPhaseEnum === 'IN_PROGRESS' ||
        data?.serviceTicketPhaseEnum === 'QUOTING'
      ) {
        updateData.commonStandardRateType = { id: data?.commonStandardRateType?.value };

        if (data?.commonStandardRateType?.result?.enum === 'FLAT_RATE') {
          updateData.commonStandardFlatRate = data?.commonStandardFlatRate;
        } else if (data?.commonStandardRateType?.result?.enum === 'HOURLY_RATE') {
          updateData.commonStandardHourlyRate = data?.commonStandardHourlyRate;
        }

        if (data?.commonDiscountRate?.type?.value && data?.commonDiscountRate?.value) {
          updateData.commonDiscountRate = {
            type: { id: data?.commonDiscountRate?.type?.value },
            value: data?.commonDiscountRate?.value,
          };
        }
      }

      await updateWorkOrderIssueActionItem({
        variables: {
          where: { id: serviceTicketId },
          data: updateData,
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Update Work Order Issue Action Item
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        {loading && !fetched ? (
          <div className="flex items-center justify-center h-200">
            <FuseLoading />
          </div>
        ) : (
          <FormProvider
            {...{
              clearErrors,
              control,
              formState,
              handleSubmit,
              reset,
              setValue,
              watch,
              ...methods,
            }}
          >
            <form
              className="w-full pt-20"
              id="work-order-issue-action-item-update-form"
              name="work-order-issue-action-item-update-form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="csiClassification"
                render={({ field }) => (
                  <EntitySearchSelectField
                    {...field}
                    className="mb-24"
                    components={{ Option: CsiClassificationHitsListOption }}
                    error={errors?.csiClassification}
                    filter={csiClassificationFilter}
                    idField="id"
                    indexName="csi_classifications"
                    isClearable
                    isDisabled={!csiClassificationFilter}
                    isMulti={false}
                    placeholder="Select CSI Classification..."
                    nameField="name"
                    sort={['number:asc']}
                  />
                )}
              />

              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    error={errors?.description}
                    fullWidth
                    helperText={errors?.description?.message}
                    required={false}
                    variant="outlined"
                    placeholder="Enter Description; Where / What... (Optional)"
                  />
                )}
              />

              <Divider className="mb-24" />

              {watchFields?.serviceTicketPhaseEnum === 'DRAFT' && (
                <>
                  <Controller
                    control={control}
                    name="notToExceedAmount"
                    render={({ field }) => (
                      <CommonCurrencyField
                        {...field}
                        className="mb-24"
                        control={control}
                        disabled={false}
                        error={errors?.notToExceedAmount}
                        placeholder="Enter Not To Exceed..."
                      />
                    )}
                  />
                </>
              )}

              {(watchFields?.serviceTicketPhaseEnum === 'IN_PROGRESS' ||
                watchFields?.serviceTicketPhaseEnum === 'QUOTING') && (
                <>
                  <Controller
                    control={control}
                    name="commonStandardRateType"
                    render={({ field }) => (
                      <CommonStandardRateTypeSelectField
                        {...field}
                        className="mb-24"
                        error={errors?.commonStandardRateType}
                        filters={{
                          ...(!_.isEmpty(
                            workOrderIssueActionItem?.workOrderIssue?.accountingWorkOrderType
                              ?.acceptedCommonStandardRateTypes
                          )
                            ? {
                                enum: {
                                  in: workOrderIssueActionItem?.workOrderIssue?.accountingWorkOrderType?.acceptedCommonStandardRateTypes.map(
                                    (acceptedCommonStandardRateType) =>
                                      acceptedCommonStandardRateType.enum
                                  ),
                                },
                              }
                            : {}),
                        }}
                        isDisabled={false}
                        isClearable
                        isMulti={false}
                        placeholder="Select Standard Rate Type..."
                        onChange={(params) => {
                          setValue('commonStandardFlatRate', null);
                          setValue('commonStandardHourlyRate', null);

                          field.onChange(params);
                        }}
                      />
                    )}
                  />

                  {watchFields?.commonStandardRateType?.result?.enum === 'FLAT_RATE' && (
                    <>
                      <Controller
                        control={control}
                        name="commonStandardFlatRate.quantity"
                        render={({ field }) => (
                          <CommonCurrencyField
                            {...field}
                            className="mb-24"
                            control={control}
                            disabled={false}
                            error={errors?.commonStandardFlatRate?.quantity}
                            placeholder="Enter Amount..."
                          />
                        )}
                      />
                    </>
                  )}

                  {watchFields?.commonStandardRateType?.result?.enum === 'HOURLY_RATE' && (
                    <div className="flex items-center">
                      <Controller
                        control={control}
                        name="commonStandardHourlyRate.technicians"
                        render={({ field }) => (
                          <CommonCurrencyField
                            {...field}
                            className="w-full mb-24 mr-12"
                            control={control}
                            disabled={false}
                            displayMode={null}
                            error={errors?.commonStandardHourlyRate?.technicians}
                            placeholder="Enter Technicians..."
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="commonStandardHourlyRate.hours"
                        render={({ field }) => (
                          <CommonCurrencyField
                            {...field}
                            className="w-full mb-24 mx-6"
                            control={control}
                            disabled={false}
                            displayMode={null}
                            error={errors?.commonStandardHourlyRate?.hours}
                            placeholder="Enter Hours..."
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="commonStandardHourlyRate.rateAmount"
                        render={({ field }) => (
                          <CommonCurrencyField
                            {...field}
                            className="w-full mb-24 ml-12"
                            control={control}
                            disabled={false}
                            displayMode="amount"
                            error={errors?.commonStandardHourlyRate?.rateAmount}
                            placeholder="Enter Rate..."
                          />
                        )}
                      />
                    </div>
                  )}

                  {watchFields?.commonStandardRateType?.value && (
                    <div className="flex items-center">
                      <Controller
                        control={control}
                        name="commonDiscountRate.type"
                        render={({ field }) => (
                          <CommonDiscountRateTypeSelectField
                            {...field}
                            className="w-full mb-24 mr-12"
                            error={errors?.commonDiscountRate?.type}
                            filters={null}
                            isDisabled={false}
                            isClearable
                            isMulti={false}
                            placeholder="Select Discount Rate Type..."
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="commonDiscountRate.value"
                        render={({ field }) => (
                          <CommonCurrencyField
                            {...field}
                            className="w-full mb-24 ml-12"
                            control={control}
                            disabled={false}
                            displayMode={watchFields?.commonDiscountRate?.type?.result?.enum.toLowerCase()}
                            error={errors?.commonDiscountRate?.value}
                            placeholder="Enter Discount Rate Value..."
                          />
                        )}
                      />
                    </div>
                  )}
                  <div />
                </>
              )}
            </form>
          </FormProvider>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-action-item-update-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueActionItemUpdateDialog;
