import { useLazyQuery } from '@apollo/client';
import {
  faCalendarAlt,
  faCheckCircle,
  faCircle as faCircleRegular,
  faCircleLeft,
  faCircleRight,
  faDotCircle,
  faFileAlt,
  faCopy,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBullhorn,
  faCircle as faCircleSolid,
  faFileInvoice,
  faHome,
  faPaperclip,
  faStream,
  faTasks,
  faTimes,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import _ from '@lodash';
import {
  AppBar,
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Chip,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@mui/material';
import { amber, blue, green, grey, red } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { CommonChatChannelListPreviewTemp } from 'app/shared-components/Common/CommonChat';
import {
  ProgramViewDrawerListItem,
  ProgramViewDrawerSectionItem,
} from 'app/shared-components/Program/ProgramViewDrawer/components';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
// import { extractProgramOrganizations } from 'app/utils';
import clsx from 'clsx';
import moment from 'moment';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  ChannelList,
  ChannelListMessenger,
  getChannel,
  useChannelListContext,
  useChatContext,
} from 'stream-chat-react';

import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { ChatChannelCreateDialog } from 'app/shared-components/ChatChannel/ChatChannelCreateDialog';

import { FETCH_PROGRAM_VIEW_DRAWER_DATA } from './queries';

const DRAWER_APP_BAR_HEIGHT = 240;

const DrawerAppBar = styled(AppBar)(({ theme }) => ({
  borderBottomWidth: 1,
  borderColor: theme.palette.mode === 'dark' ? '#353e4f' : grey[400],
  borderStyle: 'solid',
  height: DRAWER_APP_BAR_HEIGHT,
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 220,
  },
}));

// TODO:
const RenameList = (props) => {
  const { viewId: channelId } = useParams();
  const navigate = useNavigate();
  const { client, channel, setActiveChannel } = useChatContext();
  const { setChannels } = useChannelListContext();

  // TODO: Review
  const init = useRef(false);
  const channelType = 'service_ticket';
  useEffect(() => {
    let subscription;

    if (!channelId || !channelType) {
      setActiveChannel(null);
    } else if (client && (!channel?.id || channel?.id !== channelId)) {
      if (init.current) {
        setActiveChannel(client.channel(channelType, channelId));
      } else {
        subscription = client.on('channels.queried', (event) => {
          const loadedChannelData = event.queriedChannels?.channels.find(
            (response) => response.channel.id === channelId
          );

          if (loadedChannelData) {
            setActiveChannel(client.channel(channelType, channelId));
            subscription?.unsubscribe();
            return;
          }

          // FIXME:
          // eslint-disable-next-line consistent-return
          return getChannel({ client, id: channelId, type: channelType }).then((newActiveChannel) => {
            setActiveChannel(newActiveChannel);
            setChannels((channels) => {
              return [
                newActiveChannel,
                ...channels.filter((ch) => ch.data?.cid !== newActiveChannel.data?.cid),
              ];
            });
          });
        });
      }
    }

    init.current = true;

    return () => {
      subscription?.unsubscribe();
    };
  }, [channel?.id, channelId, channelType, client, setActiveChannel, setChannels]);

  return <ChannelListMessenger {...props}/>;
};

const ProgramViewDrawer = memo(({ basePath, contentWidth, onToggleDrawer, onViewChange }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();

  // TODO:
  const { client, channel, setActiveChannel } = useChatContext();

  const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

  const [
    fetchProgramViewDrawerData,
    {
      data: programViewDrawerData,
      loading: programViewDrawerLoading,
      refetch: programViewDrawerRefetch,
    },
  ] = useLazyQuery(FETCH_PROGRAM_VIEW_DRAWER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  // TODO:
  const user = useSelector(selectUser);

  const loading = useMemo(
    () => [programViewDrawerLoading].includes(true),
    [programViewDrawerLoading]
  );

  const program = useMemo(
    () => programViewDrawerData?.program,
    [programViewDrawerData?.program]
  );

  // TODO:
  const filters = {
    programId: program?.id,
    type: 'service_ticket',
    members: { $in: [user?.id] },
  };

  // const programOrganizations = useMemo(
  //   () => program && extractProgramOrganizations(program),
  //   [program]
  // );

  // const workOrderIssueResponseSeverities = useMemo(() => {
  //   if (!program?.workOrder.issues) return null;

  //   const groupedResponseSeverities = _.groupBy(
  //     program?.workOrder.issues,
  //     'serviceIssue.serviceResponseSeverity.name'
  //   );

  //   const _workOrderIssueResponseSeverities = Object.keys(groupedResponseSeverities).map((key) => {
  //     const serviceResponseSeverity = _.get(
  //       _.first(groupedResponseSeverities[key] || []),
  //       'serviceIssue.serviceResponseSeverity'
  //     );

  //     return {
  //       color: serviceResponseSeverity?.color,
  //       count: groupedResponseSeverities[key]?.length,
  //       id: serviceResponseSeverity?.id,
  //       name: serviceResponseSeverity?.name,
  //       ordinal: serviceResponseSeverity?.ordinal,
  //     };
  //   });

  //   return _workOrderIssueResponseSeverities;
  // }, [program?.workOrder.issues]);

  // const derivedStatus = useMemo(() => {
  //   let _derivedStatus;

  //   if (program?.status?.enum === 'CANCELLED') {
  //     _derivedStatus = {
  //       color: grey[800],
  //       name: 'Cancelled',
  //     };
  //   } else if (program?.status?.enum === 'COMPLETE') {
  //     _derivedStatus = {
  //       color: green[800],
  //       name: 'Complete',
  //     };
  //   } else if (program?.status?.enum === 'OPEN') {
  //     if (program?.phase?.enum === 'DRAFT') {
  //       _derivedStatus = {
  //         color: green[800],
  //         name: 'Not Submitted',
  //       };
  //     } else if (moment(moment()).startOf('day').isAfter(program?.completeBy)) {
  //       _derivedStatus = {
  //         color: red[800],
  //         name: 'Overdue',
  //       };
  //     } else if (moment(moment()).startOf('day').isBefore(program?.deferredUntil)) {
  //       _derivedStatus = {
  //         color: blue[800],
  //         name: 'Deferred',
  //       };
  //     } else if (
  //       moment().isBetween(
  //         moment(program?.completeBy).subtract(2, 'days').startOf('day'),
  //         moment(program?.completeBy).startOf('day')
  //       )
  //     ) {
  //       _derivedStatus = {
  //         color: amber[800],
  //         name: 'Open',
  //       };
  //     } else if (moment(moment()).startOf('day').isBefore(program?.completeBy)) {
  //       _derivedStatus = {
  //         color: green[800],
  //         name: 'Open',
  //       };
  //     }
  //   }

  //   return _derivedStatus;
  // }, [program]);

  // const nextServiceVisit = useMemo(() => {
  //   if (_.isEmpty(program?.serviceVisits)) {
  //     return null;
  //   }

  //   const orderedServiceVisits = _.orderBy(
  //     program?.serviceVisits || [],
  //     ({ windowStartAt }) => moment(windowStartAt).unix(),
  //     ['asc']
  //   );

  //   return (
  //     _.first(
  //       _.filter(orderedServiceVisits, ({ windowStartAt }) =>
  //         moment(windowStartAt).isAfter(moment())
  //       )
  //     ) || _.last(orderedServiceVisits)
  //   );
  // }, [program?.serviceVisits]);

  useEffect(() => {
    if (params?.programId) {
      setFetched(false);
      fetchProgramViewDrawerData({ variables: { where: { id: params?.programId } } });
    }
  }, [fetchProgramViewDrawerData, params?.programId]);

  const handleViewChange = (_viewSection, _viewSectionId) => {
    navigate(`../${_viewSection}/${_viewSectionId}`);

    if (typeof onViewChange === 'function') {
      // ROADMAP:
      // onViewChange(_viewSection, _viewSectionId);
    }

    return null;
  };

  return (
    <div className="flex-1" style={{ width: contentWidth }}>
      <DrawerAppBar color="inherit" elevation={0} position="static">
        {(loading && !fetched) || !program ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <Toolbar>
            <div className="flex flex-col w-full">
              {/* TODO: */}
              <div className="flex items-center py-16">
                <div className="flex flex-1 items-start">
                  <IconButton
                    style={{ marginLeft: -8, marginRight: 12 }}
                    onClick={() => navigate('/programs/search')}
                  >
                    <FontAwesomeIcon icon={faCircleLeft} size="sm" />
                  </IconButton>

                  <div className="flex flex-1 flex-col">
                    <Typography className="w-200" component="div" noWrap variant="h6">
                      {program?.name}
                    </Typography>

                    {/* TODO: */}
                    {/* <Typography component="div" noWrap variant="body1">
                      {`${'Placeholder Text...'}`}
                    </Typography> */}

                    <Alert className="mt-24" severity="info">
                      <AlertTitle>Under Development</AlertTitle>
                      This area is under active development.
                    </Alert>
                  </div>
                </div>

                {isMobile && onToggleDrawer && (
                  <IconButton className="w-32 h-32" onClick={onToggleDrawer}>
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                  </IconButton>
                )}
              </div>

              {/* TODO: Add Rest... */}
            </div>
          </Toolbar>
        )}
      </DrawerAppBar>

      <div
        className="flex flex-col overflow-auto"
        style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
      >
        {(loading && !fetched) || !program ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <List disablePadding>
            <ProgramViewDrawerSectionItem disabled={false} isInitiallyOpen title="Activity">
              <List disablePadding>
                {[
                  {
                    count: 0,
                    disabled: false,
                    icon: faHome,
                    name: 'overview',
                    title: 'Overview',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faBullhorn,
                    name: 'campaigns',
                    title: 'Campaigns',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faCopy,
                    name: 'issueTemplates',
                    title: 'Issue Templates',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faStream,
                    name: 'timeline',
                    title: 'Timeline',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faPaperclip,
                    name: 'file-attachments',
                    title: 'File Attachments',
                  },
                ].map(({ count, disabled, icon, name, title }) => (
                  <ProgramViewDrawerListItem
                    count={count}
                    disabled={disabled}
                    icon={icon}
                    key={`activity-${name}`}
                    selected={params?.sectionName === 'activity' && params?.viewName === name}
                    title={title}
                    onViewChange={() => handleViewChange('activity', name)}
                  />
                ))}
              </List>
            </ProgramViewDrawerSectionItem>

            {/* <ProgramViewDrawerSectionItem disabled={false} isInitiallyOpen title="Channels">
              <div className="mb-12">
                <ChannelList
                  // additionalChannelSearchProps={{
                  //   ExitSearchIcon: () => <FuseSvgIcon>heroicons-outline:arrow-left</FuseSvgIcon>,
                  //   // TODO:
                  //   popupResults: true,
                  //   searchForChannels: true,
                  //   // TODO:
                  //   // SearchResultItem: CommonChatChannelListPreview,
                  // }}
                  // ChannelSearch={ChatAppScreenHeader}
                  // customActiveChannel={'ca063e3a-2008-4ac4-9bd2-ef295f66ab78'}
                  // TODO:
                  filters={filters}
                  // TODO:
                  List={RenameList}
                  // TODO:
                  // options={options}
                  Preview={CommonChatChannelListPreviewTemp}
                  setActiveChannelOnMount={false}
                  // showChannelSearch
                  // TODO:
                  // sort={sort}
                />
              </div>

              <div className="px-24 mb-24">
                <Button
                  fullWidth
                  color="inherit"
                  variant="outlined"
                  onClick={() =>
                    dispatch(
                      openDialog({
                        children: (
                          <ChatChannelCreateDialog
                            // TODO:
                            programId={program?.id}
                            onClose={() => dispatch(closeDialog())}
                            // TODO: Review
                            onCreate={({ chatChannel }) => {
                              const base = `/programs/view/${program?.id}`;

                              navigate(`${base}/chat/channels/view/${chatChannel?.id}`);

                              // setActiveChannel(
                              //   client.channel(
                              //     chatChannel?.type.enum.toLowerCase(),
                              //     chatChannel?.id
                              //   )
                              // );
                            }}
                          />
                        ),
                        classes: {
                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                        },
                      })
                    )
                  }
                >
                  Create New Channel
                </Button>
              </div>
            </ProgramViewDrawerSectionItem> */}
          </List>
        )}
      </div>
    </div>
  );
});

export default ProgramViewDrawer;
