import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
// import Checkbox from '@mui/material/Checkbox';
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';
// TODO: Uninstall
// import 'yup-phone';
import _ from '@lodash';
import Paper from '@mui/material/Paper';
// import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
// import { useChannel, useEvent, usePresenceChannel, usePusherChannels } from 'app/pusher/channels';
import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAuth } from 'app/providers/auth';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PatternFormat } from 'react-number-format';
import { EnterCodeStep, ConfirmEmailStep, VerifiedStep } from './steps';

const defaultValues = {
  emailAddress: '',
  method: 'USER_EMAIL_ADDRESS',
  phoneNumber: '',
};

const schema = yup.object().shape({
  emailAddress: yup.mixed().when('method', {
    is: 'USER_EMAIL_ADDRESS',
    then: yup
      .string()
      .email('You must enter a valid email address')
      .required('You must enter an email address'),
  }),
  method: yup.string().oneOf(['USER_EMAIL_ADDRESS', 'USER_PHONE_NUMBER']).required(),
  phoneNumber: yup.mixed().when('method', {
    is: 'USER_PHONE_NUMBER',
    then: yup
      .string()
      .matches(/^[0-9]{10}$/, 'You must enter a valid phone number')
      .required('You must enter a phone number'),
  }),
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    // TODO: Review
    <PatternFormat
      {...other}
      // TODO:
      // allowEmptyFormatting
      format="+1 (###) ###-####"
      getInputRef={ref}
      mask="_"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

// https://www.npmjs.com/package/react-use-intercom
// https://www.npmjs.com/package/pusher
// https://github.com/mayteio/use-pusher
// https://github.com/pusher/pusher-js
// https://www.npmjs.com/package/mongoose-paginate-v2
// https://github.com/echoes-xyz/mongoose-geojson-schema#readme
// https://medium.com/@animirr/brute-force-protection-node-js-examples-cd58e8bd9b8d
// https://github.com/animir/node-rate-limiter-flexible
// https://expressjs.com/en/advanced/best-practice-security.html
// https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/489e5c5f21244a079759ee7e9e8965c2.html?locale=en-US

const SignInPage = () => {
  let { state } = useLocation();

  const [phoneStep, setPhoneStep] = useState(1)
  const [emailStep, setEmailStep] = useState(1)
  const [verificationKey, setVerificationKey] = useState('');
  // const channel = useChannel('my-channel');
  // const { members, myID } = usePresenceChannel('presence-awesome');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [messages, setMessages] = useState([]);

  const { jwtService } = useAuth();
  // TODO: Rename?
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {

    if (state === 'verified') {
      setEmailStep(3)
    }

   }, [])


  // const { client: pusher } = usePusherChannels();
  // useEffect(() => pusher?.signin(), [pusher]);

  // const testCallback = useCallback(
  //   (message) => {
  //     enqueueSnackbar(message);
  //   },
  //   [enqueueSnackbar]
  // );

  // useEvent(channel, 'my-event', ({ data }) => {
  //   testCallback(data);
  //   setMessages((_messages) => [..._messages, data]);
  // });

  const { control, formState, getValues, handleSubmit, reset, watch } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  console.log('getValues', getValues());


  const { dirtyFields, errors, isSubmitting } = formState;
  const watchMethod = watch('method');


  const onSubmit = async ({ emailAddress, method, phoneNumber }) => {
    // TODO: Clear Error Message?
    // await new Promise((resolve) => setTimeout(resolve, 5000));
    // TODO: try/catch
    try {
      const { key } = await jwtService.initializeSignIn({
        emailAddress,
        method,
        // TODO:
        phoneNumber: `+1${phoneNumber}`,
      });
      emailAddress && setEmailStep(2)
      phoneNumber && setPhoneStep(2)

      setVerificationKey(key);
    } catch (err) {
      console.log('// FIXME:', { err });
      setErrorMessage(_.get(err, 'message') || 'An unknown error occurred!');
    }
  };

  // const onSubmit2 = async ({ secret }) => {
  //   try {
  //     const { signInToken } = await jwtService.verifySignIn({
  //       key: verificationKey,
  //       secret,
  //     });

  //     console.log('// TODO:', { signInToken });

  //     if (signInToken) {
  //       // TODO:
  //       const res = await jwtService.completeSignIn({ signInToken });
  //     }
  //   } catch (err) {
  //     console.log('// FIXME1:', { err });
  //     setErrorMessage(_.get(err, 'message') || 'An unknown error occurred!');
  //   }
  // };

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32">
      {/* TODO: Move */}
      {/* <div>
        <ul>
          {Object.entries(members || {})
            // filter self from members
            .filter(([id]) => id !== myID)
            // map them to a list
            .map(([id, info]) => (
              <li key={id}>{info.name}</li>
            ))}
        </ul>

        <ul>
          {(messages || []).map((message) => (
            <li>{message}</li>
          ))}
        </ul>
      </div> */}

      <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
        <Box
          className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 pt-200 overflow-hidden bg-gradient-to-br from-purple-A500 via-pink-A500 to-purple-A500 "
        >

          <img className='absolute inset-0 -top-160 -left-72' src="assets/images/logo/hyveSignInBig.svg" />
          <img className='absolute bottom-0 right-44' src="assets/images/logo/hyveSignInCircles.svg" />

          <div className="z-10 relative w-full max-w-2xl">
            <div className="text-7xl font-bold leading-none text-white">
              <div>Welcome to</div>
              <div>our community</div>
            </div>
            <div className="mt-24 max-w-400 text-xl font-normal tracking-tight text-pink-A100">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
            </div>
          </div>
        </Box>

        <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-52 xl:p-52 rtl:border-r-1 ltr:border-l-1">
          <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
            <div className='flex flex-row items-center justify-between' >
              <div className='flex flex-row items-center' >
                <img className="w-48 mr-10" src="assets/images/logo/logo.svg" alt="logo" />
                <Typography color={'#D3D3D3'} variant='h6' component='h6' >HYVE</Typography>
              </div>
              <Typography className='text-base font-semibold' >Need Help?</Typography>
            </div>
            {/* {verificationKey ? (
              <>
                <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                  Verification
                </Typography>

                <Alert className="mb-24" severity="info" variant="outlined">
                  Depending on your verification method, enter your verification secret below, or
                  use the link provided.
                </Alert>

                <form
                  className="flex flex-col justify-center w-full mt-32"
                  // TODO: Review and Mod Other Form
                  name="signInVerificationForm"
                  noValidate
                  onSubmit={handleSubmit(onSubmit2)}
                >
                  {errorMessage && (
                    <Alert className="mb-24" severity="error" variant="outlined">
                      {errorMessage}
                    </Alert>
                  )}

                  <Controller
                    control={control}
                    name="secret"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoFocus
                        className="mb-24"
                        error={!!errors.secret}
                        fullWidth
                        helperText={errors?.secret?.message}
                        label="Secret"
                        required
                        type="text"
                        variant="outlined"
                      />
                    )}
                  />

                  <Button
                    disabled={_.isEmpty(dirtyFields) || isSubmitting}
                    fullWidth
                    type="submit"
                    // TODO: Review
                    color="secondary"
                    className="mt-16 shadow-0"
                    size="large"
                    variant="contained"
                  >
                    Verify
                  </Button>
                </form>
              </>
            ) : ( */}
            <>
              <Typography className="mb-12 text-4xl text-center	 font-semibold tracking-wide leading-tight mt-52">
                {(phoneStep === 3 || emailStep === 3) ? `Verified!` : `Sign In`}
              </Typography>
              <form
                className="flex flex-col justify-center w-full mt-32"
                name="signInForm"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                {errorMessage && (
                  <Alert className="mb-24" severity="error" variant="outlined">
                    {errorMessage}
                  </Alert>
                )}
                {watchMethod === 'USER_EMAIL_ADDRESS' && (
                  <>
                    {
                      emailStep === 1 && (
                        <>
                          <Typography className='text-xl mb-96 text-center' >Sign in with your email</Typography>
                          <Controller
                            control={control}
                            name="emailAddress"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                autoFocus
                                className="mb-60"
                                error={!!errors.emailAddress}
                                fullWidth
                                helperText={errors?.emailAddress?.message}
                                placeholder='What is your work email address?'
                                required
                                type="email"
                                variant="standard"
                                inputProps={{ style: { textAlign: 'center' } }}
                                sx={{
                                  '& .MuiInput-underline:before': { borderBottomColor: '#272A3F' },
                                  '& .MuiInput-underline:after': { borderBottomColor: '#D416B9' },
                                }}
                              />
                            )}
                          />
                          <Button
                            color='secondary'
                            size="large"
                            variant="contained"
                            disabled={_.isEmpty(dirtyFields) || isSubmitting}
                            fullWidth
                            className='mt-32 shadow-0'
                            type="submit"
                            sx={{
                              background: '#D416B9',
                              padding: '22px 0 22px 0',
                              borderRadius: '67px',
                              "&:hover": { backgroundColor: "#B7129F" }
                            }}
                          >
                            <Typography className='text-lg font-semibold text-white' >Verify</Typography>
                          </Button>
                        </>
                      )
                    }
                    {
                      emailStep === 2 && (<ConfirmEmailStep setEmailStep={setEmailStep} emailName={getValues().emailAddress} verificationKey={verificationKey} />)
                    }
                    {
                      emailStep === 3 && (<VerifiedStep verified='email' />)
                    }
                  </>
                )}

                {watchMethod === 'USER_PHONE_NUMBER' && (
                  <>
                    {
                      phoneStep === 1 && (
                        <>
                          <Typography className='text-xl text-center mt-1 mb-68'>Sign in with your mobile phone number</Typography>
                          <Controller
                            control={control}
                            name="phoneNumber"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                autoFocus
                                className="mb-24"
                                error={!!errors.phoneNumber}
                                fullWidth
                                helperText={errors?.phoneNumber?.message}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { paddingLeft: '25%' },
                                }}
                                placeholder='Mobile Phone Number'
                                required
                                type="tel"
                                variant="standard"
                                sx={{
                                  '& .MuiInput-underline:before': { borderBottomColor: '#272A3F' },
                                  '& .MuiInput-underline:after': { borderBottomColor: '#D416B9' },
                                }}
                              />
                            )}
                          />
                          <Typography className='font-normal text-sm text-center' >After confirming your phone number, we’ll use it to create your account.</Typography>
                          <Button
                            color='secondary'
                            size="large"
                            variant="contained"
                            disabled={_.isEmpty(dirtyFields) || isSubmitting}
                            fullWidth
                            className='mt-32 shadow-0'
                            type="submit"
                            sx={{
                              background: '#D416B9',
                              padding: '22px 0 22px 0',
                              borderRadius: '67px',
                              "&:hover": { backgroundColor: "#B7129F" }
                            }}
                          >
                            <Typography className='text-lg font-semibold text-white'>Sign In</Typography>
                          </Button>

                        </>
                      )
                    }
                    {
                      phoneStep === 2 && (
                        <EnterCodeStep setPhoneStep={setPhoneStep} phoneNumber={getValues().phoneNumber} />
                      )
                    }
                    {
                      phoneStep === 3 && (<VerifiedStep verified='phone number' />)
                    }
                  </>
                )}

                {
                  (phoneStep === 3 || emailStep === 3)
                    ? null
                    : <Controller
                      control={control}
                      name="method"
                      render={({ field }) => (
                        <>
                          <Button
                            className='mt-14'
                            variant='text'
                            disabled={isSubmitting}
                            onClick={() => {
                              if (field?.value === 'USER_EMAIL_ADDRESS') {
                                reset({ ...defaultValues, method: 'USER_PHONE_NUMBER' });
                                setEmailStep(1)
                              } else if (field?.value === 'USER_PHONE_NUMBER') {
                                reset({ ...defaultValues, method: 'USER_EMAIL_ADDRESS' });
                                setPhoneStep(1)
                              }
                            }}
                            sx={{ "&:hover": { backgroundColor: "transparent", } }}
                          >
                            {field?.value === 'USER_EMAIL_ADDRESS' && (
                              <Typography className='font-semibold text-lg' color='#CC1BB2' >Sign in via phone number</Typography>
                            )}

                            {field?.value === 'USER_PHONE_NUMBER' && (
                              <Typography className='font-semibold text-lg' color='#CC1BB2' >Sign in via email</Typography>
                            )}
                          </Button>
                        </>
                      )}
                    />
                }
              </form>
            </>
            {/* )} */}

            <div className='flex flex-row justify-between mt-24' >
              <Typography className='text-base font-semibold'>Privacy Policy</Typography>
              <Typography className='text-base font-semibold'>Terms & Conditions</Typography>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default SignInPage;
