import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { ServiceTicketReminderPrioritySelectField } from 'app/shared-components/ServiceTicketReminderPriority';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { CREATE_SERVICE_TICKET_REMINDER } from './queries';

const defaultValues = {
  dueAt: null,
  name: '',
  notes: '',
  priority: null,
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('A description must be provided.'),
});

const ServiceTicketReminderCreateDialog = ({ serviceTicketId, onClose }) => {
  const dispatch = useDispatch();
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createServiceTicketReminder, { loading: createServiceTicketReminderLoading }] =
    useMutation(CREATE_SERVICE_TICKET_REMINDER, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Ticket Reminder Successfully Created',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Creating Service Ticket Reminder', variant: 'error' })
        );
      },
    });

  const loading = useMemo(
    () => [createServiceTicketReminderLoading].includes(true),
    [createServiceTicketReminderLoading]
  );

  const onSubmit = async (data) => {
    try {
      await createServiceTicketReminder({
        variables: {
          where: { id: serviceTicketId },
          data: {
            ...data,
            priority: data?.priority ? { id: data.priority.value } : null,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Service Ticket Reminder
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{
            clearErrors,
            control,
            formState,
            handleSubmit,
            reset,
            setValue,
            watch,
            ...methods,
          }}
        >
          <form
            className="w-full pt-20"
            id="service-ticket-reminder-create-form"
            name="service-ticket-reminder-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  required
                  variant="outlined"
                  placeholder="Enter Name..."
                />
              )}
            />

            {/* ROADMAP: Implement `assignedTeam` */}

            <Controller
              control={control}
              name="priority"
              render={({ field }) => (
                <ServiceTicketReminderPrioritySelectField
                  {...field}
                  className="mb-24"
                  error={errors?.priority}
                  filters={null}
                  isDisabled={false}
                  isClearable
                  isMulti={false}
                  placeholder="Select a Priority..."
                />
              )}
            />

            <Controller
              control={control}
              name="dueAt"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonDatepicker
                    {...field}
                    controls={['calendar']}
                    error={errors.dueAt}
                    min={moment()}
                    placeholder="Select a Due Date..."
                    select="date"
                    stepMinute={15}
                  />
                </div>
              )}
            />

            <Divider className="mb-24" />

            <Controller
              control={control}
              name="notes"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.notes}
                  fullWidth
                  helperText={errors?.notes?.message}
                  maxRows={4}
                  minRows={4}
                  multiline
                  placeholder="Enter Notes..."
                  required={false}
                  variant="outlined"
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-ticket-reminder-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceTicketReminderCreateDialog;
