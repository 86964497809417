import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_PAUSE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const PAUSE_WORK_ORDER_ISSUE = gql`
  mutation PauseWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssuePauseInput!
  ) {
    pauseWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssuePauseDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_PAUSE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
