import { createSlice } from '@reduxjs/toolkit';

const datesSlice = createSlice({
  name: 'calendarAppScreen/dates',
  initialState: {
    contentDateRange: null,
    drawerDateRange: null,
    selectedDate: new Date(),
  },
  reducers: {
    setDatesContentDateRange: (state, action) => {
      return { ...(state || {}), contentDateRange: action?.payload };
    },
    setDatesDrawerDateRange: (state, action) => {
      return { ...(state || {}), drawerDateRange: action?.payload };
    },
    setDatesSelectedDate: (state, action) => {
      return { ...(state || {}), selectedDate: action?.payload };
    },
  },
});

export const { setDatesContentDateRange, setDatesDrawerDateRange, setDatesSelectedDate } =
  datesSlice.actions;

export const selectCalendarAppScreenDates = ({ calendarAppScreen }) => calendarAppScreen.dates;

export default datesSlice.reducer;
