import _ from '@lodash';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useChatContext } from 'stream-chat-react';
import { useEffect, useMemo, useState } from 'react';
import { ListItemButton } from '@mui/material';
import CommonChatChannelAvatar from './CommonChatChannelAvatar';

// TODO: Extract
export const getDisplayTitle = ({ channel, currentUser }) => {
  let title = channel.data?.name;
  const members = Object.values(channel.state.members);

  if (!title && members.length === 2) {
    const otherMember = members.find((member) => member.user?.id !== currentUser?.id);
    if (otherMember?.user?.name) {
      title = otherMember.user.name;
    }
  }

  return title;
};

const StyledListItem = styled(ListItemButton)(({ theme, active }) => ({
  // TODO:
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.default,
  },

  // '&.active': {
  //   backgroundColor: theme.palette.background.default,
  // },
}));

function ContactListItem({ active, channel, channelUpdateCount, setActiveChannel, watchers, ...props }) {
  const { client } = useChatContext();

  // console.log({ channel, setActiveChannel, watchers, props });
  // const routeParams = useParams();

  // TODO:
  const [lastMessage, setLastMessage] = useState(
    channel?.state?.messages[channel?.state?.messages.length - 1],
  );
  const [unread, setUnread] = useState(0);
  // const { messageDeliveryStatus } = useMessageDeliveryStatus({
  //   channel,
  //   lastMessage,
  // });

  const muted = useMemo(() => channel?.muteStatus()?.muted, [channel]);

  useEffect(() => {
    const handleEvent = (event) => {
      if (!event.cid) return setUnread(0);
      if (channel.cid === event.cid) setUnread(0);
    };

    client.on('notification.mark_read', handleEvent);
    return () => client.off('notification.mark_read', handleEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleEvent = (event) => {
      if (channel.cid !== event.cid) return;
      if (event.user?.id !== client.user?.id) return;
      setUnread(channel.countUnread());
    };
    channel.on('notification.mark_unread', handleEvent);
    return () => {
      channel.off('notification.mark_unread', handleEvent);
    };
  }, [channel, client]);

  const refreshUnreadCount = useMemo(
    () =>
      _.throttle(() => {
        if (muted) {
          setUnread(0);
        } else {
          setUnread(channel.countUnread());
        }
      }, 400),
    [channel, muted],
  );

  useEffect(() => {
    refreshUnreadCount();

    const handleEvent = (event) => {
      if (event.message) setLastMessage(event.message);
      refreshUnreadCount();
    };

    channel.on('message.new', handleEvent);
    channel.on('message.updated', handleEvent);
    channel.on('message.deleted', handleEvent);

    return () => {
      channel.off('message.new', handleEvent);
      channel.off('message.updated', handleEvent);
      channel.off('message.deleted', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUnreadCount, channelUpdateCount]);

  // console.log({ lastMessage, unread });
  const title = useMemo(
    () => getDisplayTitle({ channel, currentUser: client?.user }),
    [channel, client?.user]
  );

  const navigate = useNavigate();
  const handleRename = () => {
    // dispatch(openChatPanel());

    // TODO:
    // console.log({ channel });
    navigate(`/apps/chat/${channel?.type}/view/${channel?.id}`);
    // setActiveChannel(channel, watchers);
  };

  if (!channel) return null;

  return (
    <StyledListItem
      // button
      selected={active}
      className="px-32 py-12 min-h-80"
      onClick={handleRename}
      // active={routeParams.id === contact.id ? 1 : 0}
      // component={NavLinkAdapter}
      // to={`/apps/chat/${contact.id}`}
      // end
      // activeClassName="active"
    >
      <CommonChatChannelAvatar contact={channel} />

      <ListItemText
        classes={{
          root: 'min-w-px px-16',
          primary: 'font-medium text-14',
          secondary: 'truncate',
        }}
        primary={title}
        // TODO: Change
        secondary={lastMessage?.text || 'No chats here yet...'}
      />

{/* {!!unread && (
            <div className='str-chat__channel-preview-unread-badge' data-testid='unread-badge'>
              {unread}
            </div>
          )} */}
      {/* {contact.contactId && ( */}
        <div className="flex flex-col justify-center items-end">
          {lastMessage && (
            <Typography
              className="whitespace-nowrap mb-8 font-medium text-12"
              color="text.secondary"
            >
              {format(new Date(lastMessage?.created_at), 'PP')}
            </Typography>
          )}
          <div className="items-center">
            {muted && (
              <FuseSvgIcon size={20} color="disabled">
                heroicons-solid:volume-off
              </FuseSvgIcon>
            )}

            {Boolean(unread) && (
              <Box
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'secondary.contrastText',
                }}
                className="flex items-center justify-center min-w-20 h-20 rounded-full font-medium text-10 text-center"
              >
                {unread}
              </Box>
            )}
          </div>
        </div>
      {/* )} */}
    </StyledListItem>
  );
}

export default ContactListItem;
