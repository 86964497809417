import { gql } from '@apollo/client';
import { SERVICE_TICKET_SCHEDULE_REQUEST_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const SCHEDULE_SERVICE_TICKET_REQUEST = gql`
  mutation ScheduleServiceTicketRequest(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketScheduleRequestInput!
  ) {
    scheduleServiceTicketRequest(where: $where, data: $data) {
      id
      ...ServiceTicketScheduleRequestDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_SCHEDULE_REQUEST_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
