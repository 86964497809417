import { memo } from 'react';
// TODO:
// import { ActivityStreamPanel } from '../../shared-components/ActivityStreamPanel';
import QuickPanel from '../../shared-components/quickPanel/QuickPanel';

function RightSideLayout3() {
  return (
    <>
      <QuickPanel />

      {/* <NotificationPanel /> */}
    </>
  );
}

export default memo(RightSideLayout3);
