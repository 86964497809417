import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueActionItemUpdateDialogServiceTicketFragment on ServiceTicket {
    id
    phase {
      id
      enum
      name
    }
    workOrder {
      id
      discountAmount
      issues {
        id
        accountingWorkOrderType {
          id
          acceptedCommonStandardRateTypes {
            id
            enum
            name
          }
          targetCsiClassifications {
            id
            name
            number
            title
          }
        }
        actionItems {
          id
          commonDiscountRate {
            id
            type {
              id
              enum
              name
            }
            value
          }
          commonStandardFlatRate {
            id
            quantity
          }
          commonStandardHourlyRate {
            id
            hours
            rateAmount
            technicians
          }
          commonStandardRateType {
            id
            enum
            name
          }
          csiClassification {
            id
            name
            number
          }
          description
          discountAmount
          isCapitalExpense
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        discountAmount
        notToExceedAmount
        serviceIssue {
          id
          commonTargetType {
            id
            enum
            name
          }
          targetFacilityAreas {
            id
            type {
              id
            }
          }
        }
        status {
          id
          enum
          name
        }
        subtotalAmount
        totalAmount
        totalAmountCheck {
          id
          enum
          name
        }
      }
      notToExceedAmount
      subtotalAmount
      totalAmount
    }
  }
`;

export const WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_WORK_ORDER_ISSUE_ACTION_ITEM_FRAGMENT = gql`
  fragment WorkOrderIssueActionItemUpdateDialogWorkOrderIssueActionItemFragment on WorkOrderIssueActionItem {
    id
    commonDiscountRate {
      id
      type {
        id
        enum
        name
      }
      value
    }
    commonStandardFlatRate {
      id
      quantity
    }
    commonStandardHourlyRate {
      id
      hours
      rateAmount
      technicians
    }
    commonStandardRateType {
      id
      enum
      name
    }
    csiClassification {
      id
      name
      number
    }
    description
    notToExceedAmount
    workOrderIssue {
      id
      accountingWorkOrderType {
        id
        acceptedCommonStandardRateTypes {
          id
          enum
          name
        }
        targetCsiClassifications {
          id
          name
          number
          title
        }
      }
      actionItems {
        id
        csiClassification {
          id
          name
          number
        }
      }
      serviceIssue {
        id
        commonTargetType {
          id
          enum
          name
        }
        targetFacilityAreas {
          id
          type {
            id
          }
        }
      }
      workOrder {
        id
        serviceTicket {
          id
          phase {
            id
            enum
            name
          }
        }
      }
    }
  }
`;
