import { gql } from '@apollo/client';
import { SERVICE_TICKET_DEFERRED_UNTIL_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const FETCH_SERVICE_TICKET_DEFERRED_UNTIL_UPDATE_DIALOG_DATA = gql`
  query FetchServiceTicketDeferredUntilUpdateDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...ServiceTicketDeferredUntilUpdateDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_DEFERRED_UNTIL_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const UPDATE_SERVICE_TICKET_DEFERRED_UNTIL = gql`
  mutation UpdateServiceTicketDeferredUntil(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketDeferredUntilUpdateInput!
  ) {
    updateServiceTicketDeferredUntil(where: $where, data: $data) {
      id
      ...ServiceTicketDeferredUntilUpdateDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_DEFERRED_UNTIL_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
