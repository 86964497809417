import _ from '@lodash';
import clsx from 'clsx';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DemoSidebarContent from '@fuse/core/DemoSidebarContent';
import {
  AppBar,
  Avatar,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { EntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchDrawer } from 'app/shared-components/EntitySearch/EntitySearchDrawer';
import { EntitySearchHeader } from 'app/shared-components/EntitySearch/EntitySearchHeader';
import { EntitySearchInfiniteHitsGrid } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsGrid';
import { EntitySearchInfiniteHitsList } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsList';
import { EntitySearchInfiniteHitsMap } from 'app/shared-components/EntitySearch/EntitySearchInfiniteHitsMap';
// import { ServiceRequestCreateDialog } from 'app/shared-components/ServiceRequest/ServiceRequestCreateDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
// import { AssetSearchAdapter } from './adapters';
// import { assetSearchScreenColumnDefs } from './columnDefs';
// import { assetSearchScreenFilterDefs } from './filterDefs';

import { gql, useLazyQuery } from '@apollo/client';

import { useDeepCompareEffect } from '@fuse/hooks';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { ServiceProvisionManageListOrganizationItem } from './components';


const ServiceProvisionManageList = ({ height }) => {
  const [renameOpen, setRenameOpen] = useState(true);
  const theme = useTheme();

  const [
    getServiceProvisionManageListData,
    {
      data: serviceProvisionManageListData,
      loading: serviceProvisionManageListLoading,
      refetch: serviceProvisionManageListRefetch,
    },
  ] = useLazyQuery(
    gql`
      query GetServiceProvisionManageListData($where: ServiceProvisionWhereInput!) {
        serviceProvisions(where: $where) {
          id
          createdAt
          updatedAt

          name
          ordinal

          organizationConnection {
            id
            createdAt
            updatedAt

            consumer {
              id
              createdAt
              updatedAt

              name
            }
            provider {
              id
              createdAt
              updatedAt

              name
            }
          }
          versions {
            id
            createdAt
            updatedAt

            # TODO:
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      // TOOD:
      // onCompleted: () => null,
      // onError: (error) => null,
    }
  );

  const [filterServiceProvisions, setFilterServiceProvisions] = useState(null);
  const [groupedServiceProvisions, setGroupedServiceProvisions] = useState(null);

  useDeepCompareEffect(() => {
    console.log('// TODO: Outside', { serviceProvisionManageListData });

    if (serviceProvisionManageListData?.serviceProvisions) {
      console.log('// TODO: Inside', { filterServiceProvisions, serviceProvisionManageListData });

      setGroupedServiceProvisions(
        _.groupBy(
          _.sortBy(
            serviceProvisionManageListData.serviceProvisions.filter(
              ({ name }) =>
                _.isEmpty(filterServiceProvisions) || name.indexOf(filterServiceProvisions) !== -1
            ),
            // TODO:
            ['organizationConnection.provider.name', 'name']
          ),
          'organizationConnection.id'
        )
      );
    }
  }, [filterServiceProvisions, serviceProvisionManageListData || {}]);

  console.log({ groupedServiceProvisions });

  useEffect(() => {
    getServiceProvisionManageListData({ variables: { where: {} } });
  }, [getServiceProvisionManageListData]);

  console.log({ serviceProvisionManageListData });

  // TODO:
  const onDragEnd = useCallback((result) => {
    // setRenameOpen(true);

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    console.log('// TODO:', { result });
    // dispatch(
    //   reorderList({
    //     arr: tasks,
    //     startIndex: result.source.index,
    //     endIndex: result.destination.index,
    //   })
    // );
  }, []);

  // TODO:
  const onBeforeCapture = useCallback((a, b, c) => {
    // FIXME:
    if (a?.draggableId.indexOf('sub') === -1) {
      // setRenameOpen(false);
    }
  }, []);

  return (
    // TODO: <FuseLoading />
    <List className="m-0 p-0 w-full overflow-scroll" style={{ height }}>
      <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
        <Droppable droppableId="list" type="list" direction="vertical">
          {(provided, snapshot) => (
            <>
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver
                    ? green[700]
                    : theme.palette.background.default,
                }}
              >
                {Object.keys(groupedServiceProvisions || {}).map(
                  (organizationConnectionId, index) => {
                    // console.log({ organizationConnectionId, index, groupedServiceProvisions, hit: groupedServiceProvisions[organizationConnectionId] });

                    return (
                      <ServiceProvisionManageListOrganizationItem
                        index={index}
                        // TODO:
                        renameOpen={renameOpen}
                        // TODO: Move?
                        organization={
                          _.first(groupedServiceProvisions[organizationConnectionId])
                            .organizationConnection.provider
                        }
                        serviceProvisions={groupedServiceProvisions[organizationConnectionId]}
                      />
                    );
                  }

                )}
              </div>

              {provided.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>
    </List>
  );
};

export default ServiceProvisionManageList;
