import { gql } from '@apollo/client';
import { SERVICE_ISSUE_ATTACHMENT_DELETE_DIALOG_SERVICE_ISSUE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_SERVICE_ISSUE_ATTACHMENT = gql`
  mutation DeleteServiceIssueAttachment(
    $where: ServiceIssueWhereUniqueInput!
    $data: ServiceIssueAttachmentDeleteInput!
  ) {
    deleteServiceIssueAttachment(where: $where, data: $data) {
      id
      ...ServiceIssueAttachmentDeleteDialogServiceIssueFragment
    }
  }
  ${SERVICE_ISSUE_ATTACHMENT_DELETE_DIALOG_SERVICE_ISSUE_FRAGMENT}
`;
