import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_REVIEW_RECALL_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const FETCH_WORK_ORDER_ISSUE_REVIEW_RECALL_DIALOG_DATA = gql`
  query FetchWorkOrderIssueReviewRecallDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueReviewRecallDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_REVIEW_RECALL_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const RECALL_WORK_ORDER_ISSUE_REVIEW = gql`
  mutation RecallWorkOrderIssueReview(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueReviewRecallInput!
  ) {
    recallWorkOrderIssueReview(where: $where, data: $data) {
      id
      ...WorkOrderIssueReviewRecallDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_REVIEW_RECALL_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
