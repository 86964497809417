import { useEffect, useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtService } from 'app/providers/auth/services';
import { showMessage } from 'app/store/fuse/messageSlice';

const MagicSignInPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const autoSignIn = async ({ verificationToken }) => {
      try {
        const { signInToken } = await jwtService.magicSignIn({
          verificationToken,
        });

        if (signInToken) {
          await jwtService.completeSignIn({ signInToken });
        }

        return;
      } catch (err) {
        dispatch(
          showMessage({ message: 'Invalid or Expired Verification Token!', variant: 'error' })
        );

        navigate('sign-in', { state: 'verified' });
      }
    };

    const timeout = setTimeout(() => {
      if (queryParams?.get('token')) {
        autoSignIn({ verificationToken: queryParams.get('token') });
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [dispatch, navigate, queryParams]);

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="w-48 mx-auto" src="assets/images/logo/logo.svg" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight text-center">
            Signing You In...
          </Typography>
        </div>
      </Paper>
    </div>
  );
};

export default MagicSignInPage;
