import { gql } from '@apollo/client';
import { SERVICE_INVOICE_VIEW_DRAWER_SERVICE_INVOICE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_SERVICE_INVOICE_VIEW_DRAWER_DATA = gql`
  query FetchServiceInvoiceViewDrawerData($where: ServiceInvoiceWhereUniqueInput!) {
    serviceInvoice(where: $where) {
      id
      ...ServiceInvoiceViewDrawerServiceInvoiceFragment
    }
  }
  ${SERVICE_INVOICE_VIEW_DRAWER_SERVICE_INVOICE_FRAGMENT}
`;
