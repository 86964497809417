const GlobalSearchBarCommonHeader = ({ state, title }) => {
  return (
    <>
      <div className="aa-SourceHeaderTitle">{`${title} Matching: ${state.query}`}</div>
      <div className="aa-SourceHeaderLine" />
    </>
  );
};

export default GlobalSearchBarCommonHeader;
