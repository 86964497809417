import { gql } from '@apollo/client';
import { PROGRAM_CREATE_DIALOG_PROGRAM_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_PROGRAM = gql`
  mutation CreateProgram($data: ProgramCreateInput!) {
    createProgram(data: $data) {
      id
      ...ProgramCreateDialogProgramFragment
    }
  }
  ${PROGRAM_CREATE_DIALOG_PROGRAM_FRAGMENT}
`;
