import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_CANCEL_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueCancelDialogServiceTicketFragment on ServiceTicket {
    id
    approvalPhaseEndedAt
    approvalPhaseStartedAt
    draftPhaseEndedAt
    draftPhaseStartedAt
    inProgressPhaseEndedAt
    inProgressPhaseStartedAt
    isApprovalPhaseCompleted
    isDraftPhaseCompleted
    isInProgressPhaseCompleted
    isQuotingPhaseCompleted
    isReleasePhaseCompleted
    isSiteVisitPhaseCompleted
    phase {
      id
      enum
      name
    }
    quotingPhaseEndedAt
    quotingPhaseStartedAt
    releasePhaseEndedAt
    releasePhaseStartedAt
    siteVisitPhaseEndedAt
    siteVisitPhaseStartedAt
    status {
      id
      enum
      name
    }
    workOrder {
      id
      discountAmount
      issues {
        id
        actionItems {
          id
          commonDiscountRate {
            id
            type {
              id
              enum
              name
            }
            value
          }
          commonStandardFlatRate {
            id
            quantity
          }
          commonStandardHourlyRate {
            id
            hours
            rateAmount
            technicians
          }
          commonStandardRateType {
            id
            enum
            name
          }
          csiClassification {
            id
            name
            number
          }
          description
          discountAmount
          isCapitalExpense
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        discountAmount
        notToExceedAmount
        serviceIssue {
          id
          commonTargetType {
            id
            enum
            name
          }
          targetFacilityAreas {
            id
            type {
              id
            }
          }
        }
        status {
          id
          enum
          name
        }
        subtotalAmount
        totalAmount
      }
      notToExceedAmount
      subtotalAmount
      totalAmount
    }
  }
`;
