import { gql } from '@apollo/client';
import {
  PROGRAM_CAMPAIGN_CREATE_DIALOG_PROGRAM_FRAGMENT,
  PROGRAM_CAMPAIGN_CREATE_DIALOG_SERVICE_RESPONSE_SEVERITY_FRAGMENT,
} from './fragments';

export const CREATE_PROGRAM_CAMPAIGN = gql`
  mutation CreateProgramCampaign(
    $where: ProgramWhereUniqueInput!
    $data: ProgramCampaignCreateInput!
  ) {
    createProgramCampaign(where: $where, data: $data) {
      id
      ...ProgramCampaignCreateDialogProgramFragment
    }
  }
  ${PROGRAM_CAMPAIGN_CREATE_DIALOG_PROGRAM_FRAGMENT}
`;

export const FETCH_PROGRAM_CAMPAIGN_CREATE_DIALOG_DATA = gql`
  query FetchProgramCampaignCreateDialogData($where: ProgramWhereUniqueInput!) {
    program(where: $where) {
      id
      ...ProgramCampaignCreateDialogProgramFragment
    }
    serviceResponseSeverities(where: {}) {
      id
      ...ProgramCampaignCreateDialogServiceResponseSeverityFragment
    }
  }
  ${PROGRAM_CAMPAIGN_CREATE_DIALOG_PROGRAM_FRAGMENT}
  ${PROGRAM_CAMPAIGN_CREATE_DIALOG_SERVICE_RESPONSE_SEVERITY_FRAGMENT}
`;
