import { faMoon, faSun } from '@fortawesome/free-regular-svg-icons';
import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemAvatar, ListItemText, Menu, MenuItem } from '@mui/material';
import themesConfig from 'app/configs/themesConfig';
import { changeFuseTheme, selectMainTheme } from 'app/store/fuse/settingsSlice';
import { selectUserThemePreference, updateUserThemePreference } from 'app/store/userSlice';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const UserMenuThemeModeMenu = ({ anchorEl, onClose }) => {
  const dispatch = useDispatch();
  const mainTheme = useSelector(selectMainTheme);
  const themePreference = useSelector(selectUserThemePreference) || 'match-browser';

  // NOTE: `themeMode` !== `themePreference`
  const themeMode = useMemo(() => mainTheme?.palette.mode, [mainTheme?.palette.mode]);

  useEffect(() => {
    if (themePreference) {
      if (themePreference === 'dark') {
        dispatch(changeFuseTheme(themesConfig.defaultDark));
      } else if (themePreference === 'light') {
        dispatch(changeFuseTheme(themesConfig.default));
      } else if (themePreference === 'match-browser') {
        const isBrowserDarkMode = () =>
          window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

        dispatch(
          changeFuseTheme(isBrowserDarkMode ? themesConfig.defaultDark : themesConfig.default)
        );
      }
    }
  }, [dispatch, themePreference]);

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem
        selected={themePreference === 'light'}
        onClick={() => dispatch(updateUserThemePreference('light'))}
      >
        <ListItemAvatar>
          <FontAwesomeIcon icon={faSun} size="xl" />
        </ListItemAvatar>

        <ListItemText primary="Light" />
      </MenuItem>

      <MenuItem
        selected={themePreference === 'dark'}
        onClick={() => dispatch(updateUserThemePreference('dark'))}
      >
        <ListItemAvatar>
          <FontAwesomeIcon icon={faMoon} size="xl" />
        </ListItemAvatar>

        <ListItemText primary="Dark" />
      </MenuItem>

      <MenuItem
        selected={themePreference === 'match-browser'}
        onClick={() => dispatch(updateUserThemePreference('match-browser'))}
      >
        <ListItemAvatar>
          <FontAwesomeIcon icon={faCircleHalfStroke} size="xl" />
        </ListItemAvatar>

        <ListItemText primary="Match Browser" />
      </MenuItem>
    </Menu>
  );
};

export default UserMenuThemeModeMenu;
