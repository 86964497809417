import { lazy } from 'react';
import i18next from 'i18next';

import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

const FacilityDetails = lazy(() => import('./FacilityDetails'));

i18next.addResourceBundle('en', 'facilityDetailsPage', en);
i18next.addResourceBundle('tr', 'facilityDetailsPage', tr);
i18next.addResourceBundle('ar', 'facilityDetailsPage', ar);

const FacilityDetailsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'facilities/:id',
      element: <FacilityDetails />,
    },
  ],
};

export default FacilityDetailsConfig;
