import { gql } from '@apollo/client';
import {
  SERVICE_TICKET_REMINDER_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT,
  SERVICE_TICKET_REMINDER_UPDATE_DIALOG_SERVICE_TICKET_REMINDER_FRAGMENT,
} from './fragments';

export const FETCH_SERVICE_TICKET_REMINDER_UPDATE_DIALOG_DATA = gql`
  query FetchServiceTicketReminderUpdateDialogData($where: ServiceTicketReminderWhereUniqueInput!) {
    serviceTicketReminder(where: $where) {
      id
      ...ServiceTicketReminderUpdateDialogServiceTicketReminderFragment
    }
  }
  ${SERVICE_TICKET_REMINDER_UPDATE_DIALOG_SERVICE_TICKET_REMINDER_FRAGMENT}
`;

export const UPDATE_SERVICE_TICKET_REMINDER = gql`
  mutation UpdateServiceTicketReminder(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketReminderUpdateInput!
  ) {
    updateServiceTicketReminder(where: $where, data: $data) {
      id
      ...ServiceTicketReminderUpdateDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_REMINDER_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
