import { gql } from '@apollo/client';
import {
  WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT,
  WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_WORK_ORDER_ISSUE_ACTION_ITEM_FRAGMENT,
} from './fragments';

export const FETCH_WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueActionItemUpdateDialogData(
    $where: WorkOrderIssueActionItemWhereUniqueInput!
  ) {
    workOrderIssueActionItem(where: $where) {
      id
      ...WorkOrderIssueActionItemUpdateDialogWorkOrderIssueActionItemFragment
    }
  }
  ${WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_WORK_ORDER_ISSUE_ACTION_ITEM_FRAGMENT}
`;

export const UPDATE_WORK_ORDER_ISSUE_ACTION_ITEM = gql`
  mutation UpdateWorkOrderIssueActionItem(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueActionItemUpdateInput!
  ) {
    updateWorkOrderIssueActionItem(where: $where, data: $data) {
      id
      ...WorkOrderIssueActionItemUpdateDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_ACTION_ITEM_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
