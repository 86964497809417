import React from 'react';
import { __PositionContext } from './PositionProvider';

const usePosition = () => {
  const context = React.useContext(__PositionContext);

  if (context === undefined) {
    throw new Error('usePosition must be used within a PositionProvider');
  }

  return context;
};

export default usePosition;
