import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  COMPLETE_SERVICE_INVOICE_REVIEW,
  FETCH_SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_DATA,
} from './queries';

const ServiceInvoiceReviewApprovalDialog = ({
  serviceInvoiceReviewId,
  serviceTicketId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const [
    fetchServiceInvoiceReviewApprovalDialogData,
    {
      data: serviceInvoiceReviewApprovalDialogData,
      loading: serviceInvoiceReviewApprovalDialogLoading,
      refetch: serviceInvoiceReviewApprovalDialogRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [completeServiceInvoiceReview, { loading: completeServiceInvoiceReviewLoading }] =
    useMutation(COMPLETE_SERVICE_INVOICE_REVIEW, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Invoice Review Successfully Approved',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Approving Service Invoice Review', variant: 'error' })
        );
      },
    });

  const decision = useMemo(
    () =>
      serviceInvoiceReviewApprovalDialogData?.serviceInvoiceReviewDecisions.find(
        (serviceInvoiceReviewDecision) => serviceInvoiceReviewDecision.enum === 'APPROVE'
      ),
    [serviceInvoiceReviewApprovalDialogData?.serviceInvoiceReviewDecisions]
  );

  const loading = useMemo(
    () =>
      [completeServiceInvoiceReviewLoading, serviceInvoiceReviewApprovalDialogLoading].includes(
        true
      ),
    [completeServiceInvoiceReviewLoading, serviceInvoiceReviewApprovalDialogLoading]
  );

  useEffect(() => {
    if (serviceTicketId) {
      fetchServiceInvoiceReviewApprovalDialogData({
        // ROADMAP: Implement or Remove
        // variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchServiceInvoiceReviewApprovalDialogData, serviceTicketId]);

  const onSubmit = async (data) => {
    try {
      await completeServiceInvoiceReview({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: serviceInvoiceReviewId,
            decision: { id: decision?.id },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Confirm Service Invoice Approval
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ padding: '24px 0' }}>
          This action cannot be undone; do you wish to proceed?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={loading}
          form="work-order-issue-action-item-create-form"
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          Proceed
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceInvoiceReviewApprovalDialog;
