// eslint-disable-next-line import/prefer-default-export
export const assetSearchScreenColumnDefs = [
  {
    headerName: 'Asset',
    children: [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      // TODO:
      {
        field: 'manufacturingDate',
        headerName: 'MFG Date',
        sortable: true,
      },
      // TODO:
      {
        field: 'purchaseDate',
        headerName: 'Purchase Date',
        sortable: true,
      },
      {
        field: 'serialNumber',
        headerName: 'Serial Number',
        sortable: true,
      },
      {
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        field: 'type.name',
        headerName: 'Type',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Facility',
    children: [
      {
        field: 'facility.franchiseNumber',
        headerName: 'Number',
        sortable: true,
      },
      {
        field: 'facility.name',
        headerName: 'Name',
        sortable: true,
      },
      {
        field: 'facility.marketTeam',
        headerName: 'Market Team',
        sortable: true,
      },
      {
        field: 'facility.regionalTeam',
        headerName: 'Regional Team',
        sortable: true,
      },
      {
        field: 'facility.businessConsultantContact.name',
        headerName: 'Business Consultant',
        sortable: true,
      },
      {
        field: 'facility.esrContact.name',
        headerName: 'ESR',
        sortable: true,
      },
      {
        field: 'facility.fieldConsultantContact.name',
        headerName: 'Field Consultant',
        sortable: true,
      },
      {
        field: 'facility.leadAdvisorContact.name',
        headerName: 'Lead Advisor',
        sortable: true,
      },
      {
        field: 'facility.operatorContact.name',
        headerName: 'Operator',
        sortable: true,
      },
      {
        field: 'facility.projectAdministratorContact.name',
        headerName: 'Project Administrator',
        sortable: true,
      },
      {
        field: 'facility.regionalCoordinatorContact.name',
        headerName: 'Regional Coordinator',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Product Model',
    children: [
      {
        field: 'productModel.name',
        headerName: 'Name',
        sortable: true,
      },
      // TODO:
      {
        field: 'productModel.category.productModelClassification.name',
        headerName: 'Classification',
        sortable: true,
      },
      {
        field: 'productModel.category.name',
        headerName: 'Category',
        sortable: true,
      },
      // TODO:
      {
        field: 'productModel.owner.name',
        headerName: 'Manufacturer',
        sortable: true,
      },
    ],
  },
];
