import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import { useStreamChat } from 'app/providers/stream/chat';
import {
  ServiceInvoiceViewApp,
  ServiceInvoiceViewDrawer,
  ServiceInvoiceViewHeader,
} from 'app/shared-components/ServiceInvoice';
import {
  ServiceTicketCalendarApp,
  ServiceTicketCalendarHeader,
  ServiceTicketOverviewApp,
  ServiceTicketOverviewHeader,
  ServiceTicketViewDrawer,
} from 'app/shared-components/ServiceTicket';
import {
  ServiceTicketAttachmentViewApp,
  ServiceTicketAttachmentViewDrawer,
  ServiceTicketAttachmentViewHeader,
} from 'app/shared-components/ServiceTicketAttachment';
import { ServiceTicketChannelViewApp } from 'app/shared-components/ServiceTicketChannel';
import {
  ServiceTicketEventViewApp,
  ServiceTicketEventViewHeader,
} from 'app/shared-components/ServiceTicketEvent';
import {
  ServiceTicketReminderViewApp,
  ServiceTicketReminderViewDrawer,
  ServiceTicketReminderViewHeader,
} from 'app/shared-components/ServiceTicketReminder';
import {
  ServiceTicketTaskViewApp,
  ServiceTicketTaskViewDrawer,
  ServiceTicketTaskViewHeader,
} from 'app/shared-components/ServiceTicketTask';
import { WorkOrderViewApp, WorkOrderViewHeader } from 'app/shared-components/WorkOrder';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chat } from 'stream-chat-react';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

const LEFT_SIDEBAR_CONTENT_WIDTH = 341;
const RIGHT_SIDEBAR_CONTENT_WIDTH = 481;

const ServiceTicketViewScreenRoot = () => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();

  const basePath = `${params?.sectionName}/${params?.viewName}`;

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const handleCloseLeftSidebar = () => setLeftSidebarOpen(false);

  const handleToggleLeftSidebar = () => setLeftSidebarOpen(!leftSidebarOpen);

  const handleCloseRightSidebar = () => navigate(`../${basePath}`);

  return (
    <Root
      header={
        <>
          {basePath === 'activity/overview' && (
            <ServiceTicketOverviewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {basePath === 'activity/tasks' && (
            <ServiceTicketTaskViewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {basePath === 'activity/reminders' && (
            <ServiceTicketReminderViewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {basePath === 'activity/work-order' && (
            <WorkOrderViewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {basePath === 'activity/service-invoices' && (
            <ServiceInvoiceViewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {basePath === 'activity/calendar' && (
            <ServiceTicketCalendarHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {basePath === 'activity/timeline' && (
            <ServiceTicketEventViewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}

          {basePath === 'activity/file-attachments' && (
            <ServiceTicketAttachmentViewHeader
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}
        </>
      }
      content={
        <>
          {basePath === 'activity/overview' && <ServiceTicketOverviewApp basePath={basePath} />}

          {basePath === 'activity/tasks' && <ServiceTicketTaskViewApp basePath={basePath} />}

          {basePath === 'activity/reminders' && (
            <ServiceTicketReminderViewApp basePath={basePath} />
          )}

          {basePath === 'activity/work-order' && <WorkOrderViewApp basePath={basePath} />}

          {basePath === 'activity/service-invoices' && (
            <ServiceInvoiceViewApp basePath={basePath} />
          )}

          {basePath === 'activity/calendar' && <ServiceTicketCalendarApp basePath={basePath} />}

          {basePath === 'activity/timeline' && <ServiceTicketEventViewApp basePath={basePath} />}

          {basePath === 'activity/file-attachments' && (
            <ServiceTicketAttachmentViewApp basePath={basePath} />
          )}

          {/* TODO: */}
          {basePath === 'chat/channels' && (
            <ServiceTicketChannelViewApp
              basePath={basePath}
              onToggleLeftSidebar={handleToggleLeftSidebar}
            />
          )}
        </>
      }
      leftSidebarContent={
        <ServiceTicketViewDrawer
          basePath={basePath}
          contentWidth={LEFT_SIDEBAR_CONTENT_WIDTH}
          onToggleDrawer={handleToggleLeftSidebar}
        />
      }
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={LEFT_SIDEBAR_CONTENT_WIDTH}
      leftSidebarOnClose={handleCloseLeftSidebar}
      rightSidebarContent={
        <>
          {basePath === 'activity/tasks' && (
            <ServiceTicketTaskViewDrawer
              basePath={basePath}
              contentWidth={RIGHT_SIDEBAR_CONTENT_WIDTH}
              onCloseDrawer={handleCloseRightSidebar}
            />
          )}

          {basePath === 'activity/reminders' && (
            <ServiceTicketReminderViewDrawer
              basePath={basePath}
              contentWidth={RIGHT_SIDEBAR_CONTENT_WIDTH}
              onCloseDrawer={handleCloseRightSidebar}
            />
          )}

          {basePath === 'activity/service-invoices' && (
            <ServiceInvoiceViewDrawer
              basePath={basePath}
              contentWidth={RIGHT_SIDEBAR_CONTENT_WIDTH}
              onCloseDrawer={handleCloseRightSidebar}
            />
          )}

          {basePath === 'activity/file-attachments' && (
            <ServiceTicketAttachmentViewDrawer
              basePath={basePath}
              contentWidth={RIGHT_SIDEBAR_CONTENT_WIDTH}
              onCloseDrawer={handleCloseRightSidebar}
            />
          )}
        </>
      }
      // TODO:
      rightSidebarOpen={!!params?.viewId && basePath !== 'chat/channels'}
      rightSidebarWidth={RIGHT_SIDEBAR_CONTENT_WIDTH}
      rightSidebarOnClose={handleCloseRightSidebar}
      scroll="content"
    />
  );
};

const ServiceTicketViewScreen = () => {
  const { streamChatClient } = useStreamChat();
  const theme = useTheme();

  if (!streamChatClient) return null;

  return (
    <Chat client={streamChatClient} theme={`str-chat__theme-${theme.palette.mode}`}>
      <ServiceTicketViewScreenRoot />
    </Chat>
  );
};

export default ServiceTicketViewScreen;
