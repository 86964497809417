import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_ATTACHMENT_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketAttachmentCreateDialogServiceTicketFragment on ServiceTicket {
    id
    attachments {
      id
      createdAt
      commonStoredImage {
        id
        commonStoredUpload {
          id
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredType {
        id
        enum
        name
      }
      commonStoredPdf {
        id
        commonStoredUpload {
          id
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        originalPdfUrl
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredVideo {
        id
        captionsFileUrl
        commonStoredUpload {
          id
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        playlistFileUrl
        standardImageUrl
        thumbnailImageUrl
      }
    }
  }
`;
