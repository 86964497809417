import { gql } from '@apollo/client';
import { SERVICE_TICKET_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_SERVICE_TICKET = gql`
  mutation CreateServiceTicket($data: ServiceTicketCreateInput!) {
    createServiceTicket(data: $data) {
      id
      ...ServiceTicketCreateDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
