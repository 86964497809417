import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const USER_SEARCH_VIEW_CONTROL_USER_SEARCH_VIEW_FRAGMENT = gql`
  fragment UserSearchViewControlUserSearchViewFragment on UserSearchView {
    id
    createdAt
    updatedAt

    columnDefs
    columnState
    commonSharingRule {
      id
      subjectTeams {
        id
        name
      }
      subjectType {
        id
        enum
        name
      }
      type {
        id
        enum
        name
      }
    }
    indexName
    isDefault
    isPinned
    name
    searchUiState
    user {
      id
      name
    }
  }
`;
