import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

const CsiClassificationHitsListOption = (props) => {
  const csiClassification = props.data?.hit;

  const option = useMemo(() => {
    if (!csiClassification) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          <Avatar src={null}>
            <FontAwesomeIcon icon={faTag} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={<Typography variant="body2">{`${csiClassification.number}`}</Typography>}
          secondary={
            <div className="flex flex-1">
              <div className="flex items-center">
                <Typography className="ml-6" variant="body2">
                  {`${csiClassification.name}`}
                </Typography>
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }, [csiClassification]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default CsiClassificationHitsListOption;
