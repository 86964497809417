import { adaptRequestsForDateRange } from 'app/shared-components/EntitySearch/EntitySearchDateRangeMenu/utils';

// eslint-disable-next-line import/prefer-default-export
export const IssueTemplateSearchAdapter = (instantSearchClient) => (requests) => {
  return new Promise((resolveSearch, rejectSearch) => {
    try {
      const adaptedRequests = adaptRequestsForDateRange(requests);

      // TODO: Verify Cache
      instantSearchClient.search(adaptedRequests).then((results) => {
        resolveSearch(results);
      });
    } catch (err) {
      rejectSearch(err);
    }
  });
};
