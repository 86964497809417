import { gql } from '@apollo/client';
import { SERVICE_ISSUE_VIEW_HEADER_SERVICE_ISSUE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_SERVICE_ISSUE_VIEW_HEADER_DATA = gql`
  query FetchServiceIssueViewHeaderData($where: ServiceIssueWhereUniqueInput!) {
    serviceIssue(where: $where) {
      id
      ...ServiceIssueViewHeaderServiceIssueFragment
    }
  }
  ${SERVICE_ISSUE_VIEW_HEADER_SERVICE_ISSUE_FRAGMENT}
`;
