import { gql } from '@apollo/client';
import { PROGRAM_SELECTABLE_FACILITY_SELECT_FIELD_PROGRAM_SELECTABLE_FACILITY_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_PROGRAM_SELECTABLE_FACILITY_SELECT_FIELD_DATA = gql`
  query FetchProgramSelectableFacilitySelectFieldData(
    $where: ProgramSelectableFacilityWhereInput!
  ) {
    programSelectableFacilities(where: $where) {
      id
      ...ProgramSelectableFacilitySelectFieldProgramSelectableFacilityFragment
    }
  }
  ${PROGRAM_SELECTABLE_FACILITY_SELECT_FIELD_PROGRAM_SELECTABLE_FACILITY_FRAGMENT}
`;
