import { useMutation, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { WorkOrderIssueCompleteDialogWorkOrderIssues } from './components';
import { COMPLETE_WORK_ORDER_ISSUE, FETCH_WORK_ORDER_ISSUE_COMPLETE_DIALOG_DATA } from './queries';

const defaultValues = {
  checkedWorkOrderIssues: [],
  selectedWorkOrderIssues: [],
};

const schema = yup.object().shape({
  checkedWorkOrderIssues: yup
    .array()
    .test(
      'requireOneCheckedWorkOrderIssue',
      'One or more work order issues must be provided.',
      (value) => value && value.filter((el) => el).length
    ),
  selectedWorkOrderIssues: yup
    .array()
    .of(
      yup.object().shape({
        attachments: yup
          .array()
          .of(
            yup
              .object()
              .shape({
                file: yup.mixed().required(),
                commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
              })
              .required()
          )
          .test(
            'requireOneSelectedWorkOrderIssueAttachment',
            'One or more work order issue attachments must be provided.',
            (value) => value && value.filter((el) => el).length
          ),
        serviceVisit: yup.object().shape({
          connect: yup.mixed().when('mutation', {
            is: (mutation) => mutation?.value === 'CONNECT',
            then: yup.object().nullable().required('A service visit must be provided.'),
          }),
          create: yup.mixed().when('mutation', {
            is: (mutation) => mutation?.value === 'CREATE',
            then: yup.object().shape({
              window: yup
                .array()
                .test(
                  'requireValidArrivalWindow',
                  'An arrival window must be provided.',
                  (value) =>
                    value?.length === 2 && !_.some(value || [], (date) => !moment(date).isValid())
                ),
            }),
          }),
          mutation: yup
            .object()
            .nullable()
            .required('A service visit connection type must be provided.'),
        }),
        workOrderIssueId: yup.string().nullable().required(),
      })
    )
    .test(
      'requireOneSelectedWorkOrderIssue',
      'One or more work order issues must be provided.',
      (value) => value && value.filter((el) => el).length
    ),
});

const WorkOrderIssueCompleteDialog = ({ defaultWorkOrderIssueId, serviceTicketId, onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueCompleteDialogData,
    {
      data: workOrderIssueCompleteDialogData,
      loading: workOrderIssueCompleteDialogLoading,
      refetch: workOrderIssueCompleteDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_COMPLETE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [completeWorkOrderIssue, { loading: completeWorkOrderIssueLoading }] = useMutation(
    COMPLETE_WORK_ORDER_ISSUE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Successfully Completed',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Completing Work Order Issue', variant: 'error' }));
      },
    }
  );

  const filteredWorkOrderIssues = useMemo(
    () =>
      workOrderIssueCompleteDialogData?.serviceTicket?.workOrder?.issues
        ?.filter(({ actionItems, reviews, status, totalAmountCheck }) =>
          Boolean(
            ['APPROVED'].includes(totalAmountCheck?.enum) &&
              ['IN_PROGRESS', 'OPEN', 'SCHEDULED'].includes(status?.enum) &&
              actionItems &&
              !_.some(actionItems || [], ({ totalAmount }) => !totalAmount) &&
              !_.some(reviews || [], ({ isCancelled, isCompleted }) =>
                Boolean(!isCancelled && !isCompleted)
              )
          )
        )
        ?.map((workOrderIssues) => workOrderIssues),
    [workOrderIssueCompleteDialogData?.serviceTicket]
  );

  const loading = useMemo(
    () => [completeWorkOrderIssueLoading, workOrderIssueCompleteDialogLoading].includes(true),
    [completeWorkOrderIssueLoading, workOrderIssueCompleteDialogLoading]
  );

  const serviceTicket = useMemo(
    () => workOrderIssueCompleteDialogData?.serviceTicket,
    [workOrderIssueCompleteDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (defaultWorkOrderIssueId && filteredWorkOrderIssues) {
      const newDefaultValues = _.cloneDeep(defaultValues);
      const defaultWorkOrderIssueIndex = filteredWorkOrderIssues?.findIndex(
        ({ id }) => id === defaultWorkOrderIssueId
      );

      if (defaultWorkOrderIssueIndex !== -1) {
        newDefaultValues.checkedWorkOrderIssues[defaultWorkOrderIssueIndex] =
          defaultWorkOrderIssueId;
      }

      reset(newDefaultValues);
    }
  }, [defaultWorkOrderIssueId, filteredWorkOrderIssues, reset]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchWorkOrderIssueCompleteDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchWorkOrderIssueCompleteDialogData, serviceTicketId]);

  const handleToggleAllWorkOrderIssues = () => {
    if (
      filteredWorkOrderIssues?.length === watchFields.checkedWorkOrderIssues?.length &&
      !_.some(watchFields.checkedWorkOrderIssues || [], (value) => !value)
    ) {
      setValue('checkedWorkOrderIssues', [], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    } else {
      setValue(
        'checkedWorkOrderIssues',
        filteredWorkOrderIssues?.map(({ id }) => id),
        { shouldDirty: true, shouldTouch: true, shouldValidate: true }
      );
    }
  };

  const onSubmit = async (data) => {
    try {
      await Promise.all(
        data?.selectedWorkOrderIssues
          ?.filter((el) => el)
          ?.map(
            (selectedWorkOrderIssue) =>
              // eslint-disable-next-line no-async-promise-executor
              new Promise(async (resolveSelectedWorkOrderIssue, rejectSelectedWorkOrderIssue) => {
                try {
                  await completeWorkOrderIssue({
                    variables: {
                      where: { id: serviceTicketId },
                      data: {
                        attachments: selectedWorkOrderIssue.attachments?.map((attachment) => ({
                          commonStoredUpload: { key: attachment?.commonStoredUpload.key },
                          isCompletionAttachment: true,
                        })),
                        id: selectedWorkOrderIssue.workOrderIssueId,
                        serviceVisit: {
                          connect: selectedWorkOrderIssue.serviceVisit?.connect && {
                            id: selectedWorkOrderIssue.serviceVisit?.connect?.value,
                          },
                          create: selectedWorkOrderIssue.serviceVisit?.create && {
                            windowEndAt: _.last(
                              selectedWorkOrderIssue.serviceVisit?.create?.window || []
                            ),
                            windowStartAt: _.first(
                              selectedWorkOrderIssue.serviceVisit?.create?.window || []
                            ),
                          },
                        },
                      },
                    },
                  });

                  resolveSelectedWorkOrderIssue();
                } catch (err) {
                  rejectSelectedWorkOrderIssue(err);
                }
              })
          )
      );
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Complete Work Order Issue
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-complete-form"
            name="work-order-issue-complete-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="checkedWorkOrderIssues"
              render={({ field }) => (
                <>
                  <div className="mb-24">
                    <div
                      className="border-1 overflow-y-auto"
                      style={{
                        maxHeight: 290,
                        borderColor: errors.checkedWorkOrderIssues ? '#f44336' : grey[300],
                      }}
                    >
                      <List disablePadding>
                        <ListItemButton onClick={handleToggleAllWorkOrderIssues}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                filteredWorkOrderIssues?.length ===
                                  watchFields.checkedWorkOrderIssues?.length &&
                                !_.some(watchFields.checkedWorkOrderIssues || [], (value) => !value)
                              }
                              edge="start"
                              disableRipple
                              indeterminate={
                                (filteredWorkOrderIssues?.length !==
                                  watchFields.checkedWorkOrderIssues?.length &&
                                  _.some(
                                    watchFields.checkedWorkOrderIssues || [],
                                    (value) => value
                                  )) ||
                                (filteredWorkOrderIssues?.length ===
                                  watchFields.checkedWorkOrderIssues?.length &&
                                  _.some(
                                    watchFields.checkedWorkOrderIssues || [],
                                    (value) => value
                                  ) &&
                                  _.some(
                                    watchFields.checkedWorkOrderIssues || [],
                                    (value) => !value
                                  ))
                              }
                              tabIndex={-1}
                              // value={id}
                              onChange={handleToggleAllWorkOrderIssues}
                            />
                          </ListItemIcon>

                          <ListItemText primary="Select All" />
                        </ListItemButton>

                        {filteredWorkOrderIssues?.map(({ id, serviceIssue }, index) => {
                          const handleChange = () => {
                            const newFieldValue = [...field?.value];

                            newFieldValue[index] = !newFieldValue[index] ? id : null;

                            field.onChange(newFieldValue);
                          };

                          return (
                            <ListItemButton key={id} onClick={handleChange}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={!!field?.value?.[index]}
                                  edge="start"
                                  disableRipple
                                  tabIndex={-1}
                                  value={id}
                                  onChange={handleChange}
                                />
                              </ListItemIcon>

                              <ListItemText primary={serviceIssue.name} />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </div>

                    {errors.checkedWorkOrderIssues && (
                      <div className="mx-14 mt-3">
                        <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
                          {errors.checkedWorkOrderIssues?.message}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />

            <WorkOrderIssueCompleteDialogWorkOrderIssues
              filteredWorkOrderIssues={filteredWorkOrderIssues}
              name="selectedWorkOrderIssues"
              serviceTicket={serviceTicket}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-complete-form"
          type="submit"
          variant="contained"
        >
          Complete
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueCompleteDialog;
