import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_SERVICE_TICKET_DEFERRED_UNTIL_UPDATE_DIALOG_DATA,
  UPDATE_SERVICE_TICKET_DEFERRED_UNTIL,
} from './queries';

const defaultValues = {
  deferredUntil: null,
};

const schema = yup.object().shape({
  deferredUntil: yup.date().nullable().optional(),
});

const ServiceTicketDeferredUntilUpdateDialog = ({ serviceTicketId, onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [
    fetchServiceTicketDeferredUntilUpdateDialogData,
    {
      data: serviceTicketDeferredUntilUpdateDialogData,
      loading: serviceTicketDeferredUntilUpdateDialogLoading,
      refetch: serviceTicketDeferredUntilUpdateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_DEFERRED_UNTIL_UPDATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [updateServiceTicketDeferredUntil, { loading: updateServiceTicketDeferredUntilLoading }] =
    useMutation(UPDATE_SERVICE_TICKET_DEFERRED_UNTIL, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Ticket Successfully Updated',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Updating Service Ticket', variant: 'error' }));
      },
    });

  const loading = useMemo(
    () =>
      [
        serviceTicketDeferredUntilUpdateDialogLoading,
        updateServiceTicketDeferredUntilLoading,
      ].includes(true),
    [serviceTicketDeferredUntilUpdateDialogLoading, updateServiceTicketDeferredUntilLoading]
  );

  useEffect(() => {
    if (serviceTicketDeferredUntilUpdateDialogData?.serviceTicket?.deferredUntil) {
      // TODO:
      setValue(
        'deferredUntil',
        serviceTicketDeferredUntilUpdateDialogData?.serviceTicket?.deferredUntil
      );
    }
  }, [serviceTicketDeferredUntilUpdateDialogData?.serviceTicket?.deferredUntil, setValue]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchServiceTicketDeferredUntilUpdateDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchServiceTicketDeferredUntilUpdateDialogData, serviceTicketId]);

  const onSubmit = async (data) => {
    try {
      await updateServiceTicketDeferredUntil({
        variables: {
          where: { id: serviceTicketId },
          data: {
            deferredUntil: data.deferredUntil && moment(data.deferredUntil).format(),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Update Service Ticket
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-ticket-deferred-until-update-form"
            name="service-ticket-deferred-until-update-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* TODO: */}
            <Controller
              control={control}
              name="deferredUntil"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonDatepicker
                    {...field}
                    controls={['calendar']}
                    disabled={false}
                    error={errors?.deferredUntil}
                    min={moment().add(1, 'day').startOf('day')}
                    placeholder="Select Deferred Until Date..."
                    select="date"
                  />
                </div>
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={!isValid || loading}
          form="service-ticket-deferred-until-update-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceTicketDeferredUntilUpdateDialog;
