import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_RESCHEDULE_SITE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const FETCH_WORK_ORDER_ISSUE_RESCHEDULE_SITE_VISIT_DIALOG_DATA = gql`
  query FetchWorkOrderIssueRescheduleSiteVisitDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueRescheduleSiteVisitDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RESCHEDULE_SITE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const RESCHEDULE_SITE_VISIT_WORK_ORDER_ISSUE = gql`
  mutation RescheduleSiteVisitWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueRescheduleSiteVisitInput!
  ) {
    rescheduleSiteVisitWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueRescheduleSiteVisitDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RESCHEDULE_SITE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
