import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonSelect } from 'app/shared-components/Common';
import { showMessage } from 'app/store/fuse/messageSlice';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';

const EntitySearchFacetSelectFieldMoreSelectedBadge = ({ items }) => {
  const style = {
    background: '#4f46e5',
    borderRadius: '4px',
    fontSize: '85%',
    marginLeft: 'auto',
    order: 99,
    padding: '4px',
  };

  const label = `+ ${items.length} Item${items.length !== 1 ? 's' : ''} Selected`;
  const title = items.join(', ');

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const EntitySearchFacetSelectFieldMultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = props.selectProps?.maxToShow || 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  // eslint-disable-next-line no-nested-ternary
  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <EntitySearchFacetSelectFieldMoreSelectedBadge items={overflow} />
  ) : null;
};

const EntitySearchFacetSelectFieldOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        {props.isMulti && (
          <FontAwesomeIcon className="mr-6" icon={props.isSelected ? faSquareCheck : faSquare} />
        )}

        {props.label}
      </components.Option>
    </div>
  );
};

const EntitySearchFacetSelectField = forwardRef(
  ({ className, facetName, indexName, ...SelectProps }, rootRef) => {
    const { axiosSearchClient } = useMeilisearch();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const searchTimer = useRef(null);

    useEffect(() => {
      const searchFacets = async () => {
        try {
          setLoading(true);

          const {
            data: { facetHits },
          } = await axiosSearchClient.post(`/indexes/${indexName}/facet-search`, {
            facetName,
            facetQuery: inputValue,
          });

          if (facetHits) {
            setOptions(
              facetHits.map((facetHit) => ({ label: facetHit.value, value: facetHit.value }))
            );
          }
        } catch (err) {
          dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
        } finally {
          setLoading(false);
        }
      };

      if (searchTimer.current) {
        clearTimeout(searchTimer.current);
      }

      searchTimer.current = setTimeout(() => {
        if (axiosSearchClient && facetName && indexName) {
          searchFacets();
        } else {
          setOptions([]);
        }
      }, 300);

      return () => clearTimeout(searchTimer.current);
    }, [axiosSearchClient, dispatch, facetName, indexName, inputValue]);

    return (
      <div className={className} ref={rootRef}>
        <CommonSelect
          {...SelectProps}
          closeMenuOnSelect={!SelectProps.isMulti}
          components={{
            MultiValue: EntitySearchFacetSelectFieldMultiValue,
            Option: EntitySearchFacetSelectFieldOption,
          }}
          hideSelectedOptions={!SelectProps.isMulti}
          inputValue={inputValue}
          isLoading={loading}
          options={options}
          onInputChange={setInputValue}
        />
      </div>
    );
  }
);

export default EntitySearchFacetSelectField;
