import { gql } from '@apollo/client';
import {
  SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_CHAT_CHANNEL_ATTACHMENT_FRAGMENT,
  SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_PROGRAM_ATTACHMENT_FRAGMENT,
  SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_ISSUE_ATTACHMENT_FRAGMENT,
  SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_TICKET_ATTACHMENT_FRAGMENT,
} from './fragments';

export const FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_CHAT_CHANNEL_ATTACHMENT_DATA = gql`
  query FetchServiceTicketAttachmentViewDrawerChatChannelAttachmentData(
    $where: ChatChannelAttachmentWhereUniqueInput!
  ) {
    chatChannelAttachment(where: $where) {
      id
      ...ServiceTicketAttachmentViewDrawerChatChannelAttachmentFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_CHAT_CHANNEL_ATTACHMENT_FRAGMENT}
`;

export const FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_PROGRAM_ATTACHMENT_DATA = gql`
  query FetchServiceTicketAttachmentViewDrawerProgramAttachmentData(
    $where: ProgramAttachmentWhereUniqueInput!
  ) {
    programAttachment(where: $where) {
      id
      ...ServiceTicketAttachmentViewDrawerProgramAttachmentFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_PROGRAM_ATTACHMENT_FRAGMENT}
`;

export const FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_ISSUE_ATTACHMENT_DATA = gql`
  query FetchServiceTicketAttachmentViewDrawerServiceIssueAttachmentData(
    $where: ServiceIssueAttachmentWhereUniqueInput!
  ) {
    serviceIssueAttachment(where: $where) {
      id
      ...ServiceTicketAttachmentViewDrawerServiceIssueAttachmentFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_ISSUE_ATTACHMENT_FRAGMENT}
`;

export const FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_TICKET_ATTACHMENT_DATA = gql`
  query FetchServiceTicketAttachmentViewDrawerServiceTicketAttachmentData(
    $where: ServiceTicketAttachmentWhereUniqueInput!
  ) {
    serviceTicketAttachment(where: $where) {
      id
      ...ServiceTicketAttachmentViewDrawerServiceTicketAttachmentFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_VIEW_DRAWER_SERVICE_TICKET_ATTACHMENT_FRAGMENT}
`;
