import _ from '@lodash';
import { makeEntitySearchDateRangeMenuPresets } from './presets';

// eslint-disable-next-line import/prefer-default-export
export const adaptRequestsForDateRange = (requests, dateRangeAttribute = 'createdAt') => {
  const adaptedRequests = requests?.map((request) => ({
    ...request,
    params: {
      ...request.params,
      facetFilters: request.params.facetFilters?.map((_facetFilter) => {
        const value = _.isArray(_facetFilter) ? _facetFilter[0] : _facetFilter;
        const [attribute, dateRangeType, dateRangeString] = value?.split(':');

        const dateRangePresets = makeEntitySearchDateRangeMenuPresets({ attribute });

        let facetFilter = _facetFilter;

        if (dateRangeType === 'dateRangePreset') {
          facetFilter = dateRangePresets
            .find(({ value: _value }) => `${dateRangeType}:${dateRangeString}` === _value)
            ?.getAdaptedValue('meilisearch');
        } else if (dateRangeType === 'dateRangeCustom') {
          const [start, end] = dateRangeString.split('|');

          facetFilter = `${dateRangeAttribute} >= ${start} AND ${dateRangeAttribute} <= ${end}`;
        } else if (dateRangeType === 'dateRangeCustomMultiple') {
          const entries = dateRangeString.split(',');

          facetFilter = entries
            ?.map((entry) => {
              const [start, end] = entry.split('|');

              return `(${dateRangeAttribute} >= ${start} AND ${dateRangeAttribute} <= ${end})`;
            })
            ?.join(' OR ');
        }

        return facetFilter;
      }),
    },
  }));

  return adaptedRequests;
};
