import { faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

const ServiceVisitViewAppListItem = ({ basePath, serviceInvoice, serviceTicketOrganizations }) => {
  const navigate = useNavigate();
  const params = useParams();

  if (!serviceInvoice) return null;

  return (
    <ListItemButton
      alignItems="flex-start"
      className="py-12"
      divider
      selected={serviceInvoice.id === params?.viewId}
      onClick={() => navigate(`../${basePath}/view/${serviceInvoice.id}`)}
    >
      <ListItemIcon>
        {serviceInvoice.isApproved && (
          <Tooltip placement="bottom" title="Approved">
            <div>
              <FontAwesomeIcon className="text-18 text-green-800" icon={faCircleCheck} />
            </div>
          </Tooltip>
        )}

        {!serviceInvoice.isApproved && (
          <Tooltip placement="bottom" title="Pending">
            <div>
              <FontAwesomeIcon className="text-18 text-grey-800" icon={faCircle} />
            </div>
          </Tooltip>
        )}
      </ListItemIcon>

      <ListItemText
        disableTypography
        primary={<Typography>{`VRN: ${serviceInvoice.vendorReferenceNumber}`}</Typography>}
        secondary={
          <div className="flex items-center mt-4">
            <Tooltip placement="bottom" title="Arrival Date">
              <div className="flex items-center mr-24">
                <FontAwesomeIcon className="mr-8" icon={faPrint} />

                <Typography className="text-12">
                  {moment(serviceInvoice.createdAt).format('MMM. DD, YYYY @ hh:mm A')}
                </Typography>
              </div>
            </Tooltip>
          </div>
        }
      />
    </ListItemButton>
  );
};

export default ServiceVisitViewAppListItem;
