import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ServiceIssueTagSelectField } from 'app/shared-components/ServiceIssueTag';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ADD_SERVICE_ISSUE_SERVICE_ISSUE_TAG } from './queries';

const defaultValues = {
  tags: [],
};

const schema = yup.object().shape({
  tags: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          __isNew__: yup.boolean().optional(),
          label: yup.string().required(),
          result: yup.object().optional(),
          value: yup.string().required(),
        })
        .required()
    )
    .test(
      'requireOneServiceIssueTag',
      'One or more service issue tags must be provided.',
      (value) => value.filter((el) => el).length
    ),
});

const ServiceIssueAddServiceIssueTagDialog = ({ serviceIssueId, onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [addServiceIssueServiceIssueTag, { loading: addServiceIssueServiceIssueTagLoading }] =
    useMutation(ADD_SERVICE_ISSUE_SERVICE_ISSUE_TAG, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Issue Tag(s) Successfully Added',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Adding Service Issue Tag(s)', variant: 'error' }));
      },
    });

  const loading = useMemo(
    () => [addServiceIssueServiceIssueTag].includes(true),
    [addServiceIssueServiceIssueTag]
  );

  const onSubmit = async (data) => {
    try {
      await addServiceIssueServiceIssueTag({
        variables: {
          where: { id: serviceIssueId },
          data: {
            tags: data.tags?.map((tag) => ({
              name: tag.__isNew__ ? tag.value : tag.result?.name,
            })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Add Service Issue Tag(s)
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-issue-add-service-issue-tag-form"
            name="service-issue-add-service-issue-tag-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <ServiceIssueTagSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.tags}
                  isClearable
                  isDisabled={false}
                  isMulti
                  placeholder="Select / Create Service Issue Tags..."
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-issue-add-service-issue-tag-form"
          type="submit"
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceIssueAddServiceIssueTagDialog;
