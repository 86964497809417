import {
  faSquare,
  faSquareCheck,
  faSquareMinus,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons';
import { faAngleDown, faAngleRight, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { forwardRef } from 'react';

const Action = styled('button')(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  alignItems: 'center',
  justifyContent: 'center',
  touchAction: 'none',
  cursor: 'var(--cursor, pointer)',
  width: '28px',
  height: '28px',
  border: 'none',
  borderRadius: '5px',
  outline: 'none',
  appearance: 'none',
  backgroundColor: 'transparent',
  webkitTapHighlightColor: 'transparent',

  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: `var(--action-background, ${
        theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)'
      })`,

      svg: {
        color: `var(--action-color, ${theme.palette.mode === 'light' ? grey[800] : grey[200]})`,
      },
    },
  },

  '&:active': {
    backgroundColor: `var(--active-background, ${
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)'
    })`,

    svg: {
      color: `var(--active-color, ${theme.palette.mode === 'light' ? grey[600] : grey[400]})`,
    },
  },

  '&:focus-visible': {
    outline: 'none',
    boxShadow: '0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px $focused-outline-color',
  },
}));

const ActionButton = forwardRef(({ active, className, cursor, style, ...props }, ref) => {
  return (
    <Action
      ref={ref}
      {...props}
      className={clsx('Action', className)}
      style={{
        ...style,
        cursor,
        '--active-color': active?.color,
        '--active-background': active?.background,
      }}
      tabIndex={0}
    />
  );
});

const Collapse = forwardRef(({ collapsed, ...props }, ref) => {
  return (
    <ActionButton ref={ref} {...props} className="Collapse">
      <FontAwesomeIcon icon={collapsed ? faAngleRight : faAngleDown} />
    </ActionButton>
  );
});

const Count = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: '#2389ff',
  fontSize: '0.8rem',
  fontWeight: '600',
  color: '#fff',
}));

const Handle = forwardRef((props, ref) => {
  return (
    <ActionButton ref={ref} {...props} className="Handle" cursor="grab">
      <FontAwesomeIcon icon={faGripVertical} />
    </ActionButton>
  );
});

const Remove = forwardRef((props, ref) => {
  return (
    <ActionButton
      ref={ref}
      {...props}
      active={{
        color: 'rgba(255, 70, 70, 0.95)',
        background: 'rgba(255, 70, 70, 0.1)',
      }}
    >
      <FontAwesomeIcon icon={faTrashCan} />
    </ActionButton>
  );
});

const Text = styled('span')(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: '0.5rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const TreeItem = styled('div')(({ theme }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  height: '48px',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#ffffff',
  fontWeight: '500',
  border: theme.palette.mode === 'light' ? '1px solid #bdbdbd' : '1px solid #353e4f',
  backgroundColor: theme.palette.mode === 'light' ? '#f5f5f5' : '#18212f',
}));

const Visible = forwardRef(({ isColumn, isVisible, ...props }, ref) => {
  return (
    <ActionButton ref={ref} {...props} className="Visible">
      {isColumn ? (
        <FontAwesomeIcon icon={isVisible ? faSquareCheck : faSquare} />
      ) : (
        <>
          {_.isBoolean(isVisible) ? (
            <FontAwesomeIcon icon={isVisible ? faSquareCheck : faSquare} />
          ) : (
            <FontAwesomeIcon icon={faSquareMinus} />
          )}
        </>
      )}
    </ActionButton>
  );
});

const Wrapper = styled('li')(({ theme }) => ({
  listStyle: 'none',
  boxSizing: 'border-box',
  paddingLeft: 'var(--spacing)',
  marginBottom: '-1px',

  '&.clone': {
    display: 'inline-block',
    pointerEvents: 'none',
    padding: '0',
    paddingLeft: '10px',
    paddingTop: '5px',

    '.Count': {
      userSelect: 'none',
      webkitUserSelect: 'none',
    },

    '.Text': {
      userSelect: 'none',
      webkitUserSelect: 'none',
    },

    '.TreeItem': {
      paddingRight: '24px',
      borderRadius: '4px',
      boxShadow: '0px 15px 15px 0 rgba(34, 33, 81, 0.1)',
    },
  },

  '&.disableInteraction': {
    pointerEvents: 'none',
  },

  '&.disableSelection': {
    '.Count': {
      userSelect: 'none',
      webkitUserSelect: 'none',
    },

    '.Text': {
      userSelect: 'none',
      webkitUserSelect: 'none',
    },
  },

  '&.ghost': {
    '&.indicator': {
      opacity: '1',
      position: 'relative',
      zIndex: '1',
      marginBottom: '-1px',

      '.TreeItem': {
        position: 'relative',
        padding: '0',
        height: '8px',
        borderColor: '#2389ff',
        backgroundColor: '#56a1f8',

        '&:before': {
          position: 'absolute',
          left: '-8px',
          top: '-4px',
          display: 'block',
          content: '""',
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          border: '1px solid #2389ff',
          backgroundColor: '#ffffff',
        },

        '> *': {
          opacity: '0',
          height: '0',
        },
      },
    },

    '&:not(.indicator)': {
      opacity: '0.5',
    },

    '.TreeItem > *': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
}));

const EntitySearchInfiniteHitsGridColumnTreeTreeItem = forwardRef(
  (
    {
      childCount,
      clone,
      collapsed,
      depth,
      disableInteraction,
      disableSelection,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      isColumn,
      isVisible,
      style,
      value,
      wrapperRef,
      onCollapse,
      onRemove,
      onVisible,
      ...props
    },
    ref
  ) => {
    return (
      <Wrapper
        ref={wrapperRef}
        className={clsx('Wrapper', {
          clone,
          disableInteraction,
          disableSelection,
          ghost,
          indicator,
        })}
        style={{
          '--spacing': `${indentationWidth * depth}px`,
        }}
        {...props}
      >
        <TreeItem ref={ref} className="TreeItem" style={style}>
          <Handle {...handleProps} />

          {onCollapse && <Collapse collapsed={collapsed} onClick={onCollapse} />}

          <Text className="Text">{value}</Text>

          {!clone && onRemove && <Remove onClick={onRemove} />}

          {!clone && onVisible && (
            <Visible isColumn={isColumn} isVisible={isVisible} onClick={onVisible} />
          )}

          {clone && childCount && childCount > 1 ? (
            <Count className="Count">{childCount}</Count>
          ) : null}
        </TreeItem>
      </Wrapper>
    );
  }
);

export default EntitySearchInfiniteHitsGridColumnTreeTreeItem;
