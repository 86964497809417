import React from 'react';
import { __EntitySearchAppContext } from './EntitySearchAppProvider';

const useEntitySearchApp = () => {
  const context = React.useContext(__EntitySearchAppContext);

  if (context === undefined) {
    throw new Error('useEntitySearchApp must be used within a EntitySearchAppProvider');
  }

  return context;
};

export default useEntitySearchApp;
