import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DigitsInput from "../components/DigitsImput";
import { jwtService } from "app/providers/auth/services";


export const ConfirmEmailStep = ({ setEmailStep, emailName, verificationKey }) => {
  const [code, setCode] = useState('');
  const verifyDisabled = code.length <= 5;

  const onSubmit = async () => {
    try {
      const { signInToken } = await jwtService.verifySignIn({
        key: verificationKey,
        secret: code,
      });

      if (signInToken) {
        // TODO:
        const res = await jwtService.completeSignIn({ signInToken });
      }
    } catch (err) {
      console.log('// FIXME1:', { err });
      // setErrorMessage(_.get(err, 'message') || 'An unknown error occurred!');
    }
  };

  return (
    <>
      <Typography className='font-normal text-xl text-center mt-52'>We sent a confirmation link to</Typography>
      <Typography className='font-semibold text-xl text-center'>{emailName}</Typography>
      <Typography className='font-normal text-xl text-center mb-6'>Please check it.</Typography>

      <Typography className='font-normal text-sm text-center mt-64 mb-20'>If you have not received the email after a few minutes, please check your Junk or Spam folder.</Typography>

      <div className="flex flex-col items-center">
        <DigitsInput onChangeCode={setCode} className="mb-4" />
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={verifyDisabled}
          onClick={onSubmit}
          sx={{
            background: '#D416B9',
            padding: '20px 0 20px 0',
            borderRadius: '67px',
            marginTop: '20px',
            marginBottom: '20px',
            "&:hover": { backgroundColor: "#B7129F" }
          }}
        >
          <Typography className='text-lg font-semibold text-white'>Verify</Typography>
        </Button>

        <div className="flex flex-column space-x-20 self-stretch" >
          <Button
            onClick={() => setEmailStep(1)}
            variant="outlined"
            size="medium"
            sx={{
              borderRadius: '100px',
            }}
          >
            <KeyboardBackspaceIcon />
          </Button>
          <Button
            variant="contained"
            size="large"
            type="submit"
            fullWidth
            sx={{
              background: '#D416B9',
              padding: '10px 0',
              borderRadius: '67px',
              "&:hover": { backgroundColor: "#B7129F" }
            }}
          >
            <Typography className='text-lg font-semibold text-white' >Resend Email</Typography>
          </Button>
        </div>
      </div>
    </>
  )
}
