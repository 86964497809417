import { faCircleXmark, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { grey, lightBlue, red } from '@mui/material/colors';
import { CommonCurrencyField } from 'app/shared-components/Common';
import { CsiClassificationHitsListOption } from 'app/shared-components/CsiClassification';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

// ROADMAP: Handle Other Targets
// ROADMAP: Filter Out Other Selected csiClassifications
const makeCsiClassificationFilter = ({ accountingWorkOrderType, serviceIssue }) => {
  let _csiClassificationFilter = [];

  if (
    accountingWorkOrderType &&
    (serviceIssue?.connect || !_.isEmpty(serviceIssue?.create?.targetFacilityAreas))
  ) {
    if (!_.isEmpty(accountingWorkOrderType?.hit?.targetCsiClassifications)) {
      _csiClassificationFilter = [
        ..._csiClassificationFilter,
        `id IN [${accountingWorkOrderType?.hit?.targetCsiClassifications
          .map(({ csiClassification }) => `'${csiClassification.id}'`)
          .join(',')}]`,
      ];
    }

    // ROADMAP: Standardize ID Indexing
    if (serviceIssue?.connect?.hit) {
      _csiClassificationFilter = [
        ..._csiClassificationFilter,
        `targetFacilityAreaTypes.facilityAreaType.id IN [${serviceIssue.connect.hit.targetFacilityAreas
          ?.map(({ facilityArea }) => `'${facilityArea?.typeId}'`)
          .join(',')}]`,
      ];
    } else if (!_.isEmpty(serviceIssue?.create?.targetFacilityAreas)) {
      _csiClassificationFilter = [
        ..._csiClassificationFilter,
        `targetFacilityAreaTypes.facilityAreaType.id IN [${serviceIssue.create.targetFacilityAreas
          ?.map(({ hit }) => {
            return `'${hit?.typeId}'`;
          })
          .join(',')}]`,
      ];
    }
  }

  return _csiClassificationFilter;
};

const ServiceTicketCreateDialogWorkOrderIssueActionItems = ({
  accountingWorkOrderTypeFieldName,
  name,
  serviceIssueFieldName,
  totalFieldName,
}) => {
  const [expandedField, setExpandedField] = useState(null);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const { append, fields, insert, move, prepend, remove, replace, swap, update } = useFieldArray({
    control,
    name,
  });

  const fieldCount = useRef(0);
  const watchAccountingWorkOrderType = watch(accountingWorkOrderTypeFieldName);
  const watchServiceIssue = watch(serviceIssueFieldName);
  const watchFields = watch(name);

  const csiClassificationFilter = makeCsiClassificationFilter({
    accountingWorkOrderType: watchAccountingWorkOrderType,
    serviceIssue: watchServiceIssue,
  });
  const total = watchFields.reduce(
    (acc, { notToExceedAmount }) => acc + (notToExceedAmount || 0),
    0
  );

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    if (fields?.length > fieldCount.current) {
      setExpandedField(_.get(_.last(fields || []), 'id'));
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  // ROADMAP: Implement or Remove
  // useDeepCompareEffect(() => {
  //   [...Array(fieldCount.current).keys()].forEach((index) =>
  //     setValue(`${name}[${index}].csiClassification`, null, { shouldValidate: false })
  //   );
  // }, [csiClassificationFilter, name]);

  useEffect(() => {
    if (fieldCount.current > 0 && _.isNumber(total) && totalFieldName) {
      setValue(totalFieldName, total, { shouldValidate: true });
    } else if (fieldCount.current === 0) {
      setValue(totalFieldName, null, { shouldValidate: false });
    }
  }, [clearErrors, errors, setValue, total, totalFieldName]);

  const handleChangeExpandedField = (fieldId) => (event, newFieldId) => {
    setExpandedField(newFieldId ? fieldId : false);
  };

  return (
    <>
      {_.get(errors, name) && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More Action Items Contain Errors'}
        </Alert>
      )}

      {fields.map((item, index) => {
        let borderLeftColor = grey[600];

        if (_.get(errors, name)?.[index]) {
          // eslint-disable-next-line prefer-destructuring
          borderLeftColor = red[800];
        } else if (expandedField === item.id) {
          // eslint-disable-next-line prefer-destructuring
          borderLeftColor = lightBlue[600];
        }

        return (
          <Accordion
            expanded={expandedField === item.id}
            key={item.id}
            variant="outlined"
            onChange={handleChangeExpandedField(item.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ borderLeft: `4px solid ${borderLeftColor}` }}
            >
              <div className="flex flex-1 items-center">
                {_.isArray(_.get(errors, name)) &&
                  _.isPlainObject(_.get(errors, name)[index]) &&
                  _.some(Object.keys(_.get(errors, name)[index]), (key) =>
                    _.isPlainObject(_.get(errors, name)[index][key])
                  ) && (
                    <FontAwesomeIcon className="mr-8 text-red-800" icon={faCircleXmark} size="lg" />
                  )}

                <Typography>
                  {watchFields?.[index]?.csiClassification?.label
                    ? watchFields?.[index]?.csiClassification?.label
                    : `Action Item ${index + 1}`}
                </Typography>
              </div>

              <IconButton
                sx={{ mr: 1, padding: 0, width: '24px', height: '24px' }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  remove(index);
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} size="xs" />
              </IconButton>
            </AccordionSummary>

            <AccordionDetails sx={{ borderLeft: `4px solid ${borderLeftColor}` }}>
              <Controller
                control={control}
                name={`${name}[${index}].csiClassification`}
                render={({ field }) => (
                  <EntitySearchSelectField
                    {...field}
                    className="mb-24"
                    components={{ Option: CsiClassificationHitsListOption }}
                    error={_.get(errors, `${name}[${index}].csiClassification`)}
                    filter={csiClassificationFilter}
                    idField="id"
                    indexName="csi_classifications"
                    isClearable
                    isDisabled={_.isEmpty(csiClassificationFilter)}
                    isMulti={false}
                    placeholder="Select CSI Classification..."
                    nameField="name"
                    sort={['number:asc']}
                  />
                )}
              />

              <Controller
                control={control}
                name={`${name}[${index}].description`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    error={_.get(errors, `${name}[${index}].description`)}
                    helperText={_.get(errors, `${name}[${index}].description.message`)}
                    variant="outlined"
                    required={false}
                    fullWidth
                    placeholder="Enter Description; Where / What... (Optional)"
                  />
                )}
              />

              <Controller
                control={control}
                name={`${name}[${index}].notToExceedAmount`}
                render={({ field }) => (
                  <CommonCurrencyField
                    {...field}
                    className="mb-24"
                    control={control}
                    disabled={false}
                    error={_.get(errors, `${name}[${index}].notToExceedAmount`)}
                    placeholder="Enter Not To Exceed..."
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}

      <div className="flex w-full items-center justify-end my-24">
        <Button
          color="inherit"
          disabled={false}
          variant="outlined"
          onClick={() =>
            append({ csiClassification: null, description: '', notToExceedAmount: null })
          }
        >
          Add Action Item
        </Button>
      </div>
    </>
  );
};

export default ServiceTicketCreateDialogWorkOrderIssueActionItems;
