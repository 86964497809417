import { useEffect } from 'react';

const useEvent = (channel, eventName, callback) => {
  useEffect(() => {
    if (channel) {
      channel.bind(eventName, callback);
    }

    return () => {
      channel?.unbind(eventName, callback);
    };
  }, [channel, eventName, callback]);
};

export default useEvent;
