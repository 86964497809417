import { ServiceTicketViewScreen } from 'app/screens/ServiceTicketViewScreen';
import { Navigate, Route, Routes } from 'react-router-dom';

const ServiceTicketsViewPage = () => {
  return (
    <Routes>
      <Route element={<ServiceTicketViewScreen />} path=":sectionName/:viewName" />
      <Route element={<ServiceTicketViewScreen />} path=":sectionName/:viewName/view/:viewId" />
      <Route
        element={<ServiceTicketViewScreen />}
        path=":sectionName/:viewName/type/:typeId/view/:viewId"
      />

      <Route path="*" element={<Navigate to="activity/work-order" replace />} />
    </Routes>
  );
};

export default ServiceTicketsViewPage;
