import { gql } from '@apollo/client';
import { SERVICE_TICKET_COMPLETE_RELEASE_PHASE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const COMPLETE_SERVICE_TICKET_RELEASE_PHASE = gql`
  mutation CompleteServiceTicketReleasePhase(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketCompleteReleasePhaseInput!
  ) {
    completeServiceTicketReleasePhase(where: $where, data: $data) {
      id
      ...ServiceTicketCompleteReleasePhaseDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_COMPLETE_RELEASE_PHASE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_SERVICE_TICKET_COMPLETE_RELEASE_PHASE_DIALOG_DATA = gql`
  query FetchServiceTicketCompleteReleasePhaseDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...ServiceTicketCompleteReleasePhaseDialogServiceTicketFragment
    }
    workOrderIssueReleasePhaseDecisions(where: {}) {
      id
      enum
      name
    }
  }
  ${SERVICE_TICKET_COMPLETE_RELEASE_PHASE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
