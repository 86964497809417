import _ from '@lodash';
import { TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CommonTargetTypeSelectField } from 'app/shared-components/CommonTargetType';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const SegmentDefinitionViewHeader = ({ basePath }) => {
  const [segmentSize, setSegmentSize] = useState(null);
  const theme = useTheme();

  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const watchCommonTargetTypeField = useWatch({ name: 'commonTargetType' });
  const watchIsEditableField = useWatch({ name: 'isEditable' });
  const watchSegmentFacilityAreaExpressionCalculationField = useWatch({
    name: 'segmentFacilityAreaExpressionCalculation',
  });

  useEffect(() => {
    if (watchCommonTargetTypeField?.result?.enum === 'EQUIPMENT' && 1 === 2) {
      // TODO:
    } else if (
      watchCommonTargetTypeField?.result?.enum === 'FACILITY_AREA' &&
      _.isNumber(watchSegmentFacilityAreaExpressionCalculationField?.count)
    ) {
      setSegmentSize(watchSegmentFacilityAreaExpressionCalculationField?.count);
    } else {
      setSegmentSize(null);
    }
  }, [
    watchCommonTargetTypeField?.result?.enum,
    watchSegmentFacilityAreaExpressionCalculationField?.count,
  ]);

  return (
    <div className="w-full">
      <div className="flex flex-col sm:flex-row w-full max-w-5xl mx-auto p-16 sm:p-24">
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <div className="flex flex-col">
              <Typography className="mb-6 text-12 uppercase">Segment Name</Typography>

              <TextField
                {...field}
                className="sm:w-288 mb-24 sm:mb-0 sm:mr-24"
                disabled={isSubmitting || !watchIsEditableField}
                error={errors?.name}
                fullWidth
                helperText={errors?.name?.message}
                placeholder="Enter Segment Name..."
                required
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: theme.palette.mode === 'light' ? 'white' : '#1e293b',
                  },
                }}
                variant="outlined"
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name="commonTargetType"
          render={({ field }) => (
            <div className="flex flex-col">
              <Typography className="mb-6 text-12 uppercase">Segment Target Type</Typography>

              <CommonTargetTypeSelectField
                {...field}
                className="sm:w-288 mb-24 sm:mb-0 sm:mr-24"
                error={errors?.commonTargetType}
                filters={null}
                isClearable={false}
                isDisabled={isSubmitting || !watchIsEditableField}
                isMulti={false}
                placeholder="Select Segment Target Type..."
              />
            </div>
          )}
        />

        <div className="flex flex-1 sm:justify-end">
          <div className="flex flex-col sm:items-end">
            <Typography className="mb-6 text-12 uppercase">Segment Size</Typography>

            <Typography className="font-500" style={{ fontSize: '42px', lineHeight: '54px' }}>
              {_.isNumber(segmentSize)
                ? numeral(segmentSize).format(segmentSize < 1000 ? '0a' : '0.0a')
                : '-'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentDefinitionViewHeader;
