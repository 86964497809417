import { gql } from '@apollo/client';

export const PROGRAM_CAMPAIGN_CREATE_DIALOG_PROGRAM_FRAGMENT = gql`
  fragment ProgramCampaignCreateDialogProgramFragment on Program {
    id

    # TODO: campaigns
    issueTemplates {
      id
      name
    }
    name
  }
`;

export const PROGRAM_CAMPAIGN_CREATE_DIALOG_SERVICE_RESPONSE_SEVERITY_FRAGMENT = gql`
  fragment ProgramCampaignCreateDialogServiceResponseSeverityFragment on ServiceResponseSeverity {
    id
    color
    commonTemporalDurationUnit {
      id
      enum
      name
    }
    name
    value
  }
`;
