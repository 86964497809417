import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_RESUME_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueResumeDialogServiceTicketFragment on ServiceTicket {
    id
    completeBy
    workOrder {
      id
      issues {
        id
        completeBy
        scheduledResumptions {
          id
          createdAt
          isProcessed
          resumeAt
        }
        status {
          id
          enum
          name
        }
      }
    }
  }
`;
