import { useEffect, useMemo, useRef, useState } from 'react';
import _ from '@lodash';
import moment from 'moment';
import { gql, useMutation, useQuery } from '@apollo/client';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { useDeepCompareEffect } from '@fuse/hooks';
import {
  Avatar,
  Badge,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// TODO:
// import { Configure, Hits, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { SizeMe } from 'react-sizeme';
import { useAuth } from 'app/providers/auth';
import { jwtService } from 'app/providers/auth/services';
import { useApolloHeartbeat } from 'app/providers/apollo';
import { useMeilisearch } from 'app/providers/meilisearch';
import { usePosition } from 'app/providers/position';
import { useChannel, useEvent, usePresenceChannel } from 'app/providers/pusher/channels';
import { useAutodeskViewer } from 'app/providers/autodesk/viewer';
// import { useUppy } from 'app/providers/uppy';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { selectUser } from 'app/store/userSlice';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

// TODO:
import { Dashboard } from '@uppy/react';
import videojs from 'video.js';
// import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, onReady } = props;

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ({ height, width, onMapLoad }) => {
  const mapContainer = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const map = useRef(null);
  const mapStyle = useMemo(() => ({ height, width }), [height, width]);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-100.874, 42.76],
      zoom: 4,
    });

    map.current.on('load', async () => {
      await Promise.all(
        [
          // NOTE: Additional Markers Available in `/public/assets/images/markers`.
          {
            id: 'red-marker',
            url: 'assets/images/markers/mapbox-marker-icon-20px-red.png',
          },
        ].map(({ id, url }) => {
          return new Promise((resolveImage) => {
            map.current.loadImage(url, (error, image) => {
              if (!error) {
                map.current.addImage(id, image);
              }

              resolveImage();
            });
          });
        })
      );

      map.current.addControl(new mapboxgl.NavigationControl());

      if (typeof onMapLoad === 'function') {
        onMapLoad({ map: map.current });
      }

      setMapLoaded(true);
    });

    // return () => map.current.remove();
  }, [onMapLoad]);

  useDeepCompareEffect(() => {
    if (mapLoaded) {
      map.current.resize();
    }
  }, [mapStyle, mapLoaded]);

  return (
    <div ref={mapContainer} style={mapStyle}>
      {!mapLoaded && <div>Loading...</div>}
    </div>
  );
};

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

const Timer = () => {
  const rendered = useRef(moment());
  const [current, setCurrent] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => setCurrent(moment()), '1000');

    return () => clearInterval(interval);
  }, []);

  return <div>{moment(current).diff(rendered.current, 'seconds')} Seconds</div>;
};

const ExamplePage = ({ ...props }) => {
  const { t } = useTranslation('ExamplePage');

  const auth = useAuth();
  const [currentTab, setCurrentTab] = useState('auth');
  const user = useSelector(selectUser);
  const mainTheme = useSelector(selectMainTheme);
  const themeMode = useMemo(() => mainTheme?.palette.mode, [mainTheme?.palette.mode]);

  const handleChangeTab = (event, newValue) => setCurrentTab(newValue);

  // Auth

  const addUserVerificationMethodForm = useForm({
    defaultValues: { emailAddress: '', method: '', phoneNumber: '' },
    mode: 'onSubmit',
    // resolver: yupResolver(schema),
  });

  const watchEmailAddress = addUserVerificationMethodForm.watch('emailAddress');
  const watchMethod = addUserVerificationMethodForm.watch('method');
  const watchPhoneNumber = addUserVerificationMethodForm.watch('phoneNumber');

  const handleAddUserVerificationMethod = async ({ emailAddress, method, phoneNumber }) => {
    try {
      const res = await jwtService.addUserVerificationMethod({
        emailAddress,
        method: _.get(method, 'value'),
        phoneNumber,
      });

      console.log('// TODO: Implement Add UserVerificationMethod Handling', { res });
    } catch (err) {
      //
    }
  };

  // Apollo / Meilisearch

  const apolloHeartbeat = useApolloHeartbeat();
  const { instantSearchClient } = useMeilisearch();
  const [skip, setSkip] = useState(0);

  const { data: apolloData, loading: apolloLoading } = useQuery(
    gql`
      query GetProductModels($where: ProductModelWhereInput!, $skip: Int) {
        productModels(where: $where, skip: $skip) {
          results {
            id
            name
          }
        }
      }
    `,
    {
      variables: {
        where: {},
        skip,
      },
    }
  );

  // Position

  const map = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const position = usePosition();

  const handleMapLoad = ({ map: _map }) => {
    _map.addSource('position-source', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    _map.addLayer({
      id: 'position-layer',
      type: 'symbol',
      source: 'position-source',
      layout: {
        'icon-image': 'red-marker',
      },
    });

    map.current = _map;

    setMapLoaded(true);
  };

  useEffect(() => setMapLoaded(false), [currentTab]);

  useEffect(() => {
    if (
      currentTab === 'position' &&
      mapLoaded &&
      !_.isEmpty(_.get(position, 'currentPosition.location'))
    ) {
      map.current.getSource('position-source').setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: _.get(position, 'currentPosition.location'),
          },
        ],
      });
    }
  }, [currentTab, mapLoaded, position]);

  // Pusher Beams

  const pusherBeamsForm = useForm({
    defaultValues: { message: '', payload: '', title: '', users: [] },
    mode: 'onSubmit',
    // resolver: yupResolver(schema),
  });

  const { data: pusherBeamsData, loading: pusherBeamsLoading } = useQuery(
    gql`
      query Team($where: TeamWhereUniqueInput!) {
        team(where: $where) {
          id
          memberships {
            id
            user {
              id
              name
            }
          }
          name
        }
      }
    `,
    {
      variables: {
        where: { id: _.get(user, 'data.team.id') },
      },
    }
  );

  const [debugPusherBeams, { loading: debugPusherBeamsLoading }] = useMutation(
    gql`
      mutation DebugPusherBeams($data: PusherBeamsDebugDataInput!) {
        debugPusherBeams(data: $data)
      }
    `
  );

  const handleDebugPusherBeams = async ({ message, payload, title, users }) => {
    try {
      await debugPusherBeams({
        variables: {
          data: {
            message,
            payload,
            title,
            users: (users || []).map(({ value }) => ({ id: value })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  // Pusher Channels

  const [messages, setMessages] = useState([]);
  const { members, ...presenceChannel } = usePresenceChannel(
    `presence-streams.organization.online@organizationId=${_.get(user, 'data.organization.id')}`
  );
  const privateChannel = useChannel(
    [
      ['private-encrypted-streams', 'debug-01', 'debug-root'].join('.'),
      [`organizationId=${_.get(user, 'data.organization.id')}`].join(','),
    ].join('@')
  );
  const pusherChannelsForm = useForm({
    defaultValues: { message: '' },
    mode: 'onSubmit',
    // resolver: yupResolver(schema),
  });

  const { data: pusherChannelsData, loading: pusherChannelsLoading } = useQuery(
    gql`
      query Team($where: TeamWhereUniqueInput!) {
        team(where: $where) {
          id
          memberships {
            id
            role {
              id
              labels {
                type
                value
              }
              name
            }
            user {
              id
              name
            }
          }
          name
        }
      }
    `,
    {
      variables: {
        where: { id: _.get(user, 'data.team.id') },
      },
    }
  );

  const [debugPusherChannels, { loading: debugPusherChannelsLoading }] = useMutation(
    gql`
      mutation DebugPusherChannels($data: PusherChannelsDebugDataInput!) {
        debugPusherChannels(data: $data)
      }
    `
  );

  const handleDebugPusherChannels = async ({ message }) => {
    try {
      await debugPusherChannels({
        variables: {
          data: {
            message,
          },
        },
      });
    } catch (err) {
      //
    } finally {
      pusherChannelsForm.reset();
    }
  };

  useEvent(privateChannel, 'new-message', (message) => {
    setMessages((_messages) => [..._messages, message]);
  });

  // Uppy

  // const { uppy } = useUppy();
  // console.log('// FIXME: Uppy BEFORE', { state: uppy.getState() });
  // uppy.setOptions({ id: 'TEST-1234567890-01', sam: true });
  // console.log('// FIXME: Uppy AFTER', { state: uppy.getState() });

  // VideoJS
  const playerRef = useRef(null);
  const [videoOpen, setVideoOpen] = useState(false);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster:
      'https://dev-ultraviolet-librarian.nyc3.cdn.digitaloceanspaces.com/common-stored-videos/62dcf2e3-f25d-4ac4-8e57-6329f90a6ac9-lightbox.jpg',
    sources: [
      {
        src: 'https://dev-ultraviolet-librarian.nyc3.cdn.digitaloceanspaces.com/common-stored-videos/62dcf2e3-f25d-4ac4-8e57-6329f90a6ac9-pl.m3u8',
        type: 'application/x-mpegURL',
      },
    ],
    tracks: [
      {
        src: 'https://dev-ultraviolet-librarian.nyc3.cdn.digitaloceanspaces.com/common-stored-videos/62dcf2e3-f25d-4ac4-8e57-6329f90a6ac9.vtt',
        kind: 'captions',
        srclang: 'en',
        label: 'English',
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  // Autodesk Viewer

  const { createAutodeskViewer, destroyAutodeskViewer } = useAutodeskViewer();

  useEffect(() => {
    if (currentTab === 'autodesk-viewer') {
      // TODO:
      const htmlElement = document.getElementById('forgeViewer');

      createAutodeskViewer({ htmlElement });

      // TODO: Verify
      return () => destroyAutodeskViewer;
    }

    return () => null;
  }, [createAutodeskViewer, currentTab, destroyAutodeskViewer]);

  return (
    <Root
      header={
        <div className="flex p-24">
          <div className="flex-1">
            <h4>{t('ExamplePage:title', 'Example Page')}</h4>
          </div>

          <div>
            <Timer />
          </div>
        </div>
      }
      content={
        <div className="flex flex-col w-full h-full">
          <Tabs
            classes={{ root: 'border-b-1 px-24' }}
            indicatorColor="secondary"
            textColor="secondary"
            value={currentTab}
            onChange={handleChangeTab}
          >
            <Tab classes={{ root: 'min-w-64' }} label="Auth" value="auth" />
            <Tab classes={{ root: 'min-w-64' }} label="Apollo" value="apollo" />
            <Tab classes={{ root: 'min-w-64' }} label="Meilisearch" value="meilisearch" />
            <Tab classes={{ root: 'min-w-64' }} label="Position" value="position" />
            <Tab classes={{ root: 'min-w-64' }} label="Pusher Beams" value="pusher-beams" />
            <Tab classes={{ root: 'min-w-64' }} label="Pusher Channels" value="pusher-channels" />
            <Tab classes={{ root: 'min-w-64' }} label="Uppy" value="uppy" />
            <Tab classes={{ root: 'min-w-64' }} label="VideoJS" value="videojs" />
            <Tab classes={{ root: 'min-w-64' }} label="Autodesk Viewer" value="autodesk-viewer" />
          </Tabs>

          {/* TODO: Implement UserVerificationMethod Handling */}
          {currentTab === 'auth' && (
            <div className="flex-1 flex-col p-24 pr-64 overflow-scroll">
              <div className="flex w-full items-center mb-12">
                <div className="flex-1">User State [Redux]</div>
                <div>
                  <pre>{JSON.stringify(user, 0, 2)}</pre>
                </div>
              </div>

              <hr className="mb-12" />

              <div className="mb-12">
                <form
                  className="flex flex-col w-full"
                  name="addUserVerificationMethodForm"
                  noValidate
                  onSubmit={addUserVerificationMethodForm.handleSubmit(
                    handleAddUserVerificationMethod
                  )}
                >
                  <div className="flex flex-row items-center mb-24">
                    <div className="flex flex-1 flex-col">
                      <Controller
                        control={addUserVerificationMethodForm.control}
                        name="method"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={[
                              {
                                label: 'Email Address',
                                value: 'USER_EMAIL_ADDRESS',
                              },
                              {
                                label: 'Phone Number',
                                value: 'USER_PHONE_NUMBER',
                              },
                            ]}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                              option: (base, state) => ({
                                ...base,
                                color: state.isSelected ? '#1a1a1a' : base.color,
                                backgroundColor: state.isSelected
                                  ? '#eeeeee'
                                  : base.backgroundColor,
                              }),
                              valueContainer: (base) => ({
                                ...base,
                                padding: '14px',
                              }),
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="w-24 h-24" />

                    <div className="flex flex-1 flex-col">
                      {_.get(watchMethod, 'value') === 'USER_EMAIL_ADDRESS' && (
                        <Controller
                          control={addUserVerificationMethodForm.control}
                          name="emailAddress"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Email Address"
                              required
                              type="email"
                              variant="outlined"
                            />
                          )}
                        />
                      )}

                      {_.get(watchMethod, 'value') === 'USER_PHONE_NUMBER' && (
                        <div>USER_PHONE_NUMBER</div>
                      )}
                    </div>

                    <div className="w-24 h-24" />

                    <div className="flex flex-col">
                      <Button
                        color="secondary"
                        className="shadow-0"
                        disabled={
                          !_.get(watchMethod, 'value') || (!watchEmailAddress && !watchPhoneNumber)
                        }
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {currentTab === 'apollo' && (
            <div className="flex-1 flex-col p-24 pr-64 overflow-scroll">
              <div className="flex w-full items-center mb-12">
                <div className="flex-1">Is Online</div>
                <div>{apolloHeartbeat.isOnline ? 'Yes' : 'No'}</div>
              </div>

              <div className="flex w-full items-center mb-12">
                <div className="flex-1">Checked At</div>
                <div>{apolloHeartbeat.checkedAt}</div>
              </div>

              <hr className="mb-12" />

              <div className="mb-12">
                {apolloLoading ? (
                  <div>Loading...</div>
                ) : (
                  <ul>
                    {(_.get(apolloData, 'productModels.results') || []).map((productModel) => (
                      <li key={_.get(productModel, 'id')}>{_.get(productModel, 'name')}</li>
                    ))}
                  </ul>
                )}
              </div>

              <hr className="mb-12" />

              <div className="flex w-full items-center mb-12">
                <div className="flex-1">
                  <Button onClick={() => setSkip(skip - 10 >= 0 ? skip - 10 : 0)}>Previous</Button>
                </div>

                <div>
                  <Button onClick={() => setSkip(skip + 10)}>Next</Button>
                </div>
              </div>
            </div>
          )}

          {currentTab === 'meilisearch' && (
            <div className="flex-1 flex-col p-24 pr-64 overflow-scroll">
              <div className="flex w-full items-center mb-12">
                <div className="flex-1">Is Online</div>
                <div>{apolloHeartbeat.isOnline ? 'Yes' : 'No'}</div>
              </div>

              <div className="flex w-full items-center mb-12">
                <div className="flex-1">Checked At</div>
                <div>{apolloHeartbeat.checkedAt}</div>
              </div>

              <hr className="mb-12" />

              {instantSearchClient && (
                <div className="mb-12">
                  {/* TODO: */}
                  {/* <InstantSearch
                    indexName="AssetFacetData"
                    searchClient={instantSearchClient}
                    // searchState={searchState}
                    // onSearchStateChange={setSearchState}
                  >
                    <Configure hitsPerPage={10} paginationTotalHits={10} />

                    <SearchBox />

                    <br />

                    <Hits hitComponent={({ hit }) => <div>{_.get(hit, 'name')}</div>} />
                  </InstantSearch> */}
                </div>
              )}
            </div>
          )}

          {currentTab === 'position' && (
            <div className="flex-1 flex-col p-24 pr-64 overflow-scroll">
              <div className="flex w-full items-center mb-12">
                <div className="flex-1">Current Position</div>
                <div>
                  <pre>{JSON.stringify(position.currentPosition, 0, 2)}</pre>
                </div>
              </div>

              <div className="flex w-full items-center mb-12">
                <div className="flex-1">Checked At</div>
                <div>{position.checkedAt}</div>
              </div>

              <div className="flex w-full items-center mb-12">
                <div className="flex-1">Synced At</div>
                <div>{position.syncedAt}</div>
              </div>

              <hr className="mb-12" />

              <SizeMe>
                {({ size }) => <Map height={600} width={size.width} onMapLoad={handleMapLoad} />}
              </SizeMe>
            </div>
          )}

          {currentTab === 'pusher-beams' && (
            <div className="flex-1 flex-col p-24 pr-64 overflow-scroll">
              <form
                className="flex flex-col w-full"
                name="pusherBeamsDebugForm"
                noValidate
                onSubmit={pusherBeamsForm.handleSubmit(handleDebugPusherBeams)}
              >
                <div className="flex flex-row">
                  <div className="flex-1 mb-24">
                    <Controller
                      control={pusherBeamsForm.control}
                      name="users"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          options={(_.get(pusherBeamsData, 'team.memberships') || []).map(
                            (membership) => ({
                              label: _.get(membership, 'user.name'),
                              value: _.get(membership, 'user.id'),
                            })
                          )}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                            option: (base, state) => ({
                              ...base,
                              color: state.isSelected ? '#1a1a1a' : base.color,
                              backgroundColor: state.isSelected ? '#eeeeee' : base.backgroundColor,
                            }),
                            valueContainer: (base) => ({
                              ...base,
                              padding: '14px',
                            }),
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="w-24 mb-24" />

                  <div className="flex-1 mb-24">
                    <Controller
                      control={pusherBeamsForm.control}
                      name="title"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          placeholder="Hello World!"
                          required
                          type="text"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row mb-24">
                  <Controller
                    control={pusherBeamsForm.control}
                    name="message"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        maxRows={4}
                        minRows={4}
                        multiline
                        placeholder="This is a test push notification."
                        required
                        type="text"
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <div className="flex flex-row items-center mb-24">
                  <Controller
                    control={pusherBeamsForm.control}
                    name="payload"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        maxRows={4}
                        minRows={4}
                        multiline
                        placeholder="{ ... }"
                        type="text"
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <div className="mt-48">
                  <Button
                    color="secondary"
                    className="shadow-0"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Publish Notification
                  </Button>
                </div>
              </form>
            </div>
          )}

          {currentTab === 'pusher-channels' && (
            <div className="flex-1 flex-col overflow-hidden">
              <div className="flex flex-row h-full">
                <div className="flex w-400 h-full overflow-scroll">
                  <List className="p-0 w-full border-r-1 bg-white">
                    {(_.get(pusherChannelsData, 'team.memberships') || []).map((membership) => (
                      <ListItem
                        className="px-32 py-12 bg-white"
                        divider
                        key={_.get(membership, 'id')}
                      >
                        <ListItemAvatar>
                          <Badge
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            badgeContent=" "
                            color="default"
                            overlap="circular"
                            sx={{
                              '& .MuiBadge-dot': {
                                width: '14px',
                                height: '14px',
                                backgroundColor:
                                  members && members[_.get(membership, 'user.id')]
                                    ? green[700]
                                    : grey[400],
                                border: '2px solid #fff',
                                borderRadius: '10px',
                              },
                            }}
                            variant="dot"
                          >
                            <Avatar src={null}>
                              {_.first(_.get(membership, 'user.name').split(''))}
                            </Avatar>
                          </Badge>
                        </ListItemAvatar>

                        <ListItemText
                          primary={_.get(membership, 'user.name')}
                          secondary={_.get(
                            _.get(membership, 'role.labels').find(({ type }) => type === 'EN'),
                            'value'
                          )}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>

                <div className="flex flex-1 flex-col h-full">
                  <List className="flex-1 w-full p-20 overflow-scroll">
                    {(messages || []).map((message) => (
                      <div
                        key={_.get(message, 'id')}
                        className="px-20 py-16 mb-24 bg-white rounded"
                      >
                        <div className="flex items-center text-12 text-gray-700">
                          <div className="mr-8 font-700">{_.get(message, 'user.name')}</div>
                          <div>{moment(_.get(message, 'createdAt')).format('DD/MM, HH:mm')}</div>
                        </div>

                        <div>{_.get(message, 'body')}</div>
                      </div>
                    ))}
                  </List>

                  <form
                    className="flex flex-col w-full py-16 px-20 bg-gray-100 border-t-1"
                    name="pusherChannelsDebugForm"
                    noValidate
                    onSubmit={pusherChannelsForm.handleSubmit(handleDebugPusherChannels)}
                  >
                    <Controller
                      control={pusherChannelsForm.control}
                      name="message"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          placeholder="Hello World!"
                          required
                          type="text"
                          variant="outlined"
                        />
                      )}
                    />
                  </form>
                </div>
              </div>
            </div>
          )}

          {currentTab === 'uppy' && (
            <div className="flex-1 flex-col p-24 pr-64 overflow-scroll">
              <Dashboard
                theme={themeMode || 'light'}
                // TODO:
                // uppy={uppy}
                plugins={
                  [
                    'ImageEditor',
                    'Webcam'
                  ]
                }
              />
            </div>
          )}

          {currentTab === 'videojs' && (
            <div className="flex-1 flex-col overflow-hidden">
              <div className="flex flex-row w-full h-full">
                <div className="flex-1 items-center justify-center">
                  <Button onClick={() => setVideoOpen(true)}>Open Video</Button>

                  <Modal open={videoOpen} onClose={() => setVideoOpen(false)}>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 1280,
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        // pt: 2,
                        // px: 4,
                        // pb: 3,
                      }}
                    >
                      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          )}

          {currentTab === 'autodesk-viewer' && (
            <div className="flex-1 flex-col overflow-hidden">
              <div className="flex flex-row w-full h-full relative">
                <div id="forgeViewer" />
              </div>
            </div>
          )}
        </div>
      }
      scroll="content"
    />
  );
};

export default ExamplePage;
