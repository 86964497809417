import { gql } from '@apollo/client';
import { SERVICE_ISSUE_ADD_SERVICE_ISSUE_TAG_DIALOG_SERVICE_ISSUE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const ADD_SERVICE_ISSUE_SERVICE_ISSUE_TAG = gql`
  mutation AddServiceIssueServiceIssueTag(
    $where: ServiceIssueWhereUniqueInput!
    $data: ServiceIssueAddServiceIssueTag!
  ) {
    addServiceIssueServiceIssueTag(where: $where, data: $data) {
      id
      ...ServiceIssueAddServiceIssueTagDialogServiceIssueFragment
    }
  }
  ${SERVICE_ISSUE_ADD_SERVICE_ISSUE_TAG_DIALOG_SERVICE_ISSUE_FRAGMENT}
`;
