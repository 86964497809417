const acceptStyle = {
  borderColor: '#00e676',
};

const baseStyle = {
  alignItems: 'center',
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 2,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  outline: 'none',
  padding: '20px',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const makeStyles = ({ error, isDragAccept, isDragReject, isFocused, mode }) => {
  return {
    ...baseStyle,
    ...(mode === 'dark'
      ? {
          color: '#808080',
          borderColor: 'rgba(255, 255, 255, 0.23)',
          backgroundColor: '#1b2535',
        }
      : {
          color: '#808080',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          backgroundColor: '#fafafa',
        }),
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(error || isDragReject ? rejectStyle : {}),
  };
};

export default makeStyles;
