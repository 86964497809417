import { useLazyQuery, useMutation } from '@apollo/client';
import { useDeepCompareEffect } from '@fuse/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonCurrencyField, CommonDatepicker, CommonSelect } from 'app/shared-components/Common';
import { CommonTargetTypeSelectField } from 'app/shared-components/CommonTargetType';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { IssueTemplateHitsListOption } from 'app/shared-components/IssueTemplate';
import { IssueTemplateTagSelectField } from 'app/shared-components/IssueTemplateTag';
import { ServiceIssueHitsListOption } from 'app/shared-components/ServiceIssue';
import { ServiceIssueTagSelectField } from 'app/shared-components/ServiceIssueTag';
import { ServiceResponseSeveritySelectField } from 'app/shared-components/ServiceResponseSeverity';
import { ServiceVisitHitsListOption } from 'app/shared-components/ServiceVisit';
import { SiteVisitHitsListOption } from 'app/shared-components/SiteVisit';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { WorkOrderIssueCreateDialogServiceIssueAttachments } from './components';
import { CREATE_WORK_ORDER_ISSUE, FETCH_WORK_ORDER_ISSUE_CREATE_DIALOG_DATA } from './queries';

// ROADMAP: Handle Uncontrolled Input Warning
const defaultValues = {
  accountingWorkOrderType: null,
  mutation: null,
  isSaveAsIssueTemplate: false,
  issueTemplate: null,
  notToExceedAmount: null,
  saveAsIssueTemplate: {
    description: '',
    name: '',
    tags: [],
  },
  serviceIssue: null,
  serviceVisit: null,
  siteVisit: null,
};

const schema = yup.object().shape({
  accountingWorkOrderType: yup.object().nullable().required('A work order type must be provided.'),
  isSaveAsIssueTemplate: yup.boolean().optional(),
  issueTemplate: yup.object().nullable().optional(),
  mutation: yup.object().nullable().required('A service issue connection type must be provided.'),
  notToExceedAmount: yup.number().nullable().min(0.01, 'A value must be provided'),
  saveAsIssueTemplate: yup.mixed().when('isSaveAsIssueTemplate', {
    is: (val) => !!val,
    then: yup.object().shape({
      description: yup.string().nullable().optional(),
      name: yup.string().nullable().required('A name must be provided.'),
      tags: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              __isNew__: yup.boolean().optional(),
              label: yup.string().required(),
              result: yup.object().optional(),
              value: yup.string().required(),
            })
            .required()
        )
        .optional(),
    }),
  }),
  serviceIssue: yup
    .mixed()
    .when('mutation', {
      is: (val) => val?.value === 'CONNECT',
      then: yup.object().shape({
        connect: yup.object().nullable().required('A service issue must be provided.'),
      }),
    })
    .when('mutation', {
      is: (val) => val?.value === 'CREATE',
      then: yup.object().shape({
        create: yup.object().shape({
          attachments: yup
            .array()
            .of(
              yup
                .object()
                .shape({
                  file: yup.mixed().required(),
                  commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
                })
                .required()
            )
            .optional(),
          commonTargetType: yup.object().nullable().required('A target type must be provided.'),
          description: yup.string().nullable().optional(),
          name: yup.string().nullable().required('A name must be provided.'),
          serviceResponseSeverity: yup
            .object()
            .nullable()
            .required('A response severity must be provided.'),
          // ROADMAP: Causes Incorrect Validation State with Uncontrolled Inputs
          // tags: yup
          //   .array()
          //   .of(
          //     yup
          //       .object()
          //       .shape({
          //         __isNew__: yup.boolean().optional(),
          //         label: yup.string().required(),
          //         result: yup.object().optional(),
          //         value: yup.string().required(),
          //       })
          //       .required()
          //   )
          //   .optional(),
          targetFacilityAreas: yup.mixed().when('commonTargetType', {
            is: (val) => val?.result?.enum === 'FACILITY_AREA',
            then: yup
              .array()
              .of(
                yup
                  .object()
                  .shape({
                    hit: yup.object().optional(),
                    label: yup.string().required(),
                    value: yup.string().required(),
                  })
                  .required()
              )
              .min(1, 'Select at least one Target Facility Area')
              .required(),
          }),
        }),
      }),
    }),
  serviceVisit: yup.mixed().when('serviceTicketPhaseEnum', {
    is: (val) => val === 'IN_PROGRESS',
    then: yup.object().shape({
      connect: yup.mixed().when('mutation', {
        is: (val) => val?.value === 'CONNECT',
        then: yup.object().nullable().required('A service visit must be provided.'),
      }),
      create: yup.mixed().when('mutation', {
        is: (val) => val?.value === 'CREATE',
        then: yup.object().shape({
          window: yup
            .array()
            .test(
              'requireValidArrivalWindow',
              'An arrival window must be provided.',
              (value) =>
                value?.length === 2 && !_.some(value || [], (date) => !moment(date).isValid())
            ),
        }),
      }),
      mutation: yup.object().nullable().optional(),
    }),
  }),
  siteVisit: yup.mixed().when('serviceTicketPhaseEnum', {
    is: (val) => val === 'QUOTING',
    then: yup.object().shape({
      connect: yup.mixed().when('mutation', {
        is: (val) => val?.value === 'CONNECT',
        then: yup.object().nullable().required('A site visit must be provided.'),
      }),
      create: yup.mixed().when('mutation', {
        is: (val) => val?.value === 'CREATE',
        then: yup.object().shape({
          window: yup
            .array()
            .test(
              'requireValidArrivalWindow',
              'An arrival window must be provided.',
              (value) =>
                value?.length === 2 && !_.some(value || [], (date) => !moment(date).isValid())
            ),
        }),
      }),
      mutation: yup.object().nullable().optional(),
    }),
  }),
});

const WorkOrderIssueCreateDialog = ({ serviceTicketId, onClose }) => {
  const { axiosSearchClient } = useMeilisearch();
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const user = useSelector(selectUser);

  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    ...methods
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueCreateDialogData,
    {
      data: workOrderIssueCreateDialogData,
      loading: workOrderIssueCreateDialogLoading,
      refetch: workOrderIssueCreateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_CREATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [createWorkOrderIssue, { loading: createWorkOrderIssueLoading }] = useMutation(
    CREATE_WORK_ORDER_ISSUE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Successfully Created',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Work Order Issue', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [createWorkOrderIssueLoading, workOrderIssueCreateDialogLoading].includes(true),
    [createWorkOrderIssueLoading, workOrderIssueCreateDialogLoading]
  );

  const serviceTicket = useMemo(
    () => workOrderIssueCreateDialogData?.serviceTicket,
    [workOrderIssueCreateDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (serviceTicket) {
      const formData = {
        serviceTicketPhaseEnum: serviceTicket.phase.enum,
      };

      reset(formData);
    }
  }, [reset, serviceTicket]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchWorkOrderIssueCreateDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchWorkOrderIssueCreateDialogData, serviceTicketId]);

  const handleIssueTemplateSelect = async ({ issueTemplate }) => {
    const {
      data: { hits },
    } = await axiosSearchClient.post('/indexes/facility_areas/search', {
      filter: [
        `facility.id = '${serviceTicket.facility?.id}'`,
        `serviceProvisions.serviceProvision.id = '${serviceTicket.serviceProvision?.id}'`,
      ],
      limit: undefined,
      q: '',
    });

    const targetFacilityAreas = hits?.filter((facilityArea) =>
      issueTemplate?.hit?.serviceIssue?.targetFacilityAreaTypes
        .map(({ facilityAreaType }) => facilityAreaType.id)
        .includes(facilityArea.typeId)
    );

    [
      {
        path: 'serviceIssue.mutation',
        value: 'CREATE',
      },
      {
        path: 'isSaveAsIssueTemplate',
        value: false,
      },
      {
        path: 'serviceIssue.create.name',
        value: issueTemplate.hit?.serviceIssue?.name,
      },
      {
        path: 'accountingWorkOrderType',
        value: {
          label: issueTemplate.hit?.workOrderIssue?.accountingWorkOrderType?.name,
          result: issueTemplate.hit?.workOrderIssue?.accountingWorkOrderType,
          value: issueTemplate.hit?.workOrderIssue?.accountingWorkOrderType?.id,
        },
      },
      {
        path: 'serviceIssue.create.commonTargetType',
        value: {
          label: issueTemplate.hit?.serviceIssue?.commonTargetType?.name,
          result: issueTemplate.hit?.serviceIssue?.commonTargetType,
          value: issueTemplate.hit?.serviceIssue?.commonTargetType?.id,
        },
      },
      {
        path: 'serviceIssue.create.targetFacilityAreas',
        value:
          targetFacilityAreas?.map((targetFacilityArea) => ({
            hit: targetFacilityArea,
            label: targetFacilityArea?.name,
            value: targetFacilityArea?.id,
          })) || [],
      },
      {
        path: 'serviceIssue.create.description',
        value: issueTemplate.hit?.serviceIssue?.description,
      },
      {
        path: 'serviceIssue.create.tags',
        value:
          issueTemplate.hit?.serviceIssue?.serviceIssueTags?.map((serviceIssueTag) => ({
            label: serviceIssueTag?.name,
            result: serviceIssueTag,
            value: serviceIssueTag?.id,
          })) || [],
      },
      // ROADMAP: Implement or Remove
      // {
      //   path: 'actionItems',
      //   value:
      //     issueTemplate.hit?.workOrderIssue?.actionItems?.map((actionItem) => ({
      //       csiClassification: {
      //         hit: actionItem?.csiClassification,
      //         label: `${actionItem?.csiClassification?.name} (${actionItem?.csiClassification?.number})`,
      //         value: actionItem?.csiClassification?.id,
      //       },
      //       description: actionItem?.description,
      //       notToExceedAmount: actionItem?.notToExceedAmount,
      //     })) || [],
      // },
      {
        path: 'notToExceedAmount',
        value: issueTemplate.hit?.workOrderIssue?.notToExceedAmount,
      },
      {
        path: 'serviceIssue.create.attachments',
        value: issueTemplate.hit?.serviceIssue?.attachments
          ?.map((attachment, attachmentIndex) => {
            let attachmentsValue;

            if (attachment.commonStoredType?.enum === 'COMMON_STORED_CAD') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredCad?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredCad?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredImage?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredImage?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredPdf?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredPdf?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredVideo?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredVideo?.commonStoredUpload?.key },
              };
            }

            return attachmentsValue;
          })
          .filter((el) => el),
      },
    ].forEach(({ path, value }) => {
      setValue(path, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: false,
      });
    });

    trigger();
  };

  useDeepCompareEffect(() => {
    if (watchFields?.issueTemplate) {
      handleIssueTemplateSelect({ issueTemplate: watchFields?.issueTemplate });
    }
  }, [watchFields?.issueTemplate || {}]);

  const onSubmit = async (data) => {
    try {
      await createWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            accountingWorkOrderType: data.accountingWorkOrderType && {
              id: data.accountingWorkOrderType?.value,
            },
            issueTemplate:
              !data.isSaveAsIssueTemplate && data.issueTemplate
                ? {
                    id: data.issueTemplate.value,
                  }
                : undefined,
            notToExceedAmount: data.notToExceedAmount,
            saveAsIssueTemplate:
              data.isSaveAsIssueTemplate && data.saveAsIssueTemplate
                ? {
                    description: data.saveAsIssueTemplate.description,
                    name: data.saveAsIssueTemplate.name,
                    tags: data.saveAsIssueTemplate.tags?.map((tag) => ({
                      name: tag.__isNew__ ? tag.value : tag.result?.name,
                    })),
                  }
                : undefined,
            serviceIssue: {
              connect: data.serviceIssue?.connect && {
                id: data.serviceIssue?.connect.value,
              },
              create: data.serviceIssue?.create && {
                attachments: data.serviceIssue.create.attachments?.map((attachment) => ({
                  commonStoredUpload: { key: attachment?.commonStoredUpload.key },
                })),
                commonTargetType: {
                  id: data.serviceIssue.create.commonTargetType?.value,
                },
                description: data.serviceIssue.create.description,
                facility: { id: serviceTicket.facility?.id },
                name: data.serviceIssue.create.name,
                serviceResponseSeverity: {
                  id: data.serviceIssue.create.serviceResponseSeverity?.value,
                },
                tags: data.serviceIssue.create.tags?.map((tag) => ({
                  name: tag.__isNew__ ? tag.value : tag.result?.name,
                })),
                // ROADMAP: Implement
                // targetAsset: data.serviceIssue.create.targetAsset && {
                //   id: data.serviceIssue.create.targetAsset.value,
                // },
                targetFacilityAreas: data.serviceIssue.create.targetFacilityAreas?.map(
                  (targetFacilityArea) => ({ id: targetFacilityArea.value })
                ),
              },
            },
            ...(data?.serviceTicketPhaseEnum === 'IN_PROGRESS' &&
            (data.serviceVisit?.connect?.value || data.serviceVisit.create?.window)
              ? {
                  serviceVisit: {
                    connect: data.serviceVisit?.connect && {
                      id: data.serviceVisit?.connect?.value,
                    },
                    create: data.serviceVisit?.create && {
                      windowEndAt: _.last(data.serviceVisit.create?.window || []),
                      windowStartAt: _.first(data.serviceVisit.create?.window || []),
                    },
                  },
                }
              : {}),
            ...(data?.serviceTicketPhaseEnum === 'QUOTING' &&
            (data.siteVisit?.connect?.value || data.siteVisit.create?.window)
              ? {
                  siteVisit: {
                    connect: data.siteVisit?.connect && {
                      id: data.siteVisit?.connect?.value,
                    },
                    create: data.siteVisit?.create && {
                      windowEndAt: _.last(data.siteVisit.create?.window || []),
                      windowStartAt: _.first(data.siteVisit.create?.window || []),
                    },
                  },
                }
              : {}),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Work Order Issue
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-create-form"
            name="work-order-issue-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="mutation"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonSelect
                    {...field}
                    error={errors?.mutation}
                    isClearable
                    isDisabled={false}
                    isMulti={false}
                    options={[
                      {
                        label: 'Connect to Existing Service Issue',
                        value: 'CONNECT',
                      },
                      {
                        label: 'Create New Service Issue',
                        value: 'CREATE',
                      },
                    ]}
                    placeholder="Select Service Issue Connection..."
                    onChange={(params) => {
                      setValue('serviceIssue', null);

                      field.onChange(params);
                    }}
                  />
                </div>
              )}
            />

            {watchFields?.mutation?.value === 'CONNECT' && (
              <>
                <Controller
                  control={control}
                  name="serviceIssue.connect"
                  render={({ field }) => (
                    <EntitySearchSelectField
                      {...field}
                      className="mb-24"
                      components={{ Option: ServiceIssueHitsListOption }}
                      error={errors?.serviceIssue?.connect}
                      filter={[
                        `targetFacilityAreas.facilityArea.facility.id = '${serviceTicket.facility?.id}'`,
                        `targetFacilityAreas.facilityArea.serviceProvisions.serviceProvision.id = '${serviceTicket.serviceProvision?.id}'`,
                        `status.enum != 'IN_PROGRESS'`,
                      ]}
                      idField="id"
                      indexName="service_issues"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Service Issue..."
                      nameField="name"
                      sort={['name:asc']}
                      onChange={(params) => {
                        setValue('accountingWorkOrderType', null);

                        if (params && watchFields?.serviceTicketPhaseEnum === 'DRAFT') {
                          setValue('notToExceedAmount', params?.hit?.estimatedAmount);
                        }

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                {watchFields?.serviceIssue?.connect && (
                  <>
                    <Controller
                      control={control}
                      name="accountingWorkOrderType"
                      render={({ field }) => {
                        const selectedCommonTargetType =
                          watchFields?.serviceIssue?.connect?.hit?.commonTargetType;
                        const selectedTargetFacilityAreas =
                          watchFields?.serviceIssue?.connect?.hit?.targetFacilityAreas;

                        return (
                          <EntitySearchSelectField
                            {...field}
                            className="mb-24"
                            error={errors?.accountingWorkOrderType}
                            filter={[
                              [
                                // ROADMAP: Remove After Data is Provided
                                `acceptedCommonTargetTypes.id NOT EXISTS`,
                                `acceptedCommonTargetTypes.id = '${selectedCommonTargetType?.id}'`,
                              ],
                              [
                                // ROADMAP: Remove After Data is Provided
                                `targetFacilityAreaTypes.facilityAreaType.id NOT EXISTS`,
                                // ROADMAP: Replace `typeId` with `type?.id`
                                `targetFacilityAreaTypes.facilityAreaType.id IN [${selectedTargetFacilityAreas
                                  ?.map(({ facilityArea }) => `'${facilityArea?.typeId}'`)
                                  .join(',')}]`,
                              ],
                            ]}
                            idField="id"
                            indexName="accounting_work_order_types"
                            isClearable
                            isDisabled={false}
                            isMulti={false}
                            placeholder="Select Work Order Type..."
                            sort={['name:asc']}
                          />
                        );
                      }}
                    />
                  </>
                )}
              </>
            )}

            {watchFields?.mutation?.value === 'CREATE' && (
              <>
                <div className="flex flex-1 items-center mb-24">
                  <Controller
                    control={control}
                    name="issueTemplate"
                    render={({ field }) => (
                      <EntitySearchSelectField
                        {...field}
                        className="flex-1"
                        components={{ Option: IssueTemplateHitsListOption }}
                        error={errors?.issueTemplate}
                        filter={[
                          // ROADMAP: Expand Filter to Include FacilityAreaType
                          `status.enum = 'ACTIVE'`,
                        ]}
                        idField="id"
                        indexName="issue_templates"
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        placeholder="Select Issue Template..."
                        nameField="name"
                        sort={['name:asc']}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="isSaveAsIssueTemplate"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            {...field}
                            checked={!!field.value}
                            color="info"
                            disabled={!!watchFields?.issueTemplate}
                            onChange={(params) => {
                              setValue(`saveAsIssueTemplate.name`, '', {
                                shouldValidate: !!field.value,
                              });
                              setValue(`saveAsIssueTemplate.description`, '', {
                                shouldValidate: !!field.value,
                              });
                              setValue(`saveAsIssueTemplate.tags`, [], {
                                shouldValidate: !!field.value,
                              });

                              field?.onChange(params);
                            }}
                          />
                        }
                        label="Save"
                        labelPlacement="start"
                        sx={{ ml: 3 }}
                      />
                    )}
                  />
                </div>

                {watchFields?.isSaveAsIssueTemplate && (
                  <>
                    <Divider sx={{ mb: 3 }} />

                    <Controller
                      control={control}
                      name="saveAsIssueTemplate.name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          error={errors?.saveAsIssueTemplate?.name}
                          fullWidth
                          helperText={errors?.saveAsIssueTemplate?.name?.message}
                          placeholder="Enter Issue Template Name..."
                          required
                          variant="outlined"
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="saveAsIssueTemplate.description"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          error={errors?.saveAsIssueTemplate?.description}
                          fullWidth
                          helperText={errors?.saveAsIssueTemplate?.description?.message}
                          maxRows={4}
                          minRows={4}
                          multiline
                          placeholder="Enter Issue Template Description..."
                          required
                          variant="outlined"
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="saveAsIssueTemplate.tags"
                      render={({ field }) => (
                        <IssueTemplateTagSelectField
                          {...field}
                          className="mb-24"
                          error={errors?.saveAsIssueTemplate?.tags}
                          isClearable
                          isDisabled={false}
                          isMulti
                          placeholder="Select / Create Issue Template Tags..."
                        />
                      )}
                    />
                  </>
                )}

                <Divider sx={{ mb: 3 }} />

                <Controller
                  control={control}
                  name="serviceIssue.create.name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      error={errors?.serviceIssue?.create?.name}
                      fullWidth
                      helperText={errors?.serviceIssue?.create?.name?.message}
                      placeholder="Enter Name..."
                      required
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="accountingWorkOrderType"
                  render={({ field }) => (
                    <EntitySearchSelectField
                      {...field}
                      className="mb-24"
                      error={errors?.accountingWorkOrderType}
                      // ROADMAP: Implement Territory Filter Based on Selected Facility
                      filter={null}
                      idField="id"
                      indexName="accounting_work_order_types"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Work Order Type..."
                      sort={['name:asc']}
                      onChange={(params) => {
                        setValue('serviceIssue.create.commonTargetType', null);
                        setValue('serviceIssue.create.targetFacilityAreas', []);

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                {watchFields?.accountingWorkOrderType && (
                  <>
                    <Controller
                      control={control}
                      name="serviceIssue.create.commonTargetType"
                      render={({ field }) => (
                        <CommonTargetTypeSelectField
                          {...field}
                          className="mb-24"
                          error={errors?.serviceIssue?.create?.commonTargetType}
                          filters={{
                            ...(watchFields?.accountingWorkOrderType?.hit?.acceptedCommonTargetTypes
                              ? {
                                  enum: {
                                    in: watchFields?.accountingWorkOrderType?.hit?.acceptedCommonTargetTypes.map(
                                      (acceptedCommonTargetType) => acceptedCommonTargetType.enum
                                    ),
                                  },
                                }
                              : {}),
                          }}
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          placeholder="Select Target Type..."
                          onChange={(params) => {
                            setValue('serviceIssue.create.targetFacilityAreas', []);

                            field.onChange(params);
                          }}
                        />
                      )}
                    />

                    {watchFields?.serviceIssue?.create?.commonTargetType?.result?.enum ===
                      'FACILITY_AREA' && (
                      <Controller
                        control={control}
                        name="serviceIssue.create.targetFacilityAreas"
                        render={({ field }) => (
                          <EntitySearchSelectField
                            {...field}
                            className="mb-24"
                            error={errors?.serviceIssue?.create?.targetFacilityAreas}
                            filter={[
                              `facility.id = '${serviceTicket.facility?.id}'`,
                              `serviceProvisions.serviceProvision.id = '${serviceTicket.serviceProvision?.id}'`,
                              !_.isEmpty(
                                watchFields?.accountingWorkOrderType?.hit?.targetFacilityAreaTypes
                              ) &&
                                `typeId IN [${watchFields?.accountingWorkOrderType?.hit?.targetFacilityAreaTypes
                                  .map(({ facilityAreaType }) => `'${facilityAreaType.id}'`)
                                  .join(',')}]`,
                            ].filter((el) => el)}
                            idField="id"
                            indexName="facility_areas"
                            isClearable
                            isDisabled={false}
                            isMulti
                            placeholder="Select Target Facility Areas..."
                            sort={['name:asc']}
                          />
                        )}
                      />
                    )}

                    {!_.isEmpty(watchFields?.serviceIssue?.create?.targetFacilityAreas) && (
                      <>
                        <Controller
                          control={control}
                          name="serviceIssue.create.serviceResponseSeverity"
                          render={({ field }) => (
                            <ServiceResponseSeveritySelectField
                              {...field}
                              className="mb-24"
                              error={errors?.serviceIssue?.create?.serviceResponseSeverity}
                              filters={null}
                              isClearable
                              isDisabled={false}
                              isMulti={false}
                              placeholder="Select Response Severity..."
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="serviceIssue.create.description"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              error={errors?.serviceIssue?.create?.description}
                              fullWidth
                              helperText={errors?.serviceIssue?.create?.description?.message}
                              maxRows={4}
                              minRows={4}
                              multiline
                              placeholder="Enter Description; Where / What..."
                              required
                              variant="outlined"
                            />
                          )}
                        />

                        {['FRANCHISOR', 'OPERATOR'].includes(
                          user?.data?.organization?.type.enum
                        ) && (
                          <Controller
                            control={control}
                            name="serviceIssue.create.tags"
                            render={({ field }) => (
                              <ServiceIssueTagSelectField
                                {...field}
                                className="mb-24"
                                error={errors?.serviceIssue?.create?.tags}
                                isClearable
                                isDisabled={false}
                                isMulti
                                placeholder="Select / Create Service Issue Tags..."
                              />
                            )}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {watchFields?.serviceTicketPhaseEnum === 'DRAFT' && (
              <Controller
                control={control}
                name="notToExceedAmount"
                render={({ field }) => (
                  <CommonCurrencyField
                    {...field}
                    className="mb-24"
                    control={control}
                    disabled={false}
                    error={errors?.notToExceedAmount}
                    placeholder="Enter Not To Exceed..."
                  />
                )}
              />
            )}

            {watchFields?.serviceTicketPhaseEnum === 'QUOTING' && (
              <>
                <Controller
                  control={control}
                  name="siteVisit.mutation"
                  render={({ field }) => (
                    <div className="mb-24">
                      <CommonSelect
                        {...field}
                        error={errors?.siteVisit?.mutation}
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        options={[
                          {
                            label: 'Connect to Existing Site Visit',
                            value: 'CONNECT',
                          },
                          {
                            label: 'Create New Site Visit',
                            value: 'CREATE',
                          },
                        ]}
                        placeholder="Select Site Visit Connection..."
                        onChange={(params) => {
                          setValue('siteVisit', null);

                          field.onChange(params);
                        }}
                      />
                    </div>
                  )}
                />

                {watchFields?.siteVisit?.mutation?.value === 'CONNECT' && (
                  <>
                    <Controller
                      control={control}
                      name="siteVisit.connect"
                      render={({ field }) => (
                        <EntitySearchSelectField
                          {...field}
                          className="mb-24"
                          components={{ Option: SiteVisitHitsListOption }}
                          error={errors?.siteVisit?.connect}
                          filter={[
                            `serviceTicket.id = '${serviceTicketId}'`,
                            `serviceTicket.status.enum = 'OPEN'`,
                          ]}
                          idField="id"
                          indexName="site_visits"
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          labelFn={(hit) =>
                            `${moment(hit?.windowStartAt).format(
                              'MMM. DD, YYYY @ hh:mm A'
                            )} - ${moment(hit?.windowEndAt).format('MMM. DD, YYYY @ hh:mm A')}`
                          }
                          placeholder="Select Site Visit..."
                          nameField="name"
                          sort={['windowStartAtTimestamp:asc']}
                        />
                      )}
                    />
                  </>
                )}

                {watchFields?.siteVisit?.mutation?.value === 'CREATE' && (
                  <>
                    <Controller
                      control={control}
                      name="siteVisit.create.window"
                      render={({ field }) => (
                        <div className="mb-24">
                          <CommonDatepicker
                            {...field}
                            controls={['calendar', 'time']}
                            error={errors?.siteVisit?.create?.window}
                            min={
                              serviceTicket?.deferredUntil
                                ? moment(serviceTicket?.deferredUntil).toDate()
                                : moment().add(1, 'days').startOf('day').toDate()
                            }
                            placeholder="Select an Arrival Window..."
                            select="range"
                            stepMinute={15}
                          />
                        </div>
                      )}
                    />
                  </>
                )}
              </>
            )}

            {watchFields?.serviceTicketPhaseEnum === 'IN_PROGRESS' && (
              <>
                <Controller
                  control={control}
                  name="serviceVisit.mutation"
                  render={({ field }) => (
                    <div className="mb-24">
                      <CommonSelect
                        {...field}
                        error={errors?.serviceVisit?.mutation}
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        options={[
                          {
                            label: 'Connect to Existing Service Visit',
                            value: 'CONNECT',
                          },
                          {
                            label: 'Create New Service Visit',
                            value: 'CREATE',
                          },
                        ]}
                        placeholder="Select Service Visit Connection..."
                        onChange={(params) => {
                          setValue('serviceVisit', null);

                          field.onChange(params);
                        }}
                      />
                    </div>
                  )}
                />

                {watchFields?.serviceVisit?.mutation?.value === 'CONNECT' && (
                  <>
                    <Controller
                      control={control}
                      name="serviceVisit.connect"
                      render={({ field }) => (
                        <EntitySearchSelectField
                          {...field}
                          className="mb-24"
                          components={{ Option: ServiceVisitHitsListOption }}
                          error={errors?.serviceVisit?.connect}
                          filter={[
                            `serviceTicket.id = '${serviceTicketId}'`,
                            `serviceTicket.status.enum = 'OPEN'`,
                          ]}
                          idField="id"
                          indexName="service_visits"
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          labelFn={(hit) =>
                            `${moment(hit?.windowStartAt).format(
                              'MMM. DD, YYYY @ hh:mm A'
                            )} - ${moment(hit?.windowEndAt).format('MMM. DD, YYYY @ hh:mm A')}`
                          }
                          placeholder="Select Service Visit..."
                          nameField="name"
                          sort={['windowStartAtTimestamp:asc']}
                        />
                      )}
                    />
                  </>
                )}

                {watchFields?.serviceVisit?.mutation?.value === 'CREATE' && (
                  <>
                    <Controller
                      control={control}
                      name="serviceVisit.create.window"
                      render={({ field }) => (
                        <div className="mb-24">
                          <CommonDatepicker
                            {...field}
                            controls={['calendar', 'time']}
                            error={errors?.serviceVisit?.create?.window}
                            min={
                              serviceTicket?.deferredUntil
                                ? moment(serviceTicket?.deferredUntil).toDate()
                                : moment().add(1, 'days').startOf('day').toDate()
                            }
                            placeholder="Select an Arrival Window..."
                            select="range"
                            stepMinute={15}
                          />
                        </div>
                      )}
                    />
                  </>
                )}
              </>
            )}

            {watchFields?.mutation?.value === 'CREATE' && (
              <>
                <Divider sx={{ mb: 3 }} />

                <WorkOrderIssueCreateDialogServiceIssueAttachments name="serviceIssue.create.attachments" />
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueCreateDialog;
