import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Navigate, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import { UserProfileImageAvatar } from 'app/shared-components/User/UserProfileImageAvatar';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},

  // TODO:
  '& .FusePageSimple-rightSidebar': {
    flex: '1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
  '& .FusePageSimple-contentWrapper': {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 400,
    },
  },
}));

const UserViewScreenHeader = () => {
  const params = useParams();
  const navigate = useNavigate();

  const handleTabChange = (event, value) => {
    navigate(value);
  };

  return (
    <div className="flex flex-col">
      <img
        className="h-160 lg:h-320 object-cover w-full"
        src="assets/images/pages/profile/cover.jpg"
        alt="Profile Cover"
      />

      <div className="flex flex-col flex-0 lg:flex-row items-center max-w-5xl w-full mx-auto px-32 lg:h-72">
        <UserProfileImageAvatar />

        <div className="flex flex-col items-center lg:items-start mt-16 lg:mt-0 lg:ml-32">
          <Typography className="text-lg font-bold leading-none">Brian Hughes</Typography>
          <Typography color="text.secondary">London, UK</Typography>
        </div>

        <div className="hidden lg:flex h-32 mx-32 border-l-2" />

        <div className="flex items-center mt-24 lg:mt-0 space-x-24">
          <div className="flex flex-col items-center">
            <Typography className="font-bold">200k</Typography>
            <Typography className="text-sm font-medium" color="text.secondary">
              FOLLOWERS
            </Typography>
          </div>
          <div className="flex flex-col items-center">
            <Typography className="font-bold">1.2k</Typography>
            <Typography className="text-sm font-medium" color="text.secondary">
              FOLLOWING
            </Typography>
          </div>
        </div>

        <div className="flex flex-1 justify-end my-16 lg:my-0">
          <Tabs
            value={`../tabs/${params?.tabId}`}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons={false}
            className="-mx-4 min-h-40"
            classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
            TabIndicatorProps={{
              children: (
                <Box
                  sx={{ bgcolor: 'text.disabled' }}
                  className="w-full h-full rounded-full opacity-20"
                />
              ),
            }}
          >
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
              disableRipple
              label="Timeline"
              value="../tabs/one"
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
              disableRipple
              label="About"
              value="../tabs/two"
            />
            <Tab
              className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
              disableRipple
              label="Photos & Videos"
              value="../tabs/three"
            />
          </Tabs>
        </div>
      </div>
    </div>
  );
};

// TODO:
const VALID_TAB_IDS = ['one', 'two'];

const UserViewScreenContent = () => {
  const params = useParams();

  return (
    <div className="flex flex-auto justify-center w-full max-w-5xl mx-auto p-24 sm:p-32">
      <div>
        <pre>{JSON.stringify(params, null, 2)}</pre>
      </div>

      {/* TODO: Lazy Load */}
      {params?.tabId === 'one' && <div>Tab One</div>}
      {params?.tabId === 'two' && <div>Tab Two</div>}

      {!VALID_TAB_IDS.includes(params?.tabId) && <Navigate to="..tabs/one" replace />}
    </div>
  );
};

const UserViewScreen = () => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const params = useParams();

  return (
    <Root
      // TODO:
      header={
        <Routes>
          <Route element={<UserViewScreenHeader />} path="tabs/:tabId" />
        </Routes>
      }
      content={
        <Routes>
          <Route element={<UserViewScreenContent />} path="tabs/:tabId" />

          {/* TODO: */}
          <Route path="*" element={<Navigate to="tabs/one" replace />} />
        </Routes>
      }
      scroll="content"
    />
  );
};

export default UserViewScreen;
