import { gql } from '@apollo/client';
import { ISSUE_TEMPLATE_VIEW_HEADER_ISSUE_TEMPLATE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_ISSUE_TEMPLATE_VIEW_HEADER_DATA = gql`
  query FetchIssueTemplateViewHeaderData($where: IssueTemplateWhereUniqueInput!) {
    issueTemplate(where: $where) {
      id
      ...IssueTemplateViewHeaderIssueTemplateFragment
    }
  }
  ${ISSUE_TEMPLATE_VIEW_HEADER_ISSUE_TEMPLATE_FRAGMENT}
`;
