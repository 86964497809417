import { gql } from '@apollo/client';
import { PROGRAM_VIEW_DRAWER_PROGRAM_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_PROGRAM_VIEW_DRAWER_DATA = gql`
  query FetchProgramViewDrawerData($where: ProgramWhereUniqueInput!) {
    program(where: $where) {
      id
      ...ProgramViewDrawerProgramFragment
    }
  }
  ${PROGRAM_VIEW_DRAWER_PROGRAM_FRAGMENT}
`;
