import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const ChatAppPage = lazy(() => import('./ChatAppPage'));
const chatAppPageBase = '/apps';

// i18next.addResourceBundle('en', 'chatAppPage', en);
// i18next.addResourceBundle('tr', 'chatAppPage', tr);
// i18next.addResourceBundle('ar', 'chatAppPage', ar);

const ChatAppPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${chatAppPageBase}/chat`,
      element: <ChatAppPage />,
    },
    {
      // TODO:
      path: `${chatAppPageBase}/chat/:channelType/view/:channelId`,
      element: <ChatAppPage />,
    },
    {
      path: chatAppPageBase,
      // TODO: Wrong
      element: <Navigate to={`${chatAppPageBase}/chat`} />,
    },
  ],
};

export default ChatAppPageConfig;
