import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Badge, IconButton, Tooltip } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { useDispatch, useSelector } from 'react-redux';
import reducer from './store';
import { toggleActivityStreamPanel } from './store/stateSlice';
import { selectActivityStreamPanelUnseen } from './store/unseenSlice';

const ActivityStreamPanelToggleButton = ({ children }) => {
  const dispatch = useDispatch();
  const unseen = useSelector(selectActivityStreamPanelUnseen);

  return (
    <Tooltip title="Activity Stream">
      <IconButton
        className="w-40 h-40"
        onClick={(ev) => dispatch(toggleActivityStreamPanel())}
        size="large"
      >
        <Badge color="secondary" badgeContent={unseen}>
          {children}
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

ActivityStreamPanelToggleButton.defaultProps = {
  children: <FuseSvgIcon>heroicons-outline:bell</FuseSvgIcon>,
};

export default withReducer('activityStreamPanel', reducer)(ActivityStreamPanelToggleButton);
