import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const unseenSlice = createSlice({
  name: 'activityStreamPanel/unseen',
  initialState: 0,
  reducers: {
    decrementUnseenCount: (state, action) => {
      const unseen = state - 1;

      return unseen >= 0 ? unseen : 0;
    },
    incrementUnseenCount: (state, action) => {
      const unseen = state + 1;

      return unseen;
    },
    setUnseenCount: (state, action) => (_.isNumber(action?.payload) ? action?.payload : state),
  },
});

export const { decrementUnseenCount, incrementUnseenCount, setUnseenCount } = unseenSlice.actions;

export const selectActivityStreamPanelUnseen = ({ activityStreamPanel }) =>
  activityStreamPanel.unseen;

export default unseenSlice.reducer;
