import _ from '@lodash';
import { GlobalSearchBarCommonHeader } from '../components';

const createFacilityTagsPlugin = ({
  axiosSearchClient,
  indexName = 'facilities',
  limit = 10,
  navigate,
  sectionName = 'facilities',
  title = 'Facilities',
}) => ({
  getSources: ({ query: upperQuery, state: upperState }) => {
    if (!upperQuery && _.isEmpty(upperState?.context.tagsPlugin?.tags)) {
      return [];
    }

    return [
      {
        sourceId: 'facilityTagsPlugin',
        getItems: async ({ query, state }) => {
          try {
            const facilityTags = state.context.tagsPlugin?.tags?.filter(
              (tag) => tag.__typename === 'Facility'
            );

            const { data } = await axiosSearchClient.post(`/indexes/${indexName}/search`, {
              filter: [
                ...(!_.isEmpty(facilityTags)
                  ? [`id NOT IN [${facilityTags?.map((tag) => `'${tag?.id}'`)}]`]
                  : []),
              ],
              limit,
              q: query,
            });

            return (data?.hits || []).map((hit) => ({ ...hit, __typename: 'Facility' }));
          } catch (err) {
            return [];
          }
        },
        templates: {
          header: (props) => <GlobalSearchBarCommonHeader title={title} {...props} />,
          item({ item, ...props }) {
            return <div>{item?.name}</div>;
          },
        },
      },
    ];
  },
});

export default createFacilityTagsPlugin;
