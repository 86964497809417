import moment from 'moment';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { SCHEDULE_SERVICE_TICKET_REQUEST } from './queries';

const defaultValues = {
  notToExceedAmount: null,
};

const schema = yup.object().shape({
  notToExceedAmount: yup.number().nullable().min(0.01, 'A value must be provided.'),
});

const ServiceTicketScheduleRequestDialog = ({ deferredUntil, serviceTicketId, onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [scheduleServiceTicketRequest, { loading: scheduleServiceTicketRequestLoading }] =
    useMutation(SCHEDULE_SERVICE_TICKET_REQUEST, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Ticket Successfully Scheduled',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Scheduling Service Ticket', variant: 'error' }));
      },
    });

  const loading = useMemo(
    () => [scheduleServiceTicketRequestLoading].includes(true),
    [scheduleServiceTicketRequestLoading]
  );

  const onSubmit = async (data) => {
    try {
      await scheduleServiceTicketRequest({
        variables: {
          where: { id: serviceTicketId },
          data: {
            serviceVisit: {
              windowEndAt: _.last(data.window || []),
              windowStartAt: _.first(data.window || []),
            },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Schedule Service Visit
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-nte-update-form"
            name="work-order-issue-nte-update-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="window"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonDatepicker
                    {...field}
                    controls={['calendar', 'time']}
                    error={errors.window}
                    min={
                      deferredUntil
                        ? moment(deferredUntil).toDate()
                        : moment().add(1, 'days').startOf('day').toDate()
                    }
                    placeholder="Select an Arrival Window..."
                    select="range"
                    stepMinute={15}
                  />
                </div>
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-nte-update-form"
          type="submit"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceTicketScheduleRequestDialog;
