import { jwtService } from 'app/providers/auth/services';
import { selectUser } from 'app/store/userSlice';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StreamChat } from 'stream-chat';

const { REACT_APP_STREAM_CHAT_API_KEY } = process.env;

const tokenProvider = async () => {
  const session = jwtService.getSession();

  if (jwtService.isTokenValid(session?.chatToken)) {
    return session?.chatToken;
  }

  return new Promise((resolve, reject) => {
    console.log('// TODO: tokenPorvider => Refresh');

    jwtService
      .refreshSession()
      .then(({ chatToken }) => resolve(chatToken))
      .catch((error) => reject(error));
  });
};

const StreamChatContext = React.createContext();

const StreamChatProvider = ({ children }) => {
  const [streamChatClient, setStreamChatClient] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user?.id) {
      const _streamChatClient = new StreamChat(REACT_APP_STREAM_CHAT_API_KEY);

      let didUserConnectInterrupt = false;

      const connectionPromise = _streamChatClient
        // ROADMAP: Clean Up `user` Object
        .connectUser({ id: user.id, name: user.data?.user.name }, tokenProvider)
        .then(() => {
          if (!didUserConnectInterrupt) {
            setStreamChatClient(_streamChatClient);
          }
        });

      return () => {
        didUserConnectInterrupt = true;

        setStreamChatClient(undefined);

        connectionPromise
          .then(() => _streamChatClient.disconnectUser())
          .then(() => {
            console.log('// TODO: StreamChatProvider => Connection Closed');
          });
      };
    }

    // TODO: ...
    return undefined;
    // TODO: Review
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should re-run only if user.id changes
  }, [user?.id]);

  return (
    <StreamChatContext.Provider
      value={{
        streamChatClient,
      }}
    >
      {children}
    </StreamChatContext.Provider>
  );
};

export const __StreamChatContext = StreamChatContext;
export default StreamChatProvider;
