import { gql, useApolloClient } from '@apollo/client';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// TODO:
const config = {
  METABASE_URL: 'https://dev.ultraviolet-metabase.hyvery.io',
};

const CommonMetabaseStaticEmbed = ({
  className,
  dashboard,
  height,
  style = {},
  // TODO:
  title = 'Metabase',
}) => {
  const apolloClient = useApolloClient();
  const iframeEl = useRef(null);
  const [iframeUrl, setIframeUrl] = useState(null);
  const mainTheme = useSelector(selectMainTheme);

  const themeMode = useMemo(() => mainTheme?.palette.mode, [mainTheme?.palette.mode]);

  useEffect(() => {
    if (apolloClient) {
      apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: gql`
            query GetIntelligenceEmbedAuthenticationData(
              $where: IntelligenceEmbedAuthenticationWhereInput!
            ) {
              getIntelligenceEmbedAuthentication(where: $where) {
                id
                token
              }
            }
          `,
          variables: {
            where: {
              resource: { dashboard },
            },
          },
        })
        .then(({ data: intelligenceEmbedAuthenticationData }) => {
          setIframeUrl(
            `${config.METABASE_URL}/embed/dashboard/${intelligenceEmbedAuthenticationData?.getIntelligenceEmbedAuthentication?.token}`
          );
        })
        // ROADMAP: Implement Error Handling
        .catch(() => null);
    }
  }, [apolloClient, dashboard]);

  if (!iframeUrl) return null;

  return (
    <iframe
    id="bob"
      ref={iframeEl}
      src={[
        iframeUrl,
        ['bordered=false', 'titled=false']
          .filter((el) => el)
          .join('&'),
      ].join('#')}
      title={title}
      className={className}
      style={{ border: 'none', width: '100%', height, ...style }}
    />
  );
};

export default CommonMetabaseStaticEmbed;
