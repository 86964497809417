import { faMap } from '@fortawesome/free-regular-svg-icons';
import { faList, faTableCellsLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { useState } from 'react';

const viewModes = {
  grid: {
    faIcon: faTableCellsLarge,
    label: 'Grid',
  },
  list: {
    faIcon: faList,
    label: 'List',
  },
  map: {
    faIcon: faMap,
    label: 'Map',
  },
};

const EntitySearchHeaderViewModeButton = ({ className }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const { availableViewModes, setViewMode, viewMode } = useEntitySearchApp();

  const handleCloseMenu = (event) => setMenuAnchorEl(null);

  const handleOpenMenu = (event) => setMenuAnchorEl(event.currentTarget);

  const handleViewModeChange = (_viewMode) => setViewMode(_viewMode);

  return (
    <div className={className}>
      <Tooltip title={`View Mode: ${viewModes[viewMode]?.label}`}>
        <IconButton color="default" sx={{ width: 40, height: 40 }} onClick={handleOpenMenu}>
          <FontAwesomeIcon icon={viewModes[viewMode].faIcon} size="xs" />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
        {Object.keys(viewModes)
          .filter((key) => availableViewModes?.includes(key))
          .map((key) => (
            <MenuItem
              key={`view-mode-${key}`}
              selected={key === viewMode}
              onClick={() => {
                handleViewModeChange(key);

                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={viewModes[key]?.faIcon} />
              </ListItemIcon>

              <ListItemText>{viewModes[key]?.label}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default EntitySearchHeaderViewModeButton;
