import { gql } from '@apollo/client';
import { SERVICE_TICKET_ATTACHMENT_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_SERVICE_TICKET_ATTACHMENT = gql`
  mutation CreateServiceTicketAttachment(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceTicketAttachmentCreateInput!
  ) {
    createServiceTicketAttachment(where: $where, data: $data) {
      id
      ...ServiceTicketAttachmentCreateDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_ATTACHMENT_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
