import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonSharingRuleSubjectTypeSelectField } from 'app/shared-components/CommonSharingRuleSubjectType';
import { CommonSharingRuleTypeSelectField } from 'app/shared-components/CommonSharingRuleType';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_USER_SEARCH_VIEW_UPDATE_SETTINGS_DIALOG_DATA,
  UPDATE_USER_SEARCH_VIEW_SETTINGS,
} from './queries';

const defaultValues = {
  commonSharingRule: {
    subjectTeams: [],
    subjectType: null,
    type: null,
  },
  name: '',
};

const schema = yup.object().shape({
  commonSharingRule: yup.object().shape({
    subjectTeams: yup.mixed().when('subjectType', {
      is: (val) => val?.result?.enum === 'TEAM',
      then: yup.array().min(1, 'Select at least one Team').required(),
    }),
    subjectType: yup.mixed().when('type', {
      is: (val) => val?.result?.enum === 'READ_ONLY',
      then: yup.object().nullable().required('A subject type must be provided.'),
    }),
    type: yup.object().nullable().required('A type must be provided.'),
  }),
  name: yup.string().nullable().required('A name must be provided.'),
});

const UserSearchViewUpdateSettingsDialog = ({ userSearchViewId, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchUserSearchViewUpdateSettingsDialogData,
    {
      data: userSearchViewUpdateSettingsDialogData,
      loading: userSearchViewUpdateSettingsDialogLoading,
      refetch: userSearchViewUpdateSettingsDialogRefetch,
    },
  ] = useLazyQuery(FETCH_USER_SEARCH_VIEW_UPDATE_SETTINGS_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching User Search View Data',
          variant: 'error',
        })
      );
    },
  });

  const [updateUserSearchViewSettings, { loading: updateUserSearchViewSettingsLoading }] =
    useMutation(UPDATE_USER_SEARCH_VIEW_SETTINGS, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'User Search View Successfully Updated',
            variant: 'success',
          })
        );

        if (typeof onSuccess === 'function') {
          onSuccess(data);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Updating User Search View', variant: 'error' }));
      },
    });

  const loading = useMemo(
    () =>
      [userSearchViewUpdateSettingsDialogLoading, updateUserSearchViewSettingsLoading].includes(
        true
      ),
    [userSearchViewUpdateSettingsDialogLoading, updateUserSearchViewSettingsLoading]
  );

  const userSearchView = useMemo(
    () => userSearchViewUpdateSettingsDialogData?.userSearchView,
    [userSearchViewUpdateSettingsDialogData?.userSearchView]
  );

  useEffect(() => {
    if (userSearchView) {
      const formData = {
        commonSharingRule: {
          subjectTeams:
            userSearchView?.commonSharingRule?.subjectTeams?.map((subjectTeam) => ({
              label: subjectTeam.name,
              value: subjectTeam.id,
              hit: subjectTeam,
            })) || undefined,
          subjectType: userSearchView?.commonSharingRule?.subjectType
            ? {
                label: userSearchView?.commonSharingRule?.subjectType?.name,
                value: userSearchView?.commonSharingRule?.subjectType?.id,
                result: userSearchView?.commonSharingRule?.subjectType,
              }
            : undefined,
          type: {
            label: userSearchView?.commonSharingRule?.type?.name,
            value: userSearchView?.commonSharingRule?.type?.id,
            result: userSearchView?.commonSharingRule?.type,
          },
        },
        name: userSearchView?.name,
      };

      reset(formData);
    }
  }, [reset, userSearchView]);

  useEffect(() => {
    if (userSearchViewId) {
      setFetched(false);
      fetchUserSearchViewUpdateSettingsDialogData({
        variables: { where: { id: userSearchViewId } },
      });
    }
  }, [fetchUserSearchViewUpdateSettingsDialogData, userSearchViewId]);

  const onSubmit = async (data) => {
    try {
      await updateUserSearchViewSettings({
        variables: {
          where: { id: userSearchViewId },
          data: {
            commonSharingRule: {
              subjectTeams: data.commonSharingRule.subjectTeams?.map((subjectTeam) => ({
                id: subjectTeam?.value,
              })),
              subjectType: data.commonSharingRule.subjectType
                ? { id: data.commonSharingRule.subjectType?.value }
                : null,
              type: { id: data.commonSharingRule.type?.value },
            },
            name: data.name,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Update User Search View
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{
            clearErrors,
            control,
            formState,
            handleSubmit,
            reset,
            setValue,
            watch,
            ...methods,
          }}
        >
          <form
            className="w-full pt-20"
            id="user-search-view-update-settings-form"
            name="user-search-view-update-settings-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter User Search View Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <Controller
              control={control}
              name="commonSharingRule.type"
              render={({ field }) => (
                <CommonSharingRuleTypeSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.commonSharingRule?.type}
                  filters={{
                    enum: {
                      // ROADMAP: Handle Additional Common Sharing Rule Types
                      in: ['PRIVATE', 'READ_ONLY'],
                    },
                  }}
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Sharing Rule Type..."
                  onChange={(params) => {
                    setValue('commonSharingRule.subjectType', null);
                    setValue('commonSharingRule.subjectTeams', []);

                    field.onChange(params);
                  }}
                />
              )}
            />

            {watchFields?.commonSharingRule?.type?.result?.enum === 'READ_ONLY' && (
              <>
                <Controller
                  control={control}
                  name="commonSharingRule.subjectType"
                  render={({ field }) => (
                    <CommonSharingRuleSubjectTypeSelectField
                      {...field}
                      className="mb-24"
                      error={errors?.commonSharingRule?.subjectType}
                      filters={{
                        enum: {
                          // ROADMAP: Handle Additional Common Sharing Rule Subject Types
                          in: ['TEAM'],
                        },
                      }}
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Sharing Rule Subject Type..."
                      onChange={(params) => {
                        setValue('commonSharingRule.subjectTeams', []);

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                {watchFields?.commonSharingRule?.subjectType?.result?.enum === 'TEAM' && (
                  <>
                    <Controller
                      control={control}
                      name="commonSharingRule.subjectTeams"
                      render={({ field }) => (
                        <EntitySearchSelectField
                          {...field}
                          className="mb-24"
                          error={errors?.commonSharingRule?.subjectTeams}
                          filter={null}
                          idField="id"
                          indexName="teams"
                          isClearable
                          isDisabled={false}
                          isMulti
                          placeholder="Select Sharing Rule Subject Teams..."
                          sort={['name:asc']}
                        />
                      )}
                    />
                  </>
                )}
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="user-search-view-update-settings-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default UserSearchViewUpdateSettingsDialog;
