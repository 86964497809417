import { gql } from '@apollo/client';
import { SERVICE_TICKET_COMPLETE_QUOTING_PHASE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const COMPLETE_SERVICE_TICKET_QUOTING_PHASE = gql`
  mutation CompleteServiceTicketQuotingPhase($where: ServiceTicketWhereUniqueInput!) {
    completeServiceTicketQuotingPhase(where: $where) {
      id
      ...ServiceTicketCompleteQuotingPhaseDialogServiceTicketFragment
    }
  }
  ${SERVICE_TICKET_COMPLETE_QUOTING_PHASE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
