import React, { useEffect, useState } from "react";

const DigitsInput = ({ onChangeCode, className }) => {
  const [codes, setCodes] = useState(['', '', '', '', '', '']);

  useEffect(() => {
    onChangeCode(codes.join(''));
  }, [codes]);

  const handleChangeInput = (event) => {
    const index = Number(event.target.getAttribute('id'));
    const value = event.target.value;
    setCodes((prev) => {
      const newArr = [...prev];
      newArr[index] = value;
      return newArr;
    });
    if (event.target.nextSibling) {
      (event.target.nextSibling).focus();
    }
  };

  return (
    <div className={`flex flex-row space-x-12 self-center ${className}`} >
      {
        codes.map((code, index) => (
          <input
            className="w-48 h-68 text-center rounded-lg text-xl	shadow-code"
            maxLength={1}
            type='tel'
            key={index}
            id={String(index)}
            onChange={handleChangeInput}
            value={code}
          />
        ))
      }
    </div>
  );
};

export default DigitsInput;
