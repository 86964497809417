import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_REVIEW_COMPLETE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const COMPLETE_WORK_ORDER_ISSUE_REVIEW = gql`
  mutation CompleteWorkOrderIssueReview(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueReviewCompleteInput!
  ) {
    completeWorkOrderIssueReview(where: $where, data: $data) {
      id
      ...WorkOrderIssueReviewCompleteDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_REVIEW_COMPLETE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_WORK_ORDER_ISSUE_REVIEW_COMPLETE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueReviewCompleteDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueReviewCompleteDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_REVIEW_COMPLETE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
