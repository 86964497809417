import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

const FacilityHitsListOption = (props) => {
  const facility = props.data?.hit;

  const option = useMemo(() => {
    if (!facility) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          {/* ROADMAP: Implement */}
          <Avatar src={null}>
            <FontAwesomeIcon icon={faImage} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2">{`${facility.franchiseNumber} - ${facility.name}`}</Typography>
          }
          secondary={
            <div className="flex flex-1">
              <div className="flex items-center">
                <Typography className="ml-6" variant="body2">
                  {`${facility.address.city},  ${facility.address.region}`}
                </Typography>
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }, [facility]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default FacilityHitsListOption;
