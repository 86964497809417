import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const ServiceTicketOverviewHeader = ({ basePath, onToggleLeftSidebar }) => {
  const dispatch = useDispatch();
  const params = useParams();

  return (
    <AppBar color="inherit" elevation={0} position="static">
      <Toolbar>
        <div className="flex flex-1 items-center">
          <Tooltip title="Toggle Menu">
            <IconButton
              color="default"
              edge="start"
              sx={{ mr: 2, width: 40, height: 40 }}
              onClick={onToggleLeftSidebar}
            >
              <FontAwesomeIcon icon={faBars} size="xs" />
            </IconButton>
          </Tooltip>

          <Typography component="div" noWrap variant="h6">
            Overview
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default ServiceTicketOverviewHeader;
