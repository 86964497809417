import { useLazyQuery } from '@apollo/client';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import { Checkbox, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { CommonSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';
import { forwardRef, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { FETCH_PROGRAM_SELECTABLE_FACILITY_SELECT_FIELD_DATA } from './queries';

const ProgramSelectableFacilitySelectOption = (props) => {
  const fieldOptions = useMemo(
    () => props?.selectProps?.fieldOptions,
    [props?.selectProps?.fieldOptions]
  );

  const isSelected = useMemo(() => props?.isSelected, [props?.isSelected]);

  const programSelectableFacility = useMemo(() => props?.data?.result, [props?.data?.result]);

  const watchOptions = useMemo(
    () => props?.selectProps?.watchOptions,
    [props?.selectProps?.watchOptions]
  );

  const item = useMemo(() => {
    if (!programSelectableFacility && props?.value !== 'SELECT_ALL') return null;

    return props?.value === 'SELECT_ALL' ? (
      <ListItem component="div" dense>
        <ListItemIcon>
          <Checkbox
            checked={
              fieldOptions?.length === watchOptions?.length &&
              !_.some(watchOptions || [], (value) => !value)
            }
            color="info"
            disableRipple
            edge="start"
            indeterminate={
              (fieldOptions?.length !== watchOptions?.length &&
                _.some(watchOptions || [], (value) => value)) ||
              (fieldOptions?.length === watchOptions?.length &&
                _.some(watchOptions || [], (value) => value) &&
                _.some(watchOptions || [], (value) => !value))
            }
          />
        </ListItemIcon>

        <ListItemText
          disableTypography
          primary={
            <Typography className="font-500" variant="body2">
              Select All
            </Typography>
          }
        />
      </ListItem>
    ) : (
      <ListItem component="div" dense>
        <ListItemIcon>
          <Checkbox checked={!!isSelected} color="info" disableRipple edge="start" />
        </ListItemIcon>

        <ListItemText
          disableTypography
          primary={
            <Typography className="font-500" variant="body2">
              {props?.label}
            </Typography>
          }
          secondary={
            <div className="flex flex-1">
              <div className="flex items-center">
                <Typography variant="body2">
                  {`Vendor: ${
                    programSelectableFacility?.matchingServiceProvisions?.length === 1
                      ? programSelectableFacility?.matchingServiceProvisions?.[0]
                          ?.organizationConnection?.vendor.name
                      : 'Vendor Cannot be Automatically Determined'
                  }`}
                </Typography>
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }, [
    fieldOptions?.length,
    isSelected,
    programSelectableFacility,
    props?.label,
    props?.value,
    watchOptions,
  ]);

  return <components.Option {...props}>{item}</components.Option>;
};

const ProgramSelectableFacilitySelectField = forwardRef(
  ({ className, filters = {}, ...SelectProps }, rootRef) => {
    const dispatch = useDispatch();
    const [fetched, setFetched] = useState(false);
    const { setValue, watch } = useFormContext();

    const watchOptions = watch(SelectProps?.name);

    const [
      fetchProgramSelectableFacilitySelectFieldData,
      {
        data: programSelectableFacilitySelectFieldData,
        loading: programSelectableFacilitySelectFieldLoading,
        refetch: programSelectableFacilitySelectFieldRefetch,
      },
    ] = useLazyQuery(FETCH_PROGRAM_SELECTABLE_FACILITY_SELECT_FIELD_DATA, {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setFetched(true),
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Fetching Program Data',
            variant: 'error',
          })
        );
      },
    });

    const loading = useMemo(
      () => [programSelectableFacilitySelectFieldLoading].includes(true),
      [programSelectableFacilitySelectFieldLoading]
    );

    const programSelectableFacilities = useMemo(
      () => programSelectableFacilitySelectFieldData?.programSelectableFacilities,
      [programSelectableFacilitySelectFieldData?.programSelectableFacilities]
    );

    const fieldOptions = useMemo(
      () =>
        _.orderBy(
          programSelectableFacilities?.map((programSelectableFacility) => ({
            label: `${programSelectableFacility.facility?.franchiseNumber} - ${programSelectableFacility.facility?.name}`,
            value: programSelectableFacility.id,
            result: programSelectableFacility,
          })) || [],
          'result.facility.name'
        ),
      [programSelectableFacilities]
    );

    useDeepCompareEffect(() => {
      if (filters?.program?.id && _.isArray(filters?.issueTemplates)) {
        fetchProgramSelectableFacilitySelectFieldData({
          variables: { where: { ...(filters || {}) } },
        });
      }
    }, [fetchProgramSelectableFacilitySelectFieldData, filters]);

    const handleToggleAllOptions = () => {
      if (
        fieldOptions?.length === watchOptions?.length &&
        !_.some(watchOptions || [], (value) => !value)
      ) {
        setValue(SelectProps?.name, [], {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      } else {
        setValue(SelectProps?.name, fieldOptions, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
    };

    return (
      <div className={className} ref={rootRef}>
        <CommonSelect
          {...SelectProps}
          closeMenuOnSelect={false}
          components={{ Option: ProgramSelectableFacilitySelectOption }}
          controlShouldRenderValue={false}
          disableHighlightSelected
          hideSelectedOptions={false}
          isLoading={loading}
          options={
            !_.isEmpty(fieldOptions)
              ? [
                  {
                    label: 'Select All',
                    value: 'SELECT_ALL',
                    result: null,
                  },
                  ...fieldOptions,
                ]
              : []
          }
          fieldOptions={fieldOptions}
          watchOptions={watchOptions}
          onChange={(params) => {
            if (_.some(params, ({ value }) => value === 'SELECT_ALL')) {
              handleToggleAllOptions();
            } else {
              SelectProps?.onChange(params);
            }
          }}
        />
      </div>
    );
  }
);

export default ProgramSelectableFacilitySelectField;
