import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

const ServiceIssueHitsListOption = (props) => {
  const serviceIssue = props.data?.hit;

  const option = useMemo(() => {
    if (!serviceIssue) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          <Avatar src={null}>
            <FontAwesomeIcon icon={faWrench} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2">{`${serviceIssue.name} (${serviceIssue.serviceResponseSeverity?.name})`}</Typography>
          }
          secondary={
            <div className="flex flex-1">
              <div className="flex items-center">
                <Typography variant="body2">
                  {/* ROADMAP: Handle Other Targets */}
                  {`Facility Areas: ${serviceIssue.targetFacilityAreas
                    ?.map(({ facilityArea }) => facilityArea?.name)
                    .join(', ')}`}
                </Typography>
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }, [serviceIssue]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default ServiceIssueHitsListOption;
