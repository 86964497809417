import React, { useEffect, useRef, useState } from 'react';
import _ from '@lodash';
import { gql } from '@apollo/client';
import Pusher from 'pusher-js/with-encryption';
import { useSelector } from 'react-redux';
import { apiClient } from 'app/providers/apollo/clients';
import store from 'app/store';
import { selectUser } from 'app/store/userSlice';

const { REACT_APP_PUSHER_CHANNELS_CLUSTER, REACT_APP_PUSHER_CHANNELS_KEY } = process.env;

const pusherChannelsClient = new Pusher(REACT_APP_PUSHER_CHANNELS_KEY, {
  channelAuthorization: {
    customHandler: async ({ channelName, socketId }, callback) => {
      try {
        const { user } = store.getState();

        if (channelName && socketId && user) {
          const { data: pusherChannelsChannelAuthenticationData } = await apiClient.query({
            fetchPolicy: 'network-only',
            query: gql`
              query GetPusherChannelsChannelAuthenticationData(
                $where: PusherChannelsChannelAuthenticationWhereInput!
              ) {
                getPusherChannelsChannelAuthentication(where: $where) {
                  id
                  token
                }
              }
            `,
            variables: {
              where: {
                channel: channelName,
                socketId,
                user: { id: _.get(user, 'id') },
              },
            },
          });

          callback(null, {
            ...(_.get(
              pusherChannelsChannelAuthenticationData,
              'getPusherChannelsChannelAuthentication.token'
            ) || {}),
          });
        } else {
          throw new Error('No Valid User!');
        }
      } catch (err) {
        callback(err, null);
      }
    },
  },
  cluster: REACT_APP_PUSHER_CHANNELS_CLUSTER,
  forceTLS: true,
  userAuthentication: {
    customHandler: async ({ socketId }, callback) => {
      try {
        const { user } = store.getState();

        if (socketId && user) {
          // TODO:
          // const { data: pusherChannelsUserAuthenticationData } = await apiClient.query({
          //   fetchPolicy: 'network-only',
          //   query: gql`
          //     query GetPusherChannelsUserAuthenticationData(
          //       $where: PusherChannelsUserAuthenticationWhereInput!
          //     ) {
          //       getPusherChannelsUserAuthentication(where: $where) {
          //         id
          //         token
          //       }
          //     }
          //   `,
          //   variables: {
          //     where: {
          //       socketId,
          //       user: { id: _.get(user, 'id') },
          //     },
          //   },
          // });

          callback(null, {
            ...(_.get(
              // TODO:
              // pusherChannelsUserAuthenticationData,
              {},
              'getPusherChannelsUserAuthentication.token'
            ) || {}),
          });
        } else {
          throw new Error('No Valid User!');
        }
      } catch (err) {
        callback(err, null);
      }
    },
  },
});

const PusherChannelsContext = React.createContext();

const PusherChannelsProvider = ({ children }) => {
  const initialized = useRef(false);
  const [isConnected, setIsConnected] = useState(false);
  const user = useSelector(selectUser);

  pusherChannelsClient.connection.bind('connected', () => setIsConnected(true));
  pusherChannelsClient.connection.bind('disconnected', () => setIsConnected(false));

  useEffect(() => {
    if (!initialized.current && isConnected && user) {
      pusherChannelsClient.signin();

      initialized.current = true;
    } else if (initialized.current && !user) {
      pusherChannelsClient.disconnect();

      initialized.current = false;
    }
  }, [isConnected, user]);

  return (
    <PusherChannelsContext.Provider
      value={{
        isConnected,
        pusherChannelsClient,
      }}
    >
      {children}
    </PusherChannelsContext.Provider>
  );
};

export const __PusherChannelsContext = PusherChannelsContext;
export default PusherChannelsProvider;
