import React from 'react';
import { __FirebaseMessagingContext } from './FirebaseMessagingProvider';

const useFirebaseMessaging = () => {
  const context = React.useContext(__FirebaseMessagingContext);

  if (context === undefined) {
    throw new Error('useFirebaseMessaging must be used within a FirebaseMessagingProvider');
  }

  return context;
};

export default useFirebaseMessaging;
