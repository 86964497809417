import { gql } from '@apollo/client';
import {
  WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT,
  WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT,
} from './fragments';

export const FETCH_WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueResponseSeverityUpdateDialogData(
    $where: WorkOrderIssueWhereUniqueInput!
  ) {
    workOrderIssue(where: $where) {
      id
      ...WorkOrderIssueResponseSeverityUpdateDialogWorkOrderIssueFragment
    }
  }
  ${WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT}
`;

export const UPDATE_WORK_ORDER_ISSUE_RESPONSE_SEVERITY = gql`
  mutation UpdateWorkOrderIssueResponseSeverity(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueResponseSeverityUpdateInput!
  ) {
    updateWorkOrderIssueResponseSeverity(where: $where, data: $data) {
      id
      ...WorkOrderIssueResponseSeverityUpdateDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
