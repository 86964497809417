import { gql } from '@apollo/client';
import { PROGRAM_CAMPAIGN_VIEW_APP_PROGRAM_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_PROGRAM_CAMPAIGN_VIEW_APP_DATA = gql`
  query FetchProgramCampaignViewAppData($where: ProgramWhereUniqueInput!) {
    program(where: $where) {
      id
      ...ProgramCampaignViewAppProgramFragment
    }
  }
  ${PROGRAM_CAMPAIGN_VIEW_APP_PROGRAM_FRAGMENT}
`;
