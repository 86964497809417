import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch/EntitySearchSelectField';
import { OrganizationHitsListOption } from 'app/shared-components/Organization';
import { ServiceProvisionTagSelectField } from 'app/shared-components/ServiceProvisionTag';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { CREATE_SERVICE_PROVISION } from './queries';

const defaultValues = {
  description: '',
  isPreferred: false,
  name: '',
  tags: [],
  vendor: null,
};

const schema = yup.object().shape({
  description: yup.string().nullable().optional(),
  isPreferred: yup.boolean().required('A preferred state must be provided.'),
  name: yup.string().nullable().required('A name must be provided.'),
  tags: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          __isNew__: yup.boolean().optional(),
          label: yup.string().required(),
          result: yup.object().optional(),
          value: yup.string().required(),
        })
        .required()
    )
    .optional(),
  vendor: yup.object().nullable().required('A vendor must be provided.'),
});

const ServiceProvisionCreateDialog = ({ onClose }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createServiceProvision, { loading: createServiceProvisionLoading }] = useMutation(
    CREATE_SERVICE_PROVISION,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Provision Successfully Created',
            variant: 'success',
          })
        );

        navigate(`/service-provisions/view/${data.createServiceProvision?.id}`);
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Service Provision', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [createServiceProvisionLoading].includes(true),
    [createServiceProvisionLoading]
  );

  const onSubmit = async (data) => {
    try {
      await createServiceProvision({
        variables: {
          data: {
            description: data.description,
            isPreferred: data.isPreferred,
            name: data.name,
            tags: data.tags?.map((tag) => ({
              name: tag.__isNew__ ? tag.value : tag.result?.name,
            })),
            vendor: { id: data.vendor?.hit?.id },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Service Provision
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-provision-create-form"
            name="service-provision-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-1 items-center mb-24">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.name}
                    fullWidth
                    helperText={errors?.name?.message}
                    placeholder="Enter Service Provision Name..."
                    required
                    variant="outlined"
                  />
                )}
              />

              <Controller
                control={control}
                name="isPreferred"
                render={({ field }) => (
                  <FormControlLabel
                    control={<Switch {...field} checked={!!field.value} color="info" />}
                    label="Preferred"
                    labelPlacement="start"
                    sx={{ ml: 3 }}
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.description}
                  fullWidth
                  helperText={errors?.description?.message}
                  maxRows={4}
                  minRows={4}
                  multiline
                  placeholder="Enter Service Provision Description..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <ServiceProvisionTagSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.tags}
                  isClearable
                  isDisabled={false}
                  isMulti
                  placeholder="Select / Create Service Provision Tags..."
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <Controller
              control={control}
              name="vendor"
              render={({ field }) => (
                <EntitySearchSelectField
                  {...field}
                  className="mb-24"
                  components={{ Option: OrganizationHitsListOption }}
                  error={errors?.vendor}
                  filter={[`type.enum = 'VENDOR'`]}
                  idField="id"
                  indexName="organizations"
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Service Provision Vendor..."
                  nameField="name"
                  sort={['name:asc']}
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-provision-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceProvisionCreateDialog;
