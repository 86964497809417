import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_ACTION_ITEM_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const CREATE_WORK_ORDER_ISSUE_ACTION_ITEM = gql`
  mutation CreateWorkOrderIssueActionItem(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueActionItemCreateInput!
  ) {
    createWorkOrderIssueActionItem(where: $where, data: $data) {
      id
      ...WorkOrderIssueActionItemCreateDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_ACTION_ITEM_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_WORK_ORDER_ISSUE_ACTION_ITEM_CREATE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueActionItemCreateDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueActionItemCreateDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_ACTION_ITEM_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
