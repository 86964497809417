import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_COMPLETE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const COMPLETE_WORK_ORDER_ISSUE = gql`
  mutation CompleteWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueCompleteInput!
  ) {
    completeWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueCompleteDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_COMPLETE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_WORK_ORDER_ISSUE_COMPLETE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueCompleteDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueCompleteDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_COMPLETE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
