import { gql } from '@apollo/client';

export const WORK_ORDER_COMMENTS_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderCommentsUpdateDialogServiceTicketFragment on ServiceTicket {
    id
    workOrder {
      id
      comments
    }
  }
`;

export const WORK_ORDER_COMMENTS_UPDATE_DIALOG_WORK_ORDER_FRAGMENT = gql`
  fragment WorkOrderCommentsUpdateDialogWorkOrderFragment on WorkOrder {
    id
    comments
  }
`;
